<template>
  <div
    class="input-group"
    :class="{ 'error-padding': !error && !internalError }"
  >
    <input
      type="text"
      class="form-control"
      :class="{ 'is-invalid': error || internalError }"
      :placeholder="placeholder"
      :value="modelValue"
      @input="onInput"
    />
    <div class="input-group-append">
      <span
        class="input-group-text"
        :class="{ 'input-group-append-invalid': internalError }"
        >дин</span
      >
    </div>
    <small v-if="error" class="invalid-feedback">
      {{ errorMessage }}
    </small>
    <small v-if="internalError" class="invalid-feedback">
      {{ internalErrorMessage }}
    </small>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    modelValue: {
      type: [Number, String],
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    errorMessage: {
      type: String,
      required: false
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["update:modelValue", "input"]
})
export default class PriceInput extends Vue {
  modelValue?: number | string;
  placeholder?: string;
  errorMessage?: string;
  error!: boolean;
  internalError = false;
  internalErrorMessage?: string;

  onInput(event) {
    if (this.validate(event.target.value)) {
      this.clearErrorMessage();
    }
    this.$emit("update:modelValue", event.target.value);
    this.$emit("input");
  }

  validate(value: string) {
    if (value === "") {
      this.internalError = true;
      this.internalErrorMessage = "This field can't be empty";
      return false;
    }
    if (value) {
      if (!this.validateDecimalNumber(value) && !this.validateNumber(value)) {
        this.internalError = true;
        this.internalErrorMessage = "This field need to be number";
        return false;
      }
    }
    return true;
  }

  validateDecimalNumber(value: string): boolean {
    return /^\d+\.\d+$/.test(value);
  }

  validateNumber(value: string): boolean {
    return /^\d+$/.test(value);
  }

  clearErrorMessage(): void {
    this.internalError = false;
    this.internalErrorMessage = undefined;
  }
}
</script>

<style scoped>
.input-group-append-invalid {
  border-color: #d9534f !important;
  background: #d9534f4d;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.error-padding {
  padding-bottom: 23px;
}
</style>
