<template>
  <TenantSellingPlacePage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TenantSellingPlacePage from "@/structure/Administrator/Tenant/pages/TenantSellingPlacePage.vue";

@Options({
  components: { TenantSellingPlacePage }
})
export default class TenantSellingPlace extends Vue {}
</script>
