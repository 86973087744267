import { RouteRecordRaw } from "vue-router";
import SellingPlaces from "@/structure/Administrator/SellingPlaces/views/SellingPlaces.vue";
import PassThroughViewSellingPlaces from "@/structure/Administrator/SellingPlaces/widgets/PassThroughViewSellingPlaces.vue";
import NewSellingPlace from "@/structure/Administrator/SellingPlaces/views/NewSellingPlace.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "selling-places",
        name: "administrator-selling-places",
        component: PassThroughViewSellingPlaces,
        redirect: () => ({
            name: "administrator-selling-places-homepage",
            params: {}
        }),
        children: [
            {
                path: "",
                name: "administrator-selling-places-homepage",
                component: SellingPlaces,
                meta: {
                    title: "Prodajna mesta"
                }
            },
            {
                path: "new",
                name: "administrator-selling-places-new",
                component: NewSellingPlace,
                meta: {
                    title: "Nova"
                }
            }
        ]
    }
];

export default routes;
