<template>
  <Spinner :show-spinner="isDataLoading">
    <router-view></router-view>
  </Spinner>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import AsyncActionFactory from "@/components/AsyncAction";
import MarketApi from "@/modules/market/api";
import { useRoute } from "vue-router";
import { MutationType } from "@/store/mutations";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";

@Options({
  components: { Spinner },
  watch: {
    "$route.params.marketId"(newMarketId) {
      if (newMarketId) {
        this.clearMarket();
        this.loadData(newMarketId);
      }
    }
  }
})
export default class extends Vue {
  store = useStore();
  dataLoaded = false;

  fetchMarket = AsyncActionFactory(MarketApi.fetchMarket);

  async created() {
    await this.loadData(useRoute().params.marketId);
  }

  async loadData(marketId) {
    await this.fetchMarket.call(marketId);

    if (this.fetchMarket.isSuccessful) {
      this.store.commit(MutationType.SetMarket, this.fetchMarket.responseData);
      this.dataLoaded = true;
    }
  }

  clearMarket() {
    this.store.commit(MutationType.SetMarket, null);
  }

  beforeUnmount() {
    this.clearMarket();
  }

  get isDataLoading(): boolean {
    return this.fetchMarket.isBusy || !this.dataLoaded;
  }
}
</script>
