<style>
.draggable {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin: 1rem 0 0 1rem;
  color: #fff;
  padding: 2px;
  text-align: center;
  font-size: 12px;
  touch-action: none;
}
.draggable p {
  margin: 1px;
}
/* The heart of the matter */
.testimonial-group > .row {
  overflow-x: auto;
  white-space: nowrap;
}
.testimonial-group > .row > .draggable {
  display: inline-block;
  float: none;
}
.map {
  min-width: 1200px;
  min-height: 800px;
  border: 1px solid black;
  background-color: lightgrey;
}
</style>
<template>
  <PageLayout>
    <template v-slot:page-breadcrumbs>
      <Breadcrumbs :items="breadcrumbItems" />
    </template>
    <template v-slot:page-name>
      <PageHeaderInfoWidget :header-info="headerInfo" />
    </template>
    <template v-slot:page-widgets>
      <HeaderWidgets />
    </template>

    <div class="row">
      <div class="col-lg">
        <AddressDetailsWidget :address="market.address" />
      </div>
    </div>
    <br />
    <SpinnerWidget :show-spinner="isDataLoading">
      <div class="container testimonial-group">
        <div class="row">
          <div class="map">
            <div
              v-for="(sp, i) in sellingPlaceList.data"
              id="drag-1"
              :key="i"
              class="draggable"
              :data-x="sp.x"
              :data-y="sp.y"
              :data-sp-id="sp.id"
              :style="
                getTransform(sp.x, sp.y) + 'background-color:' + sp.type.color
              "
            >
              <button
                @click="onSellingPlaceSelected(sp.id)"
                class="btn btn-default btn-xs"
              >
                <i class="fa fa-link" style="color: white"></i>
              </button>
              <p>{{ sp.name }}</p>
              <p>{{ sp.category.name }}</p>
              <i :class="sp.type.icon"></i>
            </div>
          </div>
        </div>
      </div>
    </SpinnerWidget>
  </PageLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Cashier/SellingPlace/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo, TabItems } from "@/modules/common/types";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
import { BreadcrumbItems } from "@/modules/common/types";
import getBreadcrumbItems from "../utils/getBreadcrumbItems";
import { useStore } from "@/store";
import HeaderWidgets from "@/structure/Cashier/SellingPlace/widgets/HeaderWidgets.vue";
import { Market } from "@/modules/market/types";
import getTabItems from "@/structure/Cashier/SellingPlace/utils/getTabItems";
import AddressDetailsWidget from "@/modules/common/widgets/AddressDetailsWidget.vue";
import TabBarWidget from "@/modules/common/widgets/TabBarWidget.vue";
import { SellingPlaceList } from "@/modules/selling-place/types";
import { emptySellingPlaceList } from "@/modules/selling-place/types/EmptySellingPlaceList";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import SellingPlaceApi from "@/modules/selling-place/api";
import {
  FetchSellingPlaces,
  FetchSellingPlacesParams
} from "@/modules/selling-place/api/types";
import SpinnerWidget from "@/modules/common/widgets/SpinnerWidget.vue";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    Breadcrumbs,
    HeaderWidgets,
    TabBarWidget,
    AddressDetailsWidget,
    SpinnerWidget
  }
})
export default class SellingPlacesPage extends Vue {
  store = useStore();
  sellingPlaceList: SellingPlaceList = emptySellingPlaceList;
  fetchSellingPlaceList = AsyncActionFactory(
    SellingPlaceApi.fetchSellingPlaces as FetchSellingPlaces
  );

  created() {
    this.loadData();
  }

  async loadData() {
    await this.fetchSellingPlaceList.call({
      marketId: this.market.id,
      page: 1,
      perPage: 99999
    } as FetchSellingPlacesParams);

    if (this.fetchSellingPlaceList.isSuccessful) {
      this.sellingPlaceList = this.fetchSellingPlaceList
        .responseData as SellingPlaceList;
    }
  }

  getTransform(x, y) {
    return "transform: translate(" + x + "px, " + y + "px);";
  }

  async onSellingPlaceSelected(sellingPlaceId) {
    await this.$router.push({
      name: "cashier-selling-place-home",
      params: {
        marketId: this.market.id,
        sellingPlaceId: sellingPlaceId
      }
    });
  }

  get isDataLoading(): boolean {
    return this.fetchSellingPlaceList.isBusy;
  }

  get market(): Market {
    return this.store.getters.market;
  }

  get headerInfo(): HeaderInfo {
    return {
      icon: this.market ? this.market.icon : "ion ion-md-cube",
      section: this.market ? this.market.name : "",
      title: "Info",
      description: this.market ? this.market.description : ""
    };
  }

  get tabItems(): TabItems {
    return getTabItems(this.market);
  }

  get breadcrumbItems(): BreadcrumbItems {
    return getBreadcrumbItems("", this.market);
  }
}
</script>
