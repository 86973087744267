
import { Options, Vue } from "vue-class-component";
import { SellingPlace } from "@/modules/selling-places/types";

@Options({
  props: {
    sellingPlace: {
      type: Object,
      required: true,
      default: {}
    }
  }
})
export default class SellingPlaceListItemWidget extends Vue {
  sellingPlace!: SellingPlace;

  sellingPlaceSelected() {
    this.$router.push({
      name: "administrator-selling-place",
      params: {
        sellingPlaceId: this.sellingPlace.id
      }
    });
  }
}
