import { useStore } from "@/store";
import { MutationType } from "@/store/mutations";
import SettingsApi from "@/modules/settings/api";
import AsyncActionFactory from "@/components/AsyncAction";
import { FetchSettingsParams } from "@/modules/settings/api/types";

export default class SettingsLoader {
  store = useStore();

  fetchSettings = AsyncActionFactory(SettingsApi.fetchSettings);

  dataLoaded = false;

  async load() {
    const fetchSettingsParameter = {
      extendWithSellingPlaceTypes: true,
      extendWithUserRoles: true,
      extendWithSellingPlaceCategories: true
    } as FetchSettingsParams;

    await this.fetchSettings.call(fetchSettingsParameter);

    if (this.fetchSettings.isSuccessful) {
      this.store.commit(
        MutationType.SetSettings,
        this.fetchSettings.responseData
      );
      this.dataLoaded = true;
    }
  }

  clearSettings() {
    this.store.commit(MutationType.SetSettings, null);
  }

  isDataLoaded() {
    return this.dataLoaded;
  }

  isDataLoading() {
    return !this.dataLoaded || this.fetchSettings.isBusy;
  }
}
