<template>
  <div class="form-group">
    <TextInput
      field-name="name"
      label="Naziv"
      v-model="marketRequestData.name"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="description"
      label="Opis"
      v-model="marketRequestData.description"
      :errors="errors"
    />
  </div>

  <div class="form-group">
    <IconSelector v-model:icon="marketRequestData.icon" />
    <small v-if="errors.has('icon')" class="invalid-feedback">
      {{ errors.get("icon") }}
    </small>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TextInput from "@/modules/common/forms/TextInput.vue";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import { PostMarketRequestData } from "../api/types";
import IconSelector from "@/modules/common/forms/IconSelector.vue";

@Options({
  components: { TextInput, IconSelector },
  props: {
    marketRequestData: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  }
})
export default class CreateMarketForm extends Vue {
  marketRequestData!: PostMarketRequestData;
  errors!: BackendValidationErrors;
}
</script>
