<template>
  <span>{{ formattedPercentage }} %</span>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    amount: {
      type: Number,
      required: true
    }
  }
})
export default class Percentage extends Vue {
  amount!: number;

  get formattedPercentage(): number {
    return this.amount / 10000;
  }
}
</script>
