<template>
  <h5>General</h5>
  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Broj
        </div>
        <div>
          {{ contract.number }}
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Status
        </div>
        <div>
          <ContractStatusWidget :status="contract.status" />
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Datum Kreiranja
        </div>
        <div>
          {{ contract.createdAt.toLocaleDateString("nl",{year:"numeric",month:"2-digit", day:"2-digit"}) }}
        </div>
      </div>
      <div
        v-if="contract.canceled"
        class="d-flex align-items-center justify-content-between mb-2"
      >
        <div>
          Razlog Otkazivanja
        </div>
        <div>
          <p>{{ contract.cancellationReason.name }}</p>
          <p>{{ contract.cancellationReason.description }}</p>
        </div>
      </div>
      <div
        v-if="contract.canceled"
        class="d-flex align-items-center justify-content-between mb-2"
      >
        <div>
          Zapis Otkazivanja
        </div>
        <div>
          {{ contract.cancellationNote }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ContractStatusWidget from "@/modules/contracts/widgets/ContractStatusWidget.vue";
import { Contract } from "@/modules/contracts/types/Contract.ts";

@Options({
  components: {
    ContractStatusWidget
  },

  props: {
    contract: {
      type: Object,
      require: true
    }
  }
})
export default class ContractDetailsWidget extends Vue {
  contract?: Contract;

}
</script>
