<template>
  <PageLayout>
    <template v-slot:page-name>
      <PageHeaderInfoWidget :header-info="headerInfo" />
    </template>

    <CreateWorkOrderForm />
  </PageLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/WorkOrders/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import CreateWorkOrderForm from "@/modules/work-orders/forms/CreateWorkOrderForm.vue";

@Options({
  components: { PageLayout, PageHeaderInfoWidget, CreateWorkOrderForm }
})
export default class NewWorkOrder extends Vue {
  headerInfo: HeaderInfo = {
    icon: "ion ion-md-document",
    section: "Radni Nalozi",
    title: "Novi"
  };
}
</script>
