<template>
  <CreateModal
    v-if="displayDialog"
    @close="closeModal()"
    title="Kreiranje ugovora"
    @confirm="createContract()"
    :showSpinner="isDataLoading"
  >
    <ContractForm
      v-model:contract-request-data="contractRequestData"
      :validationErrors="postContractTypeAction.errors"
    />
  </CreateModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";
import CreateModal from "@/modules/common/modals/CreateModal.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import {
  NewPostContractRequestData,
  PostContractResponseData
} from "@/modules/contracts/api/types";
import ContractForm from "@/modules/contracts/form/ContractForm.vue";
import ContractTypeApi from "@/modules/contracts/api";

@Options({
  components: { CreateModal, ContractForm }
})
export default class CreateContractRequestListener extends Vue {
  displayDialog = false;

  contractRequestData: NewPostContractRequestData = this.createEmptyContractRequestData();
  // newPostContractRequestData: NewPostContractRequestData = {
  //     tenantId: '',
  //     sellingPlaceId: '',
  //     rentStartDate: '',
  //     rentEndDate: ''
  // }

  postContractTypeAction = AsyncActionFactory(
    ContractTypeApi.createContractType
  );

  created() {
    eventHub.on("CREATE_CONTRACT_REQUEST", () => {
      this.displayDialog = true;
    });
  }

  async createContract() {
    await this.postContractTypeAction.call(this.contractRequestData);
    if (this.postContractTypeAction.isSuccessful) {
      eventHub.emit(
        "CONTRACT_CREATED",
        (this.postContractTypeAction.responseData as PostContractResponseData)
          .id
      );
      this.closeModal();
    }
  }

  createEmptyContractRequestData(): NewPostContractRequestData {
    return {
      tenantId: null,
      sellingPlaceId: null,
      rentStartDate: "",
      rentEndDate: "",
      paid: false
    };
  }

  closeModal() {
    this.displayDialog = false;
  }

  get isDataLoading(): boolean {
    return this.postContractTypeAction.isBusy;
  }
}
</script>

<style scoped></style>
