import { BreadcrumbItem, BreadcrumbItems } from "@/modules/common/types";

function createHomeItem(): BreadcrumbItem {
  return {
    name: "Početna",
    link: {
      name: "cashier-dashboard"
    },
    icon: "ion ion-md-home"
  };
}

function createModuleItem(moduleName: string): BreadcrumbItem {
  return {
    name: moduleName
  } as BreadcrumbItem;
}

const getBreadcrumbItems = function(
  moduleName?: string,
  moduleBreadcrumbItems?: BreadcrumbItems
) {
  const items: BreadcrumbItems = [];

  items.push(createHomeItem());

  if (moduleBreadcrumbItems) {
    moduleBreadcrumbItems.forEach(function(breadcrumbItem: BreadcrumbItem) {
      items.push(breadcrumbItem);
    });
  }

  if (moduleName) {
    items.push(createModuleItem(moduleName));
  }

  return items;
};

export default getBreadcrumbItems;
