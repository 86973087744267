import {
  HttpClientInstance,
  HttpMethod,
  HttpClientRequestConfig,
  HttpClientDefaultHeaders,
  HttpClientDataPromise,
  HttpClientResponse,
  HttpClientDefaultHeaderValue
} from "./types";

/**
 * Thin wrapper around the lib used to make ajax requests
 */
export class HttpClient {
  /**
   * ClientDetails used to make the actual http requests.
   */
  clientInstance: HttpClientInstance;

  /**
   * These headers will be added to each request. The object can contain static values or
   * callbacks that will be run on each request and the return value will be used as a header value.
   */
  defaultHeaders: HttpClientDefaultHeaders = {};

  constructor(client: HttpClientInstance) {
    this.clientInstance = client;
  }

  /**
   * Adds { key: value } pair to the headers
   * @param name   Header name
   * @param value  Header value, a callback that should return the header value, or null (header will not be sent)
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addDefaultHeader(name: string, value: HttpClientDefaultHeaderValue) {
    this.defaultHeaders[name] = value;
  }

  /**
   * Remove a single header item
   * @param key
   */
  removeDefaultHeader(key: string) {
    delete this.defaultHeaders[key];
  }

  /**
   * Evaluates defaultHeaders, merges them with the given options and builds the request config object
   * @param options
   * @return HttpClientRequestConfig
   */
  protected evaluateDefaultHeaders(options: HttpClientRequestConfig) {
    if (Object.keys(this.defaultHeaders).length === 0) {
      // no headers to add
      return options;
    }

    // clone the data
    const reqOptions = { ...options };
    const headers = { ...reqOptions.headers };

    // add all headers
    Object.keys(this.defaultHeaders).forEach(key => {
      const value = this.defaultHeaders[key];

      // if it's a callback run it, otherwise just use the val itself
      const headerValue = typeof value === "function" ? value() : value;

      // nulls are skipped
      if (headerValue !== null) {
        headers[key] = headerValue;
      }
    });

    // update the options
    reqOptions.headers = headers;
    return reqOptions;
  }

  /**
   * Make a request, all http calls go through this method
   * @param method
   * @param url
   * @param options
   * @return HttpClientDataPromise
   */
  request(
    method: HttpMethod,
    url: string,
    options: HttpClientRequestConfig
  ): HttpClientDataPromise {
    const reqOptions = this.evaluateDefaultHeaders(options);

    return this.clientInstance({
      method,
      url,
      ...reqOptions
      /**
       * return just data from ApiResponse, ApiResponse contains message, data and errors if present
       * for now we return response.data.data in order to keep all pages working
       * todo: If we return data like this we lose message, this should be fixed
       */
    }).then((response: HttpClientResponse) => {
      return response.data.data;
    });
  }

  /**
   * Make GET request
   * @param url
   * @param payload
   * @param options
   * @return HttpClientDataPromise
   */
  get(url: string, payload = {}, options: HttpClientRequestConfig = {}) {
    return this.request("get", url, {
      params: { ...payload },
      ...options
    });
  }

  /**
   * Make POST request
   * @param url
   * @param payload
   * @param options
   * @return HttpClientDataPromise
   */
  post(url: string, payload: {}, options: HttpClientRequestConfig = {}) {
    return this.request("post", url, {
      data: payload,
      ...options
    });
  }

  /**
   * Make PUT request
   * @param url
   * @param payload
   * @param options
   * @return HttpClientDataPromise
   */
  put(url: string, payload: {}, options: HttpClientRequestConfig = {}) {
    return this.request("put", url, {
      data: payload,
      ...options
    });
  }

  /**
   * Make PATCH request
   * @param url
   * @param payload
   * @param options
   * @return HttpClientDataPromise
   */
  patch(url: string, payload: {}, options: HttpClientRequestConfig = {}) {
    return this.request("patch", url, {
      data: payload,
      ...options
    });
  }

  /**
   * Make DELETE request
   * @param url
   * @param payload
   * @param options
   * @return HttpClientDataPromise
   */
  delete(
    url: string,
    payload: {} | null = null,
    options: HttpClientRequestConfig = {}
  ) {
    const config = { ...options };

    if (payload !== null) {
      config.data = payload;
    }

    return this.request("delete", url, { ...config });
  }

  /**
   * Make UPLOAD request
   * @param url
   * @param formData
   * @param options
   * @param onUploadProgressCallback
   * @return HttpClientDataPromise
   */
  upload(
    url: string,
    formData: FormData,
    options: Partial<HttpClientRequestConfig> = {},
    onUploadProgressCallback?: (any) => void
  ) {
    if (onUploadProgressCallback) {
      options.onUploadProgress = onUploadProgressCallback;
    }

    return this.post(url, formData, {
      ...options,
      headers: {
        "Content-Type": "multipart/form-data",
        ...options.headers
      }
    });
  }
}
