<template>
  <ContractPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ContractPage from "@/structure/Administrator/Contracts/pages/ContractPage.vue";

@Options({
  components: { ContractPage }
})
export default class Contract extends Vue {}
</script>
