import { RouteRecordRaw } from "vue-router";
import PassThroughViewTransactionDetails from "../widgets/PassThroughViewTransactionDetails.vue";
import Transaction from "@/structure/Administrator/Transactions/views/TransactionDetails.vue";

const transactionRoutes: Array<RouteRecordRaw> = [
  {
    path: "transactions/:transactionId",
    name: "administrator-transaction",
    component: PassThroughViewTransactionDetails,
    redirect: to => ({
      name: "administrator-transaction-details",
      params: {
        transactionId: to.params.transactionId
      }
    }),
    children: [
      {
        path: "",
        name: "administrator-transaction-details",
        component: Transaction,
        meta: {
          title: ""
        }
      }
    ]
  }
];

export default transactionRoutes;
