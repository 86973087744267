
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    showSpinner: {
      required: true,
      default: false
    }
  }
})
export default class extends Vue {
  showSpinner!: boolean;
}
