<template>
  <Spinner :show-spinner="isDataLoading">
    <div class="selling-place-type-list">
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item"
          v-for="sellingPlaceType in sellingPlaceTypes"
          :key="sellingPlaceType.id"
        >
          <SellingPlaceTypeListItemWidget
            :selling-place-type="sellingPlaceType"
          />
        </li>
      </ul>
    </div>
  </Spinner>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import { inject } from "vue";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import SellingPlaceTypeListItemWidget from "./SellingPlaceTypeListItemWidget.vue";
import {
  FetchSellingPlaceType,
  FetchSellingPlaceTypes
} from "@/modules/selling-place-type/api/types";
import eventHub from "@/components/EventHub";
import SellingPlaceTypeCreatedEvent from "@/modules/selling-place-type/events/SellingPlaceTypeCreatedEvent";
import SellingPlaceTypeDeletedEvent from "@/modules/selling-place-type/events/SellingPlaceTypeDeletedEvent";
import SellingPlaceTypeUpdatedEvent from "@/modules/selling-place-type/events/SellingPlaceTypeUpdatedEvent";
import {
  SellingPlaceType,
  SellingPlaceTypeList
} from "@/modules/selling-place-type/types";

@Options({
  components: { Spinner, SellingPlaceTypeListItemWidget }
})
export default class SellingPlaceTypeListWidget extends Vue {
  sellingPlaceTypes: Array<SellingPlaceType> = [];
  filteredSellingPlaceTypes: Array<SellingPlaceType> = [];
  dataLoaded = false;

  fetchSellingPlaceTypes = AsyncActionFactory(
    inject("fetchSellingPlaceTypes") as FetchSellingPlaceTypes
  );

  fetchSellingPlaceType = AsyncActionFactory(
    inject("fetchSellingPlaceType") as FetchSellingPlaceType
  );

  created() {
    this.loadData();
    eventHub.on<SellingPlaceTypeUpdatedEvent>(
      SellingPlaceTypeUpdatedEvent.Name,
      this.onSellingPlaceTypeEdited
    );
    eventHub.on<SellingPlaceTypeDeletedEvent>(
      SellingPlaceTypeDeletedEvent.Name,
      this.onSellingPlaceTypeDeleted
    );
    eventHub.on<SellingPlaceTypeCreatedEvent>(
      SellingPlaceTypeCreatedEvent.Name,
      this.onSellingPlaceTypeCreated
    );
  }

  onSellingPlaceTypeCreated(event?: SellingPlaceTypeCreatedEvent) {
    if (!(event instanceof SellingPlaceTypeCreatedEvent)) {
      return;
    }

    this.addSellingPlaceTypeToList(event.getSellingPlaceType());
  }

  addSellingPlaceTypeToList(sellingPlaceType: SellingPlaceType) {
    this.sellingPlaceTypes.push(sellingPlaceType);
  }

  async loadData() {
    await this.fetchSellingPlaceTypes.call();

    if (this.fetchSellingPlaceTypes.isSuccessful) {
      this.sellingPlaceTypes = (this.fetchSellingPlaceTypes
        .responseData as SellingPlaceTypeList).data;
      this.dataLoaded = true;
    }
  }

  onSellingPlaceTypeEdited(event?: SellingPlaceTypeUpdatedEvent) {
    if (!(event instanceof SellingPlaceTypeUpdatedEvent)) {
      return;
    }

    this.replaceSellingPlaceTypeInList(event.getSellingPlaceType());
  }

  onSellingPlaceTypeDeleted(event?: SellingPlaceTypeDeletedEvent) {
    if (!(event instanceof SellingPlaceTypeDeletedEvent)) {
      return;
    }

    this.removeSellingPlaceTypeFromList(event.getSellingPlaceType());
  }

  replaceSellingPlaceTypeInList(sellingPlaceType: SellingPlaceType) {
    this.sellingPlaceTypes.forEach((item, i) => {
      if (item.id === sellingPlaceType.id) {
        this.sellingPlaceTypes[i] = sellingPlaceType;
      }
    });
  }

  removeSellingPlaceTypeFromList(sellingPlaceType: SellingPlaceType) {
    this.sellingPlaceTypes = this.sellingPlaceTypes.filter(
      sellingPlaceTypeItem => sellingPlaceTypeItem.id !== sellingPlaceType.id
    );
    this.filteredSellingPlaceTypes = this.sellingPlaceTypes;
  }

  get isDataLoading(): boolean {
    return this.fetchSellingPlaceTypes.isBusy || !this.dataLoaded;
  }
}
</script>
