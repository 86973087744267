<template>
  <div class="form-group">
    <TextInput
      field-name="name"
      label="Naziv"
      v-model="sellingPlaceType.name"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="description"
      label="Opis"
      v-model="sellingPlaceType.description"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <IconSelector v-model:icon="sellingPlaceType.icon" />
    <small v-if="errors.has('icon')" class="invalid-feedback">
      {{ errors.get("icon") }}
    </small>
  </div>
  <div class="form-group">
    <select class="form-control" v-model="sellingPlaceType.color">
      <option value="blue">Plava</option>
      <option value="red">Crvena</option>
      <option value="green">Zelena</option>
      <option value="orange">Narandzasta</option>
      <option value="black">Crna</option>
    </select>
  </div>
  <div class="form-group">
    <TextInput
      field-name="description"
      label="Zona prodaje"
      v-model="sellingPlaceType.sellingArea"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="description"
      label="Član 4 - Primaoce se zabranjuje izlaganje i vrsenje prodaje svih drugih proizvoda sem"
      v-model="sellingPlaceType.sellingBan"
      :errors="errors"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TextInput from "@/modules/common/forms/TextInput.vue";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import { NewSellingPlaceTypeRequestData } from "../api/types";
import IconSelector from "@/modules/common/forms/IconSelector.vue";

@Options({
  components: { TextInput, IconSelector },
  props: {
    sellingPlaceType: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  }
})
export default class SellingPlaceTypeForm extends Vue {
  sellingPlaceType!: NewSellingPlaceTypeRequestData;
  errors!: BackendValidationErrors;
}
</script>
