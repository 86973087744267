
import { Options, Vue } from "vue-class-component";
import { CashierReport } from "@/modules/user/types/CashierReport";

@Options({
  props: {
    cashierReport: {
      type: Object,
      required: true,
      default: {}
    }
  }
})
export default class CashierReportListItemWidget extends Vue {
  cashierReport!: CashierReport;
}
