<template>
  <PageLayout>
    <template v-slot:page-name>
      <PageHeaderInfoWidget :header-info="headerInfo" />
    </template>
    <MarketListWidget @market-selected="onMarketSelected($event)" />
  </PageLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Cashier/Dashboard/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import MarketListWidget from "@/modules/market/widgets/MarketListWidget/MarketListWidget.vue";
import { provide } from "vue";
import MarketApi from "@/modules/market/api";
import { FetchCashierMarkets } from "@/modules/market/api/types";

@Options({
  components: { PageLayout, PageHeaderInfoWidget, MarketListWidget }
})
export default class extends Vue {
  headerInfo: HeaderInfo = {
    icon: "ion ion-md-home",
    section: "Početna"
  };

  created() {
    provide(
      "fetchCashierMarkets",
      MarketApi.fetchCashierMarkets.bind(MarketApi) as FetchCashierMarkets
    );
  }

  async onMarketSelected(marketId: string) {
    await this.$router.push({
      name: "cashier-selling-places",
      params: {
        marketId: marketId
      }
    });
  }
}
</script>
