<template>
  <label class="form-label">Kategorije prodajnih mesta</label>
  <select
    class="form-control form-control"
    v-model="categoryItem"
    @change="selectCategory()"
  >
    <option value="">Sve</option>
    <option
      v-for="sellingPlaceCategory in categories"
      :value="sellingPlaceCategory.id"
      :key="sellingPlaceCategory"
      >{{ sellingPlaceCategory.name }}
    </option>
  </select>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import { SellingPlaceCategoryList } from "@/modules/selling-place-category/types";

@Options({
  props: {
    sellingPlaceCategoryId: {
      type: String,
      required: false,
      default: ""
    }
  },
  emits: ["updateCategory"]
})
export default class SellingPlaceCategorySelector extends Vue {
  sellingPlaceCategoryId!: string;

  store = useStore();
  categoryItem = this.sellingPlaceCategoryId;

  get categories(): SellingPlaceCategoryList {
    return this.store.getters.settings.sellingPlaceCategories;
  }

  selectCategory() {
    this.$emit("updateCategory", this.categoryItem);
  }
}
</script>
