
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/SellingPlaces/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
import AddressDetailsWidget from "@/modules/common/widgets/AddressDetailsWidget.vue";
import TenantDetailsWidget from "@/modules/tenant/widgets/TenantDetailsWidget.vue";
import { BreadcrumbItems } from "@/modules/common/types";
import getBreadcrumbItems from "../utils/getBreadcrumbItems";
import { SellingPlace } from "@/modules/selling-places/types";
import { useStore } from "@/store";
import HeaderWidgetsSellingPlace from "@/structure/Administrator/SellingPlaces/widgets/HeaderWidgetsSellingPlace.vue";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    Breadcrumbs,
    TenantDetailsWidget,
    AddressDetailsWidget,
    HeaderWidgetsSellingPlace
  }
})
export default class SellingPlaceDetailsPage extends Vue {
  store = useStore();

  get sellingPlace(): SellingPlace {
    return this.store.getters.sellingPlace;
  }

  get headerInfo(): HeaderInfo {
    return {
      icon: "ion ion-md-document",
      section: this.sellingPlace ? this.sellingPlace.name : ""
    };
  }

  get breadcrumbItems(): BreadcrumbItems {
    return getBreadcrumbItems("Detalji", this.sellingPlace);
  }
}
