import { GetterTree } from "vuex";
import { State } from "@/store/state";
import { Settings } from "@/modules/settings/types";
import { User } from "@/modules/user/types";
import { Market, MarketList } from "@/modules/market/types";
import { Tenant } from "@/modules/tenant/types";
import { SellingPlace } from "@/modules/selling-place/types";
import { Contract, ContractList, ContractCancelReasons } from "@/modules/contracts/types";
import { WorkOrder, WorkOrderList } from "@/modules/work-orders/types";
import { Transaction, TransactionList } from "@/modules/transactions/types";

export type Getters = {
  settings(state: State);
  user(state: State);
  marketList(state: State);
  tenant(state: State);
  market(state: State);
  sellingPlace(state: State);
  contractList(state: State);
  contract(state: State);
  workOrderList(state: State);
  workOrder(state: State);
  transactionList(state: State);
  transaction(state: State);
  contractCancelReasons(state: State);
};

export const getters: GetterTree<State, State> & Getters = {
  settings(state: State): Settings | null {
    return state.settings;
  },
  user(state: State): User | null {
    return state.user;
  },
  marketList(state: State): MarketList | null {
    return state.marketList;
  },
  tenant(state: State): Tenant | null {
    return state.tenant;
  },
  market(state: State): Market | null {
    return state.market;
  },
  sellingPlace(state: State): SellingPlace | null {
    return state.sellingPlace;
  },
  contract(state: State): Contract | null {
    return state.contract;
  },
  contractList(state: State): ContractList | null {
    return state.contractList;
  },
  workOrder(state: State): WorkOrder | null {
    return state.workOrder;
  },
  workOrderList(state: State): WorkOrderList | null {
    return state.workOrderList;
  },
  transaction(state: State): Transaction | null {
    return state.transaction;
  },
  transactionList(state: State): TransactionList | null {
    return state.transactionList;
  },
  contractCancelReasons(state: State): ContractCancelReasons | null {
    return state.contractCancelReasons;
  }

};
