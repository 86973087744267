<template>
  <div class="form-group">
    <TextInput
      v-model="address.line1"
      fieldName="line1"
      placeholder="Ulica i broj"
      label="Adresa 1"
      :errors="validationErrors"
    />
  </div>
  <div class="form-group">
    <TextInput
      v-model="address.line2"
      fieldName="line2"
      placeholder="intefon, sprat,..."
      label="Adresa 2"
      :errors="validationErrors"
    />
  </div>
  <div class="form-group">
    <TextInput
      v-model="address.postalCode"
      fieldName="postalCode"
      placeholder="Poštanski Broj"
      label="Poštanski Broj"
      :errors="validationErrors"
    />
  </div>
  <div class="form-group">
    <TextInput
      v-model="address.city"
      fieldName="city"
      placeholder="Grad"
      label="Grad"
      :errors="validationErrors"
    />
  </div>
  <div class="form-group">
    <TextInput
      v-model="address.state"
      fieldName="state"
      placeholder="Provincija"
      label="Provincija"
      :errors="validationErrors"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Address } from "@/modules/common/types";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import TextInput from "@/modules/common/forms/TextInput.vue";

@Options({
  components: { TextInput },
  props: {
    address: {},
    validationErrors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  }
})
export default class AddressForm extends Vue {
  address!: Address;
  validationErrors!: BackendValidationErrors;
}
</script>
