
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";
import { useStore } from "@/store";
import { Contract } from "@/modules/contracts/types";

@Options({})
export default class ContractDetailsHeaderWidgets extends Vue {
  store = useStore();

  get contract(): Contract {
    return this.store.getters.contract;
  }

  editContract() {
    // console.log('edit contract', this.contract.id)
    eventHub.emit("CONTRACT_EDIT_REQUESTED", this.contract.id);
  }
}
