<template>
  <h5>Adresa</h5>
  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Adresa 1
        </div>
        <div>
          {{ line1 }}
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Adresa 2
        </div>
        <div>
          {{ line2 }}
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Grad
        </div>
        <div>
          {{ city }}
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Poštanski Broj
        </div>
        <div>
          {{ postalCode }}
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Zemlja
        </div>
        <div>
          {{ country }}
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Provincija
        </div>
        <div>
          {{ state }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Address } from "../types";

@Options({
  props: {
    address: {
      type: Object,
      required: true
    }
  }
})
export default class AddressDetailsWidget extends Vue {
  address!: Address;

  get line1(): string {
    if (!this.address.line1) return "-";

    return this.address.line1;
  }

  get line2(): string {
    if (!this.address.line2) return "-";

    return this.address.line2;
  }

  get city(): string {
    if (!this.address.city) return "-";

    return this.address.city;
  }

  get postalCode(): string {
    if (!this.address.postalCode) return "-";

    return this.address.postalCode;
  }

  get country(): string {
    if (!this.address.country) return "-";

    return this.address.country;
  }

  get state(): string {
    if (!this.address.state) return "-";

    return this.address.state;
  }
}
</script>
