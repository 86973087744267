<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Naziv
        </div>
        <div>
          {{ workOrder.name }}
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Datum
        </div>
        <div>
          {{ formattedDate }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { formatDate } from "@/modules/localisation/helpers/date-helpers";
import { WorkOrder } from "@/modules/work-orders/types";

@Options({
  props: {
    workOrder: {
      required: true
    }
  }
})
export default class WorkOrderDetailsWidget extends Vue {
  workOrder!: WorkOrder;

  get formattedDate(): string {
    return formatDate(this.workOrder.date);
  }
}
</script>
