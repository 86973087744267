<template>
  <Spinner :show-spinner="isDataLoading">
    <router-view></router-view>
  </Spinner>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import SidebarLayout from "@/modules/common/layouts/SidebarLayout.vue";
import { useStore } from "@/store";
import AsyncActionFactory from "@/components/AsyncAction";
import SellingPlaceApi from "@/modules/selling-place/api";
import { useRoute } from "vue-router";
import { MutationType } from "@/store/mutations";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import { FetchSellingPlaceParams } from "@/modules/selling-place/api/types";

@Options({
  components: { SidebarLayout, Spinner },
  watch: {
    "$route.params.sellingPlaceId"(newSellingPlaceId) {
      if (newSellingPlaceId) {
        this.clearSellingPlace();
        this.loadData(this.$route.params.marketId, newSellingPlaceId);
      }
    },
    "$route.params.marketId"(newMarketId) {
      if (newMarketId) {
        this.clearSellingPlace();
        this.loadData(newMarketId, this.$route.params.sellingPlaceId);
      }
    }
  }
})
export default class extends Vue {
  store = useStore();

  fetchSellingPlace = AsyncActionFactory(SellingPlaceApi.fetchSellingPlace);

  async created() {
    await this.loadData(
      this.store.getters.market.id,
      useRoute().params.sellingPlaceId
    );
  }

  async loadData(marketId, sellingPlaceId) {
    await this.fetchSellingPlace.call({
      marketId: marketId,
      sellingPlaceId: sellingPlaceId
    } as FetchSellingPlaceParams);

    if (this.fetchSellingPlace.isSuccessful) {
      this.store.commit(
        MutationType.SetSellingPlace,
        this.fetchSellingPlace.responseData
      );
    }
  }

  clearSellingPlace() {
    this.store.commit(MutationType.SetSellingPlace, null);
  }

  beforeUnmount() {
    this.clearSellingPlace();
  }

  get isDataLoading(): boolean {
    return this.fetchSellingPlace.isBusy;
  }
}
</script>
