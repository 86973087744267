<template>
  <BaseNavigationWidget :navigation-links="navigationLinks" />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import BaseNavigationWidget from "@/modules/common/widgets/NavigationWidget.vue";
import { Links } from "@/modules/common/types";
import getNavigationLinks from "@/structure/Cashier/common/utils/getNavigationLinks";
import { useStore } from "@/store";
import { MarketList } from "@/modules/market/types";

@Options({
  components: { BaseNavigationWidget }
})
export default class NavigationWidget extends Vue {
  store = useStore();

  get marketList(): MarketList {
    return this.store.getters.marketList;
  }
  get navigationLinks(): Links {
    return getNavigationLinks(this.marketList);
  }
}
</script>
