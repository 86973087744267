
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Reports/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo, TabItems } from "@/modules/common/types";
import TabBarWidget from "@/modules/common/widgets/TabBarWidget.vue";
import getTabItems from "@/structure/Administrator/Reports/utils/getTabItems";
import CashierReportsListWidget from "@/modules/user/widgets/UserList/CashierReportsListWidget.vue";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    TabBarWidget,
    CashierReportsListWidget
  }
})
export default class ReportsPage extends Vue {
  get headerInfo(): HeaderInfo {
    return {
      icon: "ion ion-md-document",
      section: "Izveštaji Inkasanata",
      description: ""
    };
  }

  get tabItems(): TabItems {
    return getTabItems();
  }
}
