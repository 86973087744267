import { PostSellingPlaceCategoryResponse } from "./types";
import {
  SellingPlaceCategory,
  SellingPlaceCategoryList
} from "@/modules/selling-place-category/types";

export function sellingPlaceCategoryMapper(
  sellingPlaceCategoryData
): SellingPlaceCategory {
  const sellingPlaceCategory = new SellingPlaceCategory();

  sellingPlaceCategory.id = sellingPlaceCategoryData.id;
  sellingPlaceCategory.name = sellingPlaceCategoryData.name;
  sellingPlaceCategory.description = sellingPlaceCategoryData.description;
  sellingPlaceCategory.icon = sellingPlaceCategoryData.icon;
  sellingPlaceCategory.removable = sellingPlaceCategoryData.removable;

  return sellingPlaceCategory;
}

export function sellingPlaceCategoryListMapper(
  sellingPlaceCategories
): SellingPlaceCategoryList {
  sellingPlaceCategories.data.map(sellingPlaceCategory =>
    sellingPlaceCategoryMapper(sellingPlaceCategory)
  );
  return sellingPlaceCategories;
}

export function postSellingPlaceCategoryResponseMapper(
  response
): PostSellingPlaceCategoryResponse {
  return {
    ...response
  };
}
