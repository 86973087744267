<template>
  <EditModal
    v-if="displayDialog"
    @close="closeModal()"
    title="Izmena Zakupca"
    @confirm="editTenantAddress()"
    :showSpinner="isDataLoading"
  >
    <AddressForm
      :address="tenantAddress"
      :validation-errors="editTenantAction.errors"
    />
  </EditModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";
import { FetchTenant, EditTenantRequestData } from "@/modules/tenant/api/types";
import AsyncActionFactory from "@/components/AsyncAction";
import TenantApi from "@/modules/tenant/api";
import EditModal from "@/modules/common/modals/EditModal.vue";
import EditTenantDetailsForm from "../forms/EditTenantDetailsForm.vue";
import { Address } from "@/modules/common/types";
import AddressForm from "@/modules/common/forms/AddressForm.vue";
import { Tenant } from "@/modules/tenant/types/Tenant";

@Options({
  components: { EditModal, EditTenantDetailsForm, AddressForm }
})
export default class EditTenantAddressRequestListener extends Vue {
  tenant!: Tenant;
  tenantAddress!: Address;
  displayDialog = false;

  editTenantAction = AsyncActionFactory(TenantApi.editTenant);
  fetchTenant = AsyncActionFactory(TenantApi.fetchTenant as FetchTenant);

  async created() {
    eventHub.on(
      "EDIT_TENANT_ADDRESS_REQUESTED",
      this.onEditTenantAddressRequested
    );
  }

  async onEditTenantAddressRequested(tenantId) {
    await this.fetchTenant.call(tenantId);

    if (this.fetchTenant.isSuccessful) {
      this.tenant = this.fetchTenant.responseData as Tenant;
      this.tenantAddress = { ...this.tenant.address };
      this.displayDialog = true;
    }
  }

  async editTenantAddress() {
    await this.editTenantAction.call(this.tenant.id, {
      address: this.tenantAddress
    } as EditTenantRequestData);

    if (this.editTenantAction.isSuccessful) {
      eventHub.emit("TENANT_EDITED", this.tenant.id);
      this.closeModal();
    }
  }

  closeModal() {
    this.editTenantAction.clear();
    this.displayDialog = false;
  }

  get isDataLoading(): boolean {
    return this.editTenantAction.isBusy || this.fetchTenant.isBusy;
  }
}
</script>
