<template>
  <Spinner :show-spinner="isDataLoading">
    <div class="contract-cancellation-reason-list">
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item"
          v-for="contractCancellationReason in contractCancellationReasons"
          :key="contractCancellationReason.id"
        >
          <ContractCancellationReasonListItemWidget
            :contract-cancellation-reason="contractCancellationReason"
          />
        </li>
      </ul>
    </div>
  </Spinner>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import { inject } from "vue";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import ContractCancellationReasonListItemWidget from "@/modules/contracts-cancellation-reason/widgets/ContractCancellationReasonListItemWidget.vue";
import {
  FetchContractCancellationReason,
  FetchContractCancellationReasons
} from "@/modules/contracts-cancellation-reason/api/types";
import eventHub from "@/components/EventHub";
import ContractCancellationReasonCreatedEvent from "@/modules/contracts-cancellation-reason/events/ContractCancellationReasonCreatedEvent";
import ContractCancellationReasonDeletedEvent from "@/modules/contracts-cancellation-reason/events/ContractCancellationReasonDeletedEvent";
import ContractCancellationReasonUpdatedEvent from "@/modules/contracts-cancellation-reason/events/ContractCancellationReasonUpdatedEvent";
import {
  ContractCancellationReason,
  ContractCancellationReasonList
} from "@/modules/contracts-cancellation-reason/types";

@Options({
  components: { Spinner, ContractCancellationReasonListItemWidget }
})
export default class ContractCancellationReasonListWidget extends Vue {
  contractCancellationReasons: Array<ContractCancellationReason> = [];
  filteredContractCancellationReasons: Array<ContractCancellationReason> = [];
  dataLoaded = false;

  fetchContractCancellationReasons = AsyncActionFactory(
    inject(
      "fetchContractCancellationReasonList"
    ) as FetchContractCancellationReasons
  );

  fetchContractCancellationReason = AsyncActionFactory(
    inject("fetchContractCancellationReason") as FetchContractCancellationReason
  );

  created() {
    this.loadData();
    eventHub.on<ContractCancellationReasonCreatedEvent>(
      ContractCancellationReasonCreatedEvent.Name,
      this.onContractCancellationReasonCreated
    );
    eventHub.on<ContractCancellationReasonUpdatedEvent>(
      ContractCancellationReasonUpdatedEvent.Name,
      this.onContractCancellationReasonEdited
    );
    eventHub.on<ContractCancellationReasonDeletedEvent>(
      ContractCancellationReasonDeletedEvent.Name,
      this.onContractCancellationReasonDeleted
    );
  }

  onContractCancellationReasonCreated(
    event?: ContractCancellationReasonCreatedEvent
  ) {
    if (!(event instanceof ContractCancellationReasonCreatedEvent)) {
      return;
    }

    this.addContractCancellationReasonToList(
      event.getContractCancellationReason()
    );
  }

  onContractCancellationReasonEdited(
    event?: ContractCancellationReasonUpdatedEvent
  ) {
    if (!(event instanceof ContractCancellationReasonUpdatedEvent)) {
      return;
    }

    this.replaceContractCancellationReason(
      event.getContractCancellationReason()
    );
  }

  onContractCancellationReasonDeleted(
    event?: ContractCancellationReasonDeletedEvent
  ) {
    if (!(event instanceof ContractCancellationReasonDeletedEvent)) {
      return;
    }
    this.removeContractCancellationReasonFromList(
      event.getContractCancellationReason()
    );
  }

  async replaceContractCancellationReason(
    contractCancellationReason: ContractCancellationReason
  ) {
    this.contractCancellationReasons.forEach((item, i) => {
      if (item.id == contractCancellationReason.id) {
        this.contractCancellationReasons[i] = contractCancellationReason;
      }
    });
  }

  replaceContractCancellationReasonInList(
    contractCancellationReason: ContractCancellationReason
  ) {
    this.contractCancellationReasons.forEach((item, i) => {
      if (item.id === contractCancellationReason.id) {
        this.contractCancellationReasons[i] = contractCancellationReason;
      }
    });
  }

  addContractCancellationReasonToList(
    contractCancellationReason: ContractCancellationReason
  ) {
    this.contractCancellationReasons.push(contractCancellationReason);
  }

  removeContractCancellationReasonFromList(
    contractCancellationReason: ContractCancellationReason
  ) {
    this.contractCancellationReasons = this.contractCancellationReasons.filter(
      contractCancellationReasonItem => {
        return (
          contractCancellationReasonItem.id !== contractCancellationReason.id
        );
      }
    );
    this.filteredContractCancellationReasons = this.contractCancellationReasons;
  }

  async loadData() {
    await this.fetchContractCancellationReasons.call();
    if (this.fetchContractCancellationReasons.isSuccessful) {
      this.contractCancellationReasons = (this.fetchContractCancellationReasons
        .responseData as ContractCancellationReasonList).data;
      this.dataLoaded = true;
    }
  }

  get isDataLoading(): boolean {
    return !this.dataLoaded;
  }
}
</script>
