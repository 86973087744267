
import { Options, Vue } from "vue-class-component";
import ContractStatusSelector from "@/modules/contracts/widgets/ContractStatusSelector.vue";
import { ContractsFilter } from "@/modules/contracts/api/types";

@Options({
  props: {
    filter: {
      types: Object,
      required: true
    }
  },
  components: {
    ContractStatusSelector
  },
  emits: ["search"]
})
export default class ContractFilterWidget extends Vue {
  filter!: ContractsFilter;

  onSearch() {
    this.$emit("search", this.filter);
  }
}
