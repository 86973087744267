
import { Options, Vue } from "vue-class-component";
import { ContractStatus } from "@/modules/contracts/types";

@Options({
  props: {
    status: {
      type: String,
      required: false,
      default: ""
    }
  },
  emits: ["update:status"]
})
export default class ContractStatusSelector extends Vue {
  status!: string;
  statusModel = this.status;

  statues: Array<{ value: string; label: string }> = [];

  created() {
    this.statues.push({ value: "", label: "All" });
    for (const status in ContractStatus) {
      this.statues.push({
        value: ContractStatus[status] as string,
        label: ContractStatus[status] as string
      });
    }
  }

  onChange(event) {
    this.$emit("update:status", event.target.value);
  }
}
