
import { inject } from "vue";
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import ListWidget from "@/modules/common/widgets/ListWidget";
import ContractListItemWidget from "@/modules/contracts/widgets/ContractList/ContractListItemWidget.vue";
import { ContractList } from "@/modules/contracts/types";
import { emptyContractList } from "@/modules/contracts/types/EmptyContractList";
import { useStore } from "@/store";
import { Tenant } from "@/modules/tenant/types";
import eventHub from "@/components/EventHub";
import ContractFilterWidget from "@/modules/contracts/widgets/ContractFilterWidget.vue";
import {
  ContractsFilter,
  FetchContracts,
  FetchContractsParams,
  FetchContractCancelReasons, FetchContractCancelReasonsParams
} from "@/modules/contracts/api/types";
import { MutationType } from "@/store/mutations";

@Options({
  props: {
    hideCreateButton: {
      required: false,
      type: Boolean,
      default: false
    },
    hideFilter: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  components: {
    Spinner,
    ListWidget,
    ContractListItemWidget,
    ContractFilterWidget
  }
})
export default class ContractListWidget extends Vue {
  contractList: ContractList = emptyContractList;
  page = 1;
  hideCreateButton!: boolean;
  hideFilter!: boolean;
  store = useStore();

  filter: ContractsFilter = {
    status: ""
  };

  get tenant(): Tenant | null {
    return this.store.getters.tenant;
  }

  fetchContractsAction = AsyncActionFactory(
    inject("fetchContracts") as FetchContracts
  );

  fetchContractCancelReasonsAction = AsyncActionFactory(
    inject("fetchContractCancelReasons") as FetchContractCancelReasons
  );

  created() {
    this.loadData();
    this.loadContractCancelReasonsData();
  }

  async loadData() {
    const requestParam = {
      page: this.page,
      perPage: 30,
      tenant: !this.tenant ? undefined : this.tenant.id,
      status: !this.filter.status ? undefined : this.filter.status
    } as FetchContractsParams;
    await this.fetchContractsAction.call(requestParam);

    if (this.fetchContractsAction.isSuccessful) {
      this.contractList = this.fetchContractsAction
        .responseData as ContractList;
    }
  }

  async loadContractCancelReasonsData() {
    const requestParam = {
      perPage: -1
    } as FetchContractCancelReasonsParams;
    await this.fetchContractCancelReasonsAction.call(requestParam);

    if (this.fetchContractCancelReasonsAction.isSuccessful) {

      this.store.commit(
        MutationType.SetContractCancelReasons,
        this.fetchContractCancelReasonsAction.responseData
      );
    }
  }

  get isDataLoading(): boolean {
    return this.fetchContractsAction.isBusy;
  }

  onPaginate(page) {
    this.page = page;
    this.loadData();
  }

  onContractSelected(contractId: string) {
    eventHub.emit("CONTRACT_VIEW_REQUESTED", contractId);
  }

  async onSearch(filter) {
    this.filter = filter;
    await this.loadData();
  }

  newContract() {
    eventHub.emit("CREATE_CONTRACT_REQUEST");
  }
}
