import eventHub from "@/components/EventHub";
import { useRouter } from "@/components/Router";

export default class ContractViewRequestedEventListener {
  constructor() {
    eventHub.on("CONTRACT_VIEW_REQUESTED", this.onContractViewRequested);
  }

  async onContractViewRequested(contractId) {
    await useRouter().push({
      name: "administrator-contract",
      params: {
        contractId: contractId
      }
    });
  }
}
