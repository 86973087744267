<template>
  <PageLayout>
    <template v-slot:page-name>
      <PageHeaderInfoWidget :header-info="headerInfo" />
    </template>
    <template v-slot:page-widgets>
      <HeaderWidgets />
    </template>
    <WorkOrderListWidget @work-order-selected="onWorkOrderSelected($event)" />
  </PageLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/WorkOrders/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import HeaderWidgets from "../widgets/HeaderWidgets.vue";
import WorkOrderListWidget from "@/modules/work-orders/widgets/WorkOrderListWidget.vue";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    HeaderWidgets,
    WorkOrderListWidget
  }
})
export default class WorkOrdersPage extends Vue {
  headerInfo: HeaderInfo = {
    icon: "ion ion-md-document",
    section: "Radni Nalozi"
  };

  async onWorkOrderSelected(workOrderId: string) {
    await this.$router.push({
      name: "administrator-work-order",
      params: {
        workOrderId: workOrderId
      }
    });
  }
}
</script>
