import { PostSellingPlaceTypeResponse } from "./types";
import SellingPlaceType from "@/modules/selling-place-type/types/SellingPlaceType";
import { SellingPlaceTypeList } from "@/modules/selling-place-type/types/SellingPlaceTypeList";

export function sellingPlaceTypeMapper(sellingPlaceTypeData): SellingPlaceType {
  const sellingPlaceType = new SellingPlaceType();

  sellingPlaceType.id = sellingPlaceTypeData.id;
  sellingPlaceType.name = sellingPlaceTypeData.name;
  sellingPlaceType.description = sellingPlaceTypeData.description;
  sellingPlaceType.icon = sellingPlaceTypeData.icon;
  sellingPlaceType.removable = sellingPlaceTypeData.removable;
  sellingPlaceType.sellingArea = sellingPlaceTypeData.sellingArea;
  sellingPlaceType.sellingBan = sellingPlaceTypeData.sellingBan;
  sellingPlaceType.color = sellingPlaceTypeData.color;

  return sellingPlaceType;
}

export function sellingPlaceTypeListMapper(
  sellingPlaceTypes
): SellingPlaceTypeList {
  sellingPlaceTypes.data.map(sellingPlaceType =>
    sellingPlaceTypeMapper(sellingPlaceType)
  );
  return sellingPlaceTypes;
}

export function postSellingPlaceTypeResponseMapper(
  response
): PostSellingPlaceTypeResponse {
  return {
    ...response
  };
}
