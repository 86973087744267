<template>
  <CreateModal
    v-if="displayDialog"
    @close="closeModal()"
    title="Novi Razlog Otkazivanja"
    @confirm="createContractCancellationReason()"
    :showSpinner="isDataLoading"
  >
    <ContractCancellationForm
      v-model:contract-cancellation-reason="contractCancellationData"
      :errors="postContractCancellationReasonAction.errors"
    />
  </CreateModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";
import CreateModal from "@/modules/common/modals/CreateModal.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import ContractCancellationApi from "@/modules/contracts-cancellation-reason/api";
import ContractCancellationForm from "@/modules/contracts-cancellation-reason/forms/ContractCancellationReasonForm.vue";
import {
  FetchContractCancellationReason,
  NewContractCancellationReasonRequestData,
  PostContractCancellationReasonResponse
} from "../api/types";
import ContractCancellationReasonNewRequestedEvent from "@/modules/contracts-cancellation-reason/events/ContractCancellationReasonNewRequestedEvent";
import ContractCancellationReasonCreatedEvent from "@/modules/contracts-cancellation-reason/events/ContractCancellationReasonCreatedEvent";

@Options({
  components: { CreateModal, ContractCancellationForm }
})
export default class ContractCancellationReasonCreateRequestedListener extends Vue {
  displayDialog = false;
  contractCancellationData: NewContractCancellationReasonRequestData = this.createEmptyContractCancellationReasonRequestData();

  postContractCancellationReasonAction = AsyncActionFactory(
    ContractCancellationApi.createContractCancellationReason
  );

  fetchContractCancellationReason = AsyncActionFactory(
    ContractCancellationApi.fetchContractCancellationReason as FetchContractCancellationReason
  );

  created() {
    eventHub.on<ContractCancellationReasonNewRequestedEvent>(
      ContractCancellationReasonNewRequestedEvent.Name,
      this.onContractCancellationReasonNewRequested
    );
  }

  onContractCancellationReasonNewRequested(
    event?: ContractCancellationReasonNewRequestedEvent
  ) {
    if (!(event instanceof ContractCancellationReasonNewRequestedEvent)) {
      return;
    }
    this.displayDialog = true;
  }

  closeModal() {
    this.displayDialog = false;
  }

  async createContractCancellationReason() {
    await this.postContractCancellationReasonAction.call(
      this.contractCancellationData
    );

    if (this.postContractCancellationReasonAction.isSuccessful) {
      await this.emitContractCancellationReason(
        (this.postContractCancellationReasonAction
          .responseData as PostContractCancellationReasonResponse).id
      );
      this.closeModal();
    }
  }

  async emitContractCancellationReason(contractCancellationReasonId) {
    const contractCancellationReason = await this.fetchContractCancellationReason.call(
      contractCancellationReasonId
    );

    if (this.fetchContractCancellationReason.isSuccessful) {
      eventHub.emit<ContractCancellationReasonCreatedEvent>(
        ContractCancellationReasonCreatedEvent.Name,
        new ContractCancellationReasonCreatedEvent(contractCancellationReason)
      );
    }
  }

  createEmptyContractCancellationReasonRequestData(): NewContractCancellationReasonRequestData {
    return {
      name: "",
      description: "",
      icon: ""
    };
  }

  get isDataLoading(): boolean {
    return this.postContractCancellationReasonAction.isBusy;
  }
}
</script>
