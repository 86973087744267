import ApiClient from "@/components/ApiClient/ApiClient";

import {
  PasswordChangeRequestData,
  PasswordChange,
  UserDetailsRequestData,
  UpdateUserDetails
} from "./types";

class Api {
  public client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  changePassword: PasswordChange = (
    requestData: PasswordChangeRequestData
  ): Promise<void> => {
    const url = "profile/change-password";
    return this.client.post(url, requestData);
  };

  updateUserDetails: UpdateUserDetails = (
    requestData: UserDetailsRequestData
  ): Promise<void> => {
    const url = "profile/change-details";
    return this.client.post(url, requestData);
  };
}

export default Api;
