
import { Options, Vue } from "vue-class-component";
import ConfirmDeleteModal from "@/modules/common/modals/ConfirmDeleteModal.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import ContractCancellationReasonApi from "@/modules/contracts-cancellation-reason/api";
import eventHub from "@/components/EventHub";
import ContractCancellationReasonDeleteRequestedEvent from "@/modules/contracts-cancellation-reason/events/ContractCancellationReasonDeleteRequestedEvent";
import ContractCancellationReasonDeletedEvent from "@/modules/contracts-cancellation-reason/events/ContractCancellationReasonDeletedEvent";
import { ContractCancellationReason } from "@/modules/contracts-cancellation-reason/types";
@Options({
  components: { ConfirmDeleteModal }
})
export default class ContractCancellationReasonDeleteRequestedListener extends Vue {
  contractCancellationReason!: ContractCancellationReason;

  displayDialog = false;

  deleteContractCancellationReasonAction = AsyncActionFactory(
    ContractCancellationReasonApi.deleteContractCancellationReason
  );

  created() {
    eventHub.on<ContractCancellationReasonDeleteRequestedEvent>(
      ContractCancellationReasonDeleteRequestedEvent.Name,
      this.onContractCancellationReasonDeleteRequested
    );
  }

  onContractCancellationReasonDeleteRequested(
    event?: ContractCancellationReasonDeleteRequestedEvent
  ) {
    if (!(event instanceof ContractCancellationReasonDeleteRequestedEvent)) {
      return;
    }

    this.contractCancellationReason = event.getContractCancellationReason();
    this.displayDialog = true;
  }

  async deleteContractCancellationReason() {
    await this.deleteContractCancellationReasonAction.call(
      this.contractCancellationReason.id
    );

    if (this.deleteContractCancellationReasonAction.isSuccessful) {
      eventHub.emit<ContractCancellationReasonDeletedEvent>(
        ContractCancellationReasonDeletedEvent.Name,
        new ContractCancellationReasonDeletedEvent(
          this.contractCancellationReason
        )
      );
      this.closeDeleteModal();
    }
  }

  closeDeleteModal() {
    this.displayDialog = false;
  }

  get isDataLoading(): boolean {
    return this.deleteContractCancellationReasonAction.isBusy;
  }
}
