
import { Options, Vue } from "vue-class-component";
import { HeaderInfo } from "@/modules/common/types";

@Options({
  props: {
    headerInfo: {
      type: Object,
      required: true
    }
  }
})
export default class extends Vue {
  headerInfo!: HeaderInfo;
}
