<template>
  <PageLayout>
    <template v-slot:page-breadcrumbs>
      <Breadcrumbs :items="breadcrumbItems" />
    </template>
    <template v-slot:page-name>
      <PageHeaderInfoWidget :header-info="headerInfo" />
    </template>
    <template v-slot:page-widgets> </template>
    <template v-slot:default>
      <p>{{ transaction.title }}</p>

      <h3>Informacije o Transakciji</h3>
      <div class="row">
        <div class="col-12">
          <TransactionDetailsWidget :transaction="transaction" />
        </div>
      </div>

      <br />

      <h3>Informacije o Inkasantu</h3>
      <div class="row">
        <div class="col-6">
          <TenantDetailsWidget :tenant="transaction.cashier" />
        </div>
        <div class="col-6">
          <AddressDetailsWidget :address="transaction.cashier.address" />
        </div>
      </div>

      <br />

      <h3>informacije o Zakupcu</h3>
      <div v-if="transaction.tenant" class="row">
        <div class="col-6">
          <TenantDetailsWidget :tenant="transaction.tenant" />
        </div>
        <div class="col-6">
          <AddressDetailsWidget :address="transaction.tenant.address" />
        </div>
      </div>
      <div v-else class="row">
        <div class="col-6">
          <h6>Zakupac se nije pojavio, pijačarina je plaćena od strane drugog lica.</h6>
        </div>
      </div>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Contracts/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
import AddressDetailsWidget from "@/modules/common/widgets/AddressDetailsWidget.vue";
import TenantDetailsWidget from "@/modules/tenant/widgets/TenantDetailsWidget.vue";
import TransactionDetailsWidget from "@/modules/transactions/widgets/TransactionDetailsWidget.vue";
import { BreadcrumbItems } from "@/modules/common/types";
import getBreadcrumbItems from "../utils/getBreadcrumbItems";
import { Transaction } from "@/modules/transactions/types";
import { useStore } from "@/store";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    Breadcrumbs,
    TenantDetailsWidget,
    AddressDetailsWidget,
    TransactionDetailsWidget
  }
})
export default class TransactionDetailsPage extends Vue {
  store = useStore();

  get transaction(): Transaction {
    return this.store.getters.transaction;
  }

  get headerInfo(): HeaderInfo {
    return {
      icon: "ion ion-md-document",
      section: this.transaction ? this.transaction.name : ""
    };
  }

  get breadcrumbItems(): BreadcrumbItems {
    return getBreadcrumbItems("Detalji", this.transaction);
  }
}
</script>
