<template>
  <div class="btn-group">
    <button
      type="button"
      class="btn btn-outline-primary"
      @click="editSellingPlaceDetails()"
    >
      <i class="ion ion-md-create mr-2"></i>Izmeni
    </button>
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-outline-primary dropdown-toggle"
        data-toggle="dropdown"
      ></button>
      <div class="dropdown-menu">
        <a
          class="dropdown-item"
          href="javascript:void(0)"
          @click="deleteSellingPlace()"
        >
          <i class="fas fa-trash-alt"></i> Obriši
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import { useStore } from "@/store";
import { SellingPlace } from "@/modules/selling-places/types";
import eventHub from "@/components/EventHub";

export default class HeaderWidgetsSellingPlace extends Vue {
  store = useStore();

  get sellingPlace(): SellingPlace {
    return this.store.getters.sellingPlace;
  }

  editSellingPlaceDetails(): void {
    eventHub.emit("EDIT_SELLING_PLACE_DETAILS_REQUESTED", this.sellingPlace.id);
  }

  deleteSellingPlace(): void {
    // alert("Nema brisanja");
    eventHub.emit("DELETE_SELLING_PLACE_REQUESTED", this.sellingPlace);
  }
}
</script>
