
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Settings/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import UserListWidget from "@/modules/user/widgets/UserList/UserListWidget.vue";
import UsersPageHeaderWidgets from "@/structure/Administrator/Settings/widgets/UsersPageHeaderWidgets.vue";
import { $t } from "@/language";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    UserListWidget,
    UsersPageHeaderWidgets
  }
})
export default class UsersPage extends Vue {
  headerInfo: HeaderInfo = {
    icon: "ion ion-md-settings",
    section: "Podešavanja",
    title: "Korisnik"
  };
}
