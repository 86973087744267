
import { Options, Vue } from "vue-class-component";
import SellingPlaceCategoryListWidget from "@/modules/selling-place-category/widgets/SellingPlaceCategoryList/SellingPlaceCategoryListWidget.vue";
import eventHub from "@/components/EventHub";
import SellingPlaceCategoryNewRequestedEvent from "@/modules/selling-place-category/events/SellingPlaceCategoryNewRequestedEvent";

@Options({
  components: {
    SellingPlaceCategoryListWidget
  }
})
export default class SellingPlaceCategoriesWidget extends Vue {
  newSellingPlaceCategory() {
    eventHub.emit<SellingPlaceCategoryNewRequestedEvent>(
      SellingPlaceCategoryNewRequestedEvent.Name,
      new SellingPlaceCategoryNewRequestedEvent()
    );
  }
}
