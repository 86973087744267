
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import AsyncActionFactory from "@/components/AsyncAction";
import TenantApi from "@/modules/tenant/api";
import { MutationType } from "@/store/mutations";
import { useRoute } from "vue-router";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";

@Options({
  components: { Spinner },
  watch: {
    "$route.params.tenantId"(newTenantId) {
      if (newTenantId) {
        this.clearTenantList();
        this.loadData(newTenantId);
      }
    }
  }
})
export default class PassThroughViewTenant extends Vue {
  store = useStore();

  fetchTenant = AsyncActionFactory(TenantApi.fetchTenant);

  async created() {
    await this.loadData(useRoute().params.tenantId);
  }

  async loadData(id) {
    await this.fetchTenant.call(id);

    if (this.fetchTenant.isSuccessful) {
      this.store.commit(MutationType.SetTenant, this.fetchTenant.responseData);
    }
  }

  clearTenantList() {
    this.store.commit(MutationType.SetTenant, null);
  }

  beforeUnmount() {
    this.clearTenantList();
  }

  get isDataLoading(): boolean {
    return this.fetchTenant.isBusy;
  }
}
