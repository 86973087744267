
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import AsyncActionFactory from "@/components/AsyncAction";
import MarketApi from "@/modules/market/api";
import { useRoute } from "vue-router";
import { MutationType } from "@/store/mutations";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import eventHub from "@/components/EventHub";

@Options({
  components: { Spinner },
  watch: {
    "$route.params.marketId"(newMarketId) {
      if (newMarketId) {
        this.clearMarket();
        this.loadData(newMarketId);
      }
    }
  }
})
export default class extends Vue {
  store = useStore();

  fetchMarket = AsyncActionFactory(MarketApi.fetchMarket);

  dataLoaded = false;

  async created() {
    await this.loadData(useRoute().params.marketId);
    eventHub.on("MARKET_EDITED", this.onMarketEdited);
    eventHub.on("MARKET_DELETED", this.onMarketDeleted);
  }

  async onMarketEdited(marketId) {
    if (this.store.getters.market.id !== marketId) return;

    await this.loadData(marketId);
  }

  onMarketDeleted() {
    this.$router.push({
      name: "administrator-markets"
    });
  }

  async loadData(marketId) {
    await this.fetchMarket.call(marketId);

    if (this.fetchMarket.isSuccessful) {
      this.store.commit(MutationType.SetMarket, this.fetchMarket.responseData);
      this.dataLoaded = true;
    }
  }

  clearMarket() {
    this.store.commit(MutationType.SetMarket, null);
  }

  beforeUnmount() {
    this.clearMarket();
  }

  get isDataLoading(): boolean {
    return this.fetchMarket.isBusy || !this.dataLoaded;
  }
}
