import { SellingPlaceType } from "@/modules/selling-place-type/types";

export default class SellingPlaceTypeDeletedEvent {
  static readonly Name = "SELLING_PLACE_TYPE_DELETED";

  private readonly sellingPlaceType: SellingPlaceType;

  constructor(sellingPlaceType: SellingPlaceType) {
    this.sellingPlaceType = sellingPlaceType;
  }
  getSellingPlaceType(): SellingPlaceType {
    return this.sellingPlaceType;
  }
}
