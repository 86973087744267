import { PostContractCancellationReasonResponse } from "./types";
import {
  ContractCancellationReason,
  ContractCancellationReasonList
} from "@/modules/contracts-cancellation-reason/types";

export function contractCancellationReasonMapper(
  contractCancellationReasonData
): ContractCancellationReason {
  const contractCancellationReason = new ContractCancellationReason();

  contractCancellationReason.id = contractCancellationReasonData.id;
  contractCancellationReason.name = contractCancellationReasonData.name;
  contractCancellationReason.description =
    contractCancellationReasonData.description;
  contractCancellationReason.icon = contractCancellationReasonData.icon;
  contractCancellationReason.removable =
    contractCancellationReasonData.removable;

  return contractCancellationReason;
}

export function contractCancellationReasonListMapper(
  contractsCancellationReasons
): ContractCancellationReasonList {
  contractsCancellationReasons.data.map(contractCancellationReason =>
    contractCancellationReasonMapper(contractCancellationReason)
  );
  return contractsCancellationReasons;
}

export function postContractCancellationReasonResponseMapper(
  response
): PostContractCancellationReasonResponse {
  return {
    ...response
  };
}
