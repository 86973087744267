<template>
  <NewTransactionPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import NewTransactionPage from "@/structure/Administrator/Transactions/pages/NewTransactionPage.vue";

@Options({
  components: { NewTransactionPage }
})
export default class NewTransaction extends Vue {}
</script>
