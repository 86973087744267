export class ContractCancellationReasonEditRequestedEvent {
  static readonly Name = "CONTRACT_CANCELLATION_REASON_EDIT_REQUESTED";

  private readonly id: string;

  constructor(id: string) {
    this.id = id;
  }

  getId(): string {
    return this.id;
  }
}
