import ApiClient from "@/components/ApiClient/ApiClient";

import {
  FetchSettings,
  FetchSettingsParams,
  PatchSettings,
  RemoveSettingsLogo
} from "@/modules/settings/api/types";

import { settingsResponseMapper } from "@/modules/settings/api/mappers";
import { Settings } from "@/modules/settings/types";

class Api {
  public client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  fetchSettings: FetchSettings = (
    params: FetchSettingsParams
  ): Promise<Settings> => {
    const url = `settings`;

    const extend: Array<string> = [];
    if (params.extendWithSellingPlaceTypes) {
      extend.push("sellingPlaceTypes");
    }
    if (params.extendWithUserRoles) {
      extend.push("userRoles");
    }
    if (params.extendWithSellingPlaceCategories) {
      extend.push("sellingPlaceCategories");
    }

    const payload: { extend?: Array<string> } = {};

    if (extend.length) {
      payload.extend = extend;
    }

    return this.client.get(url, payload).then(response => {
      return settingsResponseMapper(response);
    });
  };

  patchSettings: PatchSettings = (requestData: FormData): Promise<void> => {
    const url = `settings`;
    return this.client.post(url, requestData, { params: { _method: "PATCH" } });
  };

  removeSettingsLogo: RemoveSettingsLogo = (): Promise<void> => {
    const url = `settings/logo`;
    return this.client.delete(url);
  };
}

export default Api;
