<template>
  <div class="d-flex align-items-center">
    <template v-for="iconItem in icons" :key="iconItem">
      <div
        class="icon cursor-pointer"
        :class="{ 'icon-active': iconItem === iconModel }"
        @click="selectIcon(iconItem)"
      >
        <i :class="iconItem"></i>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    icon: {
      type: String,
      required: false,
      default: ""
    }
  },
  emits: ["update:icon"]
})
export default class IconSelector extends Vue {
  icon!: string;
  iconModel = this.icon;
  // This should be pass from parent form component
  icons: Array<string> = [
    "fas fa-cube",
    "fas fa-store",
    "fas fa-store-alt",
    "fas fa-coffee",
    "fas fa-ice-cream"
  ];

  created() {
    if (!this.iconModel) {
      this.iconModel = this.icons[0];
      this.$emit("update:icon", this.icons[0]);
    }
  }

  selectIcon(icon) {
    this.$emit("update:icon", icon);
    this.iconModel = icon;
  }
}
</script>

<style scoped lang="scss">
.icon {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em;
  margin-right: 1em;
  border: 1px solid transparent;
  border-radius: 10px;

  &:last-child {
    margin-right: 0;
  }

  &.icon-active {
    border: 1px solid gray;
  }
}
</style>
