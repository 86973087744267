<template>
  <ContractsHomePage />
</template>

<script lang="ts">
import { provide } from "vue";
import { Options, Vue } from "vue-class-component";
import ContractsHomePage from "@/structure/Administrator/Contracts/pages/ContractsHomePage.vue";
import ContractApi from "@/modules/contracts/api";
import {
  FetchContractCancelReasons,
  FetchContracts
} from "@/modules/contracts/api/types";

@Options({
  components: { ContractsHomePage }
})
export default class Contracts extends Vue {
  created() {
    provide(
      "fetchContracts",
      ContractApi.fetchContracts.bind(ContractApi) as FetchContracts
    );
    provide(
      "fetchContractCancelReasons",
      ContractApi.fetchContractCancelReasons.bind(
        ContractApi
      ) as FetchContractCancelReasons
    );
  }
}
</script>
