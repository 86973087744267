import { ContractCancellationReason } from "@/modules/contracts-cancellation-reason/types";

export default class ContractCancellationReasonCreatedEvent {
  static readonly Name = "CONTRACT_CANCELLATION_REASON_CREATED";

  private readonly contractCancellationReason: ContractCancellationReason;

  constructor(contractCancellationReason: ContractCancellationReason) {
    this.contractCancellationReason = contractCancellationReason;
  }

  getContractCancellationReason(): ContractCancellationReason {
    return this.contractCancellationReason;
  }
}
