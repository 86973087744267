import { RouteRecordRaw } from "vue-router";
import PassThroughViewDashboard from "./widgets/PassThroughViewDashboard.vue";
import Dashboard from "@/structure/Administrator/Dashboard/views/Dashboard.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "dashboard",
    name: "administrator-dashboard",
    component: PassThroughViewDashboard,
    redirect: () => ({
      name: "administrator-dashboard-homepage"
    }),
    children: [
      {
        path: "",
        name: "administrator-dashboard-homepage",
        component: Dashboard,
        meta: {
          title: "Dashboard"
        }
      }
    ]
  }
];

export default routes;
