import ApiClient from "@/components/ApiClient/ApiClient";

import {
  FetchTransaction,
  FetchTodayTransaction,
  FetchTransactionParams,
  FetchTransactions,
  FetchTransactionsParams,
  PostTransaction,
  PostTransactionRequestData,
  PostTransactionResponse
} from "./types";

import { transactionListMapper, transactionMapper } from "./mappers";
import { Transaction, TransactionList } from "@/modules/transactions/types";

class Api {
  public client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  fetchTransactions: FetchTransactions = (
    params: FetchTransactionsParams
  ): Promise<TransactionList> => {
    const url = "transactions";

    return this.client.get(url, params).then(transactionList => {
      return transactionListMapper(transactionList);
    });
  };

  fetchTransaction: FetchTransaction = (
    id: string,
    params?: FetchTransactionParams
  ): Promise<Transaction> => {
    const url = `transactions/${id}`;

    return this.client.get(url, params).then(transaction => {
      return transactionMapper(transaction);
    });
  };

  fetchTodayTransaction: FetchTodayTransaction = (
    sellingPlaceId: string
  ): Promise<Transaction> => {
    const url = `transactions/selling-place/${sellingPlaceId}`;

    return this.client.get(url).then(transaction => {
      return transactionMapper(transaction);
    });
  };

  postTransaction: PostTransaction = (
    params: PostTransactionRequestData
  ): Promise<PostTransactionResponse> => {
    const url = `transactions/daily`;

    return this.client.post(url, params).then(response => {
      return response;
    });
  };
}

export default Api;
