<template>
  <div
    class="card p-3 mb-3"
    style="display: flex; flex-direction: row; align-items: center;"
  >
    <!--    <div class="media align-items-center">-->
    <!--      <img-->
    <!--        src="@/assets/company.png"-->
    <!--        class="d-block ui-w-40 rounded-circle mr-3"-->
    <!--        alt=""-->
    <!--      />-->
    <!--    </div>-->
    <div class="media-body flex-basis-auto">
      <div>
        <strong @click="workOrderSelected()" class="cursor-pointer">
          {{ workOrder.name }}
        </strong>
      </div>
    </div>

    <div>
      <button
        @click="workOrderSelected()"
        class="btn btn-outline-secondary btn-sm"
      >
        Detalji
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { WorkOrder } from "@/modules/work-orders/types";

@Options({
  props: {
    workOrder: {
      type: Object,
      required: true,
      default: {}
    }
  },
  emits: ["workOrderSelected"]
})
export default class WorkOrderListItemWidget extends Vue {
  workOrder!: WorkOrder;

  workOrderSelected() {
    this.$emit("workOrderSelected", this.workOrder.id);
  }
}
</script>
