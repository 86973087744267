import { createApp } from "vue";
import App from "./App.vue";
import router from "@/components/Router";
import { i18n } from "./language";
import { store } from "@/store";
import "./assets/assets";
import { toast } from "@/components/toast";

// Github: "https://github.com/matfish2/vue-pagination-2/tree/vue3"
import Pagination from "v-pagination-3";

import Nl2br from "@/modules/localisation/widget/Nl2br.vue";
import Money from "@/modules/localisation/widget/Money.vue";
import DateFormatter from "@/modules/localisation/widget/DateFormatter.vue";
import Percentage from "@/modules/localisation/widget/Percentage.vue";
import showErrorNotification from "@/components/Errors/utils/showErrorNotification";

const $t = i18n.global.t.bind(i18n); // create alias function $t() and you can now use it as usual

const app = createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  .use(toast)
  .component("Pagination", Pagination)
  .component("nl2br", Nl2br)
  .component("money", Money)
  .component("date", DateFormatter)
  .component("percentage", Percentage);

const defaultErrorMessage = $t("errors.general.unexpected") as string;

app.config.errorHandler = (err, vm, info) => {
  showErrorNotification(err as Error, defaultErrorMessage);
};

app.mount("#app");
