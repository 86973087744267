
import { Options, Vue } from "vue-class-component";
import { UserRole } from "@/modules/user/types";

@Options({
  props: {
    role: {
      type: String,
      required: false,
      default: ""
    }
  },
  emits: ["updateRole"]
})
export default class RoleSelector extends Vue {
  role!: string;
  roles: Array<string> = [];
  roleItem = this.role;

  created() {
    for (const role in UserRole) {
      this.roles.push(role);
    }
  }

  getValue(key) {
    return UserRole[key];
  }

  selectRole() {
    this.$emit("updateRole", this.roleItem);
  }
}
