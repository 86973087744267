
import { Options, Vue } from "vue-class-component";
import { UserRole, User } from "@/modules/user/types";
import eventHub from "@/components/EventHub";
import UserDeleteRequestedEvent from "@/modules/user/events/UserDeleteRequestedEvent";
import UserEditRequestedEvent from "@/modules/user/events/UserEditRequestedEvent";
import UserPasswordEditRequestedEvent from "@/modules/user/events/UserPasswordEditRequestedEvent";

@Options({
  props: {
    user: {
      type: Object,
      required: true,
      default: {}
    }
  }
})
export default class UserListItemWidget extends Vue {
  user!: User;

  isEditHovering = false;
  isDeleteHovering = false;
  isChangePasswordHovering = false;

  editUser() {
    eventHub.emit<UserEditRequestedEvent>(
      UserEditRequestedEvent.Name,
      new UserEditRequestedEvent(this.user.id)
    );
  }

  deleteUser() {
    eventHub.emit<UserDeleteRequestedEvent>(
      UserDeleteRequestedEvent.Name,
      new UserDeleteRequestedEvent(this.user)
    );
  }

  changeUserPassword() {
    eventHub.emit<UserPasswordEditRequestedEvent>(
      UserPasswordEditRequestedEvent.Name,
      new UserPasswordEditRequestedEvent(this.user.id)
    );
  }

  badgeClass(): string {
    if (!this.user.active) {
      return "badge-outline-secondary";
    }
    if (this.user.roles.indexOf(UserRole.SuperAdmin) != -1) {
      return "badge-outline-danger";
    }
    if (this.user.roles.indexOf(UserRole.Admin) != -1) {
      return "badge-outline-warning";
    }
    if (this.user.roles.indexOf(UserRole.Cashier) != -1) {
      return "badge-outline-primary";
    }
    if (this.user.roles.indexOf(UserRole.Tenant) != -1) {
      return "badge-outline-success";
    }
    if (this.user.roles.indexOf(UserRole.Clerk) != -1) {
      return "badge-outline-info";
    }
    return "";
  }
}
