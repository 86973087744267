<template>
  <FilterWidget @filter-changed="onFilter($event)" />
  <div class="tenant-list">
    <ul class="list-group list-group-flush">
      <li
        class="list-group-item cursor-pointer"
        v-for="tenant in tenants"
        :key="tenant"
        :class="{ active: isTenantSelected(tenant) }"
        @click="selectTenant(tenant)"
      >
        <div class="media align-items-center">
          <div>
            <i class="fas fa-user"></i>
          </div>
          <div class="media-body px-2">
            <span class="text-body"
              >{{ tenant.lastName }} {{ tenant.firstName }}</span
            >
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Tenant } from "@/modules/tenant/types";
import FilterWidget from "@/modules/tenant/widgets/TenantsSidebar/FilterWidget.vue";

@Options({
  components: { FilterWidget },
  props: {
    tenants: {
      type: Array,
      required: true
    }
  },
  emits: ["tenantSelected", "filterChanged"]
})
export default class extends Vue {
  tenants: Array<Tenant> = [];

  selectTenant(tenant: Tenant) {
    this.$emit("tenantSelected", tenant.id);
  }

  onFilter(filter) {
    this.$emit("filterChanged", filter);
  }

  isTenantSelected(tenant: Tenant): boolean {
    return this.$route.params.tenantId == tenant.id;
  }
}
</script>

<style scoped>
.tenant-list {
  overflow: auto;
  height: 100%;
}
</style>
