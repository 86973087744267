
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import { useRoute } from "vue-router";
import { MutationType } from "@/store/mutations";
import AsyncActionFactory from "@/components/AsyncAction";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import WorkOrderApi from "@/modules/work-orders/api";
import eventHub from "@/components/EventHub";

@Options({
  components: { Spinner },
  watch: {
    "$route.params.workOrderId"(newWorkOrderId) {
      if (newWorkOrderId) {
        this.clearWorkOrder();
        this.loadData(newWorkOrderId);
      }
    }
  }
})
export default class extends Vue {
  store = useStore();

  fetchWorkOrder = AsyncActionFactory(WorkOrderApi.fetchWorkOrder);

  dataLoaded = false;

  async created() {
    await this.loadData(useRoute().params.workOrderId);
    eventHub.on("WORK_ORDER_DELETED", this.onWorkOrderDeleted);
  }

  async loadData(workOrderId) {
    await this.fetchWorkOrder.call(workOrderId);

    if (this.fetchWorkOrder.isSuccessful) {
      this.store.commit(
        MutationType.SetWorkOrder,
        this.fetchWorkOrder.responseData
      );
      this.dataLoaded = true;
    }
  }

  clearWorkOrder() {
    this.store.commit(MutationType.SetWorkOrder, null);
  }

  beforeUnmount() {
    this.clearWorkOrder();
  }

  get isDataLoading(): boolean {
    return this.fetchWorkOrder.isBusy || !this.dataLoaded;
  }

  async onWorkOrderDeleted(workOrderId) {
    if (this.store.getters.workOrder.id !== workOrderId) return;

    await this.$router.push({
      name: "administrator-work-orders"
    });
  }
}
