
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";
import CreateModal from "@/modules/common/modals/CreateModal.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import MarketApi from "@/modules/market/api";
import {
  PostMarketRequestData,
  PostMarketResponse
} from "@/modules/market/api/types";
import CreateMarketForm from "@/modules/market/forms/CreateMarketForm.vue";

@Options({
  components: { CreateModal, CreateMarketForm }
})
export default class CreateMarketRequestListener extends Vue {
  displayDialog = false;
  marketRequestData: PostMarketRequestData = this.createEmptyMarketRequestData();

  postMarket = AsyncActionFactory(MarketApi.postMarket);

  created() {
    eventHub.on("CREATE_MARKET_REQUEST", () => {
      this.displayDialog = true;
    });
  }

  closeModal() {
    this.displayDialog = false;
  }

  async createMarket() {
    await this.postMarket.call(this.marketRequestData);

    if (this.postMarket.isSuccessful) {
      eventHub.emit(
        "MARKET_CREATED",
        (this.postMarket.responseData as PostMarketResponse).id
      );
      this.closeModal();
    }
  }

  createEmptyMarketRequestData(): PostMarketRequestData {
    return {
      name: "",
      description: "",
      icon: ""
    };
  }

  get isDataLoading(): boolean {
    return this.postMarket.isBusy;
  }
}
