<template>
  <PageLayout>
    <template v-slot:page-name>
      <PageHeaderInfoWidget :header-info="headerInfo" />
    </template>
    <template v-slot:page-breadcrumbs>
      <Breadcrumbs :items="breadcrumbItems" />
    </template>
    <template v-slot:page-links>
      <PageLinksWidget />
    </template>
    <h4>Market Page Content here...</h4>
  </PageLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Cashier/Market/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
import { BreadcrumbItems, HeaderInfo } from "@/modules/common/types";
import getBreadcrumbItems from "@/structure/Cashier/Market/utils/getBreadcrumbItems";
import { useStore } from "@/store";
import PageLinksWidget from "@/structure/Cashier/Market/widgets/PageLinksWidget.vue";
import { Market } from "@/modules/market/types";

@Options({
  components: { PageLayout, PageHeaderInfoWidget, Breadcrumbs, PageLinksWidget }
})
export default class MarketPage extends Vue {
  store = useStore();

  get market(): Market {
    return this.store.getters.market;
  }

  // TODO change in future
  async created() {
    await this.$router.push({
      name: "cashier-selling-places",
      params: {
        marketId: this.market.id
      }
    });
  }

  get headerInfo(): HeaderInfo {
    return {
      icon: this.market ? this.market.icon : "ion ion-md-cube",
      section: this.market ? this.market.name : "",
      description: this.market ? this.market.description : ""
    };
  }

  get breadcrumbItems(): BreadcrumbItems {
    const pageTitle = String(this.$route.meta.title) ?? "";
    return getBreadcrumbItems(pageTitle, this.market);
  }
}
</script>
