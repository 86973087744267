
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Cashier/Market/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import MarketListWidget from "@/modules/market/widgets/MarketListWidget/MarketListWidget.vue";
import { provide } from "vue";
import MarketApi from "@/modules/market/api";
import { FetchCashierMarkets } from "@/modules/market/api/types";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    MarketListWidget
  }
})
export default class MarketsHomePage extends Vue {
  headerInfo: HeaderInfo = {
    icon: "ion ion-md-cube",
    section: "Pijace"
  };

  async onMarketSelected(marketId: string) {
    await this.$router.push({
      name: "cashier-selling-places",
      params: {
        marketId: marketId
      }
    });
  }

  created() {
    provide(
      "fetchCashierMarkets",
      MarketApi.fetchCashierMarkets.bind(MarketApi) as FetchCashierMarkets
    );
  }
}
