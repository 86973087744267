
import { Options, Vue } from "vue-class-component";
import { Currency, SymbolPosition } from "../types";

@Options({
  props: {
    amount: {
      type: Number,
      required: true
    },
    currency: {
      type: Object,
      required: false
    }
  }
})
export default class Money extends Vue {
  amount!: number;
  currency: Currency = Money.getDefaultCurrency();

  private static getDefaultCurrency(): Currency {
    return {
      name: "Serbian dinar",
      alphabeticCode: "RSD",
      decimalDigits: 2,
      symbol: "дин",
      symbolPosition: SymbolPosition.right,
      symbolSeparator: " "
    };
  }

  get formattedMoney(): string {
    const price = this.amount / 100;
    const formattedPrice = price
      .toFixed(this.currency.decimalDigits)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    if (this.currency.symbolPosition === SymbolPosition.left) {
      return (
        this.currency.symbol + this.currency.symbolSeparator + formattedPrice
      );
    }

    return (
      formattedPrice + this.currency.symbolSeparator + this.currency.symbol
    );
  }
}
