<template>
  <ConfirmDeleteModal
    v-if="displayDialog"
    @close="closeDeleteModal()"
    @confirm="deleteSellingPlace()"
    title="Obriši prodajno mesto"
    description="Da li ste sigurni da želite da obrišete ovo prodajno mesto?
    (Ne može se obrisati prodajno mesto ako se nalazi u pijaci)"
    :showSpinner="isDataLoading"
  />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ConfirmDeleteModal from "@/modules/common/modals/ConfirmDeleteModal.vue";
import { DeleteSellingPlaceParams } from "../api/types";
import AsyncActionFactory from "@/components/AsyncAction";
import SellingPlaceApi from "@/modules/selling-places/api";
import eventHub from "@/components/EventHub";
import { SellingPlace } from "@/modules/selling-places/types";

@Options({
  components: { ConfirmDeleteModal }
})
export default class DeleteSellingPlaceRequestListener extends Vue {
  sellingPlace!: SellingPlace;

  displayDialog = false;

  deleteSellingPlaceAction = AsyncActionFactory(SellingPlaceApi.deleteSellingPlace);

  created() {
    eventHub.on("DELETE_SELLING_PLACE_REQUESTED", sellingPlace => {
      this.sellingPlace = sellingPlace;
      this.displayDialog = true;
    });
  }

  closeDeleteModal() {
    this.displayDialog = false;
  }

  async deleteSellingPlace() {
    await this.deleteSellingPlaceAction.call({
      id: this.sellingPlace.id
    } as DeleteSellingPlaceParams);

    if (this.deleteSellingPlaceAction.isSuccessful) {
      eventHub.emit("SELLING_PLACE_DELETED");
      this.closeDeleteModal();
    }
  }

  get isDataLoading(): boolean {
    return this.deleteSellingPlaceAction.isBusy;
  }
}
</script>
