
import { Options, Vue } from "vue-class-component";
import TextInput from "@/modules/common/forms/TextInput.vue";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import { NewSellingPlaceCategoryRequestData } from "../api/types";
import IconSelector from "@/modules/common/forms/IconSelector.vue";

@Options({
  components: { TextInput, IconSelector },
  props: {
    sellingPlaceCategory: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  }
})
export default class SellingPlaceCategoryForm extends Vue {
  sellingPlaceCategory!: NewSellingPlaceCategoryRequestData;
  errors!: BackendValidationErrors;
}
