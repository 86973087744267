<template>
  <ContractNumberingRuleCreateRequestedListener />
  <ContractNumberingRuleEditRequestedListener />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ContractNumberingRuleCreateRequestedListener from "@/modules/contract-numbering-rules/listeners/ContractNumberingRuleCreateRequestedListener.vue";
import ContractNumberingRuleEditRequestedListener from "@/modules/contract-numbering-rules/listeners/ContractNumberingRuleEditRequestedListener.vue";

@Options({
  components: {
    ContractNumberingRuleCreateRequestedListener,
    ContractNumberingRuleEditRequestedListener
  }
})
export default class Module extends Vue {}
</script>
