<template>
  <DashboardPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import DashboardPage from "@/structure/Cashier/Dashboard/pages/DashboardPage.vue";

@Options({
  components: { DashboardPage }
})
export default class extends Vue {}
</script>
