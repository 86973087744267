<template>
  <Spinner :show-spinner="isDataLoading">
    <div class="contract-cancellation-reason-list">
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item"
          v-for="contractNumberingRules in contractNumberingRulesArray"
          :key="contractNumberingRules.id"
        >
          <ContractNumberingRulesListItemWidget
            :contract-numbering-rules="contractNumberingRules"
          />
        </li>
      </ul>
    </div>
  </Spinner>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import { inject } from "vue";
import {
  ContractNumberingRules,
  ContractNumberingRulesList
} from "@/modules/contract-numbering-rules/types";
import ContractNumberingRulesListItemWidget from "@/modules/contract-numbering-rules/widgets/ContractNumberingRulesList/ContractNumberingRulesListItemWidget.vue";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import { FetchContractNumberingRules } from "@/modules/contract-numbering-rules/api/types";
import ContractNumberingRuleUpdatedEvent from "@/modules/contract-numbering-rules/events/ContractNumberingRuleUpdatedEvent";
import eventHub from "@/components/EventHub";

@Options({
  components: { Spinner, ContractNumberingRulesListItemWidget }
})
export default class ContractNumberingRulesListWidget extends Vue {
  contractNumberingRulesArray: Array<ContractNumberingRules> = [];
  dataLoaded = false;

  fetchContractNumberingRules = AsyncActionFactory(
    inject("fetchContractNumberingRules") as FetchContractNumberingRules
  );

  created() {
    this.loadData();

    eventHub.on<ContractNumberingRuleUpdatedEvent>(
      ContractNumberingRuleUpdatedEvent.Name,
      this.onContractNumberingRuleEdited
    );
    eventHub.on(
      "CONTRACT_NUMBERING_RULE_CREATED",
      this.onContractNumberingRuleCreated
    );
  }

  onContractNumberingRuleEdited(event?: ContractNumberingRuleUpdatedEvent) {
    this.loadData();

    // if (!(event instanceof ContractNumberingRuleUpdatedEvent)) {
    //   console.log(2)
    //   return;
    // }
    // this.replaceContractNumberingRule(event.getContractNumberingRule());
  }

  onContractNumberingRuleCreated() {
    this.loadData();
  }

  async loadData() {
    await this.fetchContractNumberingRules.call();

    if (this.fetchContractNumberingRules.isSuccessful) {
      this.contractNumberingRulesArray = (this.fetchContractNumberingRules
        .responseData as ContractNumberingRulesList).data;
      this.dataLoaded = true;
    }
  }

  async replaceContractNumberingRule(
    contractNumberingRules: ContractNumberingRules
  ) {
    this.contractNumberingRulesArray.forEach((item, i) => {
      if (item.id == contractNumberingRules.id) {
        console.log(4, item, i);

        this.contractNumberingRulesArray[i] = contractNumberingRules;
      }
    });
  }

  get isDataLoading(): boolean {
    return !this.dataLoaded;
  }
}
</script>
