
import { Options, Vue } from "vue-class-component";
import { Market } from "@/modules/market/types";

@Options({
  props: {
    market: {
      type: Object,
      required: true,
      default: {}
    }
  },
  emits: ["marketSelected"]
})
export default class MarketListItemWidget extends Vue {
  market!: Market;

  get formattedAddress(): string {
    const address = this.market.address;
    return `${address.line1} ${address.postalCode} ${address.postalCode} ${address.country}`;
  }

  marketSelected() {
    this.$emit("marketSelected", this.market.id);
  }
}
