import { RouteRecordRaw } from "vue-router";
import PassThroughViewTenants from "./widgets/PassThroughViewTenants.vue";
import Tenants from "@/structure/Cashier/Tenant/views/Tenants.vue";
import TenantHome from "@/structure/Cashier/Tenant/views/TenantHome.vue";
import TenantDetails from "@/structure/Cashier/Tenant/views/TenantDetails.vue";
import TenantContracts from "@/structure/Cashier/Tenant/views/TenantContracts.vue";
import TenantSellingPlaces from "@/structure/Cashier/Tenant/views/TenantSellingPlaces.vue";
import PassThroughViewTenant from "@/structure/Cashier/Tenant/widgets/PassThroughViewTenant.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "tenants",
    name: "cashier-tenants",
    component: PassThroughViewTenants,
    redirect: () => ({
      name: "cashier-tenants-homepage",
      params: {}
    }),
    children: [
      {
        path: "",
        name: "cashier-tenants-homepage",
        component: Tenants,
        meta: {
          title: ""
        }
      },
      {
        path: ":tenantId",
        name: "cashier-tenant",
        component: PassThroughViewTenant,
        redirect: to => ({
          name: "cashier-tenant-home",
          params: {
            tenantId: to.params.tenantId
          }
        }),
        children: [
          {
            path: "",
            name: "cashier-tenant-home",
            component: TenantHome,
            meta: {
              title: "Home"
            }
          },
          {
            path: "details",
            name: "cashier-tenant-details",
            component: TenantDetails,
            meta: {
              title: "Details"
            }
          },
          {
            path: "contracts",
            name: "cashier-tenant-contracts",
            component: TenantContracts,
            meta: {
              title: "Contracts"
            }
          },
          {
            path: "selling-places",
            name: "cashier-tenant-selling-places",
            component: TenantSellingPlaces,
            meta: {
              title: "Selling places"
            }
          }
        ]
      }
    ]
  }
];

export default routes;
