
import { Options, Vue } from "vue-class-component";
import TextInput from "@/modules/common/forms/TextInput.vue";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import { NewSellingPlaceTypeRequestData } from "../api/types";
import IconSelector from "@/modules/common/forms/IconSelector.vue";

@Options({
  components: { TextInput, IconSelector },
  props: {
    sellingPlaceType: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  }
})
export default class SellingPlaceTypeForm extends Vue {
  sellingPlaceType!: NewSellingPlaceTypeRequestData;
  errors!: BackendValidationErrors;
}
