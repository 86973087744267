<template>
  <h5 class="d-flex align-items-center justify-content-between">
    <span>Tipovi Prodajnih Objekata</span>
    <button
      class="btn btn-xs btn-default"
      title="Novi Tip"
      @click="newSellingPlaceType()"
    >
      <i class="ion ion-md-add"></i>
    </button>
  </h5>
  <div class="card">
    <div class="card-body p-0">
      <SellingPlaceTypeListWidget />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import SellingPlaceTypeListWidget from "./SellingPlaceTypeList/SellingPlaceTypeListWidget.vue";
import eventHub from "@/components/EventHub";
import SellingPlaceTypeNewRequestedEvent from "@/modules/selling-place-type/events/SellingPlaceTypeNewRequestedEvent";

@Options({
  components: {
    SellingPlaceTypeListWidget
  }
})
export default class SellingPlaceTypesWidget extends Vue {
  newSellingPlaceType() {
    eventHub.emit<SellingPlaceTypeNewRequestedEvent>(
      SellingPlaceTypeNewRequestedEvent.Name,
      new SellingPlaceTypeNewRequestedEvent()
    );
  }
}
</script>
