<template>
  <EditModal
    v-if="displayDialog"
    @close="closeModal()"
    title="Izmeni Pijacu"
    @confirm="editMarketAddress()"
    :showSpinner="isDataLoading"
  >
    <AddressForm
      :address="marketAddress"
      :validation-errors="editMarketAction.errors"
    />
  </EditModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { EditMarketRequestData, FetchMarket } from "@/modules/market/api/types";
import { Address } from "@/modules/common/types";
import EditModal from "@/modules/common/modals/EditModal.vue";
import EditMarketDetailsForm from "@/modules/market/forms/EditMarketDetailsForm.vue";
import MarketApi from "@/modules/market/api";
import AsyncActionFactory from "@/components/AsyncAction";
import eventHub from "@/components/EventHub";
import AddressForm from "@/modules/common/forms/AddressForm.vue";
import { Market } from "@/modules/market/types";

@Options({
  components: { EditModal, EditMarketDetailsForm, MarketApi, AddressForm }
})
export default class EditMarketAddressRequestListener extends Vue {
  market!: Market;
  marketAddress!: Address;
  displayDialog = false;

  editMarketAction = AsyncActionFactory(MarketApi.editMarket);
  fetchMarket = AsyncActionFactory(MarketApi.fetchMarket as FetchMarket);

  async created() {
    eventHub.on(
      "EDIT_MARKET_ADDRESS_REQUESTED",
      this.onEditMarketAddressRequested
    );
  }

  async onEditMarketAddressRequested(marketId) {
    await this.fetchMarket.call(marketId);

    if (this.fetchMarket.isSuccessful) {
      this.market = this.fetchMarket.responseData as Market;
      this.marketAddress = { ...this.market.address };
      this.displayDialog = true;
    }
  }

  async editMarketAddress() {
    await this.editMarketAction.call(this.market.id, {
      address: this.marketAddress
    } as EditMarketRequestData);

    if (this.editMarketAction.isSuccessful) {
      eventHub.emit("MARKET_EDITED", this.market.id);
      this.closeModal();
    }
  }

  get isDataLoading(): boolean {
    return this.editMarketAction.isBusy || this.fetchMarket.isBusy;
  }

  closeModal() {
    this.displayDialog = false;
  }
}
</script>
