<template>
  <div></div>
</template>
<script lang="ts">
import { Vue } from "vue-class-component";
import { useRouter } from "vue-router";
import Api from "@/modules/authentication/api";

export default class Logout extends Vue {
  router = useRouter();
  async created() {
    Api.logout();
    await this.router.push("login");
  }
}
</script>
