
import { Vue } from "vue-class-component";
import { useStore } from "@/store";
import { Market } from "@/modules/market/types";
import eventHub from "@/components/EventHub";
import { UserRole } from "@/modules/user/types";

export default class extends Vue {
  store = useStore();

  get market(): Market {
    return this.store.getters.market;
  }

  canUserDeleteMarket(): boolean {
    return this.store.getters.user.hasRole(UserRole.Admin);
  }

  editMarketDetails(): void {
    eventHub.emit("EDIT_MARKET_DETAILS_REQUESTED", this.market.id);
  }

  editMarketAddress(): void {
    eventHub.emit("EDIT_MARKET_ADDRESS_REQUESTED", this.market.id);
  }

  deleteMarket(): void {
    if (!this.canUserDeleteMarket()) return;
    eventHub.emit("DELETE_MARKET_REQUESTED", this.market);
  }
}
