import { BreadcrumbItem, BreadcrumbItems } from "@/modules/common/types";
import getBasicBreadcrumbItems from "@/structure/Administrator/common/utils/getBreadcrumbItems.ts";
import { WorkOrder } from "@/modules/work-orders/types";

function createWorkOrdersItem(): BreadcrumbItem {
  return {
    name: "Radni Nalozi",
    link: {
      name: "administrator-work-orders"
    }
  };
}

function createWorkOrderItem(workOrder: WorkOrder): BreadcrumbItem {
  return {
    name: workOrder.name,
    link: {
      name: "administrator-work-order",
      params: {
        workOrderId: workOrder.id
      }
    }
  };
}

const getBreadcrumbItems = function(moduleName: string, workOrder?: WorkOrder) {
  const items: BreadcrumbItems = [];

  items.push(createWorkOrdersItem());
  if (workOrder) {
    items.push(createWorkOrderItem(workOrder));
  }

  return getBasicBreadcrumbItems(moduleName, items);
};

export default getBreadcrumbItems;
