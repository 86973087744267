<template>
  <h5>{{ title }}</h5>
  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Ime
        </div>
        <div>
          {{ tenant.firstName }}
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Prezime
        </div>
        <div>
          {{ tenant.lastName }}
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          JMBG
        </div>
        <div>
          {{ tenant.registrationId }}
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Broj Telefona
        </div>
        <div>
          {{ tenant.phoneNumber }}
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Broj poljoprivrednog gazdinstva
        </div>
        <div>
          {{ tenant.bpg }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Tenant } from "../types";

@Options({
  props: {
    tenant: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: "General"
    }
  }
})
export default class TenantDetailsWidget extends Vue {
  tenant!: Tenant;
}
</script>
