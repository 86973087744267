<template>
  <label class="form-label">Tip</label>
  <select
    class="form-control form-control"
    v-model="typeItem"
    @change="selectType()"
  >
    <option value="">Svi</option>
    <option
      v-for="sellingPlaceType in types"
      :value="sellingPlaceType.id"
      :key="sellingPlaceType"
      >{{ sellingPlaceType.name }}
    </option>
  </select>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import { SellingPlaceTypeList } from "@/modules/selling-place-type/types";

@Options({
  props: {
    sellingPlaceTypeId: {
      type: String,
      required: false,
      default: ""
    }
  },
  emits: ["updateType"]
})
export default class
SellingPlaceTypeSelector extends Vue {
  sellingPlaceTypeId!: string;

  store = useStore();
  typeItem = this.sellingPlaceTypeId;

  get types(): SellingPlaceTypeList {
    return this.store.getters.settings.sellingPlaceTypes;
  }

  selectType() {
    this.$emit("updateType", this.typeItem);
  }
}
</script>
