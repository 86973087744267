import { ContractNumberingRules } from "@/modules/contract-numbering-rules/types";

export default class ContractNumberingRuleUpdatedEvent {
  static readonly Name = "CONTRACT_NUMBERING_RULE_UPDATED";

  private readonly contractNumberingRule: ContractNumberingRules;

  constructor(contractNumberingRule: ContractNumberingRules) {
    this.contractNumberingRule = contractNumberingRule;
  }

  getContractNumberingRule(): ContractNumberingRules {
    return this.contractNumberingRule;
  }
}
