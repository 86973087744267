
import { Options, Vue } from "vue-class-component";
import { HeaderOptions } from "@/modules/common/types";

@Options({
  props: {
    headerOptions: {
      type: Object,
      required: true
    }
  }
})
export default class HeaderWidget extends Vue {
  headerOptions!: HeaderOptions;
}
