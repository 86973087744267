
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Market/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo, TabItems } from "@/modules/common/types";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
import { BreadcrumbItems } from "@/modules/common/types";
import getBreadcrumbItems from "../utils/getBreadcrumbItems";
import { useStore } from "@/store";
import HeaderWidgets from "@/structure/Administrator/Market/widgets/HeaderWidgets.vue";
import TabBarWidget from "@/modules/common/widgets/TabBarWidget.vue";
import getTabItems from "@/structure/Administrator/Market/utils/getTabItems";
import { Market } from "@/modules/market/types";
import { ContractItem } from "@/modules/contracts/types";
import { Transaction } from "@/modules/transactions/types";
import { SellingPlacePrice } from "@/modules/selling-place-price/types";
import { emptySellingPlacePrice } from "@/modules/selling-place-price/types/EmptySellingPlacePrice";
import { SellingPlace } from "@/modules/selling-place/types";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import SellingPlacePriceApi from "@/modules/selling-place-price/api";
import SellingPlaceApi from "@/modules/selling-place/api";
import TransactionApi from "@/modules/transactions/api";
import ContractApi from "@/modules/contracts/api";
import SellingPlaceDetailsWidget from "@/modules/selling-place/widgets/SellingPlaceDetailsWidget.vue";
import { emptySellingPlace } from "@/modules/selling-place/types/EmptySellingPlace";
import {PostTransactionRequestData} from "@/modules/transactions/api/types";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    Breadcrumbs,
    HeaderWidgets,
    TabBarWidget,
    Spinner,
    SellingPlaceDetailsWidget
  },
  watch: {
    sellingPlace(newSellingPlace) {
      this.postTransactionData.marketId = newSellingPlace.market.id;
      this.postTransactionData.sellingPlaceId = newSellingPlace.id;
      this.getTodayTransaction();
      this.loadPrices();
      this.fetchReservation();
    }
  }
})
export default class SellingPlacesPage extends Vue {
  store = useStore();
  reservation: ContractItem | null = null;
  transaction: Transaction | null = null;
  sellingPlacePrice: SellingPlacePrice = emptySellingPlacePrice;
  sellingPlace: SellingPlace = emptySellingPlace;
  postTransactionData: PostTransactionRequestData = {
    marketId: "",
    sellingPlaceId: "",
    tenantShow: true
  };

  fetchSellingPlace = AsyncActionFactory(SellingPlaceApi.fetchSellingPlace);
  fetchSellingPlacePriceAction = AsyncActionFactory(
    SellingPlacePriceApi.fetchSellingPlacePrice
  );
  fetchContractItemAction = AsyncActionFactory(ContractApi.fetchContractItem);
  fetchTodayTransaction = AsyncActionFactory(
    TransactionApi.fetchTodayTransaction
  );

  created() {
    this.getSellingPlace();
  }

  get market(): Market {
    return this.store.getters.market;
  }

  get headerInfo(): HeaderInfo {
    return {
      icon: this.market ? this.market.icon : "ion ion-md-cube",
      section: this.market ? this.market.name : "",
      title: this.sellingPlace ? this.sellingPlace.name : "",
      description: this.market ? this.market.description : ""
    };
  }

  get tabItems(): TabItems {
    return getTabItems(this.market);
  }

  get breadcrumbItems(): BreadcrumbItems {
    return getBreadcrumbItems("Selling Place", this.market);
  }

  get isDataLoading() {
    return (
      this.fetchSellingPlace.isBusy ||
      this.fetchSellingPlacePriceAction.isBusy ||
      this.fetchContractItemAction.isBusy ||
      this.fetchTodayTransaction.isBusy
    );
  }

  async getSellingPlace() {
    await this.fetchSellingPlace.call({
      marketId: this.market.id,
      sellingPlaceId: String(this.$route.params.sellingPlaceId)
    });

    if (this.fetchSellingPlace.isSuccessful) {
      this.sellingPlace = this.fetchSellingPlace.responseData as SellingPlace;
    }
  }

  async getTodayTransaction() {
    await this.fetchTodayTransaction.call(this.sellingPlace.id);

    if (this.fetchTodayTransaction.isSuccessful) {
      if (Object.keys(this.fetchTodayTransaction.responseData).length > 0) {
        this.transaction = this.fetchTodayTransaction
          .responseData as Transaction;
      }
    }
  }

  async loadPrices() {
    await this.fetchSellingPlacePriceAction.call(
      this.sellingPlace.market.id,
      this.sellingPlace.category.id,
      this.sellingPlace.type.id
    );

    if (this.fetchSellingPlacePriceAction.isSuccessful) {
      this.sellingPlacePrice = this.fetchSellingPlacePriceAction
        .responseData as SellingPlacePrice;
    }
  }

  async fetchReservation() {
    await this.fetchContractItemAction.call(
      this.postTransactionData.sellingPlaceId
    );

    if (this.fetchContractItemAction.isSuccessful) {
      if (Object.keys(this.fetchContractItemAction.responseData).length > 0) {
        this.reservation = this.fetchContractItemAction
          .responseData as ContractItem;
      } else {
        this.reservation = null;
      }
    }
  }
}
