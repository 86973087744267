<template>
  <div class="demo-navbar-user nav-item dropdown">
    <div class="dropdown-menu dropdown-menu-right">
      <template v-for="(link, index) in profileMenuLinks" :key="index">
        <template v-if="link.type === 'link'">
          <router-link
            :to="link.linkRoute"
            class="dropdown-item"
            v-if="link.linkRoute"
          >
            <i :class="link.icon" class="mr-2"></i>
            {{ link.name }}
          </router-link>
          <a v-if="!link.linkRoute" href="#" class="dropdown-item">
            <i :class="link.icon" class="mr-2"></i>
            {{ link.name }}
          </a>
        </template>
        <template v-else>
          <div class="dropdown-divider"></div>
        </template>
      </template>
    </div>
    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
      <span
        class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
      >
        <img
          :src="user.imageUrl"
          alt="Profile Image"
          class="d-block ui-w-30 rounded-circle"
        />
        <span class="px-1 mr-lg-2 ml-2 ml-lg-0"
          >{{ user.firstName }} {{ user.lastName }}</span
        >
      </span>
    </a>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Links } from "@/modules/common/types";
import { User } from "@/modules/user/types";

@Options({
  props: {
    user: {
      type: Object,
      required: true
    },
    profileMenuLinks: {
      type: Array,
      required: true
    }
  }
})
export default class UserProfileMenu extends Vue {
  user!: User;
  profileMenuLinks!: Links;
}
</script>
