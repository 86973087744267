import ApiClient from "@/components/ApiClient/ApiClient";
import {
  ContractNumberingRules,
  ContractNumberingRulesList
} from "@/modules/contract-numbering-rules/types";
import {
  contractNumberingRulesListMapper,
  contractNumberingRuleMapper,
  postContractNumberingRuleResponseMapper
} from "@/modules/contract-numbering-rules/api/mappers";
import {
  FetchContractNumberingRules,
  FetchContractNumberingRule,
  FetchContractNumberingRuleParams,
  PostContractNumberingRule,
  NewContractNumberingRuleRequestData,
  PostContractNumberingRuleResponse,
  EditContractNumberingRule,
  EditContractNumberingRuleData,
  EditContractNumberingRuleRequestData
} from "@/modules/contract-numbering-rules/api/types";

class Api {
  public client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  fetchContractNumberingRules: FetchContractNumberingRules = (): Promise<
    ContractNumberingRulesList
  > => {
    const url = "settings/contracts/numbering-rules";

    return this.client.get(url).then(response => {
      return contractNumberingRulesListMapper(response);
    });
  };

  createContractNumberingRule: PostContractNumberingRule = (
    requestsData: NewContractNumberingRuleRequestData
  ): Promise<PostContractNumberingRuleResponse> => {
    const url = "/settings/contracts/numbering-rules";

    return this.client.post(url, requestsData).then(response => {
      return postContractNumberingRuleResponseMapper(response);
    });
  };

  editContractNumberingRule: EditContractNumberingRule = (
    id: string,
    reqData: EditContractNumberingRuleData
  ): Promise<ContractNumberingRules> => {
    const url = `/settings/contracts/numbering-rules/${id}`;
    return this.client.put(url, reqData);
  };

  fetchContractNumberingRule: FetchContractNumberingRule = (
    id: string,
    params?: FetchContractNumberingRuleParams
  ): Promise<ContractNumberingRules> => {
    const url = `/settings/contracts/numbering-rules/${id}`;

    return this.client.get(url, params).then(contractNumberingRule => {
      return contractNumberingRuleMapper(contractNumberingRule);
    });
  };
}

export default Api;
