
import { Options, Vue } from "vue-class-component";
import RoleSelector from "@/modules/user/widgets/RoleSelector.vue";
import { NewUserRequestData } from "@/modules/user/api/types";
import TextInput from "@/modules/common/forms/TextInput.vue";
import EmailInput from "@/modules/common/forms/EmailInput.vue";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";

@Options({
  components: { TextInput, EmailInput, RoleSelector },
  props: {
    userData: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  }
})
export default class CreateUserModal extends Vue {
  userData!: NewUserRequestData;
  errors!: BackendValidationErrors;
}
