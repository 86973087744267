import ApiClient from "@/components/ApiClient/ApiClient";

import {
  PostContract,
  PostContractResponseData,
  EditContract,
  EditContractRequestData,
  FetchContract,
  FetchContracts,
  FetchContractsParams,
  NewPostContractRequestData,
  PostContractType,
  FetchContractParams,
  FetchContractItem,
  FetchContractCancelReasons,
  ContractCancelRequestData,
  FetchContractCancelReasonsParams,
  DeleteContract,
  DeleteContractParams
} from "@/modules/contracts/api/types";

import {
  contractMapper,
  contractListMapper,
  postContractResponseMapper,
  contractItemMapper
} from "@/modules/contracts/api/mappers";
import {
  Contract,
  ContractItem,
  ContractList
} from "@/modules/contracts/types";
import {ContractCancellationReasonList} from "@/modules/contracts-cancellation-reason/types";

class Api {
  public client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  fetchContracts: FetchContracts = (
    params: FetchContractsParams
  ): Promise<ContractList> => {
    const url = "contracts";
    return this.client.get(url, params).then(contractList => {
      return contractListMapper(contractList);
    });
  };

  fetchContractCancelReasons: FetchContractCancelReasons = (
      params: FetchContractCancelReasonsParams
  ): Promise<
      ContractCancellationReasonList
      > => {
    const url = "/settings/contracts/cancellation-reasons";
    console.log('fetchContractCancelReasons', params);
    return this.client.get(url, params).then(response => {
      return response;
    })
  };

  createContractType: PostContractType = (
    requestsData: NewPostContractRequestData
  ): Promise<PostContractResponseData> => {
    const url = `contracts`;
    return this.client.post(url, requestsData).then(response => {
      return postContractResponseMapper(response);
    });
  };

  postContract: PostContract = (
    tenantId?: string
  ): Promise<PostContractResponseData> => {
    const url = `contracts`;
    const payload: { tenantId?: string } = {};

    if (tenantId) {
      payload.tenantId = tenantId;
    }

    return this.client.post(url, payload).then(response => {
      return postContractResponseMapper(response);
    });
  };

  fetchContract: FetchContract = (
    id: string,
    params?: FetchContractParams
  ): Promise<Contract> => {
    const url = `contracts/${id}`;

    return this.client.get(url, params).then(contract => {
      return contractMapper(contract);
    });
  };

  fetchContractItem: FetchContractItem = (
    sellingPlaceId: string
  ): Promise<ContractItem> | null => {
    const url = `contracts/reservation/${sellingPlaceId}`;

    return this.client.get(url).then(contractItem => {
      return contractItem;
    });
  };

  editContract: EditContract = (
    id: string,
    requestData: EditContractRequestData
  ) => {
    const url = `contracts/${id}`;

    return this.client.put(url, requestData);
  };

  cancelContract = (
      id: string,
      requestData: ContractCancelRequestData
  ) => {
    const url = `contracts/contract-cancel/${id}`;
    return this.client.post(url, requestData);
  }

  deleteContract: DeleteContract = (params: DeleteContractParams): Promise<void> => {
    const url = `contracts/${params.id}`;

    return this.client.delete(url);
  }
}

export default Api;
