import { HeaderOptions } from "@/modules/common/types";
import { Settings } from "@/modules/settings/types";

export function getHeaderOptions(settings: Settings | null): HeaderOptions {
  return {
    title: settings ? settings.organizationName : "",
    linkRoute: {
      name: "homepage"
    },
    logoUrl: settings ? settings.organizationLogoUrl : ""
  };
}
