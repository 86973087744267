import ApiClient from "@/components/ApiClient/ApiClient";

import {
  DeleteContractCancellationReason,
  DeleteContractCancellationReasonParams,
  EditContractCancellationReason,
  EditContractCancellationReasonData,
  FetchContractCancellationReason,
  FetchContractCancellationReasonParams,
  FetchContractCancellationReasons,
  FetchContractCancellationReasonsParams,
  PostContractCancellationReason,
  NewContractCancellationReasonRequestData,
  PostContractCancellationReasonResponse
} from "./types";

import {
  contractCancellationReasonListMapper,
  contractCancellationReasonMapper,
  postContractCancellationReasonResponseMapper
} from "./mappers";
import {
  ContractCancellationReason,
  ContractCancellationReasonList
} from "@/modules/contracts-cancellation-reason/types";

class Api {
  public client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  fetchContractCancellationReasons: FetchContractCancellationReasons = (
    params?: FetchContractCancellationReasonsParams
  ): Promise<ContractCancellationReasonList> => {
    const url = "/settings/contracts/cancellation-reasons";

    return this.client.get(url, params).then(contractCancellationReasonList => {
      return contractCancellationReasonListMapper(
        contractCancellationReasonList
      );
    });
  };

  fetchContractCancellationReason: FetchContractCancellationReason = (
    id: string,
    params?: FetchContractCancellationReasonParams
  ): Promise<ContractCancellationReason> => {
    const url = `/settings/contracts/cancellation-reasons/${id}`;

    return this.client.get(url, params).then(contractCancellationReason => {
      return contractCancellationReasonMapper(contractCancellationReason);
    });
  };

  createContractCancellationReason: PostContractCancellationReason = (
    requestsData: NewContractCancellationReasonRequestData
  ): Promise<PostContractCancellationReasonResponse> => {
    const url = "settings/contracts/cancellation-reasons";

    return this.client.post(url, requestsData).then(response => {
      return postContractCancellationReasonResponseMapper(response);
    });
  };

  editContractCancellationReason: EditContractCancellationReason = (
    id: string,
    requestData: EditContractCancellationReasonData
  ): Promise<void> => {
    const url = `/settings/contracts/cancellation-reasons/${id}`;

    return this.client.put(url, requestData);
  };

  deleteContractCancellationReason: DeleteContractCancellationReason = (
    id: string,
    params?: DeleteContractCancellationReasonParams
  ): Promise<void> => {
    const url = `/settings/contracts/cancellation-reasons/${id}`;

    return this.client.delete(url, params);
  };
}

export default Api;
