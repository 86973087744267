import { PostMarketResponse } from "./types";
import { Market, MarketList } from "@/modules/market/types";

export function marketMapper(market): Market {
  return {
    ...market
  };
}

export function marketListMapper(list): MarketList {
  list.data = list.data.map(market => marketMapper(market));
  return list;
}

export function postMarketResponseMapper(response): PostMarketResponse {
  return {
    ...response
  };
}
