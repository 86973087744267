
import { Options, Vue } from "vue-class-component";
import { SellingPlace } from "@/modules/selling-place/types";
import { SellingPlacePrice } from "@/modules/selling-place-price/types";
import { Contract, ContractItem } from "@/modules/contracts/types";
import AsyncActionFactory from "@/components/AsyncAction";
import ContractApi from "@/modules/contracts/api";
import TenantDetailsWidget from "@/modules/tenant/widgets/TenantDetailsWidget.vue";
import { Transaction } from "@/modules/transactions/types";
import UserDetailsWidget from "@/modules/user/widgets/UserDetailsWidget.vue";

@Options({
  props: {
    sellingPlace: {
      type: Object,
      required: true
    },
    sellingPlacePrice: {
      type: Object,
      required: true
    },
    reservation: {
      type: Object
    },
    transaction: {
      type: Object
    }
  },
  watch: {
    reservation() {
      this.getContract();
    }
  },
  components: {
    TenantDetailsWidget,
    UserDetailsWidget
  }
})
export default class SellingPlaceDetailsWidget extends Vue {
  sellingPlace!: SellingPlace;
  sellingPlacePrice!: SellingPlacePrice;
  transaction!: Transaction | null;
  reservation!: ContractItem | null;
  fetchContract = AsyncActionFactory(ContractApi.fetchContract);
  contract = {};

  async getContract() {
    await this.fetchContract.call(this.reservation.contractId);

    if (this.fetchContract.isSuccessful) {
      this.contract = this.fetchContract.responseData as Contract;
    }
  }

  get name(): string {
    if (!this.sellingPlace.name) return "-";

    return this.sellingPlace.name;
  }

  get type(): string {
    if (!this.sellingPlace.type.name) return "-";

    return this.sellingPlace.type.name;
  }

  get category(): string {
    if (!this.sellingPlace.category.name) return "-";

    return this.sellingPlace.category.name;
  }
}
