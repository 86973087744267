import { RouteRecordRaw } from "vue-router";
import PassThroughViewContractDetails from "../widgets/PassThroughViewContractDetails.vue";
import Contract from "@/structure/Administrator/Contracts/views/Contract.vue";
// import ContractEdit from "@/structure/Administrator/Contracts/views/ContractEdit.vue";
import Drafter from "@/structure/Administrator/Contracts/views/Drafter.vue";
import Tenant from "@/structure/Administrator/Contracts/views/Tenant.vue";
import Organization from "@/structure/Administrator/Contracts/views/Organization.vue";
import ContractPrintPage from "@/structure/Administrator/Contracts/pages/ContractPrintPage.vue";

const contractRoutes: Array<RouteRecordRaw> = [
  {
    path: "contracts/:contractId",
    name: "administrator-contract",
    component: PassThroughViewContractDetails,
    redirect: to => ({
      name: "administrator-contract-details",
      params: {
        contractId: to.params.contractId
      }
    }),
    children: [
      {
        path: "",
        name: "administrator-contract-details",
        component: Contract,
        meta: {
          title: ""
        }
      },
      {
        path: "drafter",
        name: "administrator-contract-drafter",
        component: Drafter,
        meta: {
          title: ""
        }
      },
      {
        path: "tenant",
        name: "administrator-contract-tenant",
        component: Tenant,
        meta: {
          title: ""
        }
      },
      {
        path: "organization",
        name: "administrator-contract-organization",
        component: Organization,
        meta: {
          title: ""
        }
      },
      {
        path: "print",
        name: "administrator-contract-print",
        component: ContractPrintPage,
        meta: {
          title: ""
        }
      },
      // {
      //   path: "edit",
      //   name: "administrator-contract-edit",
      //   component: ContractEdit,
      //   meta: {
      //     title: "Edit"
      //   }
      // }
    ]
  }
];

export default contractRoutes;
