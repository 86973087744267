<template>
  <PricesPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PricesPage from "@/structure/Administrator/Market/pages/PricesPage.vue";
import { provide } from "vue";
import SellingPlacePriceApi from "@/modules/selling-place-price/api";
import { FetchSellingPlacePrices } from "@/modules/selling-place-price/api/types";

@Options({
  components: { PricesPage }
})
export default class Prices extends Vue {
  created() {
    provide(
      "fetchSellingPlacePrices",
      SellingPlacePriceApi.fetchSellingPlacePrices.bind(
        SellingPlacePriceApi
      ) as FetchSellingPlacePrices
    );
  }
}
</script>
