import { i18n } from "@/language";

const $t = i18n.global.t.bind(i18n); // create alias function $t() and you can now use it as usual

const commonErrors = {
  default: $t("errors.request.default"),
  status400: $t("errors.request.400"),
  status401: $t("errors.request.401"),
  status403: $t("errors.request.403"),
  status404: $t("errors.request.404"),
  status419: $t("errors.request.419")
};

export default commonErrors;
