<template>
  <!--  todo: fix UI of this filter-->
  <div class="row mb-3 justify-content-end">
    <div class="col">
      <ContractStatusSelector v-model:status="filter.status" />
    </div>
    <div class="col text-right">
      <button
        class="btn btn-outline-primary"
        style="margin-top: 24px"
        @click="onSearch"
      >
        Pretraga
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ContractStatusSelector from "@/modules/contracts/widgets/ContractStatusSelector.vue";
import { ContractsFilter } from "@/modules/contracts/api/types";

@Options({
  props: {
    filter: {
      types: Object,
      required: true
    }
  },
  components: {
    ContractStatusSelector
  },
  emits: ["search"]
})
export default class ContractFilterWidget extends Vue {
  filter!: ContractsFilter;

  onSearch() {
    this.$emit("search", this.filter);
  }
}
</script>
