import { BreadcrumbItem, BreadcrumbItems } from "@/modules/common/types";

import getBasicBreadcrumbItems from "@/structure/Cashier/common/utils/getBreadcrumbItems.ts";
import { Market } from "@/modules/market/types";
import { SellingPlace } from "@/modules/selling-place/types";

function createMarketsItem(): BreadcrumbItem {
  return {
    name: "Pijace",
    link: {
      name: "cashier-markets"
    }
  };
}

function createSellingPlacesItem(market: Market) {
  return {
    name: "Prodajni Objekti",
    link: {
      name: "cashier-selling-places",
      params: {
        marketId: market.id
      }
    }
  };
}

function createMarketItem(market: Market): BreadcrumbItem {
  return {
    name: market.name,
    link: {
      name: "cashier-market",
      params: {
        marketId: market.id
      }
    }
  };
}

function createSellingPlaceItem(market: Market, sellingPlace: SellingPlace) {
  return {
    name: sellingPlace.name,
    link: {
      name: "cashier-selling-place",
      params: {
        marketId: market.id,
        sellingPlaceId: sellingPlace.id
      }
    }
  };
}

const getBreadcrumbItems = function(
  moduleName: string,
  market?: Market,
  sellingPlace?: SellingPlace
) {
  const items: BreadcrumbItems = [];

  items.push(createMarketsItem());
  if (market) {
    items.push(createMarketItem(market));
    items.push(createSellingPlacesItem(market));

    if (sellingPlace) {
      items.push(createSellingPlaceItem(market, sellingPlace));
    }
  }

  return getBasicBreadcrumbItems(moduleName, items);
};

export default getBreadcrumbItems;
