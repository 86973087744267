<template>
  <div class="container-fluid d-flex align-items-stretch flex-grow-1 p-0">
    <div class="side-box flex-shrink-1 flex-grow-0 w-25 bg-light">
      <slot name="sidebar"></slot>
    </div>
    <div class="page-layout flex-shrink-1 flex-grow-1">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class SidebarLayout extends Vue {}
</script>

<style scoped>
.side-box {
  position: relative;
  padding-top: 60px;
}
</style>
