
import { Options, Vue } from "vue-class-component";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import { ContractCancelRequestData } from "@/modules/contracts/api/types";
import { useStore } from "@/store";
import { ContractCancelReasons } from "@/modules/contracts/types";

@Options({
  props: {
    contractCancelRequestData: {
      type: Object,
      required: true
    },
    validationErrors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  }
})
export default class CancelContractForm extends Vue {
  store = useStore();

  validationErrors!: BackendValidationErrors;
  contractCancelRequestData!: ContractCancelRequestData;

  get cancellationReasons() {
    return this.store.getters.contractCancelReasons;
  }
}
