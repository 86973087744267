<template>
  <Modal>
    <Spinner :show-spinner="patchSettings.isBusy">
      <div class="modal-header">
        <h5 class="modal-title">
          Izmena Organizacije
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click="$emit('closeModal')"
        >
          ×
        </button>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label class="form-label" for="organization-name">Naziv Organizacije</label>
          <input
            type="text"
            id="organization-name"
            class="form-control"
            ref="organizationNameInput"
            :placeholder="$t('settings.organization name')"
            v-model.trim="model"
            @keydown.enter="onEditOrganizationRequest()"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Adresa 1</label>
          <input
            type="text"
            class="form-control"
            :placeholder="'Adresa 1'"
            v-model.trim="addressLine1"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Adresa 2</label>
          <input
            type="text"
            class="form-control"
            :placeholder="'Adresa 2'"
            v-model.trim="addressLine2"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Predstavnik</label>
          <input
            type="text"
            class="form-control"
            :placeholder="'Predstavnik'"
            v-model.trim="representative"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Mesto/Grad</label>
          <input
            type="text"
            class="form-control"
            :placeholder="'Mesto/Grad'"
            v-model.trim="addressCity"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Postanski Broj</label>
          <input
            type="text"
            class="form-control"
            :placeholder="'Postanski Broj'"
            v-model.trim="addressPostalCode"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Region</label>
          <input
            type="text"
            class="form-control"
            :placeholder="'region'"
            v-model.trim="addressState"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Drzava</label>
          <input
            type="text"
            class="form-control"
            :placeholder="'Drzava'"
            v-model.trim="addressCountry"
          />
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          @click="$emit('closeModal')"
        >
          {{ $t("button.close") }}
        </button>
        <button
          type="button"
          class="btn btn-success"
          :disabled="!isDataValid"
          @click="onEditOrganizationRequest()"
        >
          Sačuvaj
        </button>
      </div>
    </Spinner>
  </Modal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction";
import Modal from "@/modules/common/modals/Modal.vue";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import SettingsApi from "@/modules/settings/api";

@Options({
  props: {
    organizationName: {
      type: String,
      required: true
    },
    organizationRepresentative: {
      type: String,
      required: false
    },
    organizationAddressLine1: {
      type: String,
      required: false
    },
    organizationAddressLine2: {
      type: String,
      required: false
    },
    organizationAddressCity: {
      type: String,
      required: false
    },
    organizationAddressPostalCode: {
      type: String,
      required: false
    },
    organizationAddressCountry: {
      type: String,
      required: false
    },
    organizationAddressState: {
      type: String,
      required: false
    },
  },
  components: { Modal, Spinner },
  emits: ["closeModal"]
})
export default class EditOrganizationNameModal extends Vue {
  organizationName!: string;
  model: string = this.organizationName;
  organizationRepresentative!: string;
  representative: string = this.organizationRepresentative;
  organizationAddressLine1!: string;
  addressLine1: string = this.organizationAddressLine1;
  organizationAddressLine2!: string;
  addressLine2: string = this.organizationAddressLine2;
  organizationAddressCity!: string;
  addressCity: string = this.organizationAddressCity;
  organizationAddressPostalCode!: string;
  addressPostalCode: string = this.organizationAddressPostalCode;
  organizationAddressCountry!: string;
  addressCountry: string = this.organizationAddressCountry;
  organizationAddressState!: string;
  addressState: string = this.organizationAddressState;

  mounted() {
    (this.$refs.organizationNameInput as HTMLInputElement).focus();
  }

  get isDataValid(): boolean {
    return this.model.length > 0;
  }

  patchSettings = AsyncActionFactory(SettingsApi.patchSettings);

  async onEditOrganizationRequest() {
    if (!this.isDataValid) return;

    const requestData = new FormData();
    requestData.append("organizationName", this.model);
    requestData.append("organizationRepresentative", this.representative);
    requestData.append("organizationAddressLine1", this.addressLine1);
    requestData.append("organizationAddressLine2", this.addressLine2);
    requestData.append("organizationAddressCity", this.addressCity);
    requestData.append("organizationAddressPostalCode", this.addressPostalCode);
    requestData.append("organizationAddressCountry", this.addressCountry);
    requestData.append("organizationAddressState", this.addressState);

    await this.patchSettings.call(requestData);
    if (this.patchSettings.isSuccessful) {
      this.$emit("closeModal");
    }
  }
}
</script>
