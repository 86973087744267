import { Link, Links } from "@/modules/common/types";
import { MarketList, Market } from "@/modules/market/types";

function createDashboardItem(): Link {
  return {
    type: "link",
    name: "Početna",
    linkRoute: {
      name: "cashier-dashboard"
    },
    icon: "ion ion-md-home"
  };
}

function createMarketListItem(marketListItem: Market): Link {
  return {
    type: "link",
    name: marketListItem.name,
    linkRoute: {
      name: "cashier-selling-places",
      params: {
        marketId: marketListItem.id
      }
    },
    icon: "ion ion-md-cube"
  };
}

function createTenantsItem(): Link {
  return {
    type: "link",
    name: "Tenants",
    linkRoute: {
      name: "cashier-tenants"
    },
    icon: "ion ion-md-people"
  };
}

function createAllMarketsItem(): Link {
  return {
    type: "link",
    name: "All Markets",
    linkRoute: {
      name: "cashier-markets"
    },
    icon: "ion ion-md-cube"
  };
}

function createSegmentItem(segmentName: string): Link {
  return {
    type: "segment",
    name: segmentName
  };
}

function createDividerItem(): Link {
  return {
    type: "separator"
  };
}

const getNavigationLinks = function(marketList: MarketList): Links {
  const items: Links = [];
  items.push(createDashboardItem());
  // items.push(createDividerItem());
  // items.push(createAllMarketsItem());
  // items.push(createDividerItem());
  // items.push(createTenantsItem());
  // items.push(createDividerItem());

  // if (marketList) {
  //   items.push(createSegmentItem("Market"));
  //   marketList.data.forEach(marketListItem => {
  //     items.push(createMarketListItem(marketListItem));
  //   });
  //   items.push(createDividerItem());
  // }

  return items;
};

export default getNavigationLinks;
