<template>
  <MainPageLayout>
    <template v-slot:page-breadcrumbs>
      <Breadcrumbs :items="breadcrumbItems" />
    </template>
    <template v-slot:page-name>
      <slot name="page-name"></slot>
    </template>
    <template v-slot:page-widgets>
      <slot name="page-widgets"></slot>
    </template>
    <div class="p-4 bg-white border-bottom border-left border-right border-top">
      <slot></slot>
    </div>
  </MainPageLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MainPageLayout from "@/modules/common/layouts/PageLayout.vue";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";

import { BreadcrumbItems } from "@/modules/common/types";
import { getBreadcrumbItems } from "../utils/getBreadcrumbItems";

@Options({
  components: {
    MainPageLayout,
    Breadcrumbs
  }
})
export default class extends Vue {
  get breadcrumbItems(): BreadcrumbItems {
    const pageTitle = String(this.$route.meta.title) ?? "";
    return getBreadcrumbItems(pageTitle);
  }
}
</script>
