
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Transactions/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import HeaderWidgets from "../widgets/HeaderWidgets.vue";
import TransactionListWidget from "@/modules/transactions/widgets/TransactionList/TransactionListWidget.vue";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    HeaderWidgets,
    TransactionListWidget
  }
})
export default class TransactionsPage extends Vue {
  headerInfo: HeaderInfo = {
    icon: "ion ion-md-cash",
    section: "Transakcije"
  };
}
