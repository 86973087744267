<template>
  <div class="form-group">
    <TextInput
      field-name="year"
      :label="'Godina'"
      v-model="contractNumberingRule.year"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="nextDocumentNumber"
      :label="'Sledeci broj ugovora'"
      v-model="contractNumberingRule.nextDocumentNumber"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="prefix"
      :label="'Prefiks'"
      v-model="contractNumberingRule.prefix"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="suffix"
      :label="'Sufiks'"
      v-model="contractNumberingRule.suffix"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <label class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        v-model="contractNumberingRule.addSpaceBetweenElements"
      />
      <div class="form-check-label">
        Prazno mesto izmedju elemenata u broju ugovoru
      </div>
    </label>
  </div>
  <div>
    <TextInput
      field-name="yearSeparator"
      :label="'Karakter koji ce razdvajati godinu i broj ugovora'"
      v-model="contractNumberingRule.yearSeparator"
      :errors="errors"
    />
  </div>
  <div>
    <TextInput
      field-name="minimalDigitsNumber"
      :label="'Broj cifri u ugovoru'"
      v-model="contractNumberingRule.minimalDigitsNumber"
      :errors="errors"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TextInput from "@/modules/common/forms/TextInput.vue";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import { NewContractNumberingRuleRequestData } from "../api/types";

@Options({
  components: { TextInput },
  props: {
    contractNumberingRule: {
      type: Object,
      required: true
    }
  },
  errors: {
    type: Object,
    required: false,
    default: new BackendValidationErrors()
  }
})
export default class ContractNumberingRuleForm extends Vue {
  contractNumberingRule!: NewContractNumberingRuleRequestData;
  errors!: BackendValidationErrors;
}
</script>

<style scoped></style>
