
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Contracts/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
import { BreadcrumbItems } from "@/modules/common/types";
import getBreadcrumbItems from "../utils/getBreadcrumbItems";
import { WorkOrder } from "@/modules/work-orders/types";
import { useStore } from "@/store";
import WorkOrderDetailsWidget from "@/modules/work-orders/widgets/WorkOrderDetailsWidget.vue";
import AddressDetailsWidget from "@/modules/common/widgets/AddressDetailsWidget.vue";
import UserDetailsWidget from "@/modules/user/widgets/UserDetailsWidget.vue";
import DetailsHeaderWidgets from "@/structure/Administrator/WorkOrders/widgets/DetailsHeaderWidgets.vue";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    Breadcrumbs,
    WorkOrderDetailsWidget,
    AddressDetailsWidget,
    UserDetailsWidget,
    DetailsHeaderWidgets
  }
})
export default class WorkOrderDetailsPage extends Vue {
  store = useStore();

  get workOrder(): WorkOrder {
    return this.store.getters.workOrder;
  }

  get headerInfo(): HeaderInfo {
    return {
      icon: "ion ion-md-document",
      section: this.workOrder ? this.workOrder.name : ""
    };
  }

  get breadcrumbItems(): BreadcrumbItems {
    return getBreadcrumbItems("Detalji", this.workOrder);
  }
}
