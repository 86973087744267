<template>
  <BoxLayout :background-image-url="backgroundImageUrl">
    <template v-slot:default>
      <slot></slot>
    </template>
  </BoxLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import BoxLayout from "@/modules/common/layouts/BoxLayout.vue";

@Options({
  components: { BoxLayout }
})
export default class PageLayout extends Vue {
  backgroundImageUrl = require("@/assets/background.png");
}
</script>
