
import { Options, Vue } from "vue-class-component";
import BaseNavigationWidget from "@/modules/common/widgets/NavigationWidget.vue";
import { Links } from "@/modules/common/types";
import { getNavigationLinks } from "@/structure/Administrator/common/utils/getNavigationLinks";

@Options({
  components: { BaseNavigationWidget }
})
export default class NavigationWidget extends Vue {
  get navigationLinks(): Links {
    return getNavigationLinks();
  }
}
