
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Settings/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import ContractCancellationReasonsWidget from "@/modules/contracts-cancellation-reason/widgets/ContractCancellationReasonsWidget.vue";
import ContractNumberingRulesWidget from "@/modules/contract-numbering-rules/widgets/ContractNumberingRulesWidget.vue";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    ContractCancellationReasonsWidget,
    ContractNumberingRulesWidget
  }
})
export default class ContractCancellationReasonPage extends Vue {
  headerInfo: HeaderInfo = {
    icon: "ion ion-md-settings",
    section: "Podešavanja",
    title: "Ugovor"
  };
}
