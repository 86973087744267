<template>
  <template v-if="list.data.length">
    <div v-for="item in list.data" :key="item">
      <slot name="item-widget" :item="item"></slot>
    </div>
  </template>
  <template v-else>
    <slot name="no-data"></slot>
  </template>
  <PaginationWidget
    :pagination="list.pagination"
    v-if="!hidePagination"
    @paginate="onPaginate($event)"
  />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PaginationWidget from "./PaginationWidget.vue";
import { List } from "@/modules/common/types";

@Options({
  components: { PaginationWidget },
  props: {
    list: {
      type: Object,
      required: true,
      default: []
    },
    hidePagination: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["paginate"]
})
export default class ClientListItems extends Vue {
  list!: List<Record<string, string>>;
  hidePagination = false;

  onPaginate(page) {
    this.$emit("paginate", page);
  }
}
</script>
