
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";
import CreateModal from "@/modules/common/modals/CreateModal.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import CreateUserForm from "@/modules/user/forms/CreateUserForm.vue";
import UserApi from "@/modules/user/api";
import {
  FetchUser,
  NewUserRequestData,
  PostUserResponse
} from "@/modules/user/api/types";
import { UserRole } from "../types";
import UserCreatedEvent from "@/modules/user/events/UserCreatedEvent";
import UserNewRequestedEvent from "@/modules/user/events/UserNewRequestedEvent";

@Options({
  components: { CreateModal, CreateUserForm }
})
export default class CreateUserListener extends Vue {
  displayDialog = false;
  userData: NewUserRequestData = this.createEmptyUserRequestData();

  postUserAction = AsyncActionFactory(UserApi.postUser);

  fetchUser = AsyncActionFactory(UserApi.fetchUser as FetchUser);

  created() {
    eventHub.on<UserNewRequestedEvent>(
      UserNewRequestedEvent.Name,
      this.onUserNewRequestedEvent
    );
  }

  onUserNewRequestedEvent(event?: UserNewRequestedEvent) {
    if (!(event instanceof UserNewRequestedEvent)) {
      return;
    }
    this.displayDialog = true;
  }

  closeModal() {
    this.displayDialog = false;
  }

  async createUser() {
    await this.postUserAction.call(this.userData);

    if (this.postUserAction.isSuccessful) {
      await this.emitUserCreated(
        (this.postUserAction.responseData as PostUserResponse).id
      );

      this.closeModal();
    }
  }

  async emitUserCreated(userId) {
    const user = await this.fetchUser.call(userId);

    if (this.fetchUser.isSuccessful) {
      eventHub.emit<UserCreatedEvent>(
        UserCreatedEvent.Name,
        new UserCreatedEvent(user)
      );
    }
  }

  createEmptyUserRequestData(): NewUserRequestData {
    return {
      email: "",
      firstName: "",
      lastName: "",
      active: true,
      role: UserRole.Cashier,
      password: {
        first: "",
        second: ""
      }
    };
  }

  get isDataLoading(): boolean {
    return this.postUserAction.isBusy;
  }
}
