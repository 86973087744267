<template>
  <SellingPlaceDetailsPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import SellingPlaceDetailsPage from "@/structure/Administrator/SellingPlaces/pages/SellingPlaceDetailsPage.vue";

@Options({
  components: { SellingPlaceDetailsPage }
})
export default class SellingPlace extends Vue {}
</script>
