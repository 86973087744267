
import { Options, Vue } from "vue-class-component";
import ConfirmDeleteModal from "@/modules/common/modals/ConfirmDeleteModal.vue";
import { DeleteMarketParams } from "../api/types";
import AsyncActionFactory from "@/components/AsyncAction";
import MarketApi from "@/modules/market/api";
import eventHub from "@/components/EventHub";
import { Market } from "@/modules/market/types";

@Options({
  components: { ConfirmDeleteModal }
})
export default class DeleteMarketRequestListener extends Vue {
  market!: Market;

  displayDialog = false;

  deleteMarketAction = AsyncActionFactory(MarketApi.deleteMarket);

  created() {
    eventHub.on("DELETE_MARKET_REQUESTED", market => {
      this.market = market;
      this.displayDialog = true;
    });
  }

  closeDeleteModal() {
    this.displayDialog = false;
  }

  async deleteMarket() {
    await this.deleteMarketAction.call({
      id: this.market.id
    } as DeleteMarketParams);

    if (this.deleteMarketAction.isSuccessful) {
      eventHub.emit("MARKET_DELETED");
      this.closeDeleteModal();
    }
  }

  get isDataLoading(): boolean {
    return this.deleteMarketAction.isBusy;
  }
}
