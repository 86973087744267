
import { Options, Vue } from "vue-class-component";
import DashboardPage from "@/structure/Administrator/Dashboard/pages/DashboardPage.vue";
import { provide } from "vue";
import TransactionApi from "@/modules/transactions/api";
import WorkOrderApi from "@/modules/work-orders/api";
import TenantApi from "@/modules/tenant/api";
import ContractApi from "@/modules/contracts/api";
import { FetchTransactions } from "@/modules/transactions/api/types";
import { FetchWorkOrders } from "@/modules/work-orders/api/types";
import { FetchAllTenants } from "@/modules/tenant/api/types";
import { FetchContracts } from "@/modules/contracts/api/types";

@Options({
  components: { DashboardPage }
})
export default class Dashboard extends Vue {
  created() {
    provide(
      "fetchTransactions",
      TransactionApi.fetchTransactions.bind(TransactionApi) as FetchTransactions
    );

    provide(
      "fetchWorkOrders",
      WorkOrderApi.fetchWorkOrders.bind(WorkOrderApi) as FetchWorkOrders
    );

    provide(
      "fetchTenants",
      TenantApi.fetchAllTenants.bind(TenantApi) as FetchAllTenants
    );

    provide(
      "fetchContracts",
      ContractApi.fetchContracts.bind(ContractApi) as FetchContracts
    );
  }
}
