
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Cashier/SellingPlace/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
import { BreadcrumbItems } from "@/modules/common/types";
import getBreadcrumbItems from "../utils/getBreadcrumbItems";
import { useStore } from "@/store";
import HeaderWidgets from "@/structure/Cashier/SellingPlace/widgets/HeaderWidgets.vue";
import { Market } from "@/modules/market/types";
import { SellingPlace } from "@/modules/selling-place/types";
import SellingPlaceDetailsWidget from "@/modules/selling-place/widgets/SellingPlaceDetailsWidget.vue";
import TransactionForm from "@/modules/transactions/forms/TransactionForm.vue";
import { ContractItem } from "@/modules/contracts/types";
import { PostTransactionRequestData } from "@/modules/transactions/api/types";
import AsyncActionFactory from "@/components/AsyncAction";
import SellingPlacePriceApi from "@/modules/selling-place-price/api";
import { SellingPlacePrice } from "@/modules/selling-place-price/types";
import TransactionApi from "@/modules/transactions/api";
import ContractApi from "@/modules/contracts/api";
import { emptySellingPlacePrice } from "@/modules/selling-place-price/types/EmptySellingPlacePrice";
import { createToastInterface } from "vue-toastification";
import { Transaction } from "@/modules/transactions/types";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    Breadcrumbs,
    HeaderWidgets,
    SellingPlaceDetailsWidget,
    TransactionForm,
    Spinner
  },
  watch: {
    "postTransactionData.tenantShow"() {
      this.setDailyPrice();
    },
    sellingPlace(newSellingPlace) {
      if (newSellingPlace) {
        this.postTransactionData.marketId = newSellingPlace.market.id;
        this.postTransactionData.sellingPlaceId = newSellingPlace.id;
        this.loadPrices();
        this.fetchReservation();
        this.getTodayTransaction();
      }
    },
    reservation() {
      this.setDailyPrice();
    },
    sellingPlacePrice() {
      this.setDailyPrice();
    }
  }
})
export default class DetailsPage extends Vue {
  toast = createToastInterface();
  store = useStore();
  dailyPrice = 0;
  reservation: ContractItem | null = null;
  transaction: Transaction | null = null;
  postTransactionData: PostTransactionRequestData = {
    marketId: "",
    sellingPlaceId: "",
    tenantShow: true
  };
  sellingPlacePrice: SellingPlacePrice = emptySellingPlacePrice;

  fetchSellingPlacePriceAction = AsyncActionFactory(
    SellingPlacePriceApi.fetchSellingPlacePrice
  );
  postTransaction = AsyncActionFactory(TransactionApi.postTransaction);
  fetchContractItemAction = AsyncActionFactory(ContractApi.fetchContractItem);
  fetchTodayTransaction = AsyncActionFactory(
    TransactionApi.fetchTodayTransaction
  );

  get isDataLoading() {
    return (
      this.fetchSellingPlacePriceAction.isBusy ||
      this.fetchContractItemAction.isBusy ||
      this.fetchTodayTransaction.isBusy ||
      this.postTransaction.isBusy
    );
  }

  get market(): Market {
    return this.store.getters.market;
  }

  get sellingPlace(): SellingPlace {
    return this.store.getters.sellingPlace;
  }

  get headerInfo(): HeaderInfo {
    return {
      icon: this.sellingPlace ? this.sellingPlace.type.icon : "",
      section: this.market ? this.market.name : "",
      title: this.sellingPlace ? this.sellingPlace.name : "",
      description: this.market ? this.market.description : ""
    };
  }

  get breadcrumbItems(): BreadcrumbItems {
    return getBreadcrumbItems("", this.market, this.sellingPlace);
  }

  async getTodayTransaction() {
    await this.fetchTodayTransaction.call(this.sellingPlace.id);

    if (this.fetchTodayTransaction.isSuccessful) {
      if (Object.keys(this.fetchTodayTransaction.responseData).length > 0) {
        this.transaction = this.fetchTodayTransaction
          .responseData as Transaction;
      }
    }
  }

  async create() {
    await this.postTransaction.call(this.postTransactionData);

    if (this.postTransaction.isSuccessful) {
      this.toast.success("Uspesno ste kreirali transakciju");
      await this.getTodayTransaction();
    }
  }

  async loadPrices() {
    await this.fetchSellingPlacePriceAction.call(
      this.sellingPlace.market.id,
      this.sellingPlace.category.id,
      this.sellingPlace.type.id
    );

    if (this.fetchSellingPlacePriceAction.isSuccessful) {
      this.sellingPlacePrice = this.fetchSellingPlacePriceAction
        .responseData as SellingPlacePrice;
    }
  }

  async fetchReservation() {
    await this.fetchContractItemAction.call(
      this.postTransactionData.sellingPlaceId
    );

    if (this.fetchContractItemAction.isSuccessful) {
      if (Object.keys(this.fetchContractItemAction.responseData).length > 0) {
        this.reservation = this.fetchContractItemAction
          .responseData as ContractItem;
      } else {
        this.reservation = null;
      }
    }
  }

  setDailyPrice() {
    if (this.reservation === null || !this.postTransactionData.tenantShow) {
      this.dailyPrice =
        this.sellingPlacePrice.rentPriceWithoutReservationDaily / 100;
    } else {
      this.dailyPrice = this.sellingPlacePrice.rentPriceDaily / 100;
    }
  }
}
