<template>
  <PageLayout>
    <template v-slot:page-name>
      <PageHeaderInfoWidget :header-info="headerInfo" />
    </template>
    <NewSellingPlaceForm />
  </PageLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/SellingPlaces/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import NewSellingPlaceForm from "@/modules/selling-places/forms/NewSellingPlaceForm.vue";

@Options({
  components: { PageLayout, PageHeaderInfoWidget, NewSellingPlaceForm }
})
export default class NewSellingPlacePage extends Vue {
  headerInfo: HeaderInfo = {
    icon: "ion ion-md-document",
    section: "Prodajana Mesta",
    title: "Nova"
  };
}
</script>
