<template>
  <TransactionDetailsPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TransactionDetailsPage from "@/structure/Administrator/Transactions/pages/TransactionDetailsPage.vue";

@Options({
  components: { TransactionDetailsPage }
})
export default class Transaction extends Vue {}
</script>
