<template>
  <div>
    <div class="row justify-content-md-center">
    <button type="button" class="btn btn-primary btn-lg" @click="printDiv">
      Štampaj
    </button>
    </div>
    <br><br><br>
    <div id="printMe" v-if="sellingPlaceDetails.market">
      <div class="clan">
        <h2>
          УГОВОР О РЕЗЕРВАЦИЈИ ПИЈАЧНОГ ОБЈЕКТА НА АГРИКО ПИЈАЦИ
        </h2>
      </div>
      <p>
        Закључен дана _________између:
      </p>
      <br><br><br><br><br>
      <div class="printing">
        <p>
          <b>{{ contract.organizationDetails.name }}</b>, ул. {{ address }}, {{ contract.organizationDetails.address.city}},
          МБ: 08010072 , ПИБ: 100911224 кога заступа директор {{ contract.organizationDetails.representative }}, (у
          даљем тексту: Давалац ), са једне стране и
        </p>
      </div>
      <div class="printing">
        <p>
          <b
            >ПГ {{ contract.tenantDetails.firstName }}
            {{ contract.tenantDetails.lastName }}</b
          >
          са седиштем у {{ contract.tenantDetails.address.city }}, ул.
          {{ contract.tenantDetails.address.line1 }} бр.
          {{ contract.tenantDetails.address.line2 }},
          <span v-if="contract.tenantDetails.bpg">БПГ: {{ contract.tenantDetails.bpg }},</span>
          чији је законски заступник
          {{ contract.tenantDetails.firstName }}
          {{ contract.tenantDetails.lastName }} и са ЈМБГ:
          {{ contract.tenantDetails.registrationId }} ( у даљем тексту: Прималац
          ), са друге стране.
        </p>
      </div>
      <div>
        <div class="clan">
          <h4>
            Члан 1.
          </h4>
        </div>
        <div class="printing">
          <p>
            Давалац пружа Примаоцу услугу резервација пијачног објекта
            {{ sellingPlaceDetails.name }}, који се налази под тремом на објекту
            {{ sellingPlaceDetails.market.name }} у
            {{ sellingPlaceDetails.market.address.city }}, у улици
            {{ sellingPlaceDetails.market.address.line1 }}
            {{ sellingPlaceDetails.market.address.line2 }} , у зони продајe
            „{{sellingPlaceDetails.type.sellingArea}}{{sellingPlaceDetails.type.sellingArea}}“.
          </p>
          <p>
            Прималац резервацијом стиче право коришћења пијачног објекта из
            става 1. овог члана по приоритету у односу на друге продавце.
          </p>
          <p>
            Уколико Прималац не отпочне продају производа на предметном пијачном
            објекту до 07:00 часова, губи право на резервацију пијачног објекта
            из става 1. овог члана тог дана и пијачни објекат се даје на
            коришћење другом заинтересованом лицу.
          </p>
        </div>
      </div>

      <div>
        <div class="clan">
          <h4>
            Члан 2.
          </h4>
        </div>
        <div class="printing">
          <p>
            Прималац се обавезује да Даваоцу плати услугу месечне резервације
            пијачног објекта по утврђеном ценовнику Даваоца и то у износу од
            {{ contract.items[0].sellingPlaceDetails.reservationPriceMonthly }}
            4.000,00 динара са ПДВ-ом, најкасније до 5. у месецу за текући
            месец.
            <br />
            Прималац се обавезује да Даваоцу плати, поред резервације из става
            1. овог члана, дневно коришћење пијачног објекта по цени утврђеној
            ценовником Даваоца за дане којима користи пијачни објекат.
            <br />
            Цена коришћења из става 2. овог члана плаћа се на дан када се
            користи пијачни објекат.
          </p>
        </div>
      </div>
      <div>
        <div class="clan">
          <h4>
            Члан 3.
          </h4>
        </div>
        <div class="printing">
          <p>
            Примаоцу се забрањује излагање и вршење продаје свих других производа сем
            {{sellingPlaceDetails.type.sellingBan}}
            на пијачном објекту из члана 1. овог уговора.
          </p>
        </div>
      </div>
      <div>
        <div class="clan">
          <h4>
            Члан 4.
          </h4>
        </div>
        <div class="printing">
          <p>
            Уколико овлашћено лице Даваоца или друго надлежно лице утврди да
            Прималац врши продају производа која нису дозвољена на пијачном
            објекту из члана 1. овог уговора, Прималац губи право на резервацију
            пијачног објекта и истом се неће вршити повраћај новчаних средстава
            за период који је Прималац платио.
          </p>
        </div>
      </div>
      <div>
        <div class="clan">
          <h4>
            Члан 5.
          </h4>
        </div>
        <div class="printing">
          <p>
            Прималац се обавезује да током коришћења пијачног објекта на и око
            истог одржава чистоћу и да по завршетку рада уклони са пијачног
            објекта производе.
          </p>
        </div>
      </div>
      <div>
        <div class="clan">
          <h4>
            Члан 6.
          </h4>
        </div>
        <div class="printing">
          <p>
            Прималац се обавезује да се у потпуности придржава свих законских
            прописа који уређују ову делатност као и одредаба Одлуке о пијацама
            коју је донела Скупштина Града Вршца и Правилника о раду и реду на
            {{ sellingPlaceDetails.market.name }} који је прописало ЈКП Други
            октобар.
          </p>
          <p>
            Прималац не може издавати пијачни објекат из члана 1. овог уговора
            трећем лицу нити истом може пренети било каква права по питању
            резервације и коришћења тог објекта.
          </p>
          <p>
            Прималац је дужан да писменим путем тражи сагласност од Пружаоца за
            сваку промену стања на пијачном објекту из члана 1. овог уговора.
          </p>
          <p>
            Прималац је дужан да након напуштања пијачног објекта, исти врати у
            првобитно стање.
          </p>
        </div>
      </div>
      <div>
        <div class="clan">
          <h4>
            Члан 7.
          </h4>
        </div>
        <div class="printing">
          <p>
            Уговор се закључује на одређено време, у трајању од
            {{ months }} месеци.
          </p>
          <p>
            Уговор ступа на снагу даном потписивања а примењује се од
            {{ rentStartDate }} године.
          </p>
        </div>
      </div>
      <div>
        <div class="clan">
          <h4>
            Члан 8.
          </h4>
        </div>
        <div class="printing">
          <p>
            Уговор престаје да важи истеком времена за који је закључен.
          </p>
          <p>
            Уговорне стране су сагласне да свака страна може једнострано
            отказати Уговор, са отказним роком од 15 дана.
          </p>
          Давалац може раскинути уговор и пре истека уговореног рока у следећим
          случајевима:
          <ul>
            <li>
              уколико Прималац не плати услугу месечне резервације у
              предвиђеном року,
            </li>
            <li>
              уколико Прималац пијачни објекат користи супротно његовој
              намени,
            </li>
            <li>
              уколико Прималац пијачни објекат уступа другом лицу на
              коришћење,
            </li>
            <li>
              уколико се Прималац не придржава одредаба Одлуке о пијацама и
              Правилника о раду и реду на
              {{ sellingPlaceDetails.market.name }} Агрико пијаци,
            </li>
            <li>
              уколико Прималац изврши било какву преправку на пијачном објекту
              без сагласности Пружаоца
            </li>
            <li>
              уколико Пружалац пијачни простор реконструише и приводи некој
              другој намени,
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div class="clan">
          <h4>
            Члан 9.
          </h4>
        </div>
        <div class="printing">
          <p>
            У случају раскида уговора Прималац је у обавези да пијачни објекат
            ослободи од лица и ствари најкасније до дана раскида утврђеног
            обавештењем о раскиду. Уколико то не буде учинио, Давалац је
            овлашћен да комисијски изврши попис ствари и исте уклони, без икакве
            одговорности у погледу евентуалне штете која би из тога проистекла.
          </p>
        </div>
      </div>
      <div>
        <div class="clan">
          <h4>
            Члан 10.
          </h4>
        </div>
        <div class="printing">
          <p>
            Давалац не одговара за било који вид штете који Прималац нанесе
            другом кориснику.
          </p>
          <p>
            Давалац не одговара за било какав вид штете у случају уништавања
            робе или крађе.
          </p>
        </div>
      </div>
      <div>
        <div class="clan">
          <h4>
            Члан 11.
          </h4>
        </div>
        <div class="printing">
          <p>
            Евентуалне спорове по овом уговору решава територијално надлежан суд
            према Даваоцу.
          </p>
        </div>
      </div>
      <div>
        <div class="clan">
          <h4>
            Члан 12.
          </h4>
        </div>
        <div class="printing">
          <p>
            Овај уговор је закључен у три ( 3 ) истоветна примерка, од којих два
            ( 2 ) задржава Давалац, а један ( 1 ) задржава Прималац.
          </p>
        </div>
      </div>
      <div>
        <br />
        <br />
        <br />
        <div class="float-left pl-5">
          ДАВАЛАЦ
          <br />
          __________________________
          <br />
          {{ contract.organizationDetails.representative }}
        </div>
        <div class="float-right pr-5">
          ПРИМАЛАЦ
          <br />

          ________________________
          <br />
          {{ contract.tenantDetails.firstName }}
          {{ contract.tenantDetails.lastName }}
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import ContractTypeApi from "@/modules/contracts/api";
import { FetchContract } from "@/modules/contracts/api/types";
import { Contract } from "@/modules/contracts/types";
import { useStore } from "@/store";
import { FetchMarket } from "@/modules/market/api/types";
import { SellingPlace } from "@/modules/selling-place/types";
import SellingPlaceApi from "@/modules/selling-place/api";
import { FetchSellingPlaceParams } from "@/modules/selling-place/api/types";
import moment from "moment";

@Options({})
export default class Print extends Vue {
  // contractFull!: Contract;
  // contractFull = Object;
  // sellingPlaceDetails!: SellingPlace;
  sellingPlaceDetails: any = Object;
  store = useStore();

  fetchSellingPlaceDetails = AsyncActionFactory(
    SellingPlaceApi.fetchSellingPlaceDetails
  );
  months = 0;
  rentStartDate = "";

  get contract(): Contract {
    return this.store.getters.contract;
  }

  get address() {
    // if (this.contract.organizationDetails.address.line1 && this.contract.organizationDetails.address.line2) {
    //   return this.contract.organizationDetails.address.line1 + ', ' + this.contract.organizationDetails.address.line2;
    // }
    if (this.contract.organizationDetails.address.line1) {
      return this.contract.organizationDetails.address.line1;
    }
    return "-";
  }

  created() {
    // this.contract = this.contractStore;
    // this.fetchContractData(this.contract.id);
    // console.log(33333, this.contract.items[0].sellingPlaceId );

    this.fetchSellingPlaceDetailsData(this.contract.items[0].sellingPlaceId);
    // console.log(
    //   70,
    //   this.contract.items[0].rentStartDate,
    //   this.contract.items[0].rentEndDate
    // );
    this.months = this.monthDiff(
      new Date(this.contract.items[0].rentStartDate),
      new Date(this.contract.items[0].rentEndDate)
    );
    this.rentStartDate = moment(
      String(this.contract.items[0].rentStartDate)
    ).format("DD.MM.YYYY");
  }

  async fetchSellingPlaceDetailsData(sellingPlaceId) {
    // console.log(44444, sellingPlaceId);
    // await this.fetchSellingPlace.call({
    //   marketId: marketId,
    //   sellingPlaceId: sellingPlaceId
    // } as FetchSellingPlaceParams);
    await this.fetchSellingPlaceDetails.call({
      sellingPlaceId: sellingPlaceId
    });
    // console.log(1, sellingPlaceId);
    if (this.fetchSellingPlaceDetails.isSuccessful) {
      // console.log(2, this.fetchSellingPlaceDetails.responseData);
      this.sellingPlaceDetails = this.fetchSellingPlaceDetails.responseData;
      // console.log(21, this.sellingPlaceDetails.market);
    }
  }

  monthDiff(d1: Date, d2: Date) {
    let months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth() + 1;
    return months <= 0 ? 0 : months;
  }

  printDiv() {
    const printContents = document.getElementById("printMe").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
}
</script>

<style scoped>
#printMe {
  margin: 0;
  color: #000;
  background-color: #fff;
}
div.printing {
  text-align: justify;
  text-justify: inter-word;
}

div.clan {
  text-align: center;
}
</style>
