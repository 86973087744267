import { SellingPlaceCategory } from "@/modules/selling-place-category/types";

export default class SellingPlaceCategoryCreatedEvent {
  static readonly Name = "SELLING_PLACE_CATEGORY_CREATED";

  private readonly sellingPlaceCategory: SellingPlaceCategory;

  constructor(sellingPlaceCategory: SellingPlaceCategory) {
    this.sellingPlaceCategory = sellingPlaceCategory;
  }

  getSellingPlaceCategory(): SellingPlaceCategory {
    return this.sellingPlaceCategory;
  }
}
