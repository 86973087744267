
import { Options, Vue } from "vue-class-component";
import SellingPlaceTypeEditRequestedListener from "@/modules/selling-place-type/listeners/SellingPlaceTypeEditRequestedListener.vue";
import SellingPlaceTypeDeleteRequestedListener from "@/modules/selling-place-type/listeners/SellingPlaceTypeDeleteRequestedListener.vue";
import SellingPlaceTypeCreateRequestedListener from "@/modules/selling-place-type/listeners/SellingPlaceTypeCreateRequestedListener.vue";

@Options({
  components: {
    SellingPlaceTypeEditRequestedListener,
    SellingPlaceTypeDeleteRequestedListener,
    SellingPlaceTypeCreateRequestedListener
  }
})
export default class Module extends Vue {}
