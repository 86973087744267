<template>
  <FilterWidget @filter-changed="onFilter($event)" />
  <div class="tenant-list">
    <ul class="list-group list-group-flush">
      <li
        class="list-group-item cursor-pointer"
        v-for="sellingPlace in sellingPlaces"
        :key="sellingPlace"
        :class="{ active: isSellingPlaceSelected(sellingPlace) }"
        @click="selectSellingPlace(sellingPlace)"
      >
        <div class="media align-items-center">
          <div>
            <i :class="sellingPlace.type.icon"></i>
          </div>
          <div class="media-body px-2">
            <span class="text-body">
              {{ sellingPlace.name }}
              <small class="text-muted">({{ sellingPlace.type.name }})</small>
            </span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import { Market } from "@/modules/market/types";
import { SellingPlace, SellingPlaces } from "@/modules/selling-place/types";
import FilterWidget from "@/modules/selling-place/widgets/SellingPlaceSidebar/FilterWidget.vue";

@Options({
  components: { FilterWidget },
  props: {
    sellingPlaces: {
      type: Array,
      required: true
    }
  },
  emits: ["filterChanged"]
})
export default class SellingPlaceListWidget extends Vue {
  sellingPlaces: SellingPlaces = [];

  get market(): Market {
    return useStore().getters.market;
  }

  selectSellingPlace(sellingPlace: SellingPlace) {
    this.$router.push({
      name: "cashier-selling-place",
      params: {
        sellingPlaceId: sellingPlace.id,
        marketId: this.market.id
      }
    });
  }

  onFilter(filter) {
    this.$emit("filterChanged", filter);
  }

  isSellingPlaceSelected(sellingPlace: SellingPlace): boolean {
    return this.$route.params.sellingPlaceId == sellingPlace.id;
  }
}
</script>

<style scoped>
.tenant-list {
  overflow: auto;
  height: 100%;
}
</style>
