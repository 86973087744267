<template>
  <!--  <template v-if="user">-->
  <nav
    class="layout-navbar navbar navbar-expand-lg align-items-lg-center container-p-x bg-navbar-theme"
    id="layout-navbar"
  >
    <div class="app-brand demo d-flex align-items-center">
      <span class="app-brand-logo demo">
        <img
          v-if="headerOptions.logoUrl"
          :src="headerOptions.logoUrl"
          alt
          class="d-block ui-w-30"
        />
        <img v-else src="@/assets/logo.png" alt class="d-block ui-w-30" />
      </span>
      <template v-if="headerOptions.linkRoute">
        <router-link
          :to="headerOptions.linkRoute"
          class="app-brand-text demo sidenav-text font-weight-normal ml-2"
        >
          <span class="demo-brand-name font-weight-normal ml-2">
            {{ headerOptions.title }}
          </span>
        </router-link>
      </template>
      <template v-else>
        <span class="demo-brand-name font-weight-normal ml-2">
          {{ headerOptions.title }}
        </span>
      </template>
    </div>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#layout-navbar-collapse"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse" id="layout-navbar-collapse">
      <!-- Divider -->
      <hr class="d-lg-none w-100 my-2" />

      <div class="navbar-nav align-items-lg-center">
        <slot name="left"></slot>
      </div>

      <div class="navbar-nav align-items-lg-center ml-auto">
        <slot name="center"></slot>
      </div>

      <div class="navbar-nav align-items-lg-center ml-auto">
        <slot name="right"></slot>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { HeaderOptions } from "@/modules/common/types";

@Options({
  props: {
    headerOptions: {
      type: Object,
      required: true
    }
  }
})
export default class HeaderWidget extends Vue {
  headerOptions!: HeaderOptions;
}
</script>
