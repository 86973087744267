import { WorkOrder, WorkOrderList } from "@/modules/work-orders/types";

export function workOrderListItemMapper(workOrder): WorkOrder {
  return {
    ...workOrder
  };
}

export function workOrderListMapper(list): WorkOrderList {
  list.data = list.data.map(workOrderListItem =>
    workOrderListItemMapper(workOrderListItem)
  );
  return list;
}

export function workOrderMapper(workOrder): WorkOrder {
  return {
    ...workOrder
  };
}
