<template>
  <EditModal
    v-if="displayDialog"
    @close="closeModal"
    :title="'Azuriranje'"
    @confirm="onContractNumberingRuleEdited()"
    :showSpinner="isDataLoading"
  >
    <ContractNumberingRuleForm
      v-model:contract-numbering-rule="contractNumberingRule"
      :errors="editContractNumberingRule.errors"
    />
  </EditModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import EditModal from "@/modules/common/modals/EditModal.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import eventHub from "@/components/EventHub";
import ContractNumberingRuleForm from "@/modules/contract-numbering-rules/forms/ContractNumberingRuleForm.vue";
import { NewContractNumberingRuleRequestData } from "@/modules/contract-numbering-rules/api/types";
import { ContractNumberingRules } from "@/modules/contract-numbering-rules/types";
import ContractNumberingRuleApi from "@/modules/contract-numbering-rules/api";
import { FetchContractNumberingRule } from "@/modules/contract-numbering-rules/api/types";
import { ContractNumberingRuleEditRequestedEvent } from "@/modules/contract-numbering-rules/events/ContractNumberingRuleEditRequestedEvent";
import ContractNumberingRuleUpdatedEvent from "@/modules/contract-numbering-rules/events/ContractNumberingRuleUpdatedEvent";
import ContractNumberingRuleNewRequestedEvent from "@/modules/contract-numbering-rules/events/ContractNumberingRuleNewRequestedEvent";
@Options({
  components: { EditModal, ContractNumberingRuleForm }
})
export default class ContractNumberingRuleEditRequestedListener extends Vue {
  displayDialog = false;
  contractNumberingRule!: ContractNumberingRules;

  editContractNumberingRule = AsyncActionFactory(
    ContractNumberingRuleApi.editContractNumberingRule
  );
  fetchContractNumberingRule = AsyncActionFactory(
    ContractNumberingRuleApi.fetchContractNumberingRule as FetchContractNumberingRule
  );

  async created() {
    eventHub.on<ContractNumberingRuleEditRequestedEvent>(
      ContractNumberingRuleEditRequestedEvent.Name,
      this.onEditContractNumberingRuleRequested
    );
  }

  async onEditContractNumberingRuleRequested(
    event?: ContractNumberingRuleEditRequestedEvent
  ) {
    if (!(event instanceof ContractNumberingRuleEditRequestedEvent)) {
      return;
    }

    await this.fetchContractNumberingRule.call(event.getId());

    if (this.fetchContractNumberingRule.isSuccessful) {
      this.contractNumberingRule = this.fetchContractNumberingRule
        .responseData as ContractNumberingRules;
      this.displayDialog = true;
    }
  }

  async onContractNumberingRuleEdited() {
    await this.editContractNumberingRule.call(
      this.contractNumberingRule.id,
      this.contractNumberingRuleRequestData(this.contractNumberingRule)
    );

    if (this.editContractNumberingRule.isSuccessful) {
      eventHub.emit<ContractNumberingRuleUpdatedEvent>(
        ContractNumberingRuleUpdatedEvent.Name,
        new ContractNumberingRuleUpdatedEvent(this.contractNumberingRule)
      );
      this.closeModal();
    }
  }

  contractNumberingRuleRequestData(
    contractNumberingRule: ContractNumberingRules
  ) {
    return {
      year: contractNumberingRule.year,
      nextDocumentNumber: contractNumberingRule.nextDocumentNumber,
      prefix: contractNumberingRule.prefix,
      suffix: contractNumberingRule.suffix,
      addSpaceBetweenElements: contractNumberingRule.addSpaceBetweenElements,
      yearSeparator: contractNumberingRule.yearSeparator,
      minimalDigitsNumber: contractNumberingRule.minimalDigitsNumber,
      formatted: contractNumberingRule.formatted
    };
  }

  closeModal() {
    this.displayDialog = false;
    this.editContractNumberingRule.clear();
  }

  get isDataLoading(): boolean {
    return this.editContractNumberingRule.isBusy;
  }
}
</script>

<style scoped></style>
