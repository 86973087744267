<template>
  <div>
    <div class="form-group row">
      <label class="form-label col-sm-2 col-form-label">
        Razlog otkazivanja:
      </label>
      <div class="col-sm-10">
        <select v-model="contractCancelRequestData.cancellationReasonId">
          <option disabled value="">Odaberi razlog</option>
          <option
            v-for="cancellationReason in cancellationReasons.data"
            v-bind:key="cancellationReason.id"
            v-bind:value="cancellationReason.id"
            >{{ cancellationReason.name }}</option
          >
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="form-label col-sm-2 col-form-label">
        Zabeleška:
      </label>
      <div class="col-sm-10">
        <input
          v-model="contractCancelRequestData.cancellationNote"
          type="text"
        />
      </div>
    </div>
  </div>
  <div class="form-group">
    <small v-if="validationErrors.has('icon')" class="invalid-feedback">
      {{ validationErrors.get("icon") }}
    </small>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import { ContractCancelRequestData } from "@/modules/contracts/api/types";
import { useStore } from "@/store";
import { ContractCancelReasons } from "@/modules/contracts/types";

@Options({
  props: {
    contractCancelRequestData: {
      type: Object,
      required: true
    },
    validationErrors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  }
})
export default class CancelContractForm extends Vue {
  store = useStore();

  validationErrors!: BackendValidationErrors;
  contractCancelRequestData!: ContractCancelRequestData;

  get cancellationReasons() {
    return this.store.getters.contractCancelReasons;
  }
}
</script>

<style scoped></style>
