import {
  ContractNumberingRules,
  ContractNumberingRulesList
} from "@/modules/contract-numbering-rules/types";

import { PostContractNumberingRuleResponse } from "@/modules/contract-numbering-rules/api/types";

export function contractNumberingRuleMapper(
  contractNumberingRule
): ContractNumberingRules {
  return {
    ...contractNumberingRule
  };
}

export function contractNumberingRulesListMapper(
  response
): ContractNumberingRulesList {
  response.data.map(contractNumberingRule =>
    contractNumberingRuleMapper(contractNumberingRule)
  );
  return response;
}

export function postContractNumberingRuleResponseMapper(
  response
): PostContractNumberingRuleResponse {
  return {
    ...response
  };
}
