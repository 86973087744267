
import { Vue } from "vue-class-component";
import { useStore } from "@/store";
import { WorkOrder } from "@/modules/work-orders/types";
import eventHub from "@/components/EventHub";
import { UserRole } from "@/modules/user/types";

export default class DetailsHeaderWidgets extends Vue {
  store = useStore();

  get workOrder(): WorkOrder {
    return this.store.getters.workOrder;
  }

  canUserDeleteWorkOrder(): boolean {
    return this.store.getters.user.hasRole(UserRole.Admin);
  }

  deleteWorkOrder(): void {
    if (!this.canUserDeleteWorkOrder()) return;
    eventHub.emit("DELETE_WORK_ORDER_REQUESTED", this.workOrder);
  }
}
