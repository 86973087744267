<template>
  <TenantDetailsPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TenantDetailsPage from "@/structure/Administrator/Tenant/pages/TenantDetailsPage.vue";

@Options({
  components: { TenantDetailsPage }
})
export default class TenantDetails extends Vue {}
</script>
