export default class SellingPlaceType {
  public id = "";
  public name = "";
  public description = "";
  public icon = "";
  public removable = true;
  public sellingArea = "";
  public sellingBan = "";
  public color = "";
}
