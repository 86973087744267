import { MutationTree } from "vuex";
import { State } from "@/store/state";

export enum MutationType {
  SetSettings = "SET_SETTINGS",
  SetUser = "SET_USER",
  SetMarketList = "SET_MARKET_LIST",
  SetTenant = "SET_TENANT",
  UpdateTenantDetails = "UPDATE_TENANT_DETAILS",
  SetMarket = "SET_MARKET",
  SetSellingPlace = "SET_SELLING_PLACE",
  SetContract = "SET_CONTRACT",
  UpdateMarketDetails = "UPDATE_MARKET_DETAILS",
  UpdateMarketAddress = "UPDATE_MARKET_ADDRESS",
  SetWorkOrder = "SET_WORK_ORDER",
  SetTransaction = "SET_TRANSACTION",
  SetContractCancelReasons = "SET_CONTRACT_CANCEL_REASONS"
}

export type Mutations = {
  [MutationType.SetSettings](state: State, settings): void;
  [MutationType.SetUser](state: State, user): void;
  [MutationType.SetMarketList](state: State, marketList): void;
  [MutationType.SetTenant](state: State, tenant): void;
  [MutationType.UpdateTenantDetails](state: State, tenantDetails): void;
  [MutationType.SetMarket](state: State, market): void;
  [MutationType.UpdateMarketDetails](state: State, marketDetails): void;
  [MutationType.UpdateMarketAddress](state: State, marketAddress): void;
  [MutationType.SetSellingPlace](state: State, sellingPlace): void;
  [MutationType.SetContract](state: State, contract): void;
  [MutationType.SetWorkOrder](state: State, workOrder): void;
  [MutationType.SetTransaction](state: State, transaction): void;
  [MutationType.SetContractCancelReasons](state: State, contractCancellationReason): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationType.SetSettings](state, settings) {
    state.settings = settings;
  },
  [MutationType.SetUser](state, user) {
    state.user = user;
  },
  [MutationType.SetMarketList](state, marketList) {
    state.marketList = marketList;
  },
  [MutationType.SetTenant](state, tenant) {
    state.tenant = tenant;
  },
  [MutationType.UpdateTenantDetails](state, tenantDetails) {
    if (!state.tenant) return;
    state.tenant.firstName = tenantDetails.firstName;
    state.tenant.lastName = tenantDetails.lastName;
    state.tenant.registrationId = tenantDetails.registrationId;
    state.tenant.phoneNumber = tenantDetails.phoneNumber;
    state.tenant.bpg = tenantDetails.bpg;
  },
  [MutationType.SetMarket](state, market) {
    state.market = market;
  },
  [MutationType.SetSellingPlace](state, sellingPlace) {
    state.sellingPlace = sellingPlace;
  },
  [MutationType.SetContract](state, contract) {
    state.contract = contract;
  },
  [MutationType.UpdateMarketDetails](state, marketDetails) {
    if (!state.market) return;
    state.market.name = marketDetails.name;
    state.market.description = marketDetails.description;
    state.market.icon = marketDetails.icon;
  },
  [MutationType.UpdateMarketAddress](state, marketAddress) {
    if (!state.market) return;
    state.market.address = marketAddress;
  },
  [MutationType.SetWorkOrder](state, workOrder) {
    state.workOrder = workOrder;
  },
  [MutationType.SetTransaction](state, transaction) {
    state.transaction = transaction;
  },
  [MutationType.SetContractCancelReasons](state, contractCancellationReason) {
    state.contractCancelReasons = contractCancellationReason;
  }
};
