
import { Options, Vue } from "vue-class-component";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import eventHub from "@/components/EventHub";
import ContractCancellationReasonDeleteRequestedEvent from "@/modules/contracts-cancellation-reason/events/ContractCancellationReasonDeleteRequestedEvent";
import { ContractCancellationReasonEditRequestedEvent } from "@/modules/contracts-cancellation-reason/events/ContractCancellationReasonEditRequestedEvent";
import { ContractCancellationReason } from "@/modules/contracts-cancellation-reason/types";

@Options({
  components: { Spinner },
  props: {
    contractCancellationReason: {
      type: Object,
      required: true
    }
  }
})
export default class ContractCancellationReasonListItemWidget extends Vue {
  contractCancellationReason!: ContractCancellationReason;
  isEditHovering = false;
  isDeleteHovering = false;

  editContractCancellationReason(
    contractCancellationReason: ContractCancellationReason
  ) {
    eventHub.emit<ContractCancellationReasonEditRequestedEvent>(
      ContractCancellationReasonEditRequestedEvent.Name,
      new ContractCancellationReasonEditRequestedEvent(
        contractCancellationReason.id
      )
    );
  }

  deleteContractCancellationReason(
    contractCancellationReason: ContractCancellationReason
  ) {
    eventHub.emit<ContractCancellationReasonDeleteRequestedEvent>(
      ContractCancellationReasonDeleteRequestedEvent.Name,
      new ContractCancellationReasonDeleteRequestedEvent(
        contractCancellationReason
      )
    );
  }
}
