import { RouteRecordRaw } from "vue-router";
import PassThroughViewSettings from "./widgets/PassThroughViewSettings.vue";
import Settings from "@/structure/Administrator/Settings/views/Settings.vue";
import Users from "@/structure/Administrator/Settings/views/Users.vue";
import SellingPlace from "@/structure/Administrator/Settings/views/SellingPlace.vue";
import Contract from "@/structure/Administrator/Settings/views/Contract.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "settings",
    name: "administrator-settings",
    component: PassThroughViewSettings,
    redirect: () => ({
      name: "administrator-settings-homepage"
    }),
    children: [
      {
        path: "",
        name: "administrator-settings-homepage",
        component: Settings,
        meta: {
          title: ""
        }
      },
      {
        path: "users",
        name: "administrator-settings-users",
        component: Users,
        meta: {
          title: "Users"
        }
      },
      {
        path: "selling-place",
        name: "administrator-settings-selling-place",
        component: SellingPlace,
        meta: {
          title: "Selling Place"
        }
      },
      {
        path: "contract",
        name: "administrator-settings-contract",
        component: Contract,
        meta: {
          title: "Contract"
        }
      }
    ]
  }
];

export default routes;
