import { BreadcrumbItem, BreadcrumbItems } from "@/modules/common/types";
import getBasicBreadcrumbItems from "@/structure/Administrator/common/utils/getBreadcrumbItems.ts";
import { Transaction } from "@/modules/transactions/types";

function createTransactionsItem(): BreadcrumbItem {
  return {
    name: "Transakcije",
    link: {
      name: "administrator-transactions"
    }
  };
}

function createTransactionItem(transaction: Transaction): BreadcrumbItem {
  return {
    name: transaction.name,
    link: {
      name: "administrator-transaction",
      params: {
        transactionId: transaction.id
      }
    }
  };
}

const getBreadcrumbItems = function(
  moduleName: string,
  transaction?: Transaction
) {
  const items: BreadcrumbItems = [];

  items.push(createTransactionsItem());
  if (transaction) {
    items.push(createTransactionItem(transaction));
  }

  return getBasicBreadcrumbItems(moduleName, items);
};

export default getBreadcrumbItems;
