<template>
  <div
    class="card p-3 mb-3"
    style="display: flex; flex-direction: row; align-items: center;"
  >
    <!--    <div class="media align-items-center">-->
    <!--      <img-->
    <!--        src="@/assets/company.png"-->
    <!--        class="d-block ui-w-40 rounded-circle mr-3"-->
    <!--        alt=""-->
    <!--      />-->
    <!--    </div>-->
    <div class="media-body flex-basis-auto">
      <div>
        <strong @click="transactionSelected()" class="cursor-pointer">
          {{ transaction.name }}
        </strong>
      </div>
    </div>

    <div>
      <button
        @click="transactionSelected()"
        class="btn btn-outline-secondary btn-sm"
      >
        {{ $t("transaction.Details") }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Transaction } from "@/modules/transactions/types";

@Options({
  props: {
    transaction: {
      type: Object,
      required: true,
      default: {}
    }
  }
})
export default class TransactionListWidget extends Vue {
  transaction!: Transaction;

  transactionSelected() {
    this.$router.push({
      name: "administrator-transaction",
      params: {
        transactionId: this.transaction.id
      }
    });
  }
}
</script>
