
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Settings/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import GeneralWidget from "@/structure/Administrator/Settings/widgets/GeneralWidget.vue";
import { $t } from "@/language";

@Options({
  components: { PageLayout, PageHeaderInfoWidget, GeneralWidget }
})
export default class SettingsPage extends Vue {
  headerInfo: HeaderInfo = {
    icon: "ion ion-md-settings",
    section: "Podešavanja"
  };
}
