import { RouteRecordRaw } from "vue-router";
import PassThroughViewSellingPlace from "../widgets/PassThroughViewSellingPlace.vue";
import DetailsPage from "@/structure/Cashier/SellingPlace/pages/DetailsPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: ":sellingPlaceId",
    name: "cashier-selling-place",
    component: PassThroughViewSellingPlace,
    redirect: to => ({
      name: "cashier-selling-place-home",
      params: {
        marketId: to.params.marketId,
        sellingPlaceId: to.params.sellingPlaceId
      }
    }),
    children: [
      {
        path: "",
        name: "cashier-selling-place-home",
        component: DetailsPage,
        meta: {
          title: "Details"
        }
      }
    ]
  }
];

export default routes;
