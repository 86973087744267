<template>
  <PageLayout>
    <template v-slot:page-breadcrumbs>
      <Breadcrumbs :items="breadcrumbItems" />
    </template>
    <template v-slot:page-name>
      <PageHeaderInfoWidget :header-info="headerInfo" />
    </template>
    <template v-slot:page-widgets>
      <DetailsHeaderWidgets />
    </template>

    <div class="row">
      <div class="col-lg-6">
        <h4>Detalji Radnog Naloga</h4>
        <WorkOrderDetailsWidget :workOrder="workOrder" />
      </div>
      <div class="col-lg-6">
        <h4>Detalji Inkasanta</h4>
        <UserDetailsWidget :user="workOrder.cashier" />
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-lg-6">
        <h4>{{ workOrder.market.name }} - Market Details</h4>
        <AddressDetailsWidget :address="workOrder.market.address" />
      </div>
      <div class="col-lg-6">
        <h4>Detalji Sastavljača</h4>
        <UserDetailsWidget :user="workOrder.user" />
      </div>
    </div>
  </PageLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Contracts/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
import { BreadcrumbItems } from "@/modules/common/types";
import getBreadcrumbItems from "../utils/getBreadcrumbItems";
import { WorkOrder } from "@/modules/work-orders/types";
import { useStore } from "@/store";
import WorkOrderDetailsWidget from "@/modules/work-orders/widgets/WorkOrderDetailsWidget.vue";
import AddressDetailsWidget from "@/modules/common/widgets/AddressDetailsWidget.vue";
import UserDetailsWidget from "@/modules/user/widgets/UserDetailsWidget.vue";
import DetailsHeaderWidgets from "@/structure/Administrator/WorkOrders/widgets/DetailsHeaderWidgets.vue";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    Breadcrumbs,
    WorkOrderDetailsWidget,
    AddressDetailsWidget,
    UserDetailsWidget,
    DetailsHeaderWidgets
  }
})
export default class WorkOrderDetailsPage extends Vue {
  store = useStore();

  get workOrder(): WorkOrder {
    return this.store.getters.workOrder;
  }

  get headerInfo(): HeaderInfo {
    return {
      icon: "ion ion-md-document",
      section: this.workOrder ? this.workOrder.name : ""
    };
  }

  get breadcrumbItems(): BreadcrumbItems {
    return getBreadcrumbItems("Detalji", this.workOrder);
  }
}
</script>
