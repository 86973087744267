import { RouteRecordRaw } from "vue-router";
import PassThroughViewAdministrator from "./common/widgets/PassThroughViewAdministrator.vue";
import marketsRoutes from "@/structure/Administrator/Markets/routes";
import marketRoutes from "@/structure/Administrator/Market/routes";
import tenantsRoutes from "@/structure/Administrator/Tenant/routes";
import transactionsRoutes from "@/structure/Administrator/Transactions/routes/routes";
import transactionRoutes from "@/structure/Administrator/Transactions/routes/transaction-routes";
import workOrdersRoutes from "@/structure/Administrator/WorkOrders/routes/work-orders-routes";
import workOrderRoutes from "@/structure/Administrator/WorkOrders/routes/work-order-routes";
import contractsRoutes from "@/structure/Administrator/Contracts/routes/contracts-routes";
import reportsRoutes from "@/structure/Administrator/Reports/routes";
import settingsRoutes from "@/structure/Administrator/Settings/routes";
import dashboardRoutes from "@/structure/Administrator/Dashboard/routes";
import contractRoutes from "@/structure/Administrator/Contracts/routes/contract-routes";
import sellingPlacesRoutes from "@/structure/Administrator/SellingPlaces/routes/routes";
import sellingPlaceRoutes from "@/structure/Administrator/SellingPlaces/routes/selling-places-routes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/administrator",
    name: "administrator",
    component: PassThroughViewAdministrator,
    children: [
      ...dashboardRoutes,
      ...marketsRoutes,
      ...marketRoutes,
      ...transactionsRoutes,
      ...transactionRoutes,
      ...workOrdersRoutes,
      ...workOrderRoutes,
      ...tenantsRoutes,
      ...contractsRoutes,
      ...contractRoutes,
      ...reportsRoutes,
      ...settingsRoutes,
      ...sellingPlacesRoutes,
      ...sellingPlaceRoutes
    ]
  }
];

export default routes;
