<template>
  <div
    class="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4"
    :style="{ 'background-image': 'url(' + backgroundImageUrl + ')' }"
  >
    <div class="ui-bg-overlay bg-dark opacity-25"></div>

    <div class="authentication-inner py-5">
      <div class="card">
        <div class="p-4 p-sm-5">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    backgroundImageUrl: {
      type: String,
      required: false,
      default: ""
    }
  }
})
export default class BoxLayout extends Vue {
  backgroundImageUrl!: string;
}
</script>
