import { Address } from "@/modules/common/types";

export const emptyAddress: Address = {
  line1: "",
  line2: "",
  postalCode: "",
  city: "",
  country: "",
  state: ""
};
