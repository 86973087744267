
import { Options, Vue } from "vue-class-component";
import { Links } from "@/modules/common/types";
import { User } from "@/modules/user/types";

@Options({
  props: {
    user: {
      type: Object,
      required: true
    },
    profileMenuLinks: {
      type: Array,
      required: true
    }
  }
})
export default class UserProfileMenu extends Vue {
  user!: User;
  profileMenuLinks!: Links;
}
