
import { Options, Vue } from "vue-class-component";
import UserCreateRequestedListener from "@/modules/user/listeners/UserCreateRequestedListener.vue";
import UserDeleteRequestedListener from "@/modules/user/listeners/UserDeleteRequestedListener.vue";
import UserEditRequestedListener from "@/modules/user/listeners/UserEditRequestedListener.vue";
import UserEditPasswordRequestedListener from "@/modules/user/listeners/UserEditPasswordRequestedListener.vue";

@Options({
  components: {
    UserCreateRequestedListener,
    UserDeleteRequestedListener,
    UserEditRequestedListener,
    UserEditPasswordRequestedListener
  }
})
export default class Module extends Vue {}
