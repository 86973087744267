<template>
  <router-link
    type="button"
    class="btn btn-default btn-outline-primary"
    :to="{ name: 'change-password' }"
  >
    <i class="ion ion-ios-key"></i>
    Promena Lozinke
  </router-link>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class extends Vue {}
</script>
