<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Ime
        </div>
        <div>
          {{ user.firstName }}
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Prezime
        </div>
        <div>
          {{ user.lastName }}
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Email
        </div>
        <div>
          {{ user.email }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { UserDetails } from "@/modules/user/types";
import AddressDetailsWidget from "@/modules/common/widgets/AddressDetailsWidget.vue";

@Options({
  props: {
    user: {
      required: true
    }
  },

  components: { AddressDetailsWidget }
})
export default class UserDetailsWidget extends Vue {
  user!: UserDetails;
}
</script>
