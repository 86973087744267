<template>
  <TenantHomePage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TenantHomePage from "@/structure/Cashier/Tenant/pages/TenantHomePage.vue";

@Options({
  components: { TenantHomePage }
})
export default class TenantHome extends Vue {}
</script>
