<style>
.draggable {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin: 1rem 0 0 1rem;
  color: #fff;
  padding: 2px;
  text-align: center;
  font-size: 12px;
  touch-action: none;
}
.draggable p {
  margin: 1px;
}
/* The heart of the matter */
.testimonial-group > .row {
  overflow-x: auto;
  white-space: nowrap;
}
.testimonial-group > .row > .draggable {
  display: inline-block;
  float: none;
}
.map {
  min-width: 1200px;
  min-height: 800px;
  border: 1px solid black;
  background-color: lightgrey;
}
</style>
<template>
  <PageLayout>
    <template v-slot:page-breadcrumbs>
      <Breadcrumbs :items="breadcrumbItems" />
    </template>
    <template v-slot:page-name>
      <PageHeaderInfoWidget :header-info="headerInfo" />
    </template>
    <template v-slot:page-widgets>
      <HeaderWidgets />
    </template>
    <template v-slot:page-tabs>
      <TabBarWidget :items="tabItems" />
    </template>

    <Spinner :show-spinner="isDataLoading">
      <div class="container testimonial-group">
        <div class="row">
          <div class="map">
            <div
              v-for="(sp, i) in sellingPlaceList.data"
              id="drag-1"
              :key="i"
              class="draggable"
              :data-x="sp.x"
              :data-y="sp.y"
              :data-sp-id="sp.id"
              :style="getTransform(sp.x, sp.y) + 'background-color:' + sp.type.color"
            >
              <button
                @click="generateQRCode(sp)"
                class="btn btn-default btn-xs"
              >
                <i class="fa fa-qrcode" style="color: white"></i>
              </button>
              <button
                @click="sellingPlaceSelected(sp.id)"
                class="btn btn-default btn-xs"
              >
                <i class="fa fa-link" style="color: white"></i>
              </button>
              <p>{{ sp.name }}</p>
              <p>{{ sp.category.name }}</p>
              <i :class="sp.type.icon"></i>
            </div>
          </div>
        </div>
      </div>
    </Spinner>
  </PageLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Market/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo, TabItems } from "@/modules/common/types";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
import { BreadcrumbItems } from "@/modules/common/types";
import getBreadcrumbItems from "../utils/getBreadcrumbItems";
import { useStore } from "@/store";
import HeaderWidgets from "@/structure/Administrator/Market/widgets/HeaderWidgets.vue";
import TabBarWidget from "@/modules/common/widgets/TabBarWidget.vue";
import getTabItems from "@/structure/Administrator/Market/utils/getTabItems";
import SellingPlaceListWidget from "@/modules/selling-place/widgets/SellingPlaceList/SellingPlaceListWidget.vue";
import { Market } from "@/modules/market/types";
import eventHub from "@/components/EventHub";
import interact from "interactjs";
import { SellingPlace, SellingPlaceList } from "@/modules/selling-place/types";
import { emptySellingPlaceList } from "@/modules/selling-place/types/EmptySellingPlaceList";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import {
  FetchSellingPlaces,
  FetchSellingPlacesParams,
  UpdateSellingPlaceCoordinates,
  UpdateSellingPlaceCoordinatesParams
} from "@/modules/selling-place/api/types";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import { useRoute } from "vue-router";
import SellingPlaceApi from "@/modules/selling-place/api";
import { toast } from "@/components/toast";
import QRCode from "qrcode";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    Breadcrumbs,
    HeaderWidgets,
    TabBarWidget,
    SellingPlaceListWidget,
    Spinner
  }
})
export default class MapPage extends Vue {
  store = useStore();
  route = useRoute();
  timeout: any = null;
  sellingPlaceList: SellingPlaceList = emptySellingPlaceList;
  element: any = null;
  fetchSellingPlaceList = AsyncActionFactory(
    SellingPlaceApi.fetchSellingPlaces as FetchSellingPlaces
  );
  updateSellingPlaceCoordinates = AsyncActionFactory(
    SellingPlaceApi.updateSellingPlaceCoordinates as UpdateSellingPlaceCoordinates
  );

  created() {
    eventHub.on("SELLING_PLACE_VIEW_REQUESTED", this.onSellingPlaceSelected);
    this.loadData();
  }

  mounted() {
    this.element = document.getElementById("grid-snap");

    interact(".draggable").draggable({
      // enable inertial throwing
      inertia: true,
      // keep the element within the area of it's parent
      modifiers: [
        interact.modifiers.snap({
          targets: [interact.snappers.grid({ x: 15, y: 15 })],
          range: Infinity,
          relativePoints: [{ x: 0, y: 0 }]
        }),
        interact.modifiers.restrictRect({
          restriction: "parent",
          endOnly: true
        })
      ],
      // enable autoScroll
      autoScroll: true,

      listeners: {
        // call this function on every dragmove event
        move: this.dragMoveListener
      }
    });
  }

  get market(): Market {
    return this.store.getters.market;
  }

  get headerInfo(): HeaderInfo {
    return {
      icon: this.market ? this.market.icon : "ion ion-md-cube",
      section: this.market ? this.market.name : "",
      title: "Selling Places",
      description: this.market ? this.market.description : ""
    };
  }

  get tabItems(): TabItems {
    return getTabItems(this.market);
  }

  get breadcrumbItems(): BreadcrumbItems {
    return getBreadcrumbItems("Selling Places", this.market);
  }

  async onSellingPlaceSelected(sellingPlaceId) {
    await this.$router.push({
      name: "administrator-market-selling-place",
      params: {
        marketId: this.market.id,
        sellingPlaceId: sellingPlaceId
      }
    });
  }

  async loadData() {
    await this.fetchSellingPlaceList.call({
      marketId: this.route.params.marketId,
      page: 1,
      perPage: 99999
    } as FetchSellingPlacesParams);

    if (this.fetchSellingPlaceList.isSuccessful) {
      this.sellingPlaceList = this.fetchSellingPlaceList
        .responseData as SellingPlaceList;
    }
  }

  get isDataLoading(): boolean {
    return this.fetchSellingPlaceList.isBusy;
  }

  sellingPlaceSelected(id) {
    this.$router.push({
      name: "administrator-market-selling-place",
      params: {
        marketId: this.market.id,
        sellingPlaceId: id
      }
    });
  }

  getTransform(x, y) {
    return "transform: translate(" + x + "px, " + y + "px);";
  }

  dragMoveListener(event) {
    clearTimeout(this.timeout);
    const target = event.target;
    // keep the dragged position in the data-x/data-y attributes
    const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
    const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

    // translate the element
    target.style.transform = "translate(" + x + "px, " + y + "px)";

    // update the posiion attributes
    target.setAttribute("data-x", x);
    target.setAttribute("data-y", y);

    this.timeout = setTimeout(() => {
      this.setSellingPlaceCoordinates(event.target.attributes[4].value, x, y);
    }, 400);
  }

  async setSellingPlaceCoordinates(id: string, x: string, y: string) {
    await this.updateSellingPlaceCoordinates.call({
      marketId: this.route.params.marketId,
      sellingPlaceId: id,
      x: x,
      y: y
    } as UpdateSellingPlaceCoordinatesParams);

    if (this.updateSellingPlaceCoordinates.isSuccessful) {
      toast.success("Koordinate sačuvane!");
    }
  }

  async generateQRCode(sp: SellingPlace) {
    const qr = await QRCode.toDataURL(
      window.location.origin +
        this.$router.resolve({
          name: "cashier-selling-place-home",
          params: {
            marketId: this.route.params.marketId,
            sellingPlaceId: sp.id
          }
        }).fullPath,
      { errorCorrectionLevel: "H" }
    );

    const a = document.createElement("a");
    a.href = qr;
    a.download = sp.name + "_" + sp.type.name + "_" + sp.category.name + (new Date()).toDateString() +".png";
    a.click();
  }
}
</script>
