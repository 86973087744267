
import { Options, Vue } from "vue-class-component";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";

import UserLoader from "../loaders/UserLoader";
import SettingsLoader from "../loaders/SettingsLoader";

import AppHomepageRouteWatcher from "../watchers/AppHomepageRouteWatcher.vue";
import TenantViewRequestedEventListener from "@/structure/common/listeners/TenantViewRequestedEventListener";
import ContractEditEventListener from "@/structure/common/listeners/ContractEditEventListener";
import ContractCreateEventListener from "@/structure/common/listeners/ContractCreateEventListener";
@Options({
  components: {
    Spinner,
    SettingsLoader,
    UserLoader,
    AppHomepageRouteWatcher
  }
})
export default class extends Vue {
  settingsLoader: SettingsLoader = new SettingsLoader();
  userLoader: UserLoader = new UserLoader();
  listeners: Array<{}> = [];

  async created() {
    await this.settingsLoader.load();
    await this.userLoader.load();

    if (!this.userLoader.isDataLoaded()) {
      await this.$router.push({
        name: "login"
      });
    }

    this.loadListeners();
  }

  beforeUnmount() {
    this.settingsLoader.clearSettings();
    this.userLoader.clearUser();
    this.clearListeners();
  }

  get isDataLoading(): boolean {
    return (
      this.settingsLoader.isDataLoading() || this.userLoader.isDataLoading()
    );
  }

  loadListeners(): void {
    this.listeners.push(new TenantViewRequestedEventListener());
    // this.listeners.push(new ContractEditEventListener());
    this.listeners.push(new ContractCreateEventListener());
  }

  clearListeners(): void {
    this.listeners = [];
  }
}
