<template>
  <Spinner :show-spinner="isDataLoading">
    <div class="selling-place-category-list">
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item"
          v-for="sellingPlaceCategory in sellingPlaceCategories"
          :key="sellingPlaceCategory.id"
        >
          <SellingPlaceCategoryListItemWidget
            :selling-place-category="sellingPlaceCategory"
          />
        </li>
      </ul>
    </div>
  </Spinner>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import { inject } from "vue";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import SellingPlaceCategoryListItemWidget from "./SellingPlaceCategoryListItemWidget.vue";
import {
  FetchSellingPlaceCategories,
  FetchSellingPlaceCategory
} from "@/modules/selling-place-category/api/types";
import eventHub from "@/components/EventHub";
import SellingPlaceCategoryCreatedEvent from "@/modules/selling-place-category/events/SellingPlaceCategoryCreatedEvent";
import SellingPlaceCategoryDeletedEvent from "@/modules/selling-place-category/events/SellingPlaceCategoryDeletedEvent";
import SellingPlaceCategoryUpdatedEvent from "@/modules/selling-place-category/events/SellingPlaceCategoryUpdatedEvent";
import {
  SellingPlaceCategory,
  SellingPlaceCategoryList
} from "@/modules/selling-place-category/types";

@Options({
  components: { Spinner, SellingPlaceCategoryListItemWidget }
})
export default class SellingPlaceCategoryListWidget extends Vue {
  sellingPlaceCategories: Array<SellingPlaceCategory> = [];
  filteredSellingPlaceCategories: Array<SellingPlaceCategory> = [];
  dataLoaded = false;

  fetchSellingPlaceCategories = AsyncActionFactory(
    inject("fetchSellingPlaceCategories") as FetchSellingPlaceCategories
  );

  fetchSellingPlaceCategory = AsyncActionFactory(
    inject("fetchSellingPlaceCategory") as FetchSellingPlaceCategory
  );

  created() {
    this.loadData();
    eventHub.on<SellingPlaceCategoryUpdatedEvent>(
      SellingPlaceCategoryUpdatedEvent.Name,
      this.onSellingPlaceCategoryEdited
    );
    eventHub.on<SellingPlaceCategoryCreatedEvent>(
      SellingPlaceCategoryCreatedEvent.Name,
      this.onSellingPlaceCategoryCreated
    );
    eventHub.on<SellingPlaceCategoryDeletedEvent>(
      SellingPlaceCategoryDeletedEvent.Name,
      this.onSellingPlaceCategoryDeleted
    );
  }

  onSellingPlaceCategoryCreated(event?: SellingPlaceCategoryCreatedEvent) {
    if (!(event instanceof SellingPlaceCategoryCreatedEvent)) {
      return;
    }

    this.addSellingPlaceCategoryToList(event.getSellingPlaceCategory());
  }

  onSellingPlaceCategoryEdited(event?: SellingPlaceCategoryUpdatedEvent) {
    if (!(event instanceof SellingPlaceCategoryUpdatedEvent)) {
      return;
    }
    this.replaceSellingPlaceCategoryInList(event.getSellingPlaceCategory());
  }

  onSellingPlaceCategoryDeleted(event?: SellingPlaceCategoryDeletedEvent) {
    if (!(event instanceof SellingPlaceCategoryDeletedEvent)) {
      return;
    }

    this.removeSellingPlaceCategoryFromList(event.getSellingPlaceCategory());
  }

  addSellingPlaceCategoryToList(sellingPlaceCategory: SellingPlaceCategory) {
    this.sellingPlaceCategories.push(sellingPlaceCategory);
  }

  async loadData() {
    await this.fetchSellingPlaceCategories.call();

    if (this.fetchSellingPlaceCategories.isSuccessful) {
      this.sellingPlaceCategories = (this.fetchSellingPlaceCategories
        .responseData as SellingPlaceCategoryList).data;
      this.dataLoaded = true;
    }
  }

  replaceSellingPlaceCategoryInList(
    sellingPlaceCategory: SellingPlaceCategory
  ) {
    this.sellingPlaceCategories.forEach((item, i) => {
      if (item.id == sellingPlaceCategory.id) {
        this.sellingPlaceCategories[i] = sellingPlaceCategory;
      }
    });
  }

  removeSellingPlaceCategoryFromList(
    sellingPlaceCategory: SellingPlaceCategory
  ) {
    this.sellingPlaceCategories = this.sellingPlaceCategories.filter(
      sellingPlaceCategoryItem =>
        sellingPlaceCategoryItem.id !== sellingPlaceCategory.id
    );
    this.filteredSellingPlaceCategories = this.sellingPlaceCategories;
  }
  get isDataLoading(): boolean {
    return this.fetchSellingPlaceCategories.isBusy || !this.dataLoaded;
  }
}
</script>
