
import { Options, Vue } from "vue-class-component";
import AppHncfLayout from "@/structure/Administrator/common/layout/AppHncfLayout.vue";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
import { UserRole, User } from "@/modules/user/types";
import ContractViewRequestedEventListener from "@/structure/Administrator/common/listeners/ContractViewRequestedEventListener";

@Options({
  components: { AppHncfLayout },
  watch: {
    "$route.name"() {
      this.rerouteUser();
    }
  }
})
export default class extends Vue {
  store = useStore();
  router = useRouter();
  route = useRoute();
  listeners: Array<{}> = [];

  async created() {
    await this.rerouteUser();
    this.loadListeners();
  }

  beforeUnmount() {
    this.clearListeners();
  }

  async rerouteUser() {
    if (this.route.name !== "administrator") return;
    if (!this.user.hasRole(UserRole.Clerk)) {
      await this.router.push({
        name: "homepage"
      });
      return;
    }

    if (this.route.name === "administrator") {
      await this.router.push({
        name: "administrator-dashboard"
      });
    }
  }

  get user(): User {
    return this.store.getters.user;
  }

  loadListeners(): void {
    this.listeners.push(new ContractViewRequestedEventListener());
  }

  clearListeners(): void {
    this.listeners = [];
  }
}
