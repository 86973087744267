<template>
  <label class="form-label">Pijaca</label>
  <select
    class="form-control form-control"
    v-model="market"
    @change="selectMarket()"
  >
    <option value="">Sve</option>
    <option
      v-for="market in markets"
      :value="market.id"
      :key="market"
      >{{ market.name }}
    </option>
  </select>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import { MarketList } from "@/modules/market/types";

@Options({
  props: {
    marketId: {
      type: String,
      required: false,
      default: ""
    }
  },
  emits: ["updateMarket"]
})
export default class MarketSelector extends Vue {
  marketId!: string;

  store = useStore();
  market = this.marketId;

  get markets(): MarketList {
    return this.store.getters.marketList;
  }

  selectMarket() {
    this.$emit("updateMarket", this.market);
  }
}
</script>
