
import { Options, Vue } from "vue-class-component";
import BoxLayout from "@/modules/common/layouts/BoxLayout.vue";

@Options({
  components: { BoxLayout }
})
export default class PageLayout extends Vue {
  backgroundImageUrl = require("@/assets/background.png");
}
