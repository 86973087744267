import { RouteRecordRaw } from "vue-router";
import PassThroughViewMarket from "@/structure/Cashier/Market/widgets/PassThroughViewMarket.vue";
import Market from "@/structure/Cashier/Market/views/Market.vue";
import sellingPlacesRoutes from "../../SellingPlace/routes";

const routes: Array<RouteRecordRaw> = [
  {
    path: ":marketId",
    name: "cashier-market",
    component: PassThroughViewMarket,
    redirect: to => ({
      name: "cashier-market-homepage",
      params: {
        marketId: to.params.marketId
      }
    }),
    children: [
      {
        path: "",
        name: "cashier-market-homepage",
        component: Market
      },
      ...sellingPlacesRoutes
    ]
  }
];

export default routes;
