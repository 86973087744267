
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import { inject } from "vue";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import ListWidget from "@/modules/common/widgets/ListWidget";
import SellingPlaceListItemWidget from "./SellingPlaceListItemWidget.vue";
import { useRoute } from "vue-router";
import FilterWidget from "@/modules/selling-place/widgets/SellingPlaceList/FilterWidget.vue";
import {
  FetchSellingPlaces,
  FetchSellingPlacesParams,
  SellingPlaceListFilter
} from "@/modules/selling-place/api/types";
import { emptySellingPlaceFilter } from "@/modules/selling-place/types/EmptySellingPlaceFilter";
import { emptySellingPlaceList } from "@/modules/selling-place/types/EmptySellingPlaceList";
import { SellingPlaceList } from "@/modules/selling-place/types";

@Options({
  components: { Spinner, ListWidget, SellingPlaceListItemWidget, FilterWidget }
})
export default class SellingPlaceListWidget extends Vue {
  sellingPlaceList: SellingPlaceList = emptySellingPlaceList;
  filter: SellingPlaceListFilter = emptySellingPlaceFilter;
  page = 1;

  route = useRoute();

  types: Array<string> = [];

  fetchSellingPlaceList = AsyncActionFactory(
    inject("fetchSellingPlaces") as FetchSellingPlaces
  );

  async created() {
    await this.loadData();
  }

  async loadData() {
    await this.fetchSellingPlaceList.call({
      marketId: this.route.params.marketId,
      page: this.page,
      perPage: 20,
      searchTerm: this.filter.searchTerm,
      type: this.filter.typeId,
      category: this.filter.categoryId
    } as FetchSellingPlacesParams);

    if (this.fetchSellingPlaceList.isSuccessful) {
      this.sellingPlaceList = this.fetchSellingPlaceList
        .responseData as SellingPlaceList;
    }
  }

  get isDataLoading(): boolean {
    return this.fetchSellingPlaceList.isBusy;
  }

  onSearch(filter: SellingPlaceListFilter) {
    this.filter = filter;
    this.loadData();
  }

  onPaginate(page) {
    this.page = page;
    this.loadData();
  }
}
