<template>
  <img
    class="my-2"
    data-toggle="modal"
    data-target="#modals-edit-logo"
    :src="settings.organizationLogoUrl"
    alt=""
    width="150"
  />
  <div class="d-flex flex-row">
    <a
      class="btn btn-sm btn-default mb-3"
      href="javascript:void(0)"
      data-toggle="modal"
      data-target="#modals-edit-logo"
      @click="$refs.fileInput.click()"
      >{{ $t("settings.change logo") }}</a
    ><a
      v-if="showRemoveButton"
      class="btn btn-sm btn-danger mb-3 ml-3"
      href="javascript:void(0)"
      data-toggle="modal"
      data-target="#modals-edit-logo"
      @click="onRemoveLogo"
      ><i class="ion ion-md-trash"
    /></a>
  </div>

  <input
    type="file"
    @change="handleFileChange"
    ref="fileInput"
    accept="image/*"
    required
    style="display: none"
  />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import { Settings } from "@/modules/settings/types";

@Options({
  emits: ["logoChanged", "logoRemoved"]
})
export default class OrganizationLogoWidget extends Vue {
  store = useStore();

  file: File | null = null;

  get settings(): Settings {
    return this.store.getters.settings;
  }

  handleFileChange(event) {
    const files = event.target.files;
    this.file = files.length > 0 ? files[0] : null;
    if (this.validateFile()) {
      this.$emit("logoChanged", this.file);
    }
  }

  validateFile(): boolean {
    return this.file !== null;
  }

  onRemoveLogo() {
    this.$emit("logoRemoved");
  }

  get showRemoveButton(): boolean {
    return this.settings.organizationLogoUrl !== "/img/logo.png";
  }
}
</script>
