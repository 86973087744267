<template>
  <PageLayout>
    <template v-slot:page-name>
      <PageHeaderInfoWidget :header-info="headerInfo" />
    </template>
    <template v-slot:page-widgets>
      <HeaderWidgets />
    </template>
    <MarketListWidget @market-selected="onMarketSelected($event)" />
  </PageLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Markets/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import MarketListWidget from "@/modules/market/widgets/MarketListWidget/MarketListWidget.vue";
import HeaderWidgets from "../widgets/HeaderWidgets.vue";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    MarketListWidget,
    HeaderWidgets
  }
})
export default class MarketsHomePage extends Vue {
  headerInfo: HeaderInfo = {
    icon: "ion ion-md-cube",
    section: "Pijace"
  };

  async onMarketSelected(marketId: string) {
    await this.$router.push({
      name: "administrator-market",
      params: {
        marketId: marketId
      }
    });
  }
}
</script>
