
import { Options, Vue } from "vue-class-component";
import UsersPage from "@/structure/Administrator/Settings/pages/UsersPage.vue";
import { provide } from "vue";
import UserApi from "@/modules/user/api";
import { FetchUser, FetchUsers } from "@/modules/user/api/types";

@Options({
  components: { UsersPage }
})
export default class Users extends Vue {
  created() {
    provide("fetchUsers", UserApi.fetchUsers.bind(UserApi) as FetchUsers);
    provide("fetchUser", UserApi.fetchUser.bind(UserApi) as FetchUser);
  }
}
