import axios from "axios";

import { HttpClientRequestConfig } from "./types";
import { HttpClient } from "./HttpClient";
import { defaultOptions } from "./defaultOptions";

/**
 * Export HttpClient instance with the given options merged with the default ones
 * @param options
 */
export default (options: HttpClientRequestConfig = {}) => {
  // create new axios instance and set these options on it
  const axiosInstance = axios.create({
    ...defaultOptions,
    ...options
  });

  // return HttpClient instance that will use axios as a client.
  return new HttpClient(axiosInstance);
};
