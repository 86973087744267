
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import ListWidget from "@/modules/common/widgets/ListWidget";
import SellingPlaceReportListItemWidget from "./SellingPlaceReportListItemWidget.vue";
import { useRoute } from "vue-router";
import { FetchSellingPlacesReportsDataParams } from "@/modules/selling-place/api/types";
import SellingPlaceApi from "@/modules/selling-place/api";
import { emptySellingPLaceReportsList } from "@/modules/selling-place/types/EmptySellingPlaceReportsList";
import SellingPlaceTypeSelector from "@/modules/selling-place-type/widgets/SellingPlaceTypeSelector.vue";
import SellingPlaceCategorySelector from "@/modules/selling-place-category/widgets/SellingPlaceCategorySelector.vue";
import { MonthPickerInput } from "vue-month-picker";
import { SellingPlaceReportsList } from "@/modules/selling-place/types";
import { MarketList } from "@/modules/market/types";
import { emptyMarketList } from "@/modules/market/types/EmptyMarketList";
import { FetchMarketsParams } from "@/modules/market/api/types";
import MarketApi from "@/modules/market/api";

@Options({
  components: {
    Spinner,
    ListWidget,
    SellingPlaceReportListItemWidget,
    SellingPlaceTypeSelector,
    SellingPlaceCategorySelector,
    MonthPickerInput
  }
})
export default class SellingPlaceReportsListWidget extends Vue {
  date = new Date();
  first = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
  last = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
  marketList: MarketList = emptyMarketList;
  sellingPlaceReportsList: SellingPlaceReportsList = emptySellingPLaceReportsList;
  params: FetchSellingPlacesReportsDataParams = {
    from: this.formatDate(this.first),
    to: this.formatDate(this.last),
    type: undefined,
    category: undefined,
    market: undefined
  };

  route = useRoute();

  types: Array<string> = [];

  fetchReports = AsyncActionFactory(
    SellingPlaceApi.fetchSellingPlacesReportsData
  );
  fetchMarketsAction = AsyncActionFactory(MarketApi.fetchMarkets);

  async created() {
    this.loadData();
    this.fetchMarkets();
  }

  async loadData() {
    await this.fetchReports.call(this.params);

    if (this.fetchReports.isSuccessful) {
      this.sellingPlaceReportsList = this.fetchReports.responseData;
    }
  }

  get isDataLoading(): boolean {
    return this.fetchReports.isBusy || this.fetchMarketsAction.isBusy;
  }

  async fetchMarkets() {
    await this.fetchMarketsAction.call({
      perPage: -1
    } as FetchMarketsParams);

    if (this.fetchMarketsAction.isSuccessful) {
      const response = this.fetchMarketsAction.responseData as MarketList;
      if (response.data.length > 0) {
        this.marketList = response;
      }
    }
  }

  formatDate(date) {
    const d = date,
      year = d.getFullYear();

    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  printDiv() {
    const printContents = document.getElementById("printMe").innerHTML;
    // const newWin = window.open("/administrator/reports");
    // // newWin.document.write(printContents);
    // newWin.document.body.innerHTML = printContents;
    // // newWin.document.close();
    // newWin.focus();
    // newWin.print();
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
}
