
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Tenant/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { BreadcrumbItems, HeaderInfo, TabItems } from "@/modules/common/types";
import { useStore } from "@/store";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
import getBreadcrumbItems from "@/structure/Administrator/Tenant/utils/getBreadcrumbItems";
import getTabItems from "@/structure/Administrator/Tenant/utils/getTabItems";
import TabBarWidget from "@/modules/common/widgets/TabBarWidget.vue";
import { Tenant } from "@/modules/tenant/types";
import AsyncActionFactory from "@/components/AsyncAction";
import TenantApi from "@/modules/tenant/api";
import { SellingPlaceList } from "@/modules/selling-place/types";
import { emptySellingPlaceList } from "@/modules/selling-place/types/EmptySellingPlaceList";
import { FetchTenantSellingPlacesParams } from "@/modules/tenant/api/types";
import ListWidget from "@/modules/common/widgets/ListWidget/ListWidget.vue";
import SellingPlaceListItemWidget from "@/modules/selling-place/widgets/SellingPlaceList/SellingPlaceListItemWidget.vue";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import eventHub from "@/components/EventHub";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    Breadcrumbs,
    TabBarWidget,
    ListWidget,
    SellingPlaceListItemWidget,
    Spinner
  }
})
export default class TenantSellingPlacesPage extends Vue {
  store = useStore();
  fetchTenantSellingPlaces = AsyncActionFactory(
    TenantApi.fetchTenantSellingPlaces
  );
  sellingPlaceList: SellingPlaceList = emptySellingPlaceList;

  created() {
    this.loadData();
    eventHub.on("SELLING_PLACE_VIEW_REQUESTED", this.onSellingPlaceSelected);
  }

  get isDataLoading(): boolean {
    return this.fetchTenantSellingPlaces.isBusy;
  }

  get tenant(): Tenant {
    return this.store.getters.tenant;
  }

  get breadcrumbItems(): BreadcrumbItems {
    const pageTitle = String(this.$route.meta.title) ?? "";
    return getBreadcrumbItems(pageTitle, this.tenant);
  }

  get tabItems(): TabItems {
    return getTabItems(this.tenant);
  }

  get headerInfo(): HeaderInfo {
    return {
      icon: "ion ion-md-people",
      section: this.tenant
        ? `${this.tenant.firstName} ${this.tenant.lastName}`
        : "",
      title: "Prodajni Objekti"
    };
  }

  async loadData() {
    await this.fetchTenantSellingPlaces.call({
      id: this.tenant.id
    } as FetchTenantSellingPlacesParams);

    if (this.fetchTenantSellingPlaces.isSuccessful) {
      this.sellingPlaceList = this.fetchTenantSellingPlaces
        .responseData as SellingPlaceList;
    }
  }

  async onSellingPlaceSelected(sellingPlaceId) {
    await this.$router.push({
      name: "administrator-tenant-selling-place",
      params: {
        sellingPlaceId: sellingPlaceId
      }
    });
  }
}
