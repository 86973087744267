<template>
  <ContractTenantDetailsPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ContractTenantDetailsPage from "@/structure/Administrator/Contracts/pages/ContractTenantDetailsPage.vue";

@Options({
  components: { ContractTenantDetailsPage }
})
export default class Tenant extends Vue {}
</script>
