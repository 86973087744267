
import { Options, Vue } from "vue-class-component";
import { Pagination as PaginationType } from "@/modules/common/types";
import PaginationTemplate from "./PaginationTemplate.vue";

@Options({
  components: {},
  props: {
    pagination: {
      type: Object,
      required: true
    }
  },
  emits: ["paginate"]
})
export default class PaginationWidget extends Vue {
  pagination!: PaginationType;

  // todo: We can send via prop name in singular and plural and use them instead of `item` and `items`
  options = {
    edgeNavigation: true,
    texts: {
      count: `Prikaz {from} do {to} od {count} stavki | {count} stavki | 1 stavka`,
      first: "Prva",
      last: "Poslednja"
    },
    template: PaginationTemplate
  };

  onPaginate(page) {
    this.$emit("paginate", page);
  }
}
