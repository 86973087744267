import { RouteRecordRaw } from "vue-router";
import PassThroughViewContracts from "../widgets/PassThroughViewContracts.vue";
import Contracts from "@/structure/Administrator/Contracts/views/Contracts.vue";
import New from "@/structure/Administrator/Contracts/views/New.vue";
import ContractForm from "@/modules/contracts/form/ContractForm.vue";

const contractsRoutes: Array<RouteRecordRaw> = [
  {
    path: "contracts",
    name: "administrator-contracts",
    component: PassThroughViewContracts,
    redirect: () => ({
      name: "administrator-contracts-homepage",
      params: {}
    }),
    children: [
      {
        path: "",
        name: "administrator-contracts-homepage",
        component: Contracts,
        meta: {
          title: ""
        }
      },
      {
        path: "new",
        name: "administrator-contracts-new",
        component: ContractForm,
        meta: {
          title: "Novi"
        }
      }
    ]
  }
];

export default contractsRoutes;
