
import { Options, Vue } from "vue-class-component";
import { SellingPlaceListFilter } from "@/modules/selling-place/api/types";

@Options({
  emits: ["filterChanged"]
})
export default class FilterWidget extends Vue {
  searchTerm = "";

  onInput() {
    this.$emit("filterChanged", this.createFilter());
  }

  createFilter(): SellingPlaceListFilter {
    return {
      searchTerm: this.searchTerm
    };
  }
}
