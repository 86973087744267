
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Settings/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import SellingPlaceTypesWidget from "@/modules/selling-place-type/widgets/SellingPlaceTypesWidget.vue";
import SellingPlaceCategoriesWidget from "@/modules/selling-place-category/widgets/SellingPlaceCategoriesWidget.vue";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    SellingPlaceCategoriesWidget,
    SellingPlaceTypesWidget
  }
})
export default class SellingPlacePage extends Vue {
  headerInfo: HeaderInfo = {
    icon: "ion ion-md-settings",
    section: "Podešavanja",
    title: "Prodajni Objekti"
  };
}
