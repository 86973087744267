import ApiClient from "@/components/ApiClient/ApiClient";

import {
  DeleteTenant,
  DeleteTenantParams,
  EditTenant,
  EditTenantRequestData,
  FetchTenant,
  FetchTenantParams,
  FetchTenants,
  FetchAllTenants,
  FetchTenantsParams,
  PostTenant,
  PostTenantRequestData,
  PostTenantResponse,
  FetchTenantSellingPlaces,
  FetchTenantSellingPlacesParams
} from "./types";

import {
  postTenantResponseMapper,
  tenantListMapper,
  tenantMapper
} from "./mappers";
import { TenantList } from "@/modules/tenant/types/TenantList";
import { Tenant } from "@/modules/tenant/types/Tenant";
import {SellingPlaceList} from "@/modules/selling-place/types";
import {sellingPlaceListMapper} from "@/modules/selling-place/api/mappers";

class Api {
  public client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  fetchTenants: FetchTenants = (
    params: FetchTenantsParams
  ): Promise<TenantList> => {
    const url = "tenants";
    return this.client.get(url, params).then(tenants => {
      return tenantListMapper(tenants);
    });
  };

  fetchAllTenants: FetchAllTenants = (
    params: FetchTenantsParams
  ): Promise<TenantList> => {
    const url = "tenants";
    return this.client.get(url, params).then(tenants => {
      return tenantListMapper(tenants);
    });
  };

  postTenant: PostTenant = (
    requestData: PostTenantRequestData
  ): Promise<PostTenantResponse> => {
    const url = `tenants`;

    return this.client.post(url, requestData).then(response => {
      return postTenantResponseMapper(response);
    });
  };

  deleteTenant: DeleteTenant = (
    id: string,
    params?: DeleteTenantParams
  ): Promise<void> => {
    const url = `tenants/${id}`;

    return this.client.delete(url, params);
  };

  editTenant: EditTenant = (
    tenantId: string,
    requestData: EditTenantRequestData
  ): Promise<void> => {
    const url = `tenants/${tenantId}`;

    return this.client.put(url, requestData);
  };

  fetchTenant: FetchTenant = (
    id: string,
    params?: FetchTenantParams
  ): Promise<Tenant> => {
    const url = `tenants/${id}`;

    return this.client.get(url, params).then(tenant => {
      return tenantMapper(tenant);
    });
  };

  fetchTenantSellingPlaces: FetchTenantSellingPlaces = (
      params: FetchTenantSellingPlacesParams
  ): Promise<SellingPlaceList> => {
    const url = `tenants/${params.id}/selling-places`;

    return this.client.get(url).then(sellingPlace => {
      return sellingPlaceListMapper(sellingPlace);
    });
  }
}

export default Api;
