
import { Options, Vue } from "vue-class-component";
import MainPageLayout from "@/modules/common/layouts/PageLayout.vue";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
import TabBarWidget from "@/modules/common/widgets/TabBarWidget.vue";
import { BreadcrumbItems, TabItems } from "@/modules/common/types";
import getBreadcrumbItems from "@/structure/Administrator/Settings/utils/getBreadcrumbItems";
import getTabItems from "@/structure/Administrator/Settings/utils/getTabItems";

@Options({
  components: {
    MainPageLayout,
    Breadcrumbs,
    TabBarWidget
  }
})
export default class extends Vue {
  get breadcrumbItems(): BreadcrumbItems {
    const pageTitle = String(this.$route.meta.title) ?? "";
    return getBreadcrumbItems(pageTitle);
  }

  get tabItems(): TabItems {
    return getTabItems();
  }
}
