
import { Options, Vue } from "vue-class-component";
import { SellingPlace } from "@/modules/selling-place/types";
import eventHub from "@/components/EventHub";

@Options({
  props: {
    sellingPlace: {
      type: Object,
      required: true,
      default: {}
    }
  }
})
export default class SellingPlaceListItemWidget extends Vue {
  sellingPlace!: SellingPlace;

  async sellingPlaceSelected() {
    eventHub.emit("SELLING_PLACE_VIEW_REQUESTED", this.sellingPlace.id);
  }
}
