<template>
  <MarketPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MarketPage from "@/structure/Cashier/Market/pages/MarketPage.vue";

@Options({
  components: { MarketPage }
})
export default class Market extends Vue {}
</script>
