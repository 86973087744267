import { TabItem, TabItems } from "@/modules/common/types";

function createHomeItem(): TabItem {
  return {
    name: "Početna",
    link: {
      name: "administrator-settings-homepage"
    },
    active: false
  };
}

function createUsersItem(): TabItem {
  return {
    name: "Korisnici",
    link: {
      name: "administrator-settings-users"
    },
    active: false
  };
}

function createSellingPlaceItem(): TabItem {
  return {
    name: "Tezge",
    link: {
      name: "administrator-settings-selling-place"
    },
    active: false
  };
}

function createContractItem(): TabItem {
  return {
    name: "Ugovori",
    link: {
      name: "administrator-settings-contract"
    },
    active: false
  };
}

const getTabItems = function(): TabItems {
  const items: TabItems = [];

  items.push(createHomeItem());
  items.push(createUsersItem());
  items.push(createSellingPlaceItem());
  items.push(createContractItem());

  return items;
};

export default getTabItems;
