import { BreadcrumbItem, BreadcrumbItems } from "@/modules/common/types";

function createHomeItem(): BreadcrumbItem {
  return {
    name: "Dashboard",
    link: {
      name: "tenant-homepage"
    },
    icon: "ion ion-md-home"
  };
}

function createModuleItem(moduleName: string): BreadcrumbItem {
  return {
    name: moduleName
  } as BreadcrumbItem;
}

export function getBreadcrumbItems(moduleName?: string) {
  const items: BreadcrumbItems = [];

  items.push(createHomeItem());

  if (moduleName && moduleName != "Tenant ") {
    items.push(createModuleItem(moduleName));
  }

  return items;
}
