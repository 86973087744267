<template>
  <label v-if="!hideLabel" class="form-label" :for="fieldName">
    {{ label }}
  </label>
  <input
    class="form-control"
    :id="fieldName"
    :type="type"
    :placeholder="placeholder"
    :class="{ 'is-invalid': error }"
    :value="modelValue"
    @input="onInput"
  />
  <small v-if="error" class="invalid-feedback">
    {{ errorMessage }}
  </small>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    modelValue: {
      type: [String, Number],
      required: false
    },
    modelModifiers: {
      default: () => ({})
    },
    fieldName: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: "text",
      validator: value => {
        return ["text", "number", "password", "email"].includes(value);
      }
    },
    placeholder: {
      type: [String, Number],
      required: false
    },
    errorMessage: {
      type: String,
      required: false
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["update:modelValue"]
})
export default class Input extends Vue {
  modelValue?: string | number;
  fieldName!: string;
  type!: string;
  placeholder?: string | number;
  errorMessage?: string;
  error!: boolean;
  label?: string;
  hideLabel!: boolean;

  onInput(event) {
    this.$emit("update:modelValue", event.target.value);
  }
}
</script>

<style scoped></style>
