
import { Options, Vue } from "vue-class-component";
import EditModal from "@/modules/common/modals/EditModal.vue";
import { FetchContractCancellationReason } from "@/modules/contracts-cancellation-reason/api/types";
import AsyncActionFactory from "@/components/AsyncAction";
import ContractCancellationReasonApi from "@/modules/contracts-cancellation-reason/api";
import eventHub from "@/components/EventHub";
import ContractCancellationReasonForm from "@/modules/contracts-cancellation-reason/forms/ContractCancellationReasonForm.vue";
import { ContractCancellationReasonEditRequestedEvent } from "@/modules/contracts-cancellation-reason/events/ContractCancellationReasonEditRequestedEvent";
import ContractCancellationReasonUpdatedEvent from "@/modules/contracts-cancellation-reason/events/ContractCancellationReasonUpdatedEvent";
import { ContractCancellationReason } from "@/modules/contracts-cancellation-reason/types";
@Options({
  components: { EditModal, ContractCancellationReasonForm }
})
export default class ContractCancellationReasonEditRequestedListener extends Vue {
  contractCancellationReason!: ContractCancellationReason;

  displayDialog = false;

  editContractCancellationReasonAction = AsyncActionFactory(
    ContractCancellationReasonApi.editContractCancellationReason
  );
  fetchContractCancellationReason = AsyncActionFactory(
    ContractCancellationReasonApi.fetchContractCancellationReason as FetchContractCancellationReason
  );

  async created() {
    eventHub.on(
      ContractCancellationReasonEditRequestedEvent.Name,
      this.onEditContractCancellationReasonRequested
    );
  }

  async onEditContractCancellationReasonRequested(
    event?: ContractCancellationReasonEditRequestedEvent
  ) {
    if (!(event instanceof ContractCancellationReasonEditRequestedEvent)) {
      return;
    }
    await this.fetchContractCancellationReason.call(event.getId());

    if (this.fetchContractCancellationReason.isSuccessful) {
      this.contractCancellationReason = this.fetchContractCancellationReason
        .responseData as ContractCancellationReason;
      this.displayDialog = true;
    }
  }

  async onContractCancellationReasonEdited() {
    await this.editContractCancellationReasonAction.call(
      this.contractCancellationReason.id,
      this.contractCancellationReasonRequestData(
        this.contractCancellationReason
      )
    );

    if (this.editContractCancellationReasonAction.isSuccessful) {
      eventHub.emit<ContractCancellationReasonUpdatedEvent>(
        ContractCancellationReasonUpdatedEvent.Name,
        new ContractCancellationReasonUpdatedEvent(
          this.contractCancellationReason
        )
      );
      this.closeModal();
    }
  }

  contractCancellationReasonRequestData(
    contractCancellationReason: ContractCancellationReason
  ) {
    return {
      name: contractCancellationReason.name,
      description: contractCancellationReason.description,
      icon: contractCancellationReason.icon
    };
  }

  closeModal() {
    this.displayDialog = false;
    this.editContractCancellationReasonAction.clear();
  }

  get isDataLoading(): boolean {
    return (
      this.editContractCancellationReasonAction.isBusy ||
      this.fetchContractCancellationReason.isBusy
    );
  }
}
