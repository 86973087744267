<template>
  <AppHncfLayout>
    <router-view></router-view>
  </AppHncfLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import AsyncActionFactory from "@/components/AsyncAction";
import MarketApi from "@/modules/market/api";
import { MutationType } from "@/store/mutations";
import AppHncfLayout from "@/structure/Cashier/common/layout/AppHncfLayout.vue";
import { useRoute, useRouter } from "vue-router";
import { UserRole, User } from "@/modules/user/types";
import { FetchMarketsParams } from "@/modules/market/api/types";

@Options({
  components: { AppHncfLayout },
  watch: {
    "$route.name"() {
      this.rerouteUser();
    }
  }
})
export default class extends Vue {
  store = useStore();
  router = useRouter();
  route = useRoute();
  fetchMarkets = AsyncActionFactory(MarketApi.fetchMarkets);

  get user(): User {
    return this.store.getters.user;
  }

  async created() {
    await this.rerouteUser();
    await this.loadData();
  }

  async rerouteUser() {
    if (this.route.name !== "cashier") return;

    if (!this.user.hasRole(UserRole.Cashier)) {
      await this.router.push({
        name: "homepage"
      });
      return;
    }

    if (this.route.name === "cashier") {
      await this.router.push({
        name: "cashier-dashboard"
      });
    }
  }

  async loadData() {
    await this.fetchMarkets.call({} as FetchMarketsParams);

    if (this.fetchMarkets.isSuccessful) {
      this.store.commit(
        MutationType.SetMarketList,
        this.fetchMarkets.responseData
      );
    }
  }

  clearMarketList() {
    this.store.commit(MutationType.SetMarketList, null);
  }

  beforeUnmount() {
    this.clearMarketList();
  }
}
</script>
