
import { Options, Vue } from "vue-class-component";
import { WorkOrder } from "@/modules/work-orders/types";

@Options({
  props: {
    workOrder: {
      type: Object,
      required: true,
      default: {}
    }
  },
  emits: ["workOrderSelected"]
})
export default class WorkOrderListItemWidget extends Vue {
  workOrder!: WorkOrder;

  workOrderSelected() {
    this.$emit("workOrderSelected", this.workOrder.id);
  }
}
