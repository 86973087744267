
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    icon: {
      type: String,
      required: false,
      default: ""
    }
  },
  emits: ["update:icon"]
})
export default class IconSelector extends Vue {
  icon!: string;
  iconModel = this.icon;
  // This should be pass from parent form component
  icons: Array<string> = [
    "fas fa-cube",
    "fas fa-store",
    "fas fa-store-alt",
    "fas fa-coffee",
    "fas fa-ice-cream"
  ];

  created() {
    if (!this.iconModel) {
      this.iconModel = this.icons[0];
      this.$emit("update:icon", this.icons[0]);
    }
  }

  selectIcon(icon) {
    this.$emit("update:icon", icon);
    this.iconModel = icon;
  }
}
