
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import AsyncActionFactory from "@/components/AsyncAction";
import TenantApi from "@/modules/tenant/api";
import { MutationType } from "@/store/mutations";
import { useRoute } from "vue-router";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import eventHub from "@/components/EventHub";

@Options({
  components: { Spinner },
  watch: {
    "$route.params.tenantId"(newTenantId) {
      if (newTenantId) {
        this.loadData(newTenantId);
      }
    }
  }
})
export default class PassThroughViewTenant extends Vue {
  store = useStore();

  dataLoaded = false;

  fetchTenant = AsyncActionFactory(TenantApi.fetchTenant);

  async created() {
    await this.loadData(useRoute().params.tenantId);
    eventHub.on("TENANT_EDITED", this.onTenantEdited);
    eventHub.on("TENANT_DELETED", this.onTenantDeleted);
  }

  async onTenantEdited(tenantId) {
    if (this.store.getters.tenant.id !== tenantId) return;

    await this.loadData(tenantId);
  }

  async onTenantDeleted(tenantId) {
    if (this.store.getters.tenant.id !== tenantId) return;

    await this.$router.push({
      name: "administrator-tenants"
    });
  }

  async loadData(id) {
    await this.fetchTenant.call(id);

    if (this.fetchTenant.isSuccessful) {
      this.store.commit(MutationType.SetTenant, this.fetchTenant.responseData);
      this.dataLoaded = true;
    }
  }

  clearTenantList() {
    this.store.commit(MutationType.SetTenant, null);
  }

  beforeUnmount() {
    this.clearTenantList();
  }

  get isDataLoading(): boolean {
    return !this.dataLoaded;
  }
}
