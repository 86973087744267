import { RouteRecordRaw } from "vue-router";
import PassThroughViewCashier from "@/structure/Cashier/common/widgets/PassThroughViewCashier.vue";
import dashboardRoutes from "./Dashboard/routes";
import marketsRoutes from "./Market/routes";
import tenantsRoutes from "./Tenant/routes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/cashier",
    name: "cashier",
    component: PassThroughViewCashier,
    children: [...dashboardRoutes, ...marketsRoutes, ...tenantsRoutes]
  }
];

export default routes;
