
import { Options, Vue } from "vue-class-component";
import { TransactionItem } from "../types";

@Options({
  props: {
    transactionItem: {
      type: Object,
      required: true
    }
  }
})
export default class TransactionDetailsWidget extends Vue {
  transaction_item!: TransactionItem;
}
