import { Links } from "@/modules/common/types";

export function getApplicationLinks(): Links {
  return [
    {
      type: "link",
      name: "Admin",
      linkRoute: {
        name: "administrator"
      }
    },
    // {
    //   type: "link",
    //   name: "Tenant",
    //   linkRoute: {
    //     name: "tenant"
    //   }
    // },
    {
      type: "link",
      name: "Inkasant",
      linkRoute: {
        name: "cashier"
      }
    }
  ];
}
