
import { Options, Vue } from "vue-class-component";
import { EditUserRequestData } from "@/modules/user/api/types";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import TextInput from "@/modules/common/forms/TextInput.vue";

@Options({
  props: {
    userRequestData: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  },
  components: { TextInput }
})
export default class ChangePasswordModal extends Vue {
  userRequestData!: EditUserRequestData;
  errors!: BackendValidationErrors;
}
