<template>
  <SellingPlaceCategoryEditRequestedListener />
  <SellingPlaceCategoryDeleteRequestedListener />
  <SellingPlaceCategoryCreateRequestedListener />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import SellingPlaceCategoryEditRequestedListener from "@/modules/selling-place-category/listeners/SellingPlaceCategoryEditRequestedListener.vue";
import SellingPlaceCategoryDeleteRequestedListener from "@/modules/selling-place-category/listeners/SellingPlaceCategoryDeleteRequestedListener.vue";
import SellingPlaceCategoryCreateRequestedListener from "@/modules/selling-place-category/listeners/SellingPlaceCategoryCreateRequestedListener.vue";

@Options({
  components: {
    SellingPlaceCategoryEditRequestedListener,
    SellingPlaceCategoryDeleteRequestedListener,
    SellingPlaceCategoryCreateRequestedListener
  }
})
export default class Module extends Vue {}
</script>
