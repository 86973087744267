<template>
  <div
    class="card p-3 mb-3"
    style="display: flex; flex-direction: row; align-items: center;"
  >
    <!--    <div class="media align-items-center">-->
    <!--      <img-->
    <!--        src="@/assets/company.png"-->
    <!--        class="d-block ui-w-40 rounded-circle mr-3"-->
    <!--        alt=""-->
    <!--      />-->
    <!--    </div>-->
    <div class="media-body flex-basis-auto">
      <div>
        <ContractStatusWidget :status="contract.status" />
        <strong @click="contractSelected()" class="cursor-pointer ml-2">
          {{ contract.number }}
        </strong>
      </div>
    </div>

    <div class="pr-2" v-if="contract.status == 'active'">
      <button
        @click="contractCancel()"
        class="btn btn-outline-danger btn-sm"
      >
        Otkaži
      </button>
    </div>
    <div class="pr-5">
      <button
              @click="contractDelete()"
              class="btn btn-danger btn-sm"
      >
        Obriši
      </button>
    </div>
    <div>
      <button
              @click="contractSelected()"
              class="btn btn-outline-secondary btn-sm"
      >
        Detalji
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Contract } from "@/modules/contracts/types";
import ContractStatusWidget from "../ContractStatusWidget.vue";
import eventHub from "@/components/EventHub";

@Options({
  components: { ContractStatusWidget },
  props: {
    contract: {
      type: Object,
      required: true,
      default: {}
    }
  },
  emits: ["contractSelected"]
})
export default class ContractListItemWidget extends Vue {
  contract!: Contract;

  contractSelected() {
    this.$emit("contractSelected", this.contract.id);
  }
  contractCancel() {
      eventHub.emit("CREATE_CONTRACT_CANCEL_REQUEST", this.contract.id);
  }
  contractDelete() {
    console.log('emit contract DDDD', this.contract)
    eventHub.emit("DELETE_CONTRACT_REQUESTED", this.contract);

    // if(confirm(`Da li želite da obrišete ugovor ${this.contract.number}?`)) {
      // axios.delete('/api/artist/'+id)
      //         .then(resp => {
      //           this.artists.data.splice(index, 1);
      //         })
      //         .catch(error => {
      //           console.log(error);
      //         })
    // }
    // this.$emit("contractCancel", this.contract.id);
  }

}
</script>
