
  import {Options, Vue} from "vue-class-component";
  import eventHub from "@/components/EventHub";
  import {useStore} from "@/store";
  import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
  import ContractApi from "@/modules/contracts/api";
  import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
  import {MutationType} from "@/store/mutations";

  @Options({
  components: { Spinner }
})
export default class extends Vue {
  store = useStore();
  fetchContract = AsyncActionFactory(ContractApi.fetchContract);
  dataLoaded = false;

  created() {
    eventHub.on("CONTRACT_CREATED", this.onContractCreated);
    eventHub.on("CONTRACT_EDITED", this.onContractEdited);
  }

  async onContractEdited(contractId) {
      console.log('Pass', contractId);
    if (this.store.getters.contract.id !== contractId) return;

    await this.loadData(contractId)
  }

  async loadData(contractId) {
    await this.fetchContract.call(contractId);
    if (this.fetchContract.isSuccessful) {
      this.store.commit(MutationType.SetContract, this.fetchContract.responseData);
      this.dataLoaded = true;
    }
  }

  async onContractCreated(contractId) {
    await this.$router.push({
      name: "administrator-contract",
      params: {
        contractId: contractId
      }
    });
  }

  get isDataLoading(): boolean {
    return this.fetchContract.isBusy || !this.dataLoaded;
  }

}
