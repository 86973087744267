
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import AsyncActionFactory from "@/components/AsyncAction";
import TransactionApi from "@/modules/transactions/api";
import { useRoute } from "vue-router";
import { MutationType } from "@/store/mutations";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import { FetchTransactionParams } from "@/modules/transactions/api/types";

@Options({
  components: { Spinner },
  watch: {
    "$route.params.transactionId"(newTransactionId) {
      if (newTransactionId) {
        this.clearTransaction();
        this.loadData(newTransactionId);
      }
    }
  }
})
export default class extends Vue {
  store = useStore();

  fetchTransaction = AsyncActionFactory(TransactionApi.fetchTransaction);

  dataLoaded = false;

  async created() {
    await this.loadData(useRoute().params.transactionId);
  }

  async loadData(transactionId) {
    await this.fetchTransaction.call(transactionId);

    if (this.fetchTransaction.isSuccessful) {
      this.store.commit(
        MutationType.SetTransaction,
        this.fetchTransaction.responseData
      );
      this.dataLoaded = true;
    }
  }

  clearTransaction() {
    this.store.commit(MutationType.SetTransaction, null);
  }

  beforeUnmount() {
    this.clearTransaction();
  }

  get isDataLoading(): boolean {
    return this.fetchTransaction.isBusy || !this.dataLoaded;
  }
}
