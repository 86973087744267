<template>
  <EditModal
    v-if="displayDialog"
    @close="closeModal()"
    title="Izmena Kategorije Prodajnog Objekta"
    @confirm="onSellingPlaceCategoryEdited()"
    :showSpinner="isDataLoading"
  >
    <SellingPlaceCategoryForm
      v-model:selling-place-category="sellingPlaceCategory"
      :errors="editSellingPlaceCategoryAction.errors"
    />
  </EditModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import EditModal from "@/modules/common/modals/EditModal.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import eventHub from "@/components/EventHub";
import {
  EditSellingPlaceCategoryRequestData,
  FetchSellingPlaceCategory
} from "../api/types";
import SellingPlaceCategoryForm from "@/modules/selling-place-category/forms/SellingPlaceCategoryForm.vue";
import SellingPlaceCategoryApi from "@/modules/selling-place-category/api";
import SellingPlaceCategoryEditRequestedEvent from "@/modules/selling-place-category/events/SellingPlaceCategoryEditRequestedEvent";
import SellingPlaceCategoryUpdatedEvent from "@/modules/selling-place-category/events/SellingPlaceCategoryUpdatedEvent";
import { SellingPlaceCategory } from "@/modules/selling-place-category/types";

@Options({
  components: { EditModal, SellingPlaceCategoryForm }
})
export default class SellingPlaceCategoryEditRequestedListener extends Vue {
  sellingPlaceCategory!: SellingPlaceCategory;

  displayDialog = false;

  editSellingPlaceCategoryAction = AsyncActionFactory(
    SellingPlaceCategoryApi.editSellingPlaceCategory
  );
  fetchSellingPlaceCategory = AsyncActionFactory(
    SellingPlaceCategoryApi.fetchSellingPlaceCategory as FetchSellingPlaceCategory
  );

  async created() {
    eventHub.on<SellingPlaceCategoryEditRequestedEvent>(
      SellingPlaceCategoryEditRequestedEvent.Name,
      this.onEditSellingPlaceCategoryRequested
    );
  }

  async onEditSellingPlaceCategoryRequested(
    event?: SellingPlaceCategoryEditRequestedEvent
  ) {
    if (!(event instanceof SellingPlaceCategoryEditRequestedEvent)) {
      return;
    }

    await this.fetchSellingPlaceCategory.call(event.getId());

    if (this.fetchSellingPlaceCategory.isSuccessful) {
      this.sellingPlaceCategory = this.fetchSellingPlaceCategory
        .responseData as SellingPlaceCategory;
      this.displayDialog = true;
    }
  }

  async onSellingPlaceCategoryEdited() {
    await this.editSellingPlaceCategoryAction.call(
      this.sellingPlaceCategory.id,
      this.sellingPlaceCategoryRequestData(this.sellingPlaceCategory)
    );

    if (this.editSellingPlaceCategoryAction.isSuccessful) {
      eventHub.emit<SellingPlaceCategoryUpdatedEvent>(
        SellingPlaceCategoryUpdatedEvent.Name,
        new SellingPlaceCategoryUpdatedEvent(this.sellingPlaceCategory)
      );
      this.closeModal();
    }
  }

  sellingPlaceCategoryRequestData(
    sellingPlaceCategory: SellingPlaceCategory
  ): EditSellingPlaceCategoryRequestData {
    return {
      name: sellingPlaceCategory.name,
      description: sellingPlaceCategory.description,
      icon: sellingPlaceCategory.icon
    };
  }

  closeModal() {
    this.displayDialog = false;
    this.editSellingPlaceCategoryAction.clear();
  }

  get isDataLoading(): boolean {
    return (
      this.editSellingPlaceCategoryAction.isBusy ||
      this.fetchSellingPlaceCategory.isBusy
    );
  }
}
</script>
