<template>
  <div class="form-group">
    <label class="form-label">
      Datum
    </label>
    <input
      type="date"
      class="form-control"
      v-model="workOrderRequestData.date"
    />
  </div>
  <div class="form-group">
    <label class="form-label">
      Market
    </label>
    <select class="form-control" v-model="workOrderRequestData.marketId">
      <option
        v-for="(market, index) in markets"
        :key="'market' + index"
        :value="market.id"
        >{{ market.name }}</option
      >
    </select>
  </div>
  <div class="form-group">
    <label class="form-label">
      Inkasant
    </label>
    <select class="form-control" v-model="workOrderRequestData.cashierId">
      <option
        v-for="(cashier, index) in cashiers"
        :key="'cashier' + index"
        :value="cashier.id"
        >{{ cashier.firstName }} {{ cashier.lastName }}</option
      >
    </select>
  </div>

  <button type="submit" class="btn btn-primary" @click="createWorkOrder">
    Kreiraj
  </button>
  <button style="float: right" class="btn btn-default" @click="cancel()">
    Otkaži
  </button>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import { PostWorkOrderRequestData } from "@/modules/work-orders/api/types";
import AsyncActionFactory from "@/components/AsyncAction";
import WorkOrderApi from "@/modules/work-orders/api";
import { FetchMarketsParams } from "@/modules/market/api/types";
import MarketApi from "@/modules/market/api";
import UserApi from "@/modules/user/api";
import { Market } from "@/modules/market/types";
import { FetchUsersParams } from "@/modules/user/api/types";
import { User } from "@/modules/user/types";
import { useRouter } from "@/components/Router";

export default class CreateWorkOrderForm extends Vue {
  workOrderRequestData: PostWorkOrderRequestData = {
    date: "",
    marketId: null,
    cashierId: null
  };
  errors!: BackendValidationErrors;
  markets: Array<Market> = [];
  cashiers: Array<User> = [];

  fetchMarkets = AsyncActionFactory(MarketApi.fetchMarkets);
  fetchUsers = AsyncActionFactory(UserApi.fetchUsers);
  postWorkOrder = AsyncActionFactory(WorkOrderApi.postWorkOrder);

  async created() {
    await this.loadMarkets();
    await this.loadCashiers();
  }

  async createWorkOrder() {
    await this.postWorkOrder.call(this.workOrderRequestData);

    if (this.postWorkOrder.isSuccessful) {
      await useRouter().push({
        name: "administrator-work-orders"
      });
    }
  }

  async cancel() {
    await useRouter().push({
      name: "administrator-work-orders"
    });
  }

  async loadMarkets() {
    await this.fetchMarkets.call({
      perPage: -1
    } as FetchMarketsParams);

    if (this.fetchMarkets.isSuccessful) {
      this.markets = this.fetchMarkets.responseData
        ? this.fetchMarkets.responseData.data
        : [];
    }
  }

  async loadCashiers() {
    await this.fetchUsers.call({
      perPage: -1,
      role: "cashier"
    } as FetchUsersParams);

    if (this.fetchUsers.isSuccessful) {
      this.cashiers = this.fetchUsers.responseData
        ? this.fetchUsers.responseData.data
        : [];
    }
  }
}
</script>
