<template>
  <div
    id="layout-sidenav"
    class="layout-sidenav sidenav sidenav-vertical bg-sidenav-theme"
  >
    <!-- Links -->
    <ul class="sidenav-inner py-1 ps">
      <template v-for="(item, index) in navigationLinks" :key="index">
        <li class="sidenav-item" v-if="item.type === 'link'">
          <router-link class="sidenav-link" :to="item.linkRoute">
            <i class="sidenav-icon" v-if="item.icon" :class="item.icon"></i>
            <div>{{ item.name }}</div>
          </router-link>
        </li>
        <li v-if="item.type === 'separator'" class="sidenav-divider mb-1"></li>
        <li
          v-if="item.type === 'segment'"
          class="sidenav-header small font-weight-semibold"
        >
          {{ item.name }}
        </li>
      </template>
    </ul>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Links } from "@/modules/common/types";

@Options({
  props: {
    navigationLinks: {
      type: Array,
      required: true
    }
  }
})
export default class NavigationWidget extends Vue {
  navigationLinks!: Links;
}
</script>
