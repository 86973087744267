
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import AsyncActionFactory from "@/components/AsyncAction";
import ContractApi from "@/modules/contracts/api";
import { useRoute } from "vue-router";
import { MutationType } from "@/store/mutations";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import eventHub from "@/components/EventHub";

@Options({
  components: { Spinner },
  watch: {
    "$route.params.contractId"(newContractId) {
      if (newContractId) {
        this.clearContract();
        this.loadData(newContractId);
      }
    }
  }
})
export default class extends Vue {
  store = useStore();

  fetchContract = AsyncActionFactory(ContractApi.fetchContract);

  dataLoaded = false;

  async created() {
    await this.loadData(useRoute().params.contractId);
    eventHub.on("CONTRACT_EDITED", this.onContractEdited);
  }

  private async onContractEdited(contractId) {
    await this.loadData(contractId);
    await this.$router.push({
      name: "administrator-contract",
      params: {
        contractId: contractId
      }
    });
  }

  async loadData(contractId) {
    await this.fetchContract.call(contractId);

    if (this.fetchContract.isSuccessful) {
      this.store.commit(
        MutationType.SetContract,
        this.fetchContract.responseData
      );
      this.dataLoaded = true;
    }
  }

  clearContract() {
    this.store.commit(MutationType.SetContract, null);
  }

  beforeUnmount() {
    this.clearContract();
  }

  get isDataLoading(): boolean {
    return this.fetchContract.isBusy || !this.dataLoaded;
  }
}
