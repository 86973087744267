import { WorkOrderList } from "@/modules/work-orders/types/WorkOrderList";

export const emptyWorkOrderList: WorkOrderList = {
  pagination: {
    page: 1,
    lastPage: 1,
    perPage: 10,
    from: 1,
    to: 0,
    total: 0
  },
  data: []
};
