
import { Options, Vue } from "vue-class-component";
import { Tenant } from "@/modules/tenant/types";
import FilterWidget from "@/modules/tenant/widgets/TenantsSidebar/FilterWidget.vue";

@Options({
  components: { FilterWidget },
  props: {
    tenants: {
      type: Array,
      required: true
    }
  },
  emits: ["tenantSelected", "filterChanged"]
})
export default class extends Vue {
  tenants: Array<Tenant> = [];

  selectTenant(tenant: Tenant) {
    this.$emit("tenantSelected", tenant.id);
  }

  onFilter(filter) {
    this.$emit("filterChanged", filter);
  }

  isTenantSelected(tenant: Tenant): boolean {
    return this.$route.params.tenantId == tenant.id;
  }
}
