
import { Options, Vue } from "vue-class-component";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import { SellingPlaceCategory } from "@/modules/selling-place-category/types";
import eventHub from "@/components/EventHub";
import SellingPlaceCategoryDeleteRequestedEvent from "@/modules/selling-place-category/events/SellingPlaceCategoryDeleteRequestedEvent";
import SellingPlaceCategoryEditRequestedEvent from "@/modules/selling-place-category/events/SellingPlaceCategoryEditRequestedEvent";

@Options({
  components: { Spinner },
  props: {
    sellingPlaceCategory: {
      type: Object,
      required: true
    }
  }
})
export default class SellingPlaceCategoryListItemWidget extends Vue {
  sellingPlaceCategory!: SellingPlaceCategory;
  isEditHovering = false;
  isDeleteHovering = false;

  editSellingPlaceCategory(sellingPlaceCategory: SellingPlaceCategory) {
    eventHub.emit(
      SellingPlaceCategoryEditRequestedEvent.Name,
      new SellingPlaceCategoryEditRequestedEvent(sellingPlaceCategory.id)
    );
  }

  deleteSellingPlaceCategory(sellingPlaceCategory: SellingPlaceCategory) {
    eventHub.emit(
      SellingPlaceCategoryDeleteRequestedEvent.Name,
      new SellingPlaceCategoryDeleteRequestedEvent(sellingPlaceCategory)
    );
  }
}
