<template>
  <SidebarLayout>
    <template v-slot:sidebar>
      <TenantsSidebar @tenant-selected="onTenantSelected($event)" />
    </template>

    <router-view></router-view>
  </SidebarLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import SidebarLayout from "@/modules/common/layouts/SidebarLayout.vue";
import TenantsSidebar from "@/modules/tenant/widgets/TenantsSidebar/TenantsSidebar.vue";

@Options({
  components: { SidebarLayout, TenantsSidebar }
})
export default class extends Vue {
  async onTenantSelected(tenantId: string) {
    await this.$router.push({
      name: "administrator-tenant",
      params: {
        tenantId: tenantId
      }
    });
  }
}
</script>
