import { createToastInterface, ToastInterface } from "vue-toastification";
import "vue-toastification/dist/index.css";

const defaultOptions = {
  timeout: 2000
};

export const toast = createToastInterface();

export function useToast() {
  return toast as ToastInterface;
}

export function addSuccess(message: string, options = defaultOptions) {
  toast.success(message, options);
}

export function addError(message: string, options = defaultOptions) {
  toast.error(message, options);
}

export function addDefault(message: string, options = defaultOptions) {
  toast(message, options);
}

export function addInfo(message: string, options = defaultOptions) {
  toast.info(message, options);
}

export function addWarning(message: string, options = defaultOptions) {
  toast.warning(message, options);
}
