import { User } from "@/modules/user/types";

export default class UserDeletedEvent {
  static readonly Name = "USER_DELETED";

  private readonly user: User;

  constructor(user: User) {
    this.user = user;
  }

  getUser(): User {
    return this.user;
  }
}
