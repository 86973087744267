import { SellingPlaceType } from "@/modules/selling-place-type/types";

export default class SellingPlaceTypeUpdatedEvent {
  static readonly Name = "SELLING_PLACE_TYPE_UPDATED";

  private readonly sellingPlaceType: SellingPlaceType;

  constructor(sellingPlaceType: SellingPlaceType) {
    this.sellingPlaceType = sellingPlaceType;
  }

  getSellingPlaceType(): SellingPlaceType {
    return this.sellingPlaceType;
  }
}
