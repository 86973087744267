
import { Options, Vue } from "vue-class-component";
import EditSellingPlaceDetailsRequestListener from "./listeners/EditSellingPlaceDetailsRequestListener.vue";
import DeleteSellingPlaceRequestListener from "./listeners/DeleteSellingPlaceRequestListener.vue";

@Options({
  components: {
    EditSellingPlaceDetailsRequestListener,
    DeleteSellingPlaceRequestListener
  }
})
export default class Module extends Vue {}
