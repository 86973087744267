<template>
  <NewSellingPlacePage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import NewSellingPlacePage from "@/structure/Administrator/SellingPlaces/pages/NewSellingPlacePage.vue";
import { provide } from "vue";
import MarketApi from "@/modules/market/api";
import SellingPlaceTypeApi from "@/modules/selling-place-type/api";
import SellingPlaceCategoryApi from "@/modules/selling-place-category/api";
import { FetchMarkets } from "@/modules/market/api/types";
import { FetchSellingPlaceTypes } from "@/modules/selling-place-type/api/types";
import { FetchSellingPlaceCategories } from "@/modules/selling-place-category/api/types";

@Options({
  components: { NewSellingPlacePage }
})
export default class NewSellingPlace extends Vue {
  created() {
    // provide(
    //   "fetchMarkets",
    //   MarketApi.fetchMarkets.bind(MarketApi) as FetchMarkets
    // );
    // provide(
    //   "fetchSellingPlaceTypes",
    //   SellingPlaceTypeApi.fetchSellingPlaceTypes.bind(
    //     SellingPlaceTypeApi
    //   ) as FetchSellingPlaceTypes
    // );
    // provide(
    //   "fetchSellingPlaceCategories",
    //   SellingPlaceCategoryApi.fetchSellingPlaceCategories.bind(
    //     SellingPlaceCategoryApi
    //   ) as FetchSellingPlaceCategories
    // );
  }
}
</script>
