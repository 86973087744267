import { BreadcrumbItem, BreadcrumbItems } from "@/modules/common/types";

import getBasicBreadcrumbItems from "@/structure/Administrator/common/utils/getBreadcrumbItems.ts";

function createSettingsItem(): BreadcrumbItem {
  return {
    name: "Podešavanja",
    link: {
      name: "administrator-settings"
    }
  };
}

const getBreadcrumbItems = function(moduleName: string) {
  const items: BreadcrumbItems = [];

  items.push(createSettingsItem());

  return getBasicBreadcrumbItems(moduleName, items);
};

export default getBreadcrumbItems;
