import { Transaction, TransactionList } from "@/modules/transactions/types";

export function transactionMapper(transaction): Transaction {
  return {
    ...transaction
  };
}

export function transactionListMapper(list): TransactionList {
  list.data = list.data.map(transaction => transactionMapper(transaction));
  return list;
}
