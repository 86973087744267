
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";

@Options({
  components: {}
})
export default class extends Vue {
  created() {
    eventHub.on("MARKET_CREATED", this.onMarketCreated);
  }
  async onMarketCreated(marketId) {
    await this.$router.push({
      name: "administrator-market",
      params: {
        marketId: marketId
      }
    });
  }
}
