<template>
  <div
    class="card p-3 mb-3"
    style="display: flex; flex-direction: row; align-items: center;"
  >
    <!--    <div class="media align-items-center">-->
    <!--      <img-->
    <!--        src="@/assets/company.png"-->
    <!--        class="d-block ui-w-40 rounded-circle mr-3"-->
    <!--        alt=""-->
    <!--      />-->
    <!--    </div>-->
    <div class="media-body flex-basis-auto">
      <div>
        <i :class="market.icon" class="mr-2"></i>
        <strong @click="marketSelected()" class="cursor-pointer">
          {{ market.name }} <small>{{ market.description }}</small>
        </strong>
      </div>
      <div>{{ formattedAddress }}</div>
    </div>

    <div>
      <button
        @click="marketSelected()"
        class="btn btn-outline-secondary btn-sm"
      >
        Detalji
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Market } from "@/modules/market/types";

@Options({
  props: {
    market: {
      type: Object,
      required: true,
      default: {}
    }
  },
  emits: ["marketSelected"]
})
export default class MarketListItemWidget extends Vue {
  market!: Market;

  get formattedAddress(): string {
    const address = this.market.address;
    return `${address.line1} ${address.postalCode} ${address.postalCode} ${address.country}`;
  }

  marketSelected() {
    this.$emit("marketSelected", this.market.id);
  }
}
</script>
