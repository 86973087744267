<template>
  <TenantContractsPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TenantContractsPage from "@/structure/Administrator/Tenant/pages/TenantContractsPage.vue";
import { provide } from "vue";
import ContractApi from "@/modules/contracts/api";
import {
  FetchContractCancelReasons,
  FetchContracts
} from "@/modules/contracts/api/types";

@Options({
  components: { TenantContractsPage }
})
export default class TenantContracts extends Vue {
  created() {
    provide(
      "fetchContracts",
      ContractApi.fetchContracts.bind(ContractApi) as FetchContracts
    );
    provide(
      "fetchContractCancelReasons",
      ContractApi.fetchContractCancelReasons.bind(
        ContractApi
      ) as FetchContractCancelReasons
    );
  }
}
</script>
