<template>
  <router-link
    class="btn btn-default btn-outline-primary"
    :to="{
      name: 'administrator-transactions-new'
    }"
  >
    <i class="ion ion-ios-add"></i>
    Nova Transakcija
  </router-link>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class extends Vue {}
</script>
