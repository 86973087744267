<template>
  <button class="btn btn-outline-primary" @click="newUser()">
    <i class="ion ion-md-add"></i>
    Novi Korisnik
  </button>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";
import UserNewRequestedEvent from "@/modules/user/events/UserNewRequestedEvent";

export default class UsersPageHeaderWidgets extends Vue {
  newUser(): void {
    eventHub.emit<UserNewRequestedEvent>(
      UserNewRequestedEvent.Name,
      new UserNewRequestedEvent()
    );
  }
}
</script>
