import ApiClient from "@/components/ApiClient/ApiClient";

import {
  FetchWorkOrder,
  FetchWorkOrderParams,
  FetchWorkOrders,
  FetchWorkOrdersParams,
  DeleteWorkOrder,
  PostWorkOrder,
  PostWorkOrderRequestData,
  PostWorkOrderResponse
} from "./types";

import { workOrderListMapper, workOrderMapper } from "./mappers";
import { WorkOrder, WorkOrderList } from "@/modules/work-orders/types";

class Api {
  public client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  fetchWorkOrders: FetchWorkOrders = (
    params: FetchWorkOrdersParams
  ): Promise<WorkOrderList> => {
    const payload = {
      page: params.page,
      perPage: params.perPage
    };

    const url = "work-orders";

    return this.client.get(url, payload).then(workOrderList => {
      return workOrderListMapper(workOrderList);
    });
  };

  fetchWorkOrder: FetchWorkOrder = (
    id: string,
    params?: FetchWorkOrderParams
  ): Promise<WorkOrder> => {
    const url = `work-orders/${id}`;

    return this.client.get(url, params).then(workOrder => {
      return workOrderMapper(workOrder);
    });
  };

  postWorkOrder: PostWorkOrder = (
    requestData: PostWorkOrderRequestData
  ): Promise<PostWorkOrderResponse> => {
    const url = `work-orders`;

    return this.client.post(url, requestData).then(response => {
      return response;
    });
  };

  deleteWorkOrder: DeleteWorkOrder = (id: string): Promise<void> => {
    const url = `work-orders/${id}`;

    return this.client.delete(url);
  };
}

export default Api;
