import { RouteRecordRaw } from "vue-router";
import PassThroughViewMarkets from "../widgets/PassThroughViewMarkets.vue";
import MarketsHome from "@/structure/Cashier/Market/views/MarketsHome.vue";
import marketRoutes from "./market-routes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "markets",
    name: "cashier-markets",
    component: PassThroughViewMarkets,
    redirect: () => ({
      name: "cashier-markets-homepage",
      params: {}
    }),
    children: [
      {
        path: "",
        name: "cashier-markets-homepage",
        component: MarketsHome,
        meta: {
          title: "Pijace"
        }
      },
      ...marketRoutes
    ]
  }
];

export default routes;
