<template>
  <h2>
    <i :class="headerInfo.icon" class="mr-3"></i>
    <span class="font-weight-light">
      {{ headerInfo.section }}
    </span>
    <span
      v-if="headerInfo.section && headerInfo.title"
      class="font-weight-light"
    >
      /
    </span>
    <span class="font-weight-bold">
      {{ headerInfo.title }}
    </span>
  </h2>
  <small>{{ headerInfo.description }}</small>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { HeaderInfo } from "@/modules/common/types";

@Options({
  props: {
    headerInfo: {
      type: Object,
      required: true
    }
  }
})
export default class extends Vue {
  headerInfo!: HeaderInfo;
}
</script>
