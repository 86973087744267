<template>
    <PageLayout>
        <template v-slot:page-breadcrumbs>
            <Breadcrumbs :items="breadcrumbItems" />
        </template>
        <template v-slot:page-name>
            <PageHeaderInfoWidget :header-info="headerInfo" />
        </template>
<!--        <h4>Štampanje ugovora</h4>-->
        <Print />
    </PageLayout>
</template>

<script lang="ts">
    import { Options, Vue } from "vue-class-component";
    import PageLayout from "@/structure/Administrator/Contracts/layout/PageLayout.vue";
    import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
    import { HeaderInfo, TabItems } from "@/modules/common/types";
    import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
    import { BreadcrumbItems } from "@/modules/common/types";
    import getBreadcrumbItems from "../utils/getBreadcrumbItems";
    import { Contract } from "@/modules/contracts/types";
    import { useStore } from "@/store";
    import Print from "@/modules/contracts/widgets/Print.vue";


    @Options({
        components: {
            PageLayout,
            PageHeaderInfoWidget,
            Breadcrumbs,
            Print
        }
    })
    export default class ContractPrintPage extends Vue{
        store = useStore();

        get contract(): Contract {
            return this.store.getters.contract;
        }

        get headerInfo(): HeaderInfo {
            return {
                icon: "ion ion-md-document",
                section: this.contract ? "Štampanje " + this.contract.number : ""
            };
        }


        get breadcrumbItems(): BreadcrumbItems {
            return getBreadcrumbItems("Štampanje ugovora", this.contract);
        }
    }
</script>