import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isoWeek from "dayjs/plugin/isoWeek";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

const convertToOrgTimeZone = (date: Dayjs): Dayjs => {
  const orgTimeZone = "Europe/Belgrade"; // TimeZone will always be available to logged in user
  return date.tz(orgTimeZone);
};

/**
 * Create Dayjs object from UTC datetime
 * @param utcDateString
 */
const makeUtcDayjs = (utcDateString: string): Dayjs =>
  dayjs(utcDateString).utc(true);

export const formatDate = (date: Date, format = "DD.MM.YYYY"): string =>
  dayjs(date).format(format);

export function convertUtcToOrgDate(utcDateString: string): Date {
  return convertToOrgTimeZone(makeUtcDayjs(utcDateString)).toDate();
}
