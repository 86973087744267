
import { Options, Vue } from "vue-class-component";
import { BreadcrumbItems } from "@/modules/common/types";

@Options({
  props: {
    items: {
      required: true,
      default: []
    }
  }
})
export default class extends Vue {
  items!: BreadcrumbItems;
}
