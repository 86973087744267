import { PostTenantResponse } from "@/modules/tenant/api/types";
import { Tenant } from "@/modules/tenant/types/Tenant";
import { TenantList } from "@/modules/tenant/types/TenantList";

export function tenantMapper(tenant): Tenant {
  return {
    ...tenant
  };
}

export function postTenantResponseMapper(response): PostTenantResponse {
  return {
    ...response
  };
}

export function tenantListMapper(list): TenantList {
  list.data = list.data.map(tenant => tenantMapper(tenant));
  return list;
}

// export function tenantListParamsMapper(list, params): TenantList {
//   list.data = list
// }
