
import { Options, Vue } from "vue-class-component";
import CreateMarketRequestListener from "./listeners/CreateMarketRequestListener.vue";
import EditMarketDetailsRequestListener from "./listeners/EditMarketDetailsRequestListener.vue";
import DeleteMarketRequestListener from "@/modules/market/listeners/DeleteMarketRequestListener.vue";
import EditMarketAddressRequestListener from "@/modules/market/listeners/EditMarketAddressRequestListener.vue";
@Options({
  components: {
    CreateMarketRequestListener,
    EditMarketDetailsRequestListener,
    DeleteMarketRequestListener,
    EditMarketAddressRequestListener
  }
})
export default class Module extends Vue {}
