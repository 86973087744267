<template>
  <router-view />
  <Modules />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Modules from "@/modules/Modules.vue";

@Options({
  components: { Modules }
})
export default class App extends Vue {}
</script>
