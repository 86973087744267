import { RouteRecordRaw } from "vue-router";
import Dashboard from "@/structure/Cashier/Dashboard/views/Dashboard.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "cashier-dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard"
    }
  }
];

export default routes;
