<template>
  <div>
    <div class="form-group row">
      <label class="form-label col-sm-2 col-form-label">
        Zakupac:
      </label>
      <div class="col-sm-10">
        <select v-model="contractRequestData.tenantId">
          <option disabled value="">Odaberi</option>
          <option
            v-for="tenant in allTenants"
            v-bind:key="tenant.id"
            v-bind:value="tenant.id"
            >{{ tenant.lastName }} {{ tenant.firstName }}</option
          >
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label class="form-label col-sm-2 col-form-label">
        Tezga:
      </label>
      <div class="col-sm-10">
        <select v-model="contractRequestData.sellingPlaceId">
          <option disabled value="">Odaberi</option>
          <option
            v-for="sellingPlace in allSellingPlaces"
            v-bind:key="sellingPlace.id"
            v-bind:value="sellingPlace.id"
            >{{ sellingPlace.name }} (Pijaca -
            {{ sellingPlace.market.name }} )</option
          >
        </select>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-6">
        <label class="form-label">
          Datum pocetka ugovora:
        </label>
        <month-picker-input
          @input="showStartDate"
          lang="sr"
        ></month-picker-input>
      </div>
      <div class="form-group col-md-6">
        <label class="form-label">
          Datum kraja ugovora:
        </label>
        <month-picker-input @input="showEndDate" lang="sr"></month-picker-input>
      </div>
    </div>
    <div class="form-group">
      <label class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="contractRequestData.paid"
        />
        <div class="form-check-label">
          Placen ugovor:
        </div>
      </label>
    </div>

    <!--    <button type="submit" class="btn btn-primary" @click="createContract">-->
    <!--      Create-->
    <!--    </button>-->
  </div>
  <div class="form-group">
    <small v-if="validationErrors.has('icon')" class="invalid-feedback">
      {{ validationErrors.get("icon") }}
    </small>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TextInput from "@/modules/common/forms/TextInput.vue";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import { Tenant, TenantList } from "@/modules/tenant/types";
import TenantApi from "../../tenant/api";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import { FetchTenants, FetchTenantsParams } from "@/modules/tenant/api/types";
import SellingPlaceApi from "../../selling-place/api";
import { FetchAllSellingPlaces } from "@/modules/selling-place/api/types";
import { SellingPlace, SellingPlaceList } from "@/modules/selling-place/types";
import { MonthPickerInput } from "vue-month-picker";
import { NewPostContractRequestData } from "@/modules/contracts/api/types";
import ContractTypeApi from "@/modules/contracts/api";

@Options({
  components: { TextInput, MonthPickerInput },
  props: {
    contractRequestData: {
      type: Object,
      required: true
    },
    validationErrors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  }
})
export default class ContractForm extends Vue {
  validationErrors!: BackendValidationErrors;
  allTenants: Array<Tenant> = [];
  allSellingPlaces: Array<SellingPlace> = [];
  contractTenant: number | string = "";
  contractSellingPlace: number | string = "";
  rentStartDate = {
    from: null,
    to: null,
    month: null,
    year: null
  };
  rentEndDate = {
    from: null,
    to: null,
    month: null,
    year: null
  };
  contractRequestData!: NewPostContractRequestData;

  fetchTenants = AsyncActionFactory(TenantApi.fetchAllTenants as FetchTenants);
  fetchSellingPlaces = AsyncActionFactory(
    SellingPlaceApi.fetchAllSellingPlaces as FetchAllSellingPlaces
  );
  postContractTypeAction = AsyncActionFactory(
    ContractTypeApi.createContractType
  );

  showStartDate(date) {
    this.rentStartDate = date;
    this.contractRequestData.rentStartDate = date.to;
  }

  showEndDate(date) {
    this.rentEndDate = date;
    this.contractRequestData.rentEndDate = date.to;
  }

  async created() {
    await this.loadTenants();
    await this.loadSellingPlaces();
  }

  async loadTenants() {
    await this.fetchTenants.call({
      perPage: -1
    } as FetchTenantsParams);
    if (this.fetchTenants.isSuccessful) {
      this.allTenants = (this.fetchTenants.responseData as TenantList).data;
    }
  }
  async loadSellingPlaces() {
    await this.fetchSellingPlaces.call();
    if (this.fetchSellingPlaces.isSuccessful) {
      this.allSellingPlaces = (this.fetchSellingPlaces
        .responseData as SellingPlaceList).data;
    }
  }
}
</script>
<style scoped>
/deep/ .month-picker__container {
  position: absolute;
  top: 3.5em;
  background-color: white;
  z-index: 9999;
}
</style>
