import { RouteRecordRaw } from "vue-router";
import accountRoutes from "@/structure/Account/routes";
import administratorRoutes from "@/structure/Administrator/routes";
import tenantRoutes from "@/structure/Tenant/routes";
import cashierRoutes from "@/structure/Cashier/routes";
import PassThroughViewApplication from "@/structure/common/widgets/PassThroughViewApplication.vue";
import authenticationRoutes from "@/structure/Authentication/routes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "homepage",
    component: PassThroughViewApplication,
    children: [
      ...accountRoutes,
      ...administratorRoutes,
      ...tenantRoutes,
      ...cashierRoutes
    ]
  },
  ...authenticationRoutes
];

export default routes;
