<template>
  <div
    class="d-flex align-items-center p-3 border-bottom justify-content-between"
  >
    <div class="d-flex align-items-center">
      <i class="mr-2" :class="sellingPlaceReport.type.icon"></i>
      <div>
        <h5 class="mb-0">
          Pijaca: {{ sellingPlaceReport.market.name }}
        </h5>
        <h5 class="mb-0">
          Naziv: {{ sellingPlaceReport.name }} <small>({{ sellingPlaceReport.type.name }})</small> <small>({{ sellingPlaceReport.category.name }})</small>
        </h5>
        <p class="mb-0">
          Ukupno Prodato: {{ sellingPlaceReport.totalSold }} ({{ sellingPlaceReport.totalAmount / 100 }} RSD),
          Prodato Dnevnih Zakupa: {{ sellingPlaceReport.dailySold }} ({{ sellingPlaceReport.dailyTotalAmount / 100 }} RSD),
          Prodato Mesečnih Rezervacija: {{ sellingPlaceReport.monthlySold }} ({{ sellingPlaceReport.monthlyTotalAmount / 100 }} RSD)
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { SellingPlace } from "@/modules/selling-place/types";
import eventHub from "@/components/EventHub";
import {SellingPlaceReport} from "@/modules/selling-place/types/SellingPlaceReport";

@Options({
  props: {
    sellingPlaceReport: {
      type: Object,
      required: true,
      default: {}
    }
  }
})
export default class SellingPlaceReportListItemWidget extends Vue {
  sellingPlaceReport!: SellingPlaceReport;
}
</script>
