
import { Options, Vue } from "vue-class-component";
import EditModal from "@/modules/common/modals/EditModal.vue";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import ContractTypeApi from "@/modules/contracts/api";
import eventHub from "@/components/EventHub";
import { Contract } from "@/modules/contracts/types";
import {
  EditContractRequestData,
  FetchContract
} from "@/modules/contracts/api/types";
import EditContractForm from "@/modules/contracts/form/EditContractForm.vue";
// import moment from "moment";

@Options({
  components: { EditModal, EditContractForm }
})
export default class EditContractRequestListener extends Vue {
  contract!: Contract;
  contractRequestData!: EditContractRequestData;

  displayDialog = false;

  editContractAction = AsyncActionFactory(ContractTypeApi.editContract);
  fetchContract = AsyncActionFactory(
    ContractTypeApi.fetchContract as FetchContract
  );

  async created() {
    // console.log("edit listener");
    eventHub.on("CONTRACT_EDIT_REQUESTED", this.onEditContractRequested);
  }

  async editContract() {
    await this.editContractAction.call(
      this.contract.id,
      this.contractRequestData
    );

    if (this.editContractAction.isSuccessful) {
      eventHub.emit("CONTRACT_EDITED", this.contract.id);
      this.closeModal();
    }
  }

  async onEditContractRequested(contractId) {
    await this.fetchContract.call(contractId);
    console.log("ON edit listener", contractId);

    if (this.fetchContract.isSuccessful) {
      this.contract = this.fetchContract.responseData as Contract;
      this.contractRequestData = this.createEditContractRequestData();
      console.log(111, this.contractRequestData);
      this.displayDialog = true;
    }
    console.log(222);
  }

  createEditContractRequestData(): EditContractRequestData {
    console.log(333, this.contract.items[0]);
    return {
      tenantId: this.contract.tenantId,
      sellingPlaceId: this.contract.items[0].sellingPlaceId,
      // rentStartDate: moment(
      //   String(this.contract.items[0].rentStartDate)
      // ).format("YYYY/MM"),
      // rentEndDate: moment(
      //   String(this.contract.items[0].rentEndDate)
      // ).format("YYYY/MM"),
      rentStartDate: this.contract.items[0].rentStartDate,
      rentEndDate: this.contract.items[0].rentEndDate,
      paid: this.contract.issued
    };
  }

  closeModal() {
    this.displayDialog = false;
    this.editContractAction.clear();
  }

  get idDataLoading(): boolean {
    return this.editContractAction.isBusy || this.fetchContract.isBusy;
  }
}
