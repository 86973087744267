
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    backgroundImageUrl: {
      type: String,
      required: false,
      default: ""
    }
  }
})
export default class BoxLayout extends Vue {
  backgroundImageUrl!: string;
}
