import { RouteRecordRaw } from "vue-router";
import WorkOrders from "@/structure/Administrator/WorkOrders/views/WorkOrders.vue";
import PassThroughViewWorkOrders from "@/structure/Administrator/WorkOrders/widgets/PassThroughViewWorkOrders.vue";
import NewWorkOrder from "@/structure/Administrator/WorkOrders/views/NewWorkOrder.vue";

const workOrdersRoutes: Array<RouteRecordRaw> = [
  {
    path: "work-orders",
    name: "administrator-work-orders",
    component: PassThroughViewWorkOrders,
    redirect: () => ({
      name: "administrator-work-orders-homepage",
      params: {}
    }),
    children: [
      {
        path: "",
        name: "administrator-work-orders-homepage",
        component: WorkOrders,
        meta: {
          title: ""
        }
      },
      {
        path: "new",
        name: "administrator-work-orders-new",
        component: NewWorkOrder,
        meta: {
          title: "New"
        }
      }
    ]
  }
];

export default workOrdersRoutes;
