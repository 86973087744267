<style scoped>
/deep/ .month-picker__container {
  position: absolute;
  top: 3.5em;
  background-color: white;
  z-index: 9999;
}
table,
th {
  text-align: center;
}
td,
tr,
th {
  border: 1px solid #333;
  padding: 5px;
}

thead,
tfoot {
  background-color: #333;
  color: #fff;
}
</style>
<template>
  <Spinner :show-spinner="isDataLoading">
    <div class="d-flex align-items-center mb-3">
      <!--      <div class="form-group w-100 mr-2">-->
      <!--        <label class="form-label">Pijaca</label>-->
      <!--        <select v-model="params.market" class="form-control">-->
      <!--          <option :value="undefined">Sve</option>-->
      <!--          <option-->
      <!--            v-for="(market, index) in marketList.data"-->
      <!--            :key="'market' + index"-->
      <!--            :value="market.id"-->
      <!--            >{{ market.name }}</option-->
      <!--          >-->
      <!--        </select>-->
      <!--      </div>-->
      <div class="form-group w-100 mr-2">
        <label class="form-label">Od</label>
        <input type="date" class="form-control" v-model="params.from" />
      </div>
      <div class="form-group w-100 mr-2">
        <label class="form-label">Do</label>
        <input type="date" class="form-control" v-model="params.to" />
      </div>
      <button class="btn btn-outline-primary ml-2 mt-2" @click="loadData()">
        Pretraga
      </button>
    </div>
    <div id="printMe">
    <table>
      <thead>
        <tr>
          <th colspan="12">
            <h4>
              Izveštaj Po Prodajnim Mestima ({{ params.from }} -
              {{ params.to }})
            </h4>
          </th>
        </tr>
        <tr style="background-color: white; color: black">
          <th>Naziv Inkasanta</th>
          <th>Ukupno Prodato</th>
          <th>Ukupan Prihod</th>
          <th>Ukupno Prodatih Dnevnih Pijacarina</th>
          <th>Ukupan Prihod</th>
          <th>Ukupno Prodato Mesecnih Rezervacija</th>
          <th>Ukupan Prihod</th>
        </tr>
      </thead>
      <tbody v-if="cashierReportsList.data.length">
        <tr v-for="cashier in cashierReportsList.data" :key="cashier.id">
          <td>{{ cashier.lastName }} {{ cashier.firstName }}</td>
          <td>{{ cashier.totalSold }}</td>
          <td>{{ cashier.totalAmount / 100 }} RSD</td>
          <td>{{ cashier.dailySold }}</td>
          <td>{{ cashier.dailyTotalAmount / 100 }} RSD</td>
          <td>{{ cashier.monthlySold }}</td>
          <td>{{ cashier.monthlyTotalAmount / 100 }} RSD</td>
        </tr>
      </tbody>
      <tfoot v-if="cashierReportsList.data.length">
        <tr>
          <td></td>
          <td>
            {{
              cashierReportsList.data.reduce(
                (accumulator, currentValue) => {
                  return accumulator + currentValue.totalSold;
                },
                0
              )
            }}
          </td>
          <td>
            {{
              cashierReportsList.data.reduce(
                (accumulator, currentValue) => {
                  return accumulator + currentValue.totalAmount;
                },
                0
              ) / 100
            }}
            RSD
          </td>
          <td>
            {{
              cashierReportsList.data.reduce(
                (accumulator, currentValue) => {
                  return accumulator + currentValue.dailySold;
                },
                0
              )
            }}
          </td>
          <td>
            {{
              cashierReportsList.data.reduce(
                (accumulator, currentValue) => {
                  return accumulator + currentValue.dailyTotalAmount;
                },
                0
              ) / 100
            }}
            RSD
          </td>
          <td>
            {{
              cashierReportsList.data.reduce(
                (accumulator, currentValue) => {
                  return accumulator + currentValue.monthlySold;
                },
                0
              )
            }}
          </td>
          <td>
            {{
              cashierReportsList.data.reduce(
                (accumulator, currentValue) => {
                  return accumulator + currentValue.monthlyTotalAmount;
                },
                0
              ) / 100
            }}
            RSD
          </td>
        </tr>
      </tfoot>
    </table>
    </div>
    <div class="row justify-content-md-center mt-3">
      <button
              type="button"
              class="btn btn-outline-primary btn-lg"
              @click="printDiv"
      >
        Konvertuj u PDF
      </button>
    </div>
  </Spinner>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import ListWidget from "@/modules/common/widgets/ListWidget";
import CashierApi from "@/modules/user/api";
import { MonthPickerInput } from "vue-month-picker";
import { CashierReportList } from "@/modules/user/types";
import { emptyCashierReportsList } from "@/modules/user/types/emptyCashierReportsList";
import { FetchCashierReportsParams } from "@/modules/user/api/types";
import CashierReportListItemWidget from "@/modules/user/widgets/UserList/CashierReportListItemWidget.vue";
import { MarketList } from "@/modules/market/types";
import { emptyMarketList } from "@/modules/market/types/EmptyMarketList";
import MarketApi from "@/modules/market/api";
import { FetchMarketsParams } from "@/modules/market/api/types";

@Options({
  components: {
    Spinner,
    ListWidget,
    MonthPickerInput,
    CashierReportListItemWidget
  },
  watch: {
    date: {
      handler(newDate) {
        this.params.from = newDate.from;
        this.params.to = newDate.to;
      },
      deep: true
    }
  }
})
export default class CashierReportsListWidget extends Vue {
  date = new Date();
  first = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
  last = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
  marketList: MarketList = emptyMarketList;
  cashierReportsList: CashierReportList = emptyCashierReportsList;
  params: FetchCashierReportsParams = {
    from: this.formatDate(this.first),
    to: this.formatDate(this.last),
    market: undefined
  };

  fetchReports = AsyncActionFactory(CashierApi.fetchCashierReports);
  fetchMarketsAction = AsyncActionFactory(MarketApi.fetchMarkets);

  async created() {
    this.loadData();
    this.fetchMarkets();
  }

  async loadData() {
    await this.fetchReports.call(this.params);

    if (this.fetchReports.isSuccessful) {
      this.cashierReportsList = this.fetchReports.responseData;
    }
  }

  get isDataLoading(): boolean {
    return this.fetchReports.isBusy;
  }

  async fetchMarkets() {
    await this.fetchMarketsAction.call({
      perPage: -1
    } as FetchMarketsParams);

    if (this.fetchMarketsAction.isSuccessful) {
      const response = this.fetchMarketsAction.responseData as MarketList;
      if (response.data.length > 0) {
        this.marketList = response;
      }
    }
  }

  formatDate(date) {
    const d = date,
      year = d.getFullYear();

    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  printDiv() {
    const printContents = document.getElementById("printMe").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
}
</script>
