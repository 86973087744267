
import { Options, Vue } from "vue-class-component";
import ContractStatusWidget from "@/modules/contracts/widgets/ContractStatusWidget.vue";
import { Contract } from "@/modules/contracts/types/Contract.ts";

@Options({
  components: {
    ContractStatusWidget
  },

  props: {
    contract: {
      type: Object,
      require: true
    }
  }
})
export default class ContractDetailsWidget extends Vue {
  contract?: Contract;

}
