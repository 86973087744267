import ApiClient from "@/components/ApiClient/ApiClient";

import {
  FetchSellingPlace,
  FetchSellingPlaceParams,
  FetchSellingPlaces,
  FetchSellingPlacesParams,
  FetchAllSellingPlaces,
  FetchSellingPlaceDetails,
  FetchSellingPlaceDetailsParams,
  FetchSellingPlacesReportsData,
  FetchSellingPlacesReportsDataParams,
  UpdateSellingPlaceCoordinates,
  UpdateSellingPlaceCoordinatesParams
} from "./types";

import {
  sellingPlaceMapper,
  sellingPlaceListMapper,
  allSellingPlaceListMapper,
  sellingPlaceReportListMapper
} from "./mappers";
import {SellingPlace, SellingPlaceList, SellingPlaceReportsList} from "@/modules/selling-place/types";

class Api {
  public client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  fetchSellingPlaces: FetchSellingPlaces = (
    params: FetchSellingPlacesParams
  ): Promise<SellingPlaceList> => {
    const url = `markets/${params.marketId}/selling-places`;

    return this.client.get(url, params).then(sellingPlaces => {
      return sellingPlaceListMapper(sellingPlaces);
    });
  };

  fetchSellingPlace: FetchSellingPlace = (
    params: FetchSellingPlaceParams
  ): Promise<SellingPlace> => {
    const url = `markets/${params.marketId}/selling-places/${params.sellingPlaceId}`;

    //ToDo Sasa refactor this

    return this.client.get(url).then(sellingPlace => {
      return sellingPlaceMapper(sellingPlace);
    });
  };

  fetchSellingPlaceDetails: FetchSellingPlaceDetails = (
    params: FetchSellingPlaceDetailsParams
  ): Promise<SellingPlace> => {
    const url = `markets/1/selling-places-details/${params.sellingPlaceId}`;

    return this.client.get(url).then(sellingPlace => {
      return sellingPlaceMapper(sellingPlace);
    });
  };

  fetchAllSellingPlaces: FetchAllSellingPlaces = (): Promise<
    SellingPlaceList
  > => {
    const url = `selling-places-markets`;

    return this.client.get(url).then(sellingPlaces => {
      // return sellingPlaces;
      return allSellingPlaceListMapper(sellingPlaces);
    });
  };

  fetchSellingPlacesReportsData: FetchSellingPlacesReportsData = (
      params: FetchSellingPlacesReportsDataParams
  ): Promise<SellingPlaceReportsList> => {
    const url = `reports/selling-places`;

    return this.client.get(url, params).then(sellingPlaces => {
      return sellingPlaceReportListMapper(sellingPlaces);
    });
  }

  updateSellingPlaceCoordinates: UpdateSellingPlaceCoordinates = (
    params: UpdateSellingPlaceCoordinatesParams
  ): Promise<void> => {
    const url = `markets/${params.marketId}/selling-places-coordinates/${params.sellingPlaceId}`

    return this.client.patch(url, params).then(response => response);
  }
}

export default Api;
