
import { Options, Vue } from "vue-class-component";
import SellingPlaceTypeListWidget from "./SellingPlaceTypeList/SellingPlaceTypeListWidget.vue";
import eventHub from "@/components/EventHub";
import SellingPlaceTypeNewRequestedEvent from "@/modules/selling-place-type/events/SellingPlaceTypeNewRequestedEvent";

@Options({
  components: {
    SellingPlaceTypeListWidget
  }
})
export default class SellingPlaceTypesWidget extends Vue {
  newSellingPlaceType() {
    eventHub.emit<SellingPlaceTypeNewRequestedEvent>(
      SellingPlaceTypeNewRequestedEvent.Name,
      new SellingPlaceTypeNewRequestedEvent()
    );
  }
}
