<template>
  <EditModal
    v-if="displayDialog"
    @close="closeModal()"
    :title="'Izmena Lozinke za: ' + user.getName()"
    @confirm="editUserPassword()"
    :showSpinner="isDataLoading"
  >
    <ChangePasswordForm
      v-model:user-request-data="userRequestData"
      :errors="editUserPasswordAction.errors"
    />
  </EditModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import UserApi from "@/modules/user/api";
import EditModal from "@/modules/common/modals/EditModal.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import eventHub from "@/components/EventHub";
import ChangePasswordForm from "../forms/ChangePasswordForm.vue";
import { EditUserRequestData, FetchUser } from "../api/types";
import { User } from "../types";
import UserPasswordEditRequestedEvent from "@/modules/user/events/UserPasswordEditRequestedEvent";
import UserPasswordEditedEvent from "@/modules/user/events/UserPasswordEditedEvent";
@Options({
  components: { EditModal, ChangePasswordForm }
})
export default class ChangeUserPasswordListener extends Vue {
  user!: User;
  userRequestData!: EditUserRequestData;

  displayDialog = false;

  editUserPasswordAction = AsyncActionFactory(UserApi.editUser);
  fetchUser = AsyncActionFactory(UserApi.fetchUser as FetchUser);

  async created() {
    eventHub.on<UserPasswordEditRequestedEvent>(
      UserPasswordEditRequestedEvent.Name,
      this.onEditUserPasswordRequested
    );
  }

  async editUserPassword() {
    await this.editUserPasswordAction.call(this.user.id, this.userRequestData);

    if (this.editUserPasswordAction.isSuccessful) {
      eventHub.emit<UserPasswordEditedEvent>(
        UserPasswordEditedEvent.Name,
        new UserPasswordEditedEvent(this.user)
      );
      this.closeModal();
    }
  }

  async onEditUserPasswordRequested(event?: UserPasswordEditRequestedEvent) {
    if (!(event instanceof UserPasswordEditRequestedEvent)) {
      return;
    }

    await this.fetchUser.call(event.getId());
    if (this.fetchUser.isSuccessful) {
      this.user = this.fetchUser.responseData as User;
      this.userRequestData = this.createEmptyUserPasswordRequestData();
      this.displayDialog = true;
    }
  }

  createEmptyUserPasswordRequestData(): EditUserRequestData {
    return {
      password: {
        first: "",
        second: ""
      }
    };
  }

  closeModal() {
    this.displayDialog = false;
    this.editUserPasswordAction.clear();
  }

  get isDataLoading(): boolean {
    return this.editUserPasswordAction.isBusy || this.fetchUser.isBusy;
  }

  get title(): string {
    return "Edit " + this.user.firstName + " Password";
  }
}
</script>
