
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import { MarketList } from "@/modules/market/types";

@Options({
  props: {
    marketId: {
      type: String,
      required: false,
      default: ""
    }
  },
  emits: ["updateMarket"]
})
export default class MarketSelector extends Vue {
  marketId!: string;

  store = useStore();
  market = this.marketId;

  get markets(): MarketList {
    return this.store.getters.marketList;
  }

  selectMarket() {
    this.$emit("updateMarket", this.market);
  }
}
