<template>
  <Spinner :show-spinner="isDataLoading">
    <div class="d-flex justify-content-center align-items-center pb-2 mb-4">
      <div class="ui-w-60">
        <div class="w-100 d-flex align-items-center justify-content-center">
          <img :src="settings.organizationLogoUrl" alt="" width="200" />
        </div>
      </div>
    </div>

    <h5 class="text-center text-muted font-weight-normal mb-4">
      Prijava Na {{ settings.organizationName }}
    </h5>
    <p class="text-center text-danger" v-if="showErrorMessage">
      Pogrešni Kredencijali
    </p>

    <form @submit.prevent="submit()">
      <div class="form-group">
        <label class="form-label">Email</label>
        <input
          v-model="loginRequestData.email"
          type="email"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label
          class="form-label d-flex justify-content-between align-items-end"
        >
          <div>Lozinka</div>
          <!--          <a href="javascript:void(0)" class="d-block small"-->
          <!--            >Forgot password?</a-->
          <!--          >-->
        </label>
        <input
          type="password"
          class="form-control"
          v-model="loginRequestData.password"
        />
      </div>
      <div class="d-flex justify-content-between align-items-center m-0">
        <label class="custom-control custom-checkbox m-0">
          <input type="checkbox" class="custom-control-input" />
          <!--          <span class="custom-control-label">Remember me</span>-->
        </label>
        <button type="submit" class="btn btn-primary">Prijava</button>
      </div>
    </form>
  </Spinner>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import AsyncActionFactory from "@/components/AsyncAction";
import { inject } from "vue";
import { MutationType } from "@/store/mutations";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import { FetchSettings } from "@/modules/settings/api/types";
import { LoginRequestData } from "@/modules/authentication/api/types";
import { Settings } from "@/modules/settings/types";

@Options({
  components: { Spinner },
  props: {
    showErrorMessage: {
      type: Boolean
    }
  },
  emits: ["login"]
})
export default class LoginWidget extends Vue {
  loginRequestData: LoginRequestData = {
    email: "",
    password: ""
  };

  showErrorMessage!: boolean;

  dataLoaded = false;
  store = useStore();

  fetchSettings = AsyncActionFactory(inject("fetchSettings") as FetchSettings);

  async created() {
    await this.loadData();
  }

  async loadData() {
    await this.fetchSettings.call({});

    if (this.fetchSettings.isSuccessful) {
      this.store.commit(
        MutationType.SetSettings,
        this.fetchSettings.responseData
      );
      this.dataLoaded = true;
    }
  }

  async submit() {
    this.$emit("login", this.loginRequestData);
  }

  get settings(): Settings {
    return this.store.getters.settings;
  }

  get isDataLoading(): boolean {
    return this.fetchSettings.isBusy || !this.dataLoaded;
  }
}
</script>
