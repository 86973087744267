
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import ContractTypeApi from "@/modules/contracts/api";
import { FetchContract } from "@/modules/contracts/api/types";
import { Contract } from "@/modules/contracts/types";
import { useStore } from "@/store";
import { FetchMarket } from "@/modules/market/api/types";
import { SellingPlace } from "@/modules/selling-place/types";
import SellingPlaceApi from "@/modules/selling-place/api";
import { FetchSellingPlaceParams } from "@/modules/selling-place/api/types";
import moment from "moment";

@Options({})
export default class Print extends Vue {
  // contractFull!: Contract;
  // contractFull = Object;
  // sellingPlaceDetails!: SellingPlace;
  sellingPlaceDetails: any = Object;
  store = useStore();

  fetchSellingPlaceDetails = AsyncActionFactory(
    SellingPlaceApi.fetchSellingPlaceDetails
  );
  months = 0;
  rentStartDate = "";

  get contract(): Contract {
    return this.store.getters.contract;
  }

  get address() {
    // if (this.contract.organizationDetails.address.line1 && this.contract.organizationDetails.address.line2) {
    //   return this.contract.organizationDetails.address.line1 + ', ' + this.contract.organizationDetails.address.line2;
    // }
    if (this.contract.organizationDetails.address.line1) {
      return this.contract.organizationDetails.address.line1;
    }
    return "-";
  }

  created() {
    // this.contract = this.contractStore;
    // this.fetchContractData(this.contract.id);
    // console.log(33333, this.contract.items[0].sellingPlaceId );

    this.fetchSellingPlaceDetailsData(this.contract.items[0].sellingPlaceId);
    // console.log(
    //   70,
    //   this.contract.items[0].rentStartDate,
    //   this.contract.items[0].rentEndDate
    // );
    this.months = this.monthDiff(
      new Date(this.contract.items[0].rentStartDate),
      new Date(this.contract.items[0].rentEndDate)
    );
    this.rentStartDate = moment(
      String(this.contract.items[0].rentStartDate)
    ).format("DD.MM.YYYY");
  }

  async fetchSellingPlaceDetailsData(sellingPlaceId) {
    // console.log(44444, sellingPlaceId);
    // await this.fetchSellingPlace.call({
    //   marketId: marketId,
    //   sellingPlaceId: sellingPlaceId
    // } as FetchSellingPlaceParams);
    await this.fetchSellingPlaceDetails.call({
      sellingPlaceId: sellingPlaceId
    });
    // console.log(1, sellingPlaceId);
    if (this.fetchSellingPlaceDetails.isSuccessful) {
      // console.log(2, this.fetchSellingPlaceDetails.responseData);
      this.sellingPlaceDetails = this.fetchSellingPlaceDetails.responseData;
      // console.log(21, this.sellingPlaceDetails.market);
    }
  }

  monthDiff(d1: Date, d2: Date) {
    let months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth() + 1;
    return months <= 0 ? 0 : months;
  }

  printDiv() {
    const printContents = document.getElementById("printMe").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
}
