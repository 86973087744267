import eventHub from "@/components/EventHub";
import { useRouter } from "@/components/Router";

export default class TenantViewRequestedEventListener {
  constructor() {
    eventHub.on("TENANT_VIEW_REQUESTED", this.onTenantViewRequested);
  }

  async onTenantViewRequested(tenantId) {
    await useRouter().push({
      name: "administrator-tenant",
      params: {
        tenantId: tenantId
      }
    });
  }
}
