
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Reports/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo, TabItems } from "@/modules/common/types";
import TabBarWidget from "@/modules/common/widgets/TabBarWidget.vue";
import getTabItems from "@/structure/Administrator/Reports/utils/getTabItems";
import AsyncActionFactory from "@/components/AsyncAction";
import SellingPlaceApi from "@/modules/selling-place/api";
import { FetchSellingPlacesReportsDataParams } from "@/modules/selling-place/api/types";
import SellingPlaceReportsListWidget from "@/modules/selling-place/widgets/SellingPlaceList/SellingPlaceReportsListWidget.vue";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    TabBarWidget,
    SellingPlaceReportsListWidget
  }
})
export default class ReportsPage extends Vue {
  get headerInfo(): HeaderInfo {
    return {
      icon: "ion ion-md-document",
      section: "Izveštaji Prodajnih Mesta",
      description: ""
    };
  }

  get tabItems(): TabItems {
    return getTabItems();
  }
}
