
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Contracts/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import ContractsHeaderWidgets from "../widgets/ContractsHeaderWidgets.vue";
import ContractListWidget from "@/modules/contracts/widgets/ContractList/ContractListWidget.vue";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    ContractsHeaderWidgets,
    ContractListWidget
  }
})
export default class ContractsHomePage extends Vue {
  headerInfo: HeaderInfo = {
    icon: "ion ion-md-document",
    section: "Ugovori"
  };
}
