import { createI18n } from "vue-i18n";

import en from "./translations/en.json";
import sr from "./translations/sr.json";

export const i18n = createI18n({
  locale: "sr",
  fallbackLocale: "en",
  messages: {
    en: en,
    sr: sr
  }
});

export const $t = i18n.global.t.bind(i18n); // create alias function $t() and you can now use it as usual
