
import { Options, Vue } from "vue-class-component";
import SellingPlacePage from "@/structure/Administrator/Settings/pages/SellingPlacePage.vue";
import { provide } from "vue";
import SellingPlaceTypeApi from "@/modules/selling-place-type/api";
import SellingPlaceCategoryApi from "@/modules/selling-place-category/api";
import {
  FetchSellingPlaceCategories,
  FetchSellingPlaceCategory
} from "@/modules/selling-place-category/api/types";
import {
  FetchSellingPlaceType,
  FetchSellingPlaceTypes
} from "@/modules/selling-place-type/api/types";

@Options({
  components: { SellingPlacePage }
})
export default class SellingPlace extends Vue {
  created() {
    provide(
      "fetchSellingPlaceTypes",
      SellingPlaceTypeApi.fetchSellingPlaceTypes.bind(
        SellingPlaceTypeApi
      ) as FetchSellingPlaceTypes
    );
    provide(
      "fetchSellingPlaceCategories",
      SellingPlaceCategoryApi.fetchSellingPlaceCategories.bind(
        SellingPlaceCategoryApi
      ) as FetchSellingPlaceCategories
    );
    provide(
      "fetchSellingPlaceType",
      SellingPlaceTypeApi.fetchSellingPlaceType.bind(
        SellingPlaceTypeApi
      ) as FetchSellingPlaceType
    );
    provide(
      "fetchSellingPlaceCategory",
      SellingPlaceCategoryApi.fetchSellingPlaceCategory.bind(
        SellingPlaceCategoryApi
      ) as FetchSellingPlaceCategory
    );
  }
}
