
import { Options, Vue } from "vue-class-component";
import Modal from "@/modules/common/modals/Modal.vue";
import { Market } from "@/modules/market/types";
import AsyncActionFactory from "@/components/AsyncAction";
import SellingPlacePriceApi from "@/modules/selling-place-price/api";
import { useStore } from "@/store";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import PriceInput from "@/modules/common/forms/PriceInput.vue";
import { EditSellingPlacePriceItemRequestData } from "@/modules/selling-place-price/api/types";
import { SellingPlacePrice } from "@/modules/selling-place-price/types";

@Options({
  components: {
    Modal,
    Spinner,
    PriceInput
  },
  props: {
    sellingPlacePriceItem: {
      type: Object,
      required: true
    }
  },
  emits: ["closeModal", "sellingPlacePriceItemEdited"]
})
export default class SellingPlacePriceEditModal extends Vue {
  store = useStore();

  sellingPlacePriceItem!: SellingPlacePrice;

  rentPriceDaily = Number(this.sellingPlacePriceItem.rentPriceDaily) / 100;

  rentPriceWithoutReservationDaily =
    Number(this.sellingPlacePriceItem.rentPriceWithoutReservationDaily) / 100;

  reservationPriceMonthly =
    Number(this.sellingPlacePriceItem.reservationPriceMonthly) / 100;

  editSellingPlacePriceItemAction = AsyncActionFactory(
    SellingPlacePriceApi.editSellingPlacePriceItem
  );

  closeModal() {
    this.$emit("closeModal");
  }

  get market(): Market {
    return this.store.getters.market;
  }

  async editSellingPlacePriceItem() {
    if (!this.isDataValid) {
      return;
    }

    const requestData: EditSellingPlacePriceItemRequestData = {
      rentPriceDaily: this.rentPriceDaily * 100,
      rentPriceWithoutReservationDaily:
        this.rentPriceWithoutReservationDaily * 100,
      reservationPriceMonthly: this.reservationPriceMonthly * 100
    };
    await this.editSellingPlacePriceItemAction.call(
      this.market.id,
      this.sellingPlacePriceItem.sellingPlaceCategory.id,
      this.sellingPlacePriceItem.sellingPlaceType.id,
      requestData
    );

    if (this.editSellingPlacePriceItemAction.isSuccessful) {
      this.$emit("sellingPlacePriceItemEdited");
    }
  }

  get isDataValid(): boolean {
    if (isNaN(this.rentPriceDaily) || this.rentPriceDaily < 1) return false;
    if (
      isNaN(this.rentPriceWithoutReservationDaily) ||
      this.rentPriceWithoutReservationDaily < 1
    )
      return false;
    if (isNaN(this.reservationPriceMonthly) || this.reservationPriceMonthly < 1)
      return false;

    return true;
  }
}
