
import { Options, Vue } from "vue-class-component";
import HncfLayout from "@/modules/common/layouts/HncfLayout.vue";
import FooterWidget from "@/modules/common/widgets/FooterWidget.vue";
import { HeaderOptions, Links } from "@/modules/common/types";
import { getHeaderOptions } from "@/structure/common/utils/getHeaderOptions";
import { getProfileMenuLinks } from "@/structure/common/utils/getProfileMenuLinks";
import { getApplicationLinks } from "@/structure/common/utils/getApplicationLinks";
import NavigationWidget from "@/structure/Administrator/common/widgets/NavigationWidget.vue";
import { useStore } from "@/store";
import AppHeaderWidget from "@/structure/common/widgets/AppHeaderWidget.vue";
import { Settings } from "@/modules/settings/types";

@Options({
  components: { HncfLayout, AppHeaderWidget, FooterWidget, NavigationWidget }
})
export default class extends Vue {
  store = useStore();

  get settings(): Settings {
    return this.store.getters.settings;
  }

  get headerOptions(): HeaderOptions {
    return getHeaderOptions(this.settings);
  }

  get profileMenuLinks(): Links {
    return getProfileMenuLinks();
  }

  get applicationLinks(): Links {
    return getApplicationLinks();
  }
}
