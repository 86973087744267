<template>
  <EditModal
    v-if="displayDialog"
    @close="closeModal()"
    title="Izmena Tipa Prodajnog Objekta"
    @confirm="onSellingPlaceTypeEdited()"
    :showSpinner="isDataLoading"
  >
    <SellingPlaceTypeForm
      v-model:selling-place-type="sellingPlaceType"
      :errors="editSellingPlaceTypeReasonAction.errors"
    />
  </EditModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import EditModal from "@/modules/common/modals/EditModal.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import eventHub from "@/components/EventHub";
import {
  EditSellingPlaceTypeRequestData,
  FetchSellingPlaceType
} from "@/modules/selling-place-type/api/types";
import SellingPlaceTypeForm from "@/modules/selling-place-type/forms/SellingPlaceTypeForm.vue";
import SellingPlaceTypeApi from "@/modules/selling-place-type/api";
import SellingPlaceTypeEditRequestedEvent from "@/modules/selling-place-type/events/SellingPlaceTypeEditRequestedEvent";
import SellingPlaceTypeUpdatedEvent from "@/modules/selling-place-type/events/SellingPlaceTypeUpdatedEvent";
import { SellingPlaceType } from "@/modules/selling-place-type/types";
@Options({
  components: { EditModal, SellingPlaceTypeForm }
})
export default class SellingPlaceTypeEditRequestedListener extends Vue {
  sellingPlaceType!: SellingPlaceType;

  displayDialog = false;

  editSellingPlaceTypeReasonAction = AsyncActionFactory(
    SellingPlaceTypeApi.editSellingPlaceType
  );
  fetchSellingPlaceType = AsyncActionFactory(
    SellingPlaceTypeApi.fetchSellingPlaceType as FetchSellingPlaceType
  );

  async created() {
    eventHub.on<SellingPlaceTypeEditRequestedEvent>(
      SellingPlaceTypeEditRequestedEvent.Name,
      this.onEditSellingPlaceTypeRequested
    );
  }

  async onEditSellingPlaceTypeRequested(
    event?: SellingPlaceTypeEditRequestedEvent
  ) {
    if (!(event instanceof SellingPlaceTypeEditRequestedEvent)) {
      return;
    }

    await this.fetchSellingPlaceType.call(event.getId());

    if (this.fetchSellingPlaceType.isSuccessful) {
      this.sellingPlaceType = this.fetchSellingPlaceType
        .responseData as SellingPlaceType;
      this.displayDialog = true;
    }
  }

  async onSellingPlaceTypeEdited() {
    await this.editSellingPlaceTypeReasonAction.call(
      this.sellingPlaceType.id,
      this.sellingPlaceTypeRequestData(this.sellingPlaceType)
    );

    if (this.editSellingPlaceTypeReasonAction.isSuccessful) {
      eventHub.emit(
        SellingPlaceTypeUpdatedEvent.Name,
        new SellingPlaceTypeUpdatedEvent(this.sellingPlaceType)
      );
      this.closeModal();
    }
  }

  sellingPlaceTypeRequestData(
    sellingPlaceType: SellingPlaceType
  ): EditSellingPlaceTypeRequestData {
    return {
      name: sellingPlaceType.name,
      description: sellingPlaceType.description,
      icon: sellingPlaceType.icon,
      sellingArea: sellingPlaceType.sellingArea,
      sellingBan: sellingPlaceType.sellingBan,
      color: sellingPlaceType.color
    };
  }

  closeModal() {
    this.displayDialog = false;
    this.editSellingPlaceTypeReasonAction.clear();
  }

  get isDataLoading(): boolean {
    return (
      this.editSellingPlaceTypeReasonAction.isBusy ||
      this.fetchSellingPlaceType.isBusy
    );
  }
}
</script>
