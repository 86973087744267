<template>
  <ReportsPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ReportsPage from "@/structure/Administrator/Reports/pages/ReportsPage.vue";

@Options({
  components: { ReportsPage }
})
export default class Reports extends Vue {}
</script>
