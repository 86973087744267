<template>
  <button
    type="button"
    class="btn btn-default btn-outline-primary"
    @click="newMarket()"
  >
    <i class="ion ion-ios-add"></i>
    Nova Pijaca
  </button>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";

export default class extends Vue {
  newMarket() {
    eventHub.emit("CREATE_MARKET_REQUEST");
  }
}
</script>
