<template>
  <CreateModal
    v-if="displayDialog"
    @close="closeModal()"
    :title="'Pravilo za numerisanje ugovora'"
    @confirm="createContractNumberingRule()"
    :showSpinner="isDataLoading"
  >
    <ContractNumberingRuleForm
      v-model:contract-numbering-rule="contractNumberingRuleData"
      :errors="postContractNumberingRuleAction.errors"
    />
  </CreateModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";
import CreateModal from "@/modules/common/modals/CreateModal.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import ContractNumberingRulesApi from "@/modules/contract-numbering-rules/api";
import ContractNumberingRuleForm from "@/modules/contract-numbering-rules/forms/ContractNumberingRuleForm.vue";
import ContractNumberingRuleNewRequestedEvent from "@/modules/contract-numbering-rules/events/ContractNumberingRuleNewRequestedEvent";
import {
  NewContractNumberingRuleRequestData,
  PostContractNumberingRuleResponse
} from "../api/types";

@Options({
  components: { CreateModal, ContractNumberingRuleForm }
})
export default class ContractNumberingRuleCreateRequestedListener extends Vue {
  displayDialog = false;
  contractNumberingRuleData: NewContractNumberingRuleRequestData = this.createEmptyContractNumberingRuleRequestData();

  postContractNumberingRuleAction = AsyncActionFactory(
    ContractNumberingRulesApi.createContractNumberingRule
  );

  created() {
    eventHub.on<ContractNumberingRuleNewRequestedEvent>(
      ContractNumberingRuleNewRequestedEvent.Name,
      () => {
        this.displayDialog = true;
      }
    );
  }

  async createContractNumberingRule() {
    await this.postContractNumberingRuleAction.call(
      this.contractNumberingRuleData
    );

    if (this.postContractNumberingRuleAction.isSuccessful) {
      eventHub.emit(
        "CONTRACT_NUMBERING_RULE_CREATED",
        (this.postContractNumberingRuleAction
          .responseData as PostContractNumberingRuleResponse).id
      );
      this.closeModal();
    }
  }

  closeModal() {
    this.displayDialog = false;
    this.contractNumberingRuleData = this.createEmptyContractNumberingRuleRequestData();
  }

  createEmptyContractNumberingRuleRequestData(): NewContractNumberingRuleRequestData {
    return {
      year: new Date().getFullYear(),
      nextDocumentNumber: 0,
      prefix: "",
      suffix: "",
      addSpaceBetweenElements: true,
      yearSeparator: "-",
      minimalDigitsNumber: 3,
      formatted: ""
    };
  }

  get isDataLoading(): boolean {
    return this.postContractNumberingRuleAction.isBusy;
  }
}
</script>

<style scoped></style>
