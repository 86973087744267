
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Transactions/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import TransactionForm from "@/modules/transactions/forms/TransactionForm.vue";

@Options({
  components: { PageLayout, PageHeaderInfoWidget, TransactionForm }
})
export default class NewTransactionPage extends Vue {
  headerInfo: HeaderInfo = {
    icon: "ion ion-md-document",
    section: "Transakcije",
    title: "Nova"
  };
}
