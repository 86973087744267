
import { Options, Vue } from "vue-class-component";
import SellingPlacePage from "@/structure/Administrator/Market/pages/SellingPlacePage.vue";
import { provide } from "vue";
import SellingPlaceApi from "@/modules/selling-place/api";
import { FetchSellingPlace } from "@/modules/selling-place/api/types";

@Options({
  components: { SellingPlacePage }
})
export default class SellingPlace extends Vue {
  created() {
    provide(
      "fetchSellingPlace",
      SellingPlaceApi.fetchSellingPlace.bind(
        SellingPlaceApi
      ) as FetchSellingPlace
    );
  }
}
