<template>
  <SellingPlacesHomePage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import SellingPlacesHomePage from "@/structure/Administrator/SellingPlaces/pages/SellingPlacesHomePage.vue";
import SellingPlacesApi from "@/modules/selling-places/api";
import { provide } from "vue";
import { FetchSellingPlaces } from "@/modules/selling-places/api/types";

@Options({
  components: { SellingPlacesHomePage }
})
export default class SellingPlaces extends Vue {
  created() {
    provide(
      "fetchSellingPlaces",
      SellingPlacesApi.fetchSellingPlaces.bind(
        SellingPlacesApi
      ) as FetchSellingPlaces
    );
  }
}
</script>
