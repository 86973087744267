
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Market/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo, TabItems } from "@/modules/common/types";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
import { BreadcrumbItems } from "@/modules/common/types";
import getBreadcrumbItems from "../utils/getBreadcrumbItems";
import { useStore } from "@/store";
import HeaderWidgets from "@/structure/Administrator/Market/widgets/HeaderWidgets.vue";
import TabBarWidget from "@/modules/common/widgets/TabBarWidget.vue";
import getTabItems from "@/structure/Administrator/Market/utils/getTabItems";
import { Market } from "@/modules/market/types";
import SellingPlacePricesWidget from "@/modules/selling-place-price/widgets/SellingPlacePricesWidget/SellingPlacePricesWidget.vue";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    Breadcrumbs,
    TabBarWidget,
    HeaderWidgets,
    SellingPlacePricesWidget
  }
})
export default class PricesPage extends Vue {
  store = useStore();

  get market(): Market {
    return this.store.getters.market;
  }

  get headerInfo(): HeaderInfo {
    return {
      icon: this.market ? this.market.icon : "ion ion-md-cube",
      section: this.market ? this.market.name : "",
      title: "Prices",
      description: this.market ? this.market.description : ""
    };
  }

  get tabItems(): TabItems {
    return getTabItems(this.market);
  }

  get breadcrumbItems(): BreadcrumbItems {
    return getBreadcrumbItems("Prices", this.market);
  }
}
