import { RouteRecordRaw } from "vue-router";
import PassThroughViewProfile from "./widgets/PassThroughViewProfile.vue";
import ProfileGeneral from "./views/ProfileGeneral.vue";
import ChangePassword from "./views/ChangePassword.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/profile",
    name: "profile",
    component: PassThroughViewProfile,
    redirect: () => ({
      name: "profile-general",
      params: {}
    }),
    children: [
      {
        path: "",
        name: "profile-general",
        component: ProfileGeneral,
        meta: {
          title: "General"
        }
      },
      {
        path: "change-password",
        name: "change-password",
        component: ChangePassword,
        meta: {
          title: "Promena Lozinke"
        }
      }
    ]
  }
];

export default routes;
