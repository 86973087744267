<template>
  <Spinner :show-spinner="isDataLoading">
<!--    <FilterWidget @search="onSearch($event)" />  -->
  <div
    class="card p-3 mb-3"
    style="display: flex; flex-direction: row; align-items: center;"
  >
    <!--    <div class="media align-items-center">-->
    <!--      <img-->
    <!--        src="@/assets/company.png"-->
    <!--        class="d-block ui-w-40 rounded-circle mr-3"-->
    <!--        alt=""-->
    <!--      />-->
    <!--    </div>-->
    <div class="media-body flex-basis-auto">
      <div>
        <strong @click="sellingPlaceSelected()" class="cursor-pointer">
          {{ sellingPlace.name }}
        </strong>
      </div>
    </div>

    <div>
      <button
        @click="sellingPlaceSelected()"
        class="btn btn-outline-secondary btn-sm"
      >
        Detalji
      </button>
    </div>
  </div>
  </Spinner>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { SellingPlace } from "@/modules/selling-places/types";

@Options({
  props: {
    sellingPlace: {
      type: Object,
      required: true,
      default: {}
    }
  }
})
export default class SellingPlaceListItemWidget extends Vue {
  sellingPlace!: SellingPlace;

  sellingPlaceSelected() {
    this.$router.push({
      name: "administrator-selling-place",
      params: {
        sellingPlaceId: this.sellingPlace.id
      }
    });
  }
}
</script>
