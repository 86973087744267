<template>
  <SellingPlacesPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import SellingPlacesPage from "@/structure/Administrator/Market/pages/SellingPlacesPage.vue";
import { provide } from "vue";
import SellingPlaceApi from "@/modules/selling-place/api";
import { FetchSellingPlaces } from "@/modules/selling-place/api/types";

@Options({
  components: { SellingPlacesPage }
})
export default class SellingPlaces extends Vue {
  created() {
    provide(
      "fetchSellingPlaces",
      SellingPlaceApi.fetchSellingPlaces.bind(
        SellingPlaceApi
      ) as FetchSellingPlaces
    );
  }
}
</script>
