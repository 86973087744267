
import { Options, Vue } from "vue-class-component";
import { Address } from "@/modules/common/types";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import TextInput from "@/modules/common/forms/TextInput.vue";

@Options({
  components: { TextInput },
  props: {
    address: {},
    validationErrors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  }
})
export default class AddressForm extends Vue {
  address!: Address;
  validationErrors!: BackendValidationErrors;
}
