<template>
  <TenantSellingPlacesPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TenantSellingPlacesPage from "@/structure/Cashier/Tenant/pages/TenantSellingPlacesPage.vue";

@Options({
  components: { TenantSellingPlacesPage }
})
export default class TenantSellingPlaces extends Vue {}
</script>
