<template>
  <button type="button" class="btn btn-outline-primary" @click="newTenant()">
    <i class="ion ion-ios-add mr-2"></i>Novi Zakupac
  </button>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";

@Options({
  components: {}
})
export default class TenantsHeaderWidgets extends Vue {
  newTenant() {
    eventHub.emit("CREATE_TENANT_REQUESTED");
  }
}
</script>
