
import { Options, Vue } from "vue-class-component";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import eventHub from "@/components/EventHub";
import { ContractNumberingRules } from "../../types";
import { ContractNumberingRuleEditRequestedEvent } from "@/modules/contract-numbering-rules/events/ContractNumberingRuleEditRequestedEvent";

@Options({
  components: {
    Spinner
  },
  props: {
    contractNumberingRules: {
      type: Object,
      required: true
    }
  }
})
export default class ContractNumberingRulesListItemWidget extends Vue {
  contractNumberingRules!: ContractNumberingRules;
  isEditHovering = false;

  editContractNumberingRule(contractNumberingRule: ContractNumberingRules) {
    eventHub.emit<ContractNumberingRuleEditRequestedEvent>(
      ContractNumberingRuleEditRequestedEvent.Name,
      new ContractNumberingRuleEditRequestedEvent(contractNumberingRule.id)
    );
  }
}
