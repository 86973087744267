<template>
  <ConfirmDeleteModal
    v-if="displayDialog"
    @close="closeDeleteModal()"
    @confirm="deleteTenant()"
    title="Brisanje Zakupca"
    description="Da li ste sigurni da želite da obrišete zakupca?"
    :showSpinner="isDataLoading"
  />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";
import AsyncActionFactory from "@/components/AsyncAction";
import TenantApi from "@/modules/tenant/api";
import ConfirmDeleteModal from "@/modules/common/modals/ConfirmDeleteModal.vue";
import { Tenant } from "@/modules/tenant/types";

@Options({
  components: { ConfirmDeleteModal }
})
export default class DeleteTenantRequestListener extends Vue {
  tenant!: Tenant;

  displayDialog = false;

  deleteTenantAction = AsyncActionFactory(TenantApi.deleteTenant);

  created() {
    eventHub.on("DELETE_TENANT_REQUESTED", e => {
      this.tenant = e;
      this.displayDialog = true;
    });
  }

  async deleteTenant() {
    await this.deleteTenantAction.call(this.tenant.id);

    if (this.deleteTenantAction.isSuccessful) {
      eventHub.emit("TENANT_DELETED", this.tenant.id);
      this.closeDeleteModal();
    }
  }

  closeDeleteModal() {
    this.displayDialog = false;
  }

  get isDataLoading(): boolean {
    return this.deleteTenantAction.isBusy;
  }
}
</script>
