
import { Options, Vue } from "vue-class-component";
import ConfirmDeleteModal from "@/modules/common/modals/ConfirmDeleteModal.vue";
import { DeleteContractParams } from "../api/types";
import AsyncActionFactory from "@/components/AsyncAction";
import ContractApi from "@/modules/contracts/api";
import eventHub from "@/components/EventHub";
import { Contract } from "@/modules/contracts/types";
import { useRouter } from "@/components/Router";

@Options({
  components: { ConfirmDeleteModal }
})
export default class DeleteContractRequestListener extends Vue {
  contract!: Contract;

  displayDialog = false;

  deleteContractAction = AsyncActionFactory(ContractApi.deleteContract);

  created() {
    eventHub.on("DELETE_CONTRACT_REQUESTED", contract => {
      this.contract = contract;
      this.displayDialog = true;
    });
  }

  closeDeleteModal() {
    this.displayDialog = false;
  }

  async deleteContract() {
    await this.deleteContractAction.call({
      id: this.contract.id
    } as DeleteContractParams);

    if (this.deleteContractAction.isSuccessful) {
      await useRouter().push({
        name: "administrator-contracts"
      });
      this.closeDeleteModal();
    }
  }

  get isDataLoading(): boolean {
    // return true;
    return this.deleteContractAction.isBusy;
  }
}
