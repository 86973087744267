
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";

@Options({
  components: {}
})
export default class TenantsHeaderWidgets extends Vue {
  newTenant() {
    eventHub.emit("CREATE_TENANT_REQUESTED");
  }
}
