<template>
  <Modal>
    <Spinner :show-spinner="editSellingPlacePriceItemAction.isBusy">
      <div class="modal-header">
        <h5 class="modal-title text-center pr-3">
          {{ market.name }}:
          <em>{{ sellingPlacePriceItem.sellingPlaceCategory.name }}</em> |
          <em>{{ sellingPlacePriceItem.sellingPlaceType.name }}</em>
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click="closeModal()"
        >
          ×
        </button>
      </div>
      <div class="modal-body">
        <div class="row align-items-center">
          <div class="col-2">
            <span>&nbsp;</span>
          </div>
          <div class="col">
            <b>Renta</b>
          </div>
          <div class="col">
            <b>Rezervacija</b>
          </div>
        </div>

        <div class="row mt-2 align-items-center">
          <div class="col-2" style="padding-bottom: 23px">
            <b>Dnevna</b>
          </div>
          <div class="col">
            <div class="form-group">
              <PriceInput v-model="rentPriceDaily" />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <PriceInput v-model="rentPriceWithoutReservationDaily" />
            </div>
          </div>
        </div>

        <div class="row align-items-center">
          <div class="col-2">
            <span>&nbsp;</span>
          </div>
          <div class="col">
            <b>Rezervacija</b>
          </div>
        </div>

        <div class="row mt-2 align-items-center">
          <div class="col-2" style="padding-bottom: 23px">
            <b>Mesečna</b>
          </div>
          <div class="col">
            <div class="form-group">
              <PriceInput v-model="reservationPriceMonthly" />
            </div>
          </div>
          <div class="col"></div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" @click="closeModal()">
          Zatvori
        </button>
        <button
          type="button"
          class="btn btn-success"
          @click="editSellingPlacePriceItem"
        >
          Sačuvaj
        </button>
      </div>
    </Spinner>
  </Modal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Modal from "@/modules/common/modals/Modal.vue";
import { Market } from "@/modules/market/types";
import AsyncActionFactory from "@/components/AsyncAction";
import SellingPlacePriceApi from "@/modules/selling-place-price/api";
import { useStore } from "@/store";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import PriceInput from "@/modules/common/forms/PriceInput.vue";
import { EditSellingPlacePriceItemRequestData } from "@/modules/selling-place-price/api/types";
import { SellingPlacePrice } from "@/modules/selling-place-price/types";

@Options({
  components: {
    Modal,
    Spinner,
    PriceInput
  },
  props: {
    sellingPlacePriceItem: {
      type: Object,
      required: true
    }
  },
  emits: ["closeModal", "sellingPlacePriceItemEdited"]
})
export default class SellingPlacePriceEditModal extends Vue {
  store = useStore();

  sellingPlacePriceItem!: SellingPlacePrice;

  rentPriceDaily = Number(this.sellingPlacePriceItem.rentPriceDaily) / 100;

  rentPriceWithoutReservationDaily =
    Number(this.sellingPlacePriceItem.rentPriceWithoutReservationDaily) / 100;

  reservationPriceMonthly =
    Number(this.sellingPlacePriceItem.reservationPriceMonthly) / 100;

  editSellingPlacePriceItemAction = AsyncActionFactory(
    SellingPlacePriceApi.editSellingPlacePriceItem
  );

  closeModal() {
    this.$emit("closeModal");
  }

  get market(): Market {
    return this.store.getters.market;
  }

  async editSellingPlacePriceItem() {
    if (!this.isDataValid) {
      return;
    }

    const requestData: EditSellingPlacePriceItemRequestData = {
      rentPriceDaily: this.rentPriceDaily * 100,
      rentPriceWithoutReservationDaily:
        this.rentPriceWithoutReservationDaily * 100,
      reservationPriceMonthly: this.reservationPriceMonthly * 100
    };
    await this.editSellingPlacePriceItemAction.call(
      this.market.id,
      this.sellingPlacePriceItem.sellingPlaceCategory.id,
      this.sellingPlacePriceItem.sellingPlaceType.id,
      requestData
    );

    if (this.editSellingPlacePriceItemAction.isSuccessful) {
      this.$emit("sellingPlacePriceItemEdited");
    }
  }

  get isDataValid(): boolean {
    if (isNaN(this.rentPriceDaily) || this.rentPriceDaily < 1) return false;
    if (
      isNaN(this.rentPriceWithoutReservationDaily) ||
      this.rentPriceWithoutReservationDaily < 1
    )
      return false;
    if (isNaN(this.reservationPriceMonthly) || this.reservationPriceMonthly < 1)
      return false;

    return true;
  }
}
</script>

<style scoped></style>
