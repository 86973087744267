<template>
  <div class="d-flex flex-row justify-content-between align-items-center">
    <h5 class="mb-0">{{ contractNumberingRules.formatted }}</h5>
    <div class="ml-auto">
      <i
        @click="editContractNumberingRule(contractNumberingRules)"
        @mouseover="isEditHovering = true"
        @mouseleave="isEditHovering = false"
        class="ion ion-md-create cursor-pointer"
        :class="{
          'text-primary': isEditHovering,
          'text-secondary': !isEditHovering
        }"
      ></i>
    </div>
  </div>
  <small class="text-muted">Sledeći Broj Ugovora</small>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import eventHub from "@/components/EventHub";
import { ContractNumberingRules } from "../../types";
import { ContractNumberingRuleEditRequestedEvent } from "@/modules/contract-numbering-rules/events/ContractNumberingRuleEditRequestedEvent";

@Options({
  components: {
    Spinner
  },
  props: {
    contractNumberingRules: {
      type: Object,
      required: true
    }
  }
})
export default class ContractNumberingRulesListItemWidget extends Vue {
  contractNumberingRules!: ContractNumberingRules;
  isEditHovering = false;

  editContractNumberingRule(contractNumberingRule: ContractNumberingRules) {
    eventHub.emit<ContractNumberingRuleEditRequestedEvent>(
      ContractNumberingRuleEditRequestedEvent.Name,
      new ContractNumberingRuleEditRequestedEvent(contractNumberingRule.id)
    );
  }
}
</script>
