
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import MarketApi from "@/modules/market/api";
import { FetchMarketsParams } from "@/modules/market/api/types";
import { MarketList } from "@/modules/market/types";
import { MutationType } from "@/store/mutations";

@Options({})
export default class extends Vue {
  store = useStore();

  fetchMarketsAction = AsyncActionFactory(MarketApi.fetchMarkets);

  created() {
    this.fetchMarkets();
  }

  async fetchMarkets() {
    await this.fetchMarketsAction.call({
      perPage: -1
    } as FetchMarketsParams);

    if (this.fetchMarketsAction.isSuccessful) {
      const response = this.fetchMarketsAction.responseData as MarketList;
      if (response.data.length > 0) {
        this.store.commit(MutationType.SetMarketList, response.data);
      }
    }
  }
}
