
import { Options, Vue } from "vue-class-component";
import ContractCancellationReasonListWidget from "@/modules/contracts-cancellation-reason/widgets/ContractCancellationReasonListWidget.vue";
import eventHub from "@/components/EventHub";
import ContractCancellationReasonNewRequestedEvent from "@/modules/contracts-cancellation-reason/events/ContractCancellationReasonNewRequestedEvent";

@Options({
  components: {
    ContractCancellationReasonListWidget
  }
})
export default class ContractCancellationReasonsWidget extends Vue {
  newCreateContractCancellationReason() {
    eventHub.emit<ContractCancellationReasonNewRequestedEvent>(
      ContractCancellationReasonNewRequestedEvent.Name,
      new ContractCancellationReasonNewRequestedEvent()
    );
  }
}
