
import { Options, Vue } from "vue-class-component";
import WorkOrdersPage from "@/structure/Administrator/WorkOrders/pages/WorkOrdersPage.vue";
import WorkOrderApi from "@/modules/work-orders/api";
import { provide } from "vue";
import { FetchWorkOrders } from "@/modules/work-orders/api/types";

@Options({
  components: { WorkOrdersPage }
})
export default class WorkOrders extends Vue {
  created() {
    provide(
      "fetchWorkOrders",
      WorkOrderApi.fetchWorkOrders.bind(WorkOrderApi) as FetchWorkOrders
    );
  }
}
