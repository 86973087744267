<template>
  <h5 class="d-flex justify-content-between">
    <span>Numeracija</span>
    <button
      class="btn btn-xs btn-default"
      :title="'Pravilo za numerisanje ugovora'"
      @click="newCreateContractNumberingRule()"
    >
      <i class="ion ion-md-add"></i>
    </button>
  </h5>
  <div class="card">
    <div class="card-body p-0">
      <ContractNumberingRulesListWidget />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";
import ContractNumberingRulesListWidget from "@/modules/contract-numbering-rules/widgets/ContractNumberingRulesList/ContractNumberingRulesListWidget.vue";
import ContractNumberingRuleNewRequestedEvent from "@/modules/contract-numbering-rules/events/ContractNumberingRuleNewRequestedEvent";

@Options({
  components: { ContractNumberingRulesListWidget }
})
export default class ContractNumberingRulesWidget extends Vue {
  newCreateContractNumberingRule() {
    eventHub.emit<ContractNumberingRuleNewRequestedEvent>(
      ContractNumberingRuleNewRequestedEvent.Name,
      new ContractNumberingRuleNewRequestedEvent()
    );
  }
}
</script>
