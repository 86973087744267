<template>
  <div class="btn-group">
    <button
      type="button"
      class="btn btn-outline-primary"
      @click="editMarketDetails()"
    >
      <i class="ion ion-md-create mr-2"></i>Izmeni
    </button>
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-outline-primary dropdown-toggle"
        data-toggle="dropdown"
      ></button>
      <div class="dropdown-menu">
        <a
          class="dropdown-item"
          href="javascript:void(0)"
          @click="editMarketAddress()"
        >
          <i class="ion ion-md-create"></i> Izmeni Adresu
        </a>
        <a
          class="dropdown-item"
          href="javascript:void(0)"
          @click="deleteMarket()"
        >
          <i class="fas fa-trash-alt"></i> Obriši
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import { useStore } from "@/store";
import { Market } from "@/modules/market/types";
import eventHub from "@/components/EventHub";
import { UserRole } from "@/modules/user/types";

export default class extends Vue {
  store = useStore();

  get market(): Market {
    return this.store.getters.market;
  }

  canUserDeleteMarket(): boolean {
    return this.store.getters.user.hasRole(UserRole.Admin);
  }

  editMarketDetails(): void {
    eventHub.emit("EDIT_MARKET_DETAILS_REQUESTED", this.market.id);
  }

  editMarketAddress(): void {
    eventHub.emit("EDIT_MARKET_ADDRESS_REQUESTED", this.market.id);
  }

  deleteMarket(): void {
    if (!this.canUserDeleteMarket()) return;
    eventHub.emit("DELETE_MARKET_REQUESTED", this.market);
  }
}
</script>
