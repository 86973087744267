
import { Options, Vue } from "vue-class-component";
import Modal from "@/modules/common/modals/Modal.vue";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import RoleSelector from "@/modules/user/widgets/RoleSelector.vue";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import { User } from "@/modules/user/types";

@Options({
  components: { Modal, Spinner, RoleSelector },
  props: {
    user: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  }
})
export default class EditUserModal extends Vue {
  user!: User;
  errors!: BackendValidationErrors;
}
