import { sellingPlaceTypeMapper } from "@/modules/selling-place-type/api/mappers";
import { sellingPlaceCategoryMapper } from "@/modules/selling-place-category/api/mappers";
import { marketMapper } from "@/modules/market/api/mappers";
import {SellingPlace, SellingPlaceList, SellingPlaceReportsList} from "@/modules/selling-place/types";

export function sellingPlaceMapper(sellingPlace): SellingPlace {
  return {
    ...sellingPlace,
    type: sellingPlaceTypeMapper(sellingPlace.type),
    category: sellingPlaceCategoryMapper(sellingPlace.category)
  };
}

export function allSellingPlaceMapper(sellingPlace): SellingPlace {
  return {
    ...sellingPlace,
    type: sellingPlaceTypeMapper(sellingPlace.type),
    category: sellingPlaceCategoryMapper(sellingPlace.category),
    market: marketMapper(sellingPlace.market)
  };
}

export function sellingPlaceListMapper(sellingPlaceList): SellingPlaceList {
  sellingPlaceList.data.map(sellingPlace => sellingPlaceMapper(sellingPlace));

  return sellingPlaceList;
}

export function sellingPlaceReportListMapper(SellingPlaceReportsList): SellingPlaceReportsList {
  return SellingPlaceReportsList;
}

export function allSellingPlaceListMapper(sellingPlaceList): SellingPlaceList {
  // sellingPlaceList.map(sellingPlace => sellingPlaceMapper(sellingPlace));
  //
  // return sellingPlaceList;
  // console.log('mapper', sellingPlaceList)
  sellingPlaceList.data.map(sellingPlace =>
    allSellingPlaceMapper(sellingPlace)
  );
  // console.log('mapper2', sellingPlaceList)

  return sellingPlaceList;
}
