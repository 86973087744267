<template>
  <template v-for="(link, index) in applicationLinks" :key="index">
    <router-link
      :to="link.linkRoute"
      class="app-brand-text demo sidenav-text font-weight-normal ml-2"
    >
      <span class="demo-brand-name font-weight-normal ml-2">
        {{ link.name }}
      </span>
    </router-link>
  </template>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Links } from "@/modules/common/types";

@Options({
  props: {
    applicationLinks: {
      type: Array,
      required: true
    }
  }
})
export default class ApplicationLinks extends Vue {
  applicationLinks!: Links;
}
</script>
