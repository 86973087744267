
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Tenant/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";

@Options({
  components: { PageLayout, PageHeaderInfoWidget }
})
export default class extends Vue {
  headerInfo: HeaderInfo = {
    icon: "ion ion-md-home",
    section: "Početna"
  };
}
