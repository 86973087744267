
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Dashboard/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import {
  FetchTransactions,
  FetchTransactionsParams
} from "@/modules/transactions/api/types";
import { TransactionList } from "@/modules/transactions/types";
import moment from "moment";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import { inject } from "vue";
import ListWidget from "@/modules/common/widgets/ListWidget/ListWidget.vue";
import TransactionListItemWidget from "@/modules/transactions/widgets/TransactionList/TransactionListItemWidget.vue";
import { emptyTransactionList } from "@/modules/transactions/types/EmptyTransactionList";
import WorkOrderListItemWidget from "@/modules/work-orders/widgets/WorkOrderListItemWidget.vue";
import ContractListItemWidget from "@/modules/contracts/widgets/ContractList/ContractListItemWidget.vue";
import {
  FetchWorkOrders,
  FetchWorkOrdersParams
} from "@/modules/work-orders/api/types";
import { WorkOrderList } from "@/modules/work-orders/types";
import { emptyWorkOrderList } from "@/modules/work-orders/types/EmptyWorkOrderList";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import { Tenant, TenantList } from "@/modules/tenant/types";
import { ContractList } from "@/modules/contracts/types";
import { emptyContractList } from "@/modules/contracts/types/EmptyContractList";
import { FetchTenants, FetchTenantsParams } from "@/modules/tenant/api/types";
import {
  FetchContracts,
  FetchContractsParams
} from "@/modules/contracts/api/types";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    ListWidget,
    TransactionListItemWidget,
    WorkOrderListItemWidget,
    Spinner,
    ContractListItemWidget
  }
})
export default class DashboardPage extends Vue {
  headerInfo: HeaderInfo = {
    icon: "ion ion-md-home",
    section: "Početna"
  };
  from = moment()
    .startOf("day")
    .toString();
  to = moment()
    .endOf("day")
    .toString();
  dailyAmount = 0;

  transactionList: TransactionList = emptyTransactionList;
  workOrderList: WorkOrderList = emptyWorkOrderList;
  tenantList: Array<Tenant> = [];
  contractList: ContractList = emptyContractList;

  fetchTransactionsAction = AsyncActionFactory(
    inject("fetchTransactions") as FetchTransactions
  );
  fetchWorkOrdersAction = AsyncActionFactory(
    inject("fetchWorkOrders") as FetchWorkOrders
  );
  fetchTenantsAction = AsyncActionFactory(
    inject("fetchTenants") as FetchTenants
  );

  fetchContractsAction = AsyncActionFactory(
    inject("fetchContracts") as FetchContracts
  );

  created() {
    this.loadTransactionData();
    this.loadTodayTransactionData();
    this.loadWorkOrderData();
    this.loadContractData();
    this.loadTenantData();
  }

  async loadTransactionData() {
    await this.fetchTransactionsAction.call({
      page: 1,
      perPage: 10,
      from: this.from,
      to: this.to
    } as FetchTransactionsParams);

    if (this.fetchTransactionsAction.isSuccessful) {
      this.transactionList = this.fetchTransactionsAction
        .responseData as TransactionList;
    }
  }

  async loadTodayTransactionData() {
    await this.fetchTransactionsAction.call({
      page: 1,
      perPage: 99999,
      from: this.from,
      to: this.to
    } as FetchTransactionsParams);

    if (this.fetchTransactionsAction.isSuccessful) {
      this.dailyAmount = this.fetchTransactionsAction.responseData.data
        .map(a => a.total / 100)
        .reduce(function(a, b) {
          return a + b;
        });
    }
  }

  get showTransactionSpinner() {
    return this.fetchTransactionsAction.isBusy;
  }

  async loadWorkOrderData() {
    await this.fetchWorkOrdersAction.call({
      page: 1,
      perPage: -1,
      from: this.from,
      to: this.to
    } as FetchWorkOrdersParams);

    if (this.fetchWorkOrdersAction.isSuccessful) {
      this.workOrderList = this.fetchWorkOrdersAction
        .responseData as WorkOrderList;
    }
  }

  get showWorkOrderSpinner() {
    return this.fetchWorkOrdersAction.isBusy;
  }

  async loadContractData() {
    const requestParam = {
      page: 1,
      perPage: 10
    } as FetchContractsParams;
    await this.fetchContractsAction.call(requestParam);

    if (this.fetchContractsAction.isSuccessful) {
      this.contractList = this.fetchContractsAction
        .responseData as ContractList;
    }
  }

  get showContractSpinner() {
    return this.fetchContractsAction.isBusy;
  }

  async loadTenantData() {
    const requestParam = {
      page: 1,
      perPage: 10,
      orderBy: "createdAt"
    } as FetchTenantsParams;
    await this.fetchTenantsAction.call(requestParam);

    if (this.fetchTenantsAction.isSuccessful) {
      this.tenantList = (this.fetchTenantsAction
        .responseData as TenantList).data;
    }
  }

  get showTenantSpinner() {
    return this.fetchTenantsAction.isBusy;
  }

  async selectTenant(tenantId) {
    await this.$router.push({
      name: "administrator-tenant",
      params: {
        tenantId: tenantId
      }
    });
  }
}
