
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    text: {
      type: String,
      required: true
    },
    className: {
      type: String,
      required: false
    }
  }
})
export default class Nl2br extends Vue {
  text!: string;
  className = "";
}
