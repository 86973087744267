import { TabItem, TabItems } from "@/modules/common/types";
import { Market } from "@/modules/market/types";

function createHomeItem(market: Market): TabItem {
  return {
    name: "Početna",
    link: {
      name: "administrator-market-home",
      params: {
        marketId: market.id
      }
    },
    active: false
  };
}

function createSellingPlacesItem(market: Market): TabItem {
  return {
    name: "Prodajni Objekti",
    link: {
      name: "administrator-market-selling-places",
      params: {
        marketId: market.id
      }
    },
    active: false
  };
}

function createSellingPlacesMap(market: Market): TabItem {
  return {
    name: "Mapa",
    link: {
      name: "administrator-market-map",
      params: {
        marketId: market.id
      }
    },
    active: false
  };
}

function createPricesItem(market: Market): TabItem {
  return {
    name: "Cene",
    link: {
      name: "administrator-market-prices",
      params: {
        marketId: market.id
      }
    },
    active: false
  };
}

const getTabItems = function(market: Market): TabItems {
  const items: TabItems = [];

  if (!market) {
    return items;
  }

  items.push(createHomeItem(market));
  items.push(createPricesItem(market));
  items.push(createSellingPlacesItem(market));
  items.push(createSellingPlacesMap(market));

  return items;
};

export default getTabItems;
