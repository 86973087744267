import ApiClient from "@/components/ApiClient/ApiClient";

import {
  DeleteMarket,
  DeleteMarketParams,
  EditMarket,
  EditMarketRequestData,
  FetchMarket,
  FetchMarkets,
  FetchMarketsParams,
  PostMarketRequestData,
  PostMarket,
  PostMarketResponse,
  FetchMarketParams,
  FetchCashierMarkets
} from "./types";

import {
  marketListMapper,
  marketMapper,
  postMarketResponseMapper
} from "./mappers";
import { Market, MarketList } from "@/modules/market/types";

class Api {
  public client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  fetchMarkets: FetchMarkets = (
    params: FetchMarketsParams
  ): Promise<MarketList> => {
    const payload = {
      page: params.page,
      perPage: params.perPage
    };

    const url = "markets";

    return this.client.get(url, payload).then(marketList => {
      return marketListMapper(marketList);
    });
  };

  fetchCashierMarkets: FetchCashierMarkets = (
      params: FetchMarketsParams
  ): Promise<MarketList> => {
    const payload = {
      page: params.page,
      perPage: params.perPage
    };

    const url = "markets/cashier";

    return this.client.get(url, payload).then(marketList => {
      return marketListMapper(marketList);
    });
  };

  fetchMarket: FetchMarket = (
    id: string,
    params?: FetchMarketParams
  ): Promise<Market> => {
    const url = `markets/${id}`;

    return this.client.get(url, params).then(market => {
      return marketMapper(market);
    });
  };

  postMarket: PostMarket = (
    reqData: PostMarketRequestData
  ): Promise<PostMarketResponse> => {
    const url = `markets`;

    return this.client.post(url, reqData).then(response => {
      return postMarketResponseMapper(response);
    });
  };

  deleteMarket: DeleteMarket = (params: DeleteMarketParams): Promise<void> => {
    const url = `markets/${params.id}`;

    return this.client.delete(url);
  };

  editMarket: EditMarket = (
    marketId: string,
    requestData: EditMarketRequestData
  ): Promise<void> => {
    const url = `markets/${marketId}`;

    return this.client.put(url, requestData);
  };
}

export default Api;
