import {
  Contract,
  ContractList,
  ContractItems,
  ContractItem
} from "@/modules/contracts/types";
import { convertUtcToOrgDate } from "@/modules/localisation/helpers/date-helpers";
import { PostContractResponseData } from "@/modules/contracts/api/types";

export function contractItemMapper(contractItem): ContractItem {
  return {
    ...contractItem,
    rentStartDate: convertUtcToOrgDate(contractItem.rentStartDate),
    rentEndDate: convertUtcToOrgDate(contractItem.rentEndDate)
  };
}

export function contractItemsMapper(contractItems): ContractItems {
  contractItems.map(contractItem => contractItemMapper(contractItem));
  return contractItems;
}

export function contractMapper(contract): Contract {
  return {
    ...contract,
    createdAt: convertUtcToOrgDate(contract.createdAt),
    items: contractItemsMapper(contract.items)
  };
}

export function postContractResponseMapper(response): PostContractResponseData {
  return {
    ...response
  };
}

export function contractListMapper(list): ContractList {
  list.data = list.data.map(contractListItem =>
    contractMapper(contractListItem)
  );
  return list;
}
