
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/SellingPlaces/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import SellingPlaceListWidget from "@/modules/selling-places/widgets/SellingPlaceSidebar/SellingPlaceListWidget.vue";
import HeaderWidgets from "../widgets/HeaderWidgets.vue";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    HeaderWidgets,
    SellingPlaceListWidget
  }
})
export default class SellingPlacesHomePage extends Vue {
  headerInfo: HeaderInfo = {
    icon: "ion ion-md-cube",
    section: "Prodajna mesta"
  };
}
