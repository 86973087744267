<template>
  <MapPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MapPage from "@/structure/Administrator/Market/pages/MapPage.vue";

@Options({
  components: { MapPage }
})
export default class Map extends Vue {}
</script>
