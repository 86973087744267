import { RouteRecordRaw } from "vue-router";
import PassThroughViewTenants from "./widgets/PassThroughViewTenants.vue";
import Tenants from "@/structure/Administrator/Tenant/views/Tenants.vue";
import TenantHome from "@/structure/Administrator/Tenant/views/TenantHome.vue";
import TenantDetails from "@/structure/Administrator/Tenant/views/TenantDetails.vue";
import TenantContracts from "@/structure/Administrator/Tenant/views/TenantContracts.vue";
import TenantSellingPlaces from "@/structure/Administrator/Tenant/views/TenantSellingPlaces.vue";
import TenantSellingPlace from "@/structure/Administrator/Tenant/views/TenantSellingPlace.vue";
import PassThroughViewTenant from "@/structure/Administrator/Tenant/widgets/PassThroughViewTenant.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "tenants",
    name: "administrator-tenants",
    component: PassThroughViewTenants,
    redirect: () => ({
      name: "administrator-tenants-homepage",
      params: {}
    }),
    children: [
      {
        path: "",
        name: "administrator-tenants-homepage",
        component: Tenants,
        meta: {
          title: ""
        }
      },
      {
        path: ":tenantId",
        name: "administrator-tenant",
        component: PassThroughViewTenant,
        redirect: to => ({
          name: "administrator-tenant-details",
          params: {
            tenantId: to.params.tenantId
          }
        }),
        children: [
          {
            path: "",
            name: "administrator-tenant-home",
            component: TenantHome,
            meta: {
              title: "Početna"
            }
          },
          {
            path: "details",
            name: "administrator-tenant-details",
            component: TenantDetails,
            meta: {
              title: "Detalji"
            }
          },
          {
            path: "contracts",
            name: "administrator-tenant-contracts",
            component: TenantContracts,
            meta: {
              title: "Ugovori"
            }
          },
          {
            path: "selling-places",
            name: "administrator-tenant-selling-places",
            component: TenantSellingPlaces,
            meta: {
              title: "Prodajni Objekti"
            }
          },
          {
            path: "selling-place/:sellingPlaceId",
            name: "administrator-tenant-selling-place",
            component: TenantSellingPlace,
            meta: {
              title: "Prodajni Objekat"
            }
          }
        ]
      }
    ]
  }
];

export default routes;
