
import { Options, Vue } from "vue-class-component";
import { formatDate } from "@/modules/localisation/helpers/date-helpers";
import { WorkOrder } from "@/modules/work-orders/types";

@Options({
  props: {
    workOrder: {
      required: true
    }
  }
})
export default class WorkOrderDetailsWidget extends Vue {
  workOrder!: WorkOrder;

  get formattedDate(): string {
    return formatDate(this.workOrder.date);
  }
}
