<template>
  <NewWorkOrderPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import NewWorkOrderPage from "@/structure/Administrator/WorkOrders/pages/NewWorkOrderPage.vue";

@Options({
  components: { NewWorkOrderPage }
})
export default class NewWorkOrder extends Vue {}
</script>
