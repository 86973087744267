<template>
  <CashierPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CashierPage from "@/structure/Administrator/Reports/pages/CashiersPage.vue";

@Options({
  components: { CashierPage }
})
export default class Cashiers extends Vue {}
</script>
