import { TabItem, TabItems } from "@/modules/common/types";
import { Market } from "@/modules/market/types";

function createHomeItem(market: Market): TabItem {
  return {
    name: "Home",
    link: {
      name: "cashier-selling-places-home",
      params: {
        marketId: market.id
      }
    },
    active: false
  };
}

function createSellingPlacesItem(market: Market): TabItem {
  return {
    name: "Selling Places",
    link: {
      name: "cashier-market-selling-places",
      params: {
        marketId: market.id
      }
    },
    active: false
  };
}

function createPricesItem(market: Market): TabItem {
  return {
    name: "Prices",
    link: {
      name: "cashier-market-prices",
      params: {
        marketId: market.id
      }
    },
    active: false
  };
}

const getTabItems = function(market: Market): TabItems {
  const items: TabItems = [];

  if (!market) {
    return items;
  }

  items.push(createHomeItem(market));
  // items.push(createSellingPlacesItem(market));
  // items.push(createPricesItem(market));

  return items;
};

export default getTabItems;
