import BackendValidationErrors from "./Errors/BackendValidationErrors";
import wrapRequestErrors from "./Errors/wrapRequestErrors";
import { AsyncActionOptions } from "./types";
// import store from '@/store'
import commonErrors from "./Errors/commonRequestErrorsMessages";

// type Parameters<T extends (...args: any) => any> = T extends (...args: infer P) => any ? P : never;

// type ParametersArray<T extends (...args: any) => any> = Parameters<T>;
type Parameters<T extends (...args: any) => any> = T extends (
  ...args: infer P
) => any
  ? P
  : never;

/**
 * Wrapper class that keeps track of request status and can handle errors for you
 * Example:
 * - In Vue data section declare some actions (passing options is optional, see below in the code for the available options)
 * <code>
 *   foo = AsyncActionFactory(fetchSomeData, { captureValidationErrors: false })
 *   bar = AsyncActionFactory(saveSomething, { errorMsg: { default: 'Failed to save the data, please try again.'} })
 * </code>
 *
 * - And then later in code you can run those actions to send api requests
 * <code>
 *   // simple call, the response will be available in this.foo.responseData
 *   this.foo.call()
 *   // making a call that sends some data, and when the request is finished does additional processing
 *   this.bar.call(name, email, something)
 *         .then((response) => somethingElse())
 * </code>
 * Note: call() returns a Promise, so you can chain then(), catch() and finally() to it, if you need it. However
 * by default you don't have to do anything, it's all handled automatically and data is available through the class itself.
 *
 * - The following flags and data are available for each request:
 *   - isBusy       (is there ongoing requests)
 *   - isSuccessful (request was successful)
 *   - hasFailed    (request has returned some error status or there was no response)
 *   - responseData (if successful call this will hold the data returned, else it's null)
 *
 * - If there was an error and handleErrors option is set, AsyncAction will capture the error and wrap it into a new
 *   RequestError class, and then reject promise with it to allow for further processing (by adding .catch() after .call()), and if not handled
 *   it will generate a Notification for user using the this.options.ErrorMsg text from the action, if provided, or default error message otherwise.
 * - If handleErrors option is false, no error processing happens, the promise is just rejected with the original error fo r you to catch() it and process
 *
 * - If there was a validation error and options handleErrors and captureValidationErrors are true (the default), the
 *   error with be handled and error messages will be available at this.errors as BackendValidationErrors class (with methods to
 *   get and manipulate the errors). In this case you can't further .catch() the error in your code as nothing is re-thrown and no Error Notification will be shown.
 *   To show errors to user in this case use the status flags available (this.hasFailed, this.errors.get(email), etc.)
 *
 *   To handle validation errors on your own (if needed), just set captureValidationErrors to false and then add .catch() to the call().
 *   You'll receive the RequestError with all the fields filled, and validation errors will be accessible through RequestError.validationErrors,
 *   while the original error is in RequestError.previousError (see the RequestError class for the full list)
 */
// eslint-disable-next-line
export class AsyncActionFactory<T extends (...params: any) => Promise<any>> {
  // status flags:
  isBusy = false;
  isSuccessful = false;
  hasFailed = false;

  // result of the successful request
  responseData:
    | (ReturnType<T> extends PromiseLike<infer U> ? U : ReturnType<T>)
    | null = null;

  // captured validation errors (if request failed with a 422 http status)
  errors = new BackendValidationErrors();

  // the action to call to make the request
  protected action;

  // misc options:
  protected options: AsyncActionOptions = {
    // true  - (default) handles errors automatically, wraps them in the RequestError class and re-throws it
    // false - errors are not handled - you need to catch them manually
    // (all status flags will be set regardless)
    handleErrors: true,

    // true  - (default) validation errors are saved to this.errors (BackendValidationErrors object), no errors are re-thrown
    // false - validation errors are handled like any other error, this.errors methods are still available, but has no errors
    captureValidationErrors: true,

    // Text to use for the public error message (used for Notification)
    errorMsg: commonErrors
  };

  /**
   * @param action    Function to call to make the request
   * @param options   Set AsyncAction options
   */
  constructor(action: T, options: Partial<AsyncActionOptions> = {}) {
    this.action = action;

    // merge all error messages first
    options.errorMsg = { ...this.options.errorMsg, ...options.errorMsg };
    // then update options
    this.options = { ...this.options, ...options };
  }

  /**
   * Run the action and return a promise
   * @param params    Arguments passed to the action
   * @return Promise
   */
  call(...params: Parameters<T>): ReturnType<T> {
    this.clear();
    this.isBusy = true;

    const paramsArray = [];
    params.forEach(function(item) {
      paramsArray.push(item as never);
    });

    // run it and handle the response
    return this.action(...paramsArray)
      .then(response => {
        this.isBusy = false;
        this.setSuccessStatus();
        this.responseData = response;
        return response;
      })
      .catch(error => {
        this.isBusy = false;
        this.setErrorStatus();
        return this.handleErrors(error);
      });
  }

  /**
   * Reset the status to the initial state
   */
  clear() {
    this.responseData = null;
    this.errors.clearAll();
    this.isBusy = false;
    this.isSuccessful = false;
    this.hasFailed = false;
  }

  protected handleErrors(error) {
    if (!this.options.handleErrors) {
      // don't handle anything, re-reject the original error so that the calling code can .catch() on it
      return Promise.reject(error);
    }

    // wrap error and set the public message (specific  msg for some 4xx errors or the default one)
    const reqError = wrapRequestErrors(error, this.options.errorMsg);

    if (this.options.captureValidationErrors && reqError.httpStatus === 422) {
      this.errors.setErrors(reqError.validationErrors);
      return error;
    }

    if (reqError.httpStatus === 401 || reqError.httpStatus === 419) {
      // force the login prompt, unless we've already been redirected there
      // store.dispatch("login/logout");
      return error;
    }

    // re-throw it, and let developer handle it or global error handler show it as a Notification
    return Promise.reject(reqError);
  }

  protected setSuccessStatus() {
    this.isSuccessful = true;
    this.hasFailed = false;
  }

  protected setErrorStatus() {
    this.isSuccessful = false;
    this.hasFailed = true;
  }
}

// Factory method has to repeat all the types of the class constructor for TS to figure it out correctly what's going on
// eslint-disable-next-line
export default <T extends (...params: any) => Promise<any>> (asyncMethod: T, options: AsyncActionOptions|{} = {}) => {
  return new AsyncActionFactory(asyncMethod, options);
};
