
import { Options, Vue } from "vue-class-component";
import { SellingPlacePrice } from "@/modules/selling-place-price/types";

@Options({
  props: {
    sellingPlacePriceItem: {
      type: Object,
      required: true
    }
  },
  emits: ["editSellingPlacePriceItem"]
})
export default class SellingPlacePricesItemWidget extends Vue {
  sellingPlacePriceItem!: SellingPlacePrice;
  showEditIcon = false;

  editSellingPlacePriceItem() {
    this.$emit("editSellingPlacePriceItem", this.sellingPlacePriceItem);
  }
}
