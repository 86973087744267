import { TabItem, TabItems } from "@/modules/common/types";
import { Market } from "@/modules/market/types";

function createHomeItem(): TabItem {
  return {
    name: "Početna",
    link: {
      name: "administrator-reports-homepage"
    },
    active: false
  };
}

function createSellingPlacesItem(): TabItem {
  return {
    name: "Prodajni Objekti",
    link: {
      name: "administrator-reports-selling-places"
    },
    active: false
  };
}

function createCashierItem(): TabItem {
  return {
    name: "Inkasanti",
    link: {
      name: "administrator-reports-cashiers"
    },
    active: false
  };
}

const getTabItems = function(): TabItems {
  const items: TabItems = [];

  // items.push(createHomeItem());
  items.push(createSellingPlacesItem());
  items.push(createCashierItem());

  return items;
};

export default getTabItems;
