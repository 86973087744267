<template>
  <a href="javascript:void(0)" class="badge" :class="badgeClass()">
    {{ status }}
  </a>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { ContractStatus } from "../types";

@Options({
  props: {
    status: {
      type: String,
      required: true
    }
  }
})
export default class ContractStatusWidget extends Vue {
  status!: ContractStatus;

  badgeClass(): string {
    if (this.status == ContractStatus.Draft) return "badge-default";
    if (this.status == ContractStatus.Active) return "badge-success";
    if (this.status == ContractStatus.ExpiringSoon) return "badge-info";
    // if (this.status == ContractStatus.PartialExpired) return "badge-warning";
    if (this.status == ContractStatus.Expired) return "badge-danger";
    if (this.status == ContractStatus.Canceled) return "badge-secondary";
    if (this.status == ContractStatus.Archived) return "badge-dark";

    return "";
  }
}
</script>
