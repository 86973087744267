import { useStore } from "@/store";
import { MutationType } from "@/store/mutations";
import UserApi from "@/modules/user/api";
import AsyncActionFactory from "@/components/AsyncAction";
import { User } from "@/modules/user/types";

export default class UserLoader {
  store = useStore();

  fetchProfile = AsyncActionFactory(UserApi.fetchProfile);
  dataLoaded = false;

  async load() {
    await this.fetchProfile.call();
    if (this.fetchProfile.isSuccessful) {
      const user = this.fetchProfile.responseData as User;
      this.store.commit(MutationType.SetUser, user);
      this.dataLoaded = true;
    }
  }

  clearUser() {
    this.store.commit(MutationType.SetUser, null);
  }

  isDataLoaded() {
    return this.dataLoaded;
  }

  isDataLoading() {
    return !this.dataLoaded || this.fetchProfile.isBusy;
  }
}
