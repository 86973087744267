import ApiClient from "@/components/ApiClient/ApiClient";

import {
  FetchSellingPlacePrice,
  FetchSellingPlacePrices,
  EditSellingPlacePriceItem,
  EditSellingPlacePriceItemRequestData
} from "@/modules/selling-place-price/api/types";

import { sellingPlacePricesMapper } from "./mappers";
import {SellingPlacePrice, SellingPlacePriceList} from "@/modules/selling-place-price/types";

class Api {
  public client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  editSellingPlacePriceItem: EditSellingPlacePriceItem = (
    marketId: string,
    categoryId: string,
    typeId: string,
    requestData: EditSellingPlacePriceItemRequestData
  ): Promise<void> => {
    const url = `/markets/${marketId}/prices/categories/${categoryId}/types/${typeId}`;
    return this.client.put(url, requestData);
  };

  fetchSellingPlacePrice: FetchSellingPlacePrice = (
      marketId: string,
      categoryId: string,
      typeId: string,
  ): Promise<SellingPlacePrice> => {
    const url = `/markets/${marketId}/prices/categories/${categoryId}/types/${typeId}`;
    return this.client.get(url);
  };

  fetchSellingPlacePrices: FetchSellingPlacePrices = (
    marketId: string
  ): Promise<SellingPlacePriceList> => {
    const url = `markets/${marketId}/prices`;
    return this.client.get(url).then(sellingPlacePrices => {
      return sellingPlacePricesMapper(sellingPlacePrices);
    });
  };
}

export default Api;
