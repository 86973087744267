<template>
  <div>
    <div
      class="text-right edit-wrapper"
      @mouseover="showEditIcon = true"
      @mouseleave="showEditIcon = false"
    >
      <div class="button-container">
        <button
          v-if="showEditIcon"
          class="btn btn-primary"
          @click="editSellingPlacePriceItem"
        >
          <i class="ion ion-md-create"></i> Izmeni
        </button>
      </div>
      <table class="table-borderless m-auto">
        <tbody>
          <tr>
            <td title="Cena Dnevnog Zakupa">
              <money :amount="sellingPlacePriceItem.rentPriceDaily" />
            </td>
            <td
              title="Cena Dnevnog Zakupa Bez rezervacije"
            >
              <money
                :amount="sellingPlacePriceItem.rentPriceWithoutReservationDaily"
              />
            </td>
            <td title="Cena Mesečne Rezervacije">
              <money :amount="sellingPlacePriceItem.reservationPriceMonthly" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { SellingPlacePrice } from "@/modules/selling-place-price/types";

@Options({
  props: {
    sellingPlacePriceItem: {
      type: Object,
      required: true
    }
  },
  emits: ["editSellingPlacePriceItem"]
})
export default class SellingPlacePricesItemWidget extends Vue {
  sellingPlacePriceItem!: SellingPlacePrice;
  showEditIcon = false;

  editSellingPlacePriceItem() {
    this.$emit("editSellingPlacePriceItem", this.sellingPlacePriceItem);
  }
}
</script>

<style scoped>
.edit-wrapper {
  position: relative;
}
.button-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background: white;
}
</style>
