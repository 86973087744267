<template>
  <ul class="nav nav-tabs nav-responsive-xl">
    <li class="nav-item" v-for="item in items" :key="item">
      <router-link
        :to="{
          name: item.link.name,
          params: item.link.params
        }"
        class="nav-link"
        v-bind:class="{ active: item.active }"
      >
        <i v-if="item.icon" :class="item.icon"></i>
        {{ item.name }}
      </router-link>
    </li>
  </ul>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { TabItem, TabItems } from "@/modules/common/types";

@Options({
  props: {
    items: {
      required: true,
      default: []
    }
  },
  watch: {
    items() {
      this.onItemsUpdated();
    }
  }
})
export default class extends Vue {
  items!: TabItems;

  updateActiveTab(activeRouteName: string) {
    this.items.forEach(function(item: TabItem) {
      item.active = item.link.name == activeRouteName;
    });
  }

  created() {
    this.onItemsUpdated();
  }

  onItemsUpdated() {
    this.updateActiveTab(this.$route.name as string);
  }
}
</script>
