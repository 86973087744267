<template>
  <TenantContractsPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TenantContractsPage from "@/structure/Cashier/Tenant/pages/TenantContractsPage.vue";

@Options({
  components: { TenantContractsPage }
})
export default class TenantContracts extends Vue {}
</script>
