<template>
  <div></div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class extends Vue {}
</script>
