import { RouteRecordRaw } from "vue-router";
import PassThroughViewWorkOrderDetails from "../widgets/PassThroughViewWorkOrderDetails.vue";
import WorkOrder from "@/structure/Administrator/WorkOrders/views/WorkOrderDetails.vue";

const workOrderRoutes: Array<RouteRecordRaw> = [
  {
    path: "work-orders/:workOrderId",
    name: "administrator-work-order",
    component: PassThroughViewWorkOrderDetails,
    redirect: to => ({
      name: "administrator-work-order-details",
      params: {
        workOrderId: to.params.workOrderId
      }
    }),
    children: [
      {
        path: "",
        name: "administrator-work-order-details",
        component: WorkOrder,
        meta: {
          title: ""
        }
      }
    ]
  }
];

export default workOrderRoutes;
