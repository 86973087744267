<template>
  <div class="form-group">
    <TextInput
      field-name="firstName"
      label="Ime"
      v-model="tenantRequestData.firstName"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="lastName"
      label="Prezime"
      v-model="tenantRequestData.lastName"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="registrationId"
      label="JMBG"
      v-model="tenantRequestData.registrationId"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="phoneNumber"
      label="Broj telefona"
      v-model="tenantRequestData.phoneNumber"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
            field-name="bpg"
            label="Broj poljoprivrednog gazdinstva"
            v-model="tenantRequestData.bpg"
            :errors="errors"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import TextInput from "@/modules/common/forms/TextInput.vue";
import { PostTenantRequestData } from "@/modules/tenant/api/types";

@Options({
  components: { TextInput },
  props: {
    tenantRequestData: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  }
})
export default class CreateTenantForm extends Vue {
  tenantRequestData!: PostTenantRequestData;
  errors!: BackendValidationErrors;
}
</script>
