<template>
  <div class="form-group">
    <TextInput
      field-name="email"
      label="Email"
      v-model="user.email"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="firstName"
      label="Ime"
      v-model="user.firstName"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="lastName"
      label="Prezime"
      v-model="user.lastName"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="addressLine1"
      label="Adresa 1"
      v-model="user.address.line1"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="addressLine2"
      label="Adresa 2"
      v-model="user.address.line2"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="addressPostalCode"
      label="Poštanski Broj"
      v-model="user.address.postalCode"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="addressCity"
      label="Grad"
      v-model="user.address.city"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="addressCountry"
      label="Zemlja"
      v-model="user.address.country"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="addressState"
      label="Provincija"
      v-model="user.address.state"
      :errors="errors"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { UserDetailsRequestData } from "@/modules/account/api/types";
import TextInput from "@/modules/common/forms/TextInput.vue";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";

@Options({
  components: { TextInput },
  props: {
    user: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  }
})
export default class MarketListItemWidget extends Vue {
  user!: UserDetailsRequestData;
  errors!: BackendValidationErrors;
}
</script>
