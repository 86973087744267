<template>
  <div class="page-loader">
    <div class="bg-primary"></div>
  </div>
  <div class="layout-wrapper layout-1">
    <!-- Layout inner -->
    <div class="layout-inner">
      <slot name="header"></slot>
      <div class="layout-container">
        <slot name="navigation"></slot>

        <div class="layout-content">
          <slot />

          <nav class="layout-footer footer bg-footer-theme">
            <div
              class="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x py-3"
            >
              <slot name="footer"></slot>
            </div>
          </nav>
        </div>
      </div>
    </div>
    <!-- Layout inner -->

    <div class="layout-overlay layout-sidenav-toggle"></div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    showSideBox: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
export default class HncfLayout extends Vue {
  showSideBox = false;
}
</script>
