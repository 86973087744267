import ApiClient from "@/components/ApiClient/ApiClient";

import {
  EditSellingPlaceCategory,
  EditSellingPlaceCategoryRequestData,
  FetchSellingPlaceCategories,
  NewSellingPlaceCategoryRequestData,
  PostSellingPlaceCategory,
  PostSellingPlaceCategoryResponse,
  DeleteSellingPlaceCategory,
  FetchSellingPlaceCategoriesParams,
  FetchSellingPlaceCategory,
  FetchSellingPlaceCategoryParams
} from "@/modules/selling-place-category/api/types";

import {
  postSellingPlaceCategoryResponseMapper,
  sellingPlaceCategoryListMapper,
  sellingPlaceCategoryMapper
} from "@/modules/selling-place-category/api/mappers";
import {
  SellingPlaceCategory,
  SellingPlaceCategoryList
} from "@/modules/selling-place-category/types";

class Api {
  public client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  fetchSellingPlaceCategories: FetchSellingPlaceCategories = (
    params?: FetchSellingPlaceCategoriesParams
  ): Promise<SellingPlaceCategoryList> => {
    const url = "settings/selling-places/categories";

    return this.client.get(url, params).then(sellingPlaceCategories => {
      return sellingPlaceCategoryListMapper(sellingPlaceCategories);
    });
  };

  fetchSellingPlaceCategory: FetchSellingPlaceCategory = (
    id: string,
    params?: FetchSellingPlaceCategoryParams
  ): Promise<SellingPlaceCategory> => {
    const url = `settings/selling-places/categories/${id}`;

    return this.client.get(url, params).then(sellingPlaceCategory => {
      return sellingPlaceCategoryMapper(sellingPlaceCategory);
    });
  };

  createSellingPlaceCategory: PostSellingPlaceCategory = (
    requestsData: NewSellingPlaceCategoryRequestData
  ): Promise<PostSellingPlaceCategoryResponse> => {
    const url = "settings/selling-places/categories";

    return this.client.post(url, requestsData).then(response => {
      return postSellingPlaceCategoryResponseMapper(response);
    });
  };

  editSellingPlaceCategory: EditSellingPlaceCategory = (
    id: string,
    requestData: EditSellingPlaceCategoryRequestData
  ): Promise<void> => {
    const url = `settings/selling-places/categories/${id}`;

    return this.client.patch(url, requestData);
  };

  deleteSellingPlaceCategory: DeleteSellingPlaceCategory = (
    id: string
  ): Promise<void> => {
    const url = `settings/selling-places/categories/${id}`;

    return this.client.delete(url);
  };
}

export default Api;
