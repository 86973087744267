<template>
  <div class="d-flex align-items-center mb-3">
    <div class="form-group w-100 mr-2">
      <label class="form-label" for="selling-place-name">Name</label
      ><input
        type="text"
        class="form-control"
        @keydown.enter="search()"
        v-model.trim="filter.searchTerm"
        id="selling-place-name"
      />
    </div>
    <div class="form-group w-100 mr-2">
      <SellingPlaceTypeSelector
        v-model:selling-place-type-id="filter.typeId"
        @update-type="filter.typeId = $event"
      />
    </div>
    <div class="form-group w-100">
      <SellingPlaceCategorySelector
        v-model:selling-place-category-id="filter.categoryId"
        @update-category="filter.categoryId = $event"
      />
    </div>
    <button class="btn btn-outline-primary ml-2 mt-2" @click="search()">
      Search
    </button>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import SellingPlaceTypeSelector from "@/modules/selling-place-type/widgets/SellingPlaceTypeSelector.vue";
import SellingPlaceCategorySelector from "@/modules/selling-place-category/widgets/SellingPlaceCategorySelector.vue";
import { SellingPlaceListFilter } from "@/modules/selling-place/api/types";
import { emptySellingPlaceFilter } from "@/modules/selling-place/types/EmptySellingPlaceFilter";

@Options({
  components: {
    SellingPlaceTypeSelector,
    SellingPlaceCategorySelector
  },
  emits: ["search"]
})
export default class FilterWidget extends Vue {
  store = useStore();
  filter: SellingPlaceListFilter = emptySellingPlaceFilter;

  search() {
    this.$emit("search", this.filter);
  }
}
</script>
