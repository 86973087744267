
import { Options, Vue } from "vue-class-component";
import { ModalSize } from "@/modules/common/types";

@Options({
  props: {
    size: {
      type: String,
      required: false
    }
  }
})
export default class Modal extends Vue {
  size: ModalSize = "";

  mounted() {
    if (window.innerWidth > document.body.clientWidth) {
      document.querySelector("body")?.classList.add("padding-right");
    }
    document.querySelector("body")?.classList.add("modal-open");
    document.querySelector("#modal-backdrop")?.classList.add("show");
    document.querySelector("#modal-backdrop")?.classList.add("modal-backdrop");
  }

  beforeUnmount() {
    if (document.querySelector("body")?.classList.contains("padding-right")) {
      document.querySelector("body")?.classList.remove("padding-right");
    }
    document.querySelector("body")?.classList.remove("modal-open");
    document.querySelector("#modal-backdrop")?.classList.remove("show");
    document
      .querySelector("#modal-backdrop")
      ?.classList.remove("modal-backdrop");
  }

  get sizeModalClass(): string {
    if (this.size === "sm") {
      return "modal-sm";
    }

    if (this.size === "lg") {
      return "modal-lg";
    }

    return "";
  }
}
