import { RouteRecordRaw } from "vue-router";
import Login from "@/structure/Authentication/views/Login.vue";
import Logout from "@/structure/Authentication/views/Logout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout
  }
];

export default routes;
