
import { Options, Vue } from "vue-class-component";
import { Links } from "@/modules/common/types";

@Options({
  props: {
    navigationLinks: {
      type: Array,
      required: true
    }
  }
})
export default class NavigationWidget extends Vue {
  navigationLinks!: Links;
}
