
import { Options, Vue } from "vue-class-component";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import { SellingPlaceType } from "@/modules/selling-place-type/types";
import eventHub from "@/components/EventHub";
import SellingPlaceTypeDeleteRequestedEvent from "@/modules/selling-place-type/events/SellingPlaceTypeDeleteRequestedEvent";
import SellingPlaceTypeEditRequestedEvent from "@/modules/selling-place-type/events/SellingPlaceTypeEditRequestedEvent";

@Options({
  components: { Spinner },
  props: {
    sellingPlaceType: {
      type: Object,
      required: true
    }
  }
})
export default class SellingPlaceTypeListItemWidget extends Vue {
  sellingPlaceType!: SellingPlaceType;
  isEditHovering = false;
  isDeleteHovering = false;

  editSellingPlaceType(sellingPlaceType: SellingPlaceType) {
    eventHub.emit(
      SellingPlaceTypeEditRequestedEvent.Name,
      new SellingPlaceTypeEditRequestedEvent(sellingPlaceType.id)
    );
  }

  deleteSellingPlaceType(sellingPlaceType: SellingPlaceType) {
    eventHub.emit(
      SellingPlaceTypeDeleteRequestedEvent.Name,
      new SellingPlaceTypeDeleteRequestedEvent(sellingPlaceType)
    );
  }
}
