import ApiClient from "@/components/ApiClient/ApiClient";

import {
  FetchSellingPlace,
  FetchSellingPlaceParams,
  FetchSellingPlaces,
  FetchSellingPlacesParams,
  FetchAllSellingPlaces,
  FetchSellingPlaceDetails,
  FetchSellingPlaceDetailsParams,
  PostSellingPlace,
  PostSellingPlaceRequestData,
  PostSellingPlaceResponse,
  EditSellingPlace,
  EditSellingPlaceRequestData,
  DeleteSellingPlaceParams,
  DeleteSellingPlace
} from "./types";

import {
  sellingPlaceMapper,
  sellingPlaceListMapper,
  allSellingPlaceListMapper
} from "./mappers";
import { SellingPlace, SellingPlaceList } from "@/modules/selling-place/types";

class Api {
  public client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  fetchSellingPlaces: FetchSellingPlaces = (
    params: FetchSellingPlacesParams
  ): Promise<SellingPlaceList> => {
    const url = `selling-places`;

    return this.client.get(url, params).then(sellingPlaces => {
      return sellingPlaceListMapper(sellingPlaces);
    });
  };

  fetchSellingPlace: FetchSellingPlace = (
    id: string,
    params?: FetchSellingPlaceParams
  ): Promise<SellingPlace> => {
    const url = `selling-places/${id}`;

    return this.client.get(url, params).then(sellingPlace => {
      return sellingPlaceMapper(sellingPlace);
    });
  };

  fetchSellingPlaceDetails: FetchSellingPlaceDetails = (
    params: FetchSellingPlaceDetailsParams
  ): Promise<SellingPlace> => {
    const url = `selling-places-details/${params.sellingPlaceId}`;

    return this.client.get(url).then(sellingPlace => {
      return sellingPlaceMapper(sellingPlace);
    });
  };

  //TODO mozda ne treba
  // fetchAllSellingPlaces: FetchAllSellingPlaces = (): Promise<
  //   SellingPlaceList
  // > => {
  //   const url = `selling-places-markets`;
  //
  //   return this.client.get(url).then(sellingPlaces => {
  //     // return sellingPlaces;
  //     return allSellingPlaceListMapper(sellingPlaces);
  //   });
  // };

  postSellingPlace: PostSellingPlace = (
    params: PostSellingPlaceRequestData
  ): Promise<PostSellingPlaceResponse> => {
    const url = `selling-places`;

    return this.client.post(url, params).then(response => {
      return response;
    })
  };

  editSellingPlace: EditSellingPlace = (
      sellingPlaceId: string,
      requestData: EditSellingPlaceRequestData
  ): Promise<void> => {
    const url = `selling-places/${sellingPlaceId}`;

    return this.client.put(url, requestData);
  };


  deleteSellingPlace: DeleteSellingPlace = (params: DeleteSellingPlaceParams): Promise<void> => {
    const url = `selling-places/${params.id}`;

    return this.client.delete(url);
  };
}

export default Api;
