<template>
  <h5>Prodajni Objekat</h5>
  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Naziv
        </div>
        <div>
          {{ name }}
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Tip
        </div>
        <div>
          {{ type }}
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Kategorija
        </div>
        <div>
          {{ category }}
        </div>
      </div>
      <template v-if="sellingPlacePrice">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div>
            Cena Mesecne Rezervacije
          </div>
          <div>{{ sellingPlacePrice.reservationPriceMonthly / 100 }} RSD</div>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div>
            Cena Dnevnog Zakupa Sa Rezervacijom
          </div>
          <div>{{ sellingPlacePrice.rentPriceDaily / 100 }} RSD</div>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div>
            Cena Dnevnog Zakupa Bez Rezervacije
          </div>
          <div>
            {{ sellingPlacePrice.rentPriceWithoutReservationDaily / 100 }} RSD
          </div>
        </div>
      </template>
      <br />
      <template v-if="reservation">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div>
            Broj Ugovora
          </div>
          <div>
            {{ contract.number }}
          </div>
        </div>
        <br />
        <TenantDetailsWidget
          v-if="contract.tenantDetails"
          :tenant="contract.tenantDetails"
          title="Osnovni Podaci Zakupca"
        />
      </template>
      <template v-else>
        <p><b>Prodajni objekat nema rezervaciju za danasnji dan</b></p>
      </template>
      <template v-if="transaction">
        <h5>Informacije o Danasnjoj Transakciji</h5>
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between mb-2">
              <div>
                Cena
              </div>
              <div>
                {{ transaction.total / 100 }} RSD
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <div>
                Tip
              </div>
              <div>
                Dnevni Zakup
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <div>
                Napomena
              </div>
              <div>
                {{ transaction.tenant ? 'Dnevni zakup je platio zakupac' : 'Dnevni zakup nije platio zakupac' }}
              </div>
            </div>
          </div>
        </div>
        <br>
        <h5>Informacije o Inkasantu</h5>
        <UserDetailsWidget :user="transaction.cashier"/>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { SellingPlace } from "@/modules/selling-place/types";
import { SellingPlacePrice } from "@/modules/selling-place-price/types";
import { Contract, ContractItem } from "@/modules/contracts/types";
import AsyncActionFactory from "@/components/AsyncAction";
import ContractApi from "@/modules/contracts/api";
import TenantDetailsWidget from "@/modules/tenant/widgets/TenantDetailsWidget.vue";
import { Transaction } from "@/modules/transactions/types";
import UserDetailsWidget from "@/modules/user/widgets/UserDetailsWidget.vue";

@Options({
  props: {
    sellingPlace: {
      type: Object,
      required: true
    },
    sellingPlacePrice: {
      type: Object,
      required: true
    },
    reservation: {
      type: Object
    },
    transaction: {
      type: Object
    }
  },
  watch: {
    reservation() {
      this.getContract();
    }
  },
  components: {
    TenantDetailsWidget,
    UserDetailsWidget
  }
})
export default class SellingPlaceDetailsWidget extends Vue {
  sellingPlace!: SellingPlace;
  sellingPlacePrice!: SellingPlacePrice;
  transaction!: Transaction | null;
  reservation!: ContractItem | null;
  fetchContract = AsyncActionFactory(ContractApi.fetchContract);
  contract = {};

  async getContract() {
    await this.fetchContract.call(this.reservation.contractId);

    if (this.fetchContract.isSuccessful) {
      this.contract = this.fetchContract.responseData as Contract;
    }
  }

  get name(): string {
    if (!this.sellingPlace.name) return "-";

    return this.sellingPlace.name;
  }

  get type(): string {
    if (!this.sellingPlace.type.name) return "-";

    return this.sellingPlace.type.name;
  }

  get category(): string {
    if (!this.sellingPlace.category.name) return "-";

    return this.sellingPlace.category.name;
  }
}
</script>
