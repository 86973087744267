<template>
  <HncfLayout>
    <template v-slot:header>
      <slot name="header">
        <AppHeaderWidget
          :header-options="headerOptions"
          :profile-menu-links="profileMenuLinks"
          :application-links="applicationLinks"
        />
      </slot>
    </template>
    <template v-slot:navigation>
      <NavigationWidget />
    </template>
    <template v-slot:footer>
      <slot name="footer">
        <FooterWidget />
      </slot>
    </template>
    <slot></slot>
  </HncfLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HncfLayout from "@/modules/common/layouts/HncfLayout.vue";
import FooterWidget from "@/modules/common/widgets/FooterWidget.vue";
import { getHeaderOptions } from "@/structure/common/utils/getHeaderOptions";
import { getProfileMenuLinks } from "@/structure/common/utils/getProfileMenuLinks";
import { getApplicationLinks } from "@/structure/common/utils/getApplicationLinks";
import NavigationWidget from "@/structure/Cashier/common/widgets/NavigationWidget.vue";
import { useStore } from "@/store";
import AppHeaderWidget from "@/structure/common/widgets/AppHeaderWidget.vue";
import { Settings } from "@/modules/settings/types";
import { HeaderOptions, Links } from "@/modules/common/types";

@Options({
  components: { HncfLayout, AppHeaderWidget, FooterWidget, NavigationWidget }
})
export default class extends Vue {
  store = useStore();

  get settings(): Settings {
    return this.store.getters.settings;
  }

  get headerOptions(): HeaderOptions {
    return getHeaderOptions(this.settings);
  }

  get profileMenuLinks(): Links {
    return getProfileMenuLinks();
  }

  get applicationLinks(): Links {
    return getApplicationLinks();
  }
}
</script>
