import { BreadcrumbItem, BreadcrumbItems } from "@/modules/common/types";
import getBasicBreadcrumbItems from "@/structure/Administrator/common/utils/getBreadcrumbItems.ts";
import { SellingPlace } from "@/modules/selling-places/types";

function createSellingPlacesItem(): BreadcrumbItem {
  return {
    name: "Prodajna mesta",
    link: {
      name: "administrator-selling-places"
    }
  };
}

function createSellingPlaceItem(sellingPlace: SellingPlace): BreadcrumbItem {
  return {
    name: sellingPlace.name,
    link: {
      name: "administrator-selling-place",
      params: {
        sellingPlaceId: sellingPlace.id
      }
    }
  };
}

const getBreadcrumbItems = function(
  moduleName: string,
  sellingPlace?: SellingPlace
) {
  const items: BreadcrumbItems = [];

  items.push(createSellingPlacesItem());
  if (sellingPlace) {
    items.push(createSellingPlaceItem(sellingPlace));
  }

  return getBasicBreadcrumbItems(moduleName, items);
};

export default getBreadcrumbItems;
