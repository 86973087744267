import { BreadcrumbItem, BreadcrumbItems } from "@/modules/common/types";
import getBaseBreadcrumbItems from "@/structure/Cashier/common/utils/getBreadcrumbItems.ts";
import { Tenant } from "@/modules/tenant/types";

function createTenantsItem(): BreadcrumbItem {
  return {
    name: "Zakupci",
    link: {
      name: "cashier-tenants"
    }
  };
}

function createTenantItem(tenant: Tenant): BreadcrumbItem {
  return {
    name: `${tenant.firstName} ${tenant.lastName}`,
    link: {
      name: "cashier-tenant",
      params: {
        marketId: tenant.id
      }
    }
  };
}

const getBreadcrumbItems = function(moduleName?: string, tenant?: Tenant) {
  const items: BreadcrumbItems = [];

  items.push(createTenantsItem());

  if (tenant) {
    items.push(createTenantItem(tenant));
  }

  return getBaseBreadcrumbItems(moduleName, items);
};

export default getBreadcrumbItems;
