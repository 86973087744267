import {
  SellingPlacePrice,
  SellingPlacePriceList
} from "@/modules/selling-place-price/types";

function sellingPlacePriceItemMapper(sellingPlacePriceItem): SellingPlacePrice {
  return {
    ...sellingPlacePriceItem
  };
}

export function sellingPlacePricesMapper(
  sellingPlacePrices
): SellingPlacePriceList {
  sellingPlacePrices.data.map(sellingPlacePriceItem =>
    sellingPlacePriceItemMapper(sellingPlacePriceItem)
  );
  return sellingPlacePrices;
}
