<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Market
        </div>
        <div>
          {{ transactionItem.sellingPlace.market.name }}
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Prodajni Objekat
        </div>
        <div>
          {{ transactionItem.sellingPlace.name }}
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Početak - Kraj Datum
        </div>
        <div>
          {{ transactionItem.startDate }} - {{ transactionItem.endDate }}
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Tip
        </div>
        <div>
          {{ transactionItem.serviceType }}
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Cena
        </div>
        <div>
          {{ transactionItem.price / 100 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { TransactionItem } from "../types";

@Options({
  props: {
    transactionItem: {
      type: Object,
      required: true
    }
  }
})
export default class TransactionDetailsWidget extends Vue {
  transaction_item!: TransactionItem;
}
</script>
