
import { Options, Vue } from "vue-class-component";
import MainPageLayout from "@/modules/common/layouts/PageLayout.vue";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";

import { BreadcrumbItems } from "@/modules/common/types";
import getBreadcrumbItems from "@/structure/Administrator/common/utils/getBreadcrumbItems";

@Options({
  components: {
    MainPageLayout,
    Breadcrumbs
  }
})
export default class extends Vue {
  get breadcrumbItems(): BreadcrumbItems {
    const pageTitle = ""; // this.$route.meta.title ?? "";
    return getBreadcrumbItems(pageTitle);
  }
}
