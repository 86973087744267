
import { inject } from "vue";
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import ListWidget from "@/modules/common/widgets/ListWidget";
import MarketListItemWidget from "@/modules/market/widgets/MarketListWidget/MarketListItemWidget.vue";
import {
  FetchCashierMarkets,
  FetchMarkets,
  FetchMarketsParams
} from "@/modules/market/api/types";
import { emptyMarketList } from "@/modules/market/types/EmptyMarketList";
import { MarketList } from "@/modules/market/types";

@Options({
  components: { Spinner, ListWidget, MarketListItemWidget },
  emits: ["marketSelected"]
})
export default class MarketListWidget extends Vue {
  marketList: MarketList = emptyMarketList;
  page = 1;

  fetchMarketsAction = AsyncActionFactory(
    inject("fetchMarkets") as FetchMarkets
  );

  fetchCashierMarketsAction = AsyncActionFactory(
    inject("fetchCashierMarkets") as FetchCashierMarkets
  );

  created() {
    this.loadData();
  }

  async loadData() {
    if (this.$route.path.includes("/cashier")) {
      await this.fetchCashierMarketsAction.call({
        page: this.page,
        perPage: 30
      } as FetchMarketsParams);

      if (this.fetchCashierMarketsAction.isSuccessful) {
        this.marketList = this.fetchCashierMarketsAction
          .responseData as MarketList;
      }
    } else {
      await this.fetchMarketsAction.call({
        page: this.page,
        perPage: 30
      } as FetchMarketsParams);

      if (this.fetchMarketsAction.isSuccessful) {
        this.marketList = this.fetchMarketsAction.responseData as MarketList;
      }
    }
  }

  get isDataLoading(): boolean {
    return this.fetchMarketsAction.isBusy || this.fetchCashierMarketsAction.isBusy;
  }

  onPaginate(page) {
    this.page = page;
    this.loadData();
  }

  onMarketSelected(marketId: string) {
    this.$emit("marketSelected", marketId);
  }
}
