
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Reports/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo, TabItems } from "@/modules/common/types";
import getTabItems from "@/structure/Administrator/Reports/utils/getTabItems";
import TabBarWidget from "@/modules/common/widgets/TabBarWidget.vue";

@Options({
  components: { PageLayout, PageHeaderInfoWidget, TabBarWidget }
})
export default class ReportsPage extends Vue {
  headerInfo: HeaderInfo = {
    icon: "ion ion-md-document",
    section: "Izveštaji"
  };

  get tabItems(): TabItems {
    return getTabItems();
  }
}
