<template>
  <div class="form-group">
    <label class="form-label" for="edit-user-firstname">Ime</label
    ><input
      type="text"
      class="form-control"
      :class="{ 'is-invalid': errors.has('firstName') }"
      v-model.trim="user.firstName"
      id="edit-user-firstname"
      @input="errors.clear('firstName')"
    />
    <small v-if="errors.has('firstName')" class="invalid-feedback">
      {{ errors.get("firstName") }}
    </small>
  </div>
  <div class="form-group">
    <label class="form-label" for="edit-user-lastname">Prezime</label
    ><input
      type="text"
      class="form-control"
      :class="{ 'is-invalid': errors.has('lastName') }"
      v-model.trim="user.lastName"
      id="edit-user-lastname"
      @input="errors.clear('lastName')"
    />
    <small v-if="errors.has('lastName')" class="invalid-feedback">
      {{ errors.get("lastName") }}
    </small>
  </div>
  <div class="form-group">
    <!--<RoleSelector v-model:role="role" @update-role="role = $event" />-->
    <!--    todo: refactor this and then uncomment when finished-->
  </div>
  <div class="form-group">
    <label class="form-check">
      <input class="form-check-input" type="checkbox" v-model="user.active" />
      <div class="form-check-label">
        Odobren Pristup
      </div>
    </label>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Modal from "@/modules/common/modals/Modal.vue";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import RoleSelector from "@/modules/user/widgets/RoleSelector.vue";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import { User } from "@/modules/user/types";

@Options({
  components: { Modal, Spinner, RoleSelector },
  props: {
    user: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  }
})
export default class EditUserModal extends Vue {
  user!: User;
  errors!: BackendValidationErrors;
}
</script>
