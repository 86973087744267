
import { Options, Vue } from "vue-class-component";
import TextInput from "@/modules/common/forms/TextInput.vue";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import { Tenant, TenantList } from "@/modules/tenant/types";
import TenantApi from "../../tenant/api";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import { FetchTenants, FetchTenantsParams } from "@/modules/tenant/api/types";
import SellingPlaceApi from "../../selling-place/api";
import { FetchAllSellingPlaces } from "@/modules/selling-place/api/types";
import { SellingPlace, SellingPlaceList } from "@/modules/selling-place/types";
import { MonthPickerInput } from "vue-month-picker";
import { NewPostContractRequestData } from "@/modules/contracts/api/types";
import ContractTypeApi from "@/modules/contracts/api";

@Options({
  components: { TextInput, MonthPickerInput },
  props: {
    contractRequestData: {
      type: Object,
      required: true
    },
    validationErrors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  }
})
export default class ContractForm extends Vue {
  validationErrors!: BackendValidationErrors;
  allTenants: Array<Tenant> = [];
  allSellingPlaces: Array<SellingPlace> = [];
  contractTenant: number | string = "";
  contractSellingPlace: number | string = "";
  rentStartDate = {
    from: null,
    to: null,
    month: null,
    year: null
  };
  rentEndDate = {
    from: null,
    to: null,
    month: null,
    year: null
  };
  contractRequestData!: NewPostContractRequestData;

  fetchTenants = AsyncActionFactory(TenantApi.fetchAllTenants as FetchTenants);
  fetchSellingPlaces = AsyncActionFactory(
    SellingPlaceApi.fetchAllSellingPlaces as FetchAllSellingPlaces
  );
  postContractTypeAction = AsyncActionFactory(
    ContractTypeApi.createContractType
  );

  showStartDate(date) {
    this.rentStartDate = date;
    this.contractRequestData.rentStartDate = date.to;
  }

  showEndDate(date) {
    this.rentEndDate = date;
    this.contractRequestData.rentEndDate = date.to;
  }

  async created() {
    await this.loadTenants();
    await this.loadSellingPlaces();
  }

  async loadTenants() {
    await this.fetchTenants.call({
      perPage: -1
    } as FetchTenantsParams);
    if (this.fetchTenants.isSuccessful) {
      this.allTenants = (this.fetchTenants.responseData as TenantList).data;
    }
  }
  async loadSellingPlaces() {
    await this.fetchSellingPlaces.call();
    if (this.fetchSellingPlaces.isSuccessful) {
      this.allSellingPlaces = (this.fetchSellingPlaces
        .responseData as SellingPlaceList).data;
    }
  }
}
