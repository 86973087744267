<template>
  <PageLayout>
    <template v-slot:page-name>
      <PageHeaderInfoWidget :header-info="headerInfo" />
    </template>
    <template v-slot:page-widgets>
      <HeaderWidgets />
    </template>
    Informacije Profila

    <EditProfileForm
      :user="requestData"
      :errors="editUserDetailsAction.errors"
    />
    <button type="submit" class="btn btn-primary" @click="submit">
      Sačuvaj
    </button>
  </PageLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PageLayout from "../layout/PageLayout.vue";
import { HeaderInfo } from "@/modules/common/types";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import HeaderWidgets from "../widgets/HeaderWidgets.vue";
import { useStore } from "@/store";
import { User } from "@/modules/user/types";
import EditProfileForm from "@/modules/account/forms/EditProfileForm.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import AccountApi from "@/modules/account/api";
import { UserDetailsRequestData } from "@/modules/account/api/types";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    HeaderWidgets,
    EditProfileForm
  }
})
export default class extends Vue {
  store = useStore();

  get user(): User {
    return this.store.getters.user;
  }

  requestData: UserDetailsRequestData = {
    email: this.user.email,
    firstName: this.user.firstName,
    lastName: this.user.lastName,
    address: this.user.address
  };

  editUserDetailsAction = AsyncActionFactory(AccountApi.updateUserDetails);

  async submit() {
    await this.editUserDetailsAction.call(this.requestData);
  }

  headerInfo: HeaderInfo = {
    icon: "ion ion-md-person",
    section: this.user ? this.user.firstName + " " + this.user.lastName : ""
  };
}
</script>
