<template>
  <LoginPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import LoginPage from "@/structure/Authentication/pages/LoginPage.vue";
import { provide } from "vue";
import AuthenticationApi from "@/modules/authentication/api";
import SettingsApi from "@/modules/settings/api";
import { Login as LoginAction } from "@/modules/authentication/api/types";
import { FetchSettings } from "@/modules/settings/api/types";

@Options({
  components: { LoginPage }
})
export default class Login extends Vue {
  created() {
    provide(
      "login",
      AuthenticationApi.login.bind(AuthenticationApi) as LoginAction
    );
    provide(
      "fetchSettings",
      SettingsApi.fetchSettings.bind(SettingsApi) as FetchSettings
    );
  }
}
</script>
