<template>
  <ol class="breadcrumb">
    <li
      class="breadcrumb-item"
      v-for="item in items"
      :class="{ active: !item.link }"
      :key="item.name"
    >
      <template v-if="item.link">
        <router-link :to="item.link">
          <i :class="item.icon" v-if="item.icon"></i>
          {{ item.name }}
        </router-link>
      </template>
      <template v-else>
        <i :class="item.icon" v-if="item.icon"></i>
        {{ item.name }}
      </template>
    </li>
  </ol>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { BreadcrumbItems } from "@/modules/common/types";

@Options({
  props: {
    items: {
      required: true,
      default: []
    }
  }
})
export default class extends Vue {
  items!: BreadcrumbItems;
}
</script>
