import { RouteRecordRaw } from "vue-router";
import PassThroughViewSellingPlaceDetails from "../widgets/PassThroughViewSellingPlaceDetails.vue";
import SellingPlace from "@/structure/Administrator/SellingPlaces/views/SellingPlaceDetails.vue";

const sellingPlaceRoutes: Array<RouteRecordRaw> = [
    {
        path: "selling-places/:sellingPlaceId",
        name: "administrator-selling-place",
        component: PassThroughViewSellingPlaceDetails,
        redirect: to => ({
            name: "administrator-selling-place-details",
            params: {
                sellingPlaceId: to.params.sellingPlaceId
            }
        }),
        children: [
            {
                path: "",
                name: "administrator-selling-place-details",
                component: SellingPlace,
                meta: {
                    title: ""
                }
            }
        ]
    }
];

export default sellingPlaceRoutes;
