
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction";
import { inject } from "vue";
import { useStore } from "@/store";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import { Market } from "@/modules/market/types";
import SellingPlacePricesItemWidget from "@/modules/selling-place-price/widgets/SellingPlacePricesWidget/SellingPlacePricesItemWidget.vue";
import SellingPlacePriceEditModal from "@/modules/selling-place-price/widgets/SellingPlacePriceEditModal.vue";
import {
  SellingPlacePrice,
  SellingPlacePriceList,
  SellingPlacePricesTableHeaderItem
} from "@/modules/selling-place-price/types";
import { SellingPlaceType } from "@/modules/selling-place-type/types";
import { SellingPlaceCategory } from "@/modules/selling-place-category/types";
import { emptySellingPlacePriceList } from "@/modules/selling-place-price/types/EmptySellingPlacePriceList";
import { FetchSellingPlacePrices } from "@/modules/selling-place-price/api/types";

@Options({
  components: {
    Spinner,
    SellingPlacePricesItemWidget,
    SellingPlacePriceEditModal
  }
})
export default class SellingPlacePricesWidget extends Vue {
  store = useStore();

  sellingPlacePrices: SellingPlacePriceList = emptySellingPlacePriceList;

  categories: Array<SellingPlacePricesTableHeaderItem> = [];
  types: Array<SellingPlacePricesTableHeaderItem> = [];

  dataLoaded = false;

  displayEditSellingPlacePriceItemModal = false;
  sellingPlacePriceItem!: SellingPlacePrice;

  get market(): Market {
    return this.store.getters.market;
  }

  fetchSellingPlacePrices = AsyncActionFactory(
    inject("fetchSellingPlacePrices") as FetchSellingPlacePrices
  );

  created() {
    this.loadData();
  }

  async loadData() {
    await this.fetchSellingPlacePrices.call(this.market.id);

    if (this.fetchSellingPlacePrices.isSuccessful) {
      this.sellingPlacePrices = this.fetchSellingPlacePrices
        .responseData as SellingPlacePriceList;
      this.filterTableHeaders();
      this.dataLoaded = true;
    }
  }

  get isDataLoading(): boolean {
    return this.fetchSellingPlacePrices.isBusy || !this.dataLoaded;
  }

  filterTableHeaders() {
    this.sellingPlacePrices.data.forEach(
      (sellingPlacePriceItem: SellingPlacePrice) => {
        const sellingPlaceCategory = sellingPlacePriceItem.sellingPlaceCategory;
        this.filterCategories(sellingPlaceCategory);

        const sellingPlaceType = sellingPlacePriceItem.sellingPlaceType;
        this.filterTypes(sellingPlaceType);
      }
    );
  }

  filterCategories(sellingPlaceCategory: SellingPlaceCategory): void {
    if (!this.hasCategory(sellingPlaceCategory)) {
      this.categories.push({
        id: sellingPlaceCategory.id,
        name: sellingPlaceCategory.name
      } as SellingPlacePricesTableHeaderItem);
    }
  }

  filterTypes(sellingPlacePype: SellingPlaceType): void {
    if (!this.hasType(sellingPlacePype)) {
      this.types.push({
        id: sellingPlacePype.id,
        name: sellingPlacePype.name
      } as SellingPlacePricesTableHeaderItem);
    }
  }

  hasCategory(sellingPlaceCategory: SellingPlaceCategory): boolean {
    const category = this.categories.find(
      category => category.id === sellingPlaceCategory.id
    );
    return category !== undefined;
  }

  hasType(sellingPlaceType: SellingPlaceType): boolean {
    const type = this.types.find(type => type.id === sellingPlaceType.id);
    return type !== undefined;
  }

  getItem(
    type: SellingPlacePricesTableHeaderItem,
    category: SellingPlacePricesTableHeaderItem
  ): SellingPlacePrice | undefined {
    return this.sellingPlacePrices.data.find(
      (sellingPlacePriceItem: SellingPlacePrice) =>
        sellingPlacePriceItem.sellingPlaceType.id === type.id &&
        sellingPlacePriceItem.sellingPlaceCategory.id === category.id
    );
  }

  onEditSellingPlacePriceItem(sellingPlacePriceItem: SellingPlacePrice) {
    this.displayEditSellingPlacePriceItemModal = true;
    this.sellingPlacePriceItem = sellingPlacePriceItem;
  }

  onCloseEditModal() {
    this.displayEditSellingPlacePriceItemModal = false;
  }

  async onSellingPlacePriceItemEdited() {
    this.displayEditSellingPlacePriceItemModal = false;
    await this.loadData();
  }
}
