import ApiClient from "@/components/ApiClient/ApiClient";

import { Login, LoginRequestData, LoginResponseData, Logout } from "./types";
import { loginResponseMapper } from "@/modules/authentication/api/mappers";

class Api {
  public client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  login: Login = (
    requestData: LoginRequestData
  ): Promise<LoginResponseData> => {
    const url = "login-check";
    return this.client.post(url, requestData).then(response => {
      this.client.setAccessToken(response.token);
      return loginResponseMapper(response);
    });
  };

  logout: Logout = () => {
    this.client.clearAccessToken();
  };
}

export default Api;
