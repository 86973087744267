
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    props: {
      type: Object,
      required: true,
      default: {}
    },
    emits: ["paginate"]
  }
})
export default class PaginationTemplate extends Vue {
  props;
}
