
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import { Market } from "@/modules/market/types";

@Options({})
export default class PageLinksWidget extends Vue {
  store = useStore();

  get market(): Market {
    return this.store.getters.market;
  }
}
