import CustomError from "./CustomError";
import { RequestErrorContext } from "../types";

class RequestError extends CustomError {
  constructor(
    errorMessage: string,
    privateMessage: string | null = null,
    context: RequestErrorContext | null = null
  ) {
    super(errorMessage, privateMessage, context);

    // Set the prototype explicitly to fix inheritance problems w/ built-in types
    Object.setPrototypeOf(this, RequestError.prototype);
    this.name = this.constructor.name;
  }

  get headers() {
    return this.getFromContext("headers");
  }

  get body() {
    return this.getFromContext("body");
  }

  get httpStatus(): number {
    return Number(this.getFromContext("httpStatus"));
  }

  get validationErrors(): {} {
    const errors = this.getFromContext("validationErrors");
    return errors && typeof errors === "object" ? errors : {};
  }

  get request() {
    return this.getFromContext("request");
  }
}

export default RequestError;
