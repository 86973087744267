<template>
  <PageLayout>
    <template v-slot:page-name>
      <PageHeaderInfoWidget :header-info="headerInfo" />
    </template>
    <div class="row">
      <div class="col-6">
        <SellingPlaceTypesWidget />
      </div>
      <div class="col-6">
        <SellingPlaceCategoriesWidget />
      </div>
    </div>
  </PageLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Settings/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import SellingPlaceTypesWidget from "@/modules/selling-place-type/widgets/SellingPlaceTypesWidget.vue";
import SellingPlaceCategoriesWidget from "@/modules/selling-place-category/widgets/SellingPlaceCategoriesWidget.vue";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    SellingPlaceCategoriesWidget,
    SellingPlaceTypesWidget
  }
})
export default class SellingPlacePage extends Vue {
  headerInfo: HeaderInfo = {
    icon: "ion ion-md-settings",
    section: "Podešavanja",
    title: "Prodajni Objekti"
  };
}
</script>
