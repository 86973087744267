<template>
  <Spinner :show-spinner="isDataLoading">
    <TenantListWidget
      :tenants="filteredTenants"
      @tenant-selected="onTenantSelected($event)"
      @filter-changed="onFilter($event)"
    />
  </Spinner>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TenantListWidget from "./TenantListWidget.vue";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import TenantApi from "@/modules/tenant/api";
import {
  TenantsFilter,
  FetchTenants,
  FetchTenantsParams
} from "@/modules/tenant/api/types";
import eventHub from "@/components/EventHub";
import { Tenant, TenantList } from "@/modules/tenant/types";

@Options({
  components: { TenantListWidget, Spinner },
  emits: ["tenantSelected"]
})
export default class TenantsSidebar extends Vue {
  allTenants: Array<Tenant> = [];
  filteredTenants: Array<Tenant> = [];
  dataLoaded = false;

  fetchTenants = AsyncActionFactory(TenantApi.fetchTenants as FetchTenants);

  async created() {
    await this.loadTenants();
    eventHub.on("TENANT_DELETED", this.onTenantDeleted);
    eventHub.on("TENANT_EDITED", this.onTenantEdited);
    eventHub.on("TENANT_CREATED", this.onTenantCreated);
  }

  async onTenantDeleted(tenantId) {
    this.removeTenantFromList(tenantId);
  }

  onTenantEdited(tenantId) {
    this.replaceTenant(tenantId);
  }

  async onTenantCreated(tenantId) {
    const tenant = (await this.loadTenant(tenantId)) as Tenant;
    this.addTenantToList(tenant);
  }

  async replaceTenant(tenantId) {
    const tenant = (await this.loadTenant(tenantId)) as Tenant;
    this.replaceTenantInList(tenant);
  }

  replaceTenantInList(tenant: Tenant) {
    this.allTenants.forEach((tenantItem, i) => {
      if (tenantItem.id == tenant.id) {
        this.allTenants[i] = tenant;
      }
    });
  }

  addTenantToList(tenant: Tenant) {
    this.allTenants.push(tenant);
  }

  removeTenantFromList(id: string) {
    this.allTenants = this.allTenants.filter(
      tenantItem => tenantItem.id !== id
    );
    this.filteredTenants = this.allTenants;
  }

  async loadTenant(tenantId) {
    await this.fetchTenants.call({
      id: tenantId
    } as FetchTenantsParams);

    if (this.fetchTenants.isSuccessful) {
      const response = this.fetchTenants.responseData as TenantList;
      if (response.data.length > 0) {
        return response.data[0];
      }
    }
  }

  async loadTenants() {
    await this.fetchTenants.call({
      perPage: -1
    } as FetchTenantsParams);
    if (this.fetchTenants.isSuccessful) {
      this.allTenants = (this.fetchTenants.responseData as TenantList).data;
      this.filteredTenants = this.allTenants;
      this.dataLoaded = true;
    }
  }

  get isDataLoading(): boolean {
    return !this.dataLoaded;
  }

  onFilter(filter) {
    this.filteredTenants = this.filterTenants(filter);
  }

  onTenantSelected(tenantId: string) {
    this.$emit("tenantSelected", tenantId);
  }

  filterTenants(filter: TenantsFilter) {
    return this.allTenants.filter(tenant => {
      const fullName = `${tenant.firstName} ${tenant.lastName}`;
      return (
        fullName.toLowerCase().indexOf(filter.searchTerm.toLowerCase()) !== -1
      );
    });
  }
}
</script>
