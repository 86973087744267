import ApiClient from "@/components/ApiClient/ApiClient";

import {
  FetchSellingPlaceTypes,
  EditSellingPlaceType,
  EditSellingPlaceTypeRequestData,
  NewSellingPlaceTypeRequestData,
  PostSellingPlaceType,
  PostSellingPlaceTypeResponse,
  DeleteSellingPlaceType,
  FetchSellingPlaceType,
  FetchSellingPlaceTypeParams,
  DeleteSellingPlaceTypeParams,
  FetchSellingPlaceTypesParams
} from "./types";

import {
  postSellingPlaceTypeResponseMapper,
  sellingPlaceTypeListMapper,
  sellingPlaceTypeMapper
} from "./mappers";
import {
  SellingPlaceType,
  SellingPlaceTypeList
} from "@/modules/selling-place-type/types";

class Api {
  public client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  fetchSellingPlaceTypes: FetchSellingPlaceTypes = (
    params?: FetchSellingPlaceTypesParams
  ): Promise<SellingPlaceTypeList> => {
    const url = "settings/selling-places/types";

    return this.client.get(url, params).then(sellingPlaceTypes => {
      return sellingPlaceTypeListMapper(sellingPlaceTypes);
    });
  };

  fetchSellingPlaceType: FetchSellingPlaceType = (
    id: string,
    params?: FetchSellingPlaceTypeParams
  ): Promise<SellingPlaceType> => {
    const url = `settings/selling-places/types/${id}`;

    return this.client.get(url, params).then(sellingPlaceType => {
      return sellingPlaceTypeMapper(sellingPlaceType);
    });
  };

  createSellingPlaceType: PostSellingPlaceType = (
    requestsData: NewSellingPlaceTypeRequestData
  ): Promise<PostSellingPlaceTypeResponse> => {
    const url = "settings/selling-places/types";

    return this.client.post(url, requestsData).then(response => {
      return postSellingPlaceTypeResponseMapper(response);
    });
  };

  editSellingPlaceType: EditSellingPlaceType = (
    id: string,
    requestData: EditSellingPlaceTypeRequestData
  ): Promise<void> => {
    const url = `settings/selling-places/types/${id}`;

    return this.client.patch(url, requestData);
  };

  deleteSellingPlaceType: DeleteSellingPlaceType = (
    id: string,
    params?: DeleteSellingPlaceTypeParams
  ): Promise<void> => {
    const url = `settings/selling-places/types/${id}`;

    return this.client.delete(url, params);
  };
}

export default Api;
