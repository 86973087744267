
import { Options, Vue } from "vue-class-component";
import { Transaction } from "../types";
import TransactionItemDetailsWidget from "@/modules/transactions/widgets/TransactionItemDetailsWidget.vue";

@Options({
  props: {
    transaction: {
      type: Object,
      required: true
    }
  },
  components: { TransactionItemDetailsWidget }
})
export default class TransactionDetailsWidget extends Vue {
  transaction!: Transaction;
}
