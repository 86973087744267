
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction";
import Modal from "@/modules/common/modals/Modal.vue";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import SettingsApi from "@/modules/settings/api";

@Options({
  props: {
    organizationName: {
      type: String,
      required: true
    },
    organizationRepresentative: {
      type: String,
      required: false
    },
    organizationAddressLine1: {
      type: String,
      required: false
    },
    organizationAddressLine2: {
      type: String,
      required: false
    },
    organizationAddressCity: {
      type: String,
      required: false
    },
    organizationAddressPostalCode: {
      type: String,
      required: false
    },
    organizationAddressCountry: {
      type: String,
      required: false
    },
    organizationAddressState: {
      type: String,
      required: false
    },
  },
  components: { Modal, Spinner },
  emits: ["closeModal"]
})
export default class EditOrganizationNameModal extends Vue {
  organizationName!: string;
  model: string = this.organizationName;
  organizationRepresentative!: string;
  representative: string = this.organizationRepresentative;
  organizationAddressLine1!: string;
  addressLine1: string = this.organizationAddressLine1;
  organizationAddressLine2!: string;
  addressLine2: string = this.organizationAddressLine2;
  organizationAddressCity!: string;
  addressCity: string = this.organizationAddressCity;
  organizationAddressPostalCode!: string;
  addressPostalCode: string = this.organizationAddressPostalCode;
  organizationAddressCountry!: string;
  addressCountry: string = this.organizationAddressCountry;
  organizationAddressState!: string;
  addressState: string = this.organizationAddressState;

  mounted() {
    (this.$refs.organizationNameInput as HTMLInputElement).focus();
  }

  get isDataValid(): boolean {
    return this.model.length > 0;
  }

  patchSettings = AsyncActionFactory(SettingsApi.patchSettings);

  async onEditOrganizationRequest() {
    if (!this.isDataValid) return;

    const requestData = new FormData();
    requestData.append("organizationName", this.model);
    requestData.append("organizationRepresentative", this.representative);
    requestData.append("organizationAddressLine1", this.addressLine1);
    requestData.append("organizationAddressLine2", this.addressLine2);
    requestData.append("organizationAddressCity", this.addressCity);
    requestData.append("organizationAddressPostalCode", this.addressPostalCode);
    requestData.append("organizationAddressCountry", this.addressCountry);
    requestData.append("organizationAddressState", this.addressState);

    await this.patchSettings.call(requestData);
    if (this.patchSettings.isSuccessful) {
      this.$emit("closeModal");
    }
  }
}
