
import { Options, Vue } from "vue-class-component";
import ChangePasswordPage from "../pages/ChangePasswordPage.vue";
import { provide } from "vue";
import AccountApi from "@/modules/account/api";
import { PasswordChange as PasswordChangeAction } from "@/modules/account/api/types";

@Options({
  components: {
    ChangePasswordPage
  }
})
export default class extends Vue {
  created() {
    provide(
      "changePassword",
      AccountApi.changePassword.bind(AccountApi) as PasswordChangeAction
    );
  }
}
