<template>
  <div class="media align-items-baseline">
    <div>
      <i :class="sellingPlaceCategory.icon"></i>
    </div>
    <div class="media-body px-2">
      <p class="text-body mb-1 font-weight-bold">
        {{ sellingPlaceCategory.name }}
      </p>
      <small class="text-secondary">{{
        sellingPlaceCategory.description
      }}</small>
    </div>
    <div class="ml-auto">
      <i
        @click="editSellingPlaceCategory(sellingPlaceCategory)"
        @mouseover="isEditHovering = true"
        @mouseleave="isEditHovering = false"
        class="ion ion-md-create cursor-pointer"
        :class="{
          'text-primary': isEditHovering,
          'text-secondary': !isEditHovering
        }"
      ></i>
      <i
        v-if="sellingPlaceCategory.removable"
        @click="deleteSellingPlaceCategory(sellingPlaceCategory)"
        @mouseover="isDeleteHovering = true"
        @mouseleave="isDeleteHovering = false"
        class="ion ion-md-trash ml-2 cursor-pointer"
        :class="{
          'text-danger': isDeleteHovering,
          'text-secondary': !isDeleteHovering
        }"
      ></i>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import { SellingPlaceCategory } from "@/modules/selling-place-category/types";
import eventHub from "@/components/EventHub";
import SellingPlaceCategoryDeleteRequestedEvent from "@/modules/selling-place-category/events/SellingPlaceCategoryDeleteRequestedEvent";
import SellingPlaceCategoryEditRequestedEvent from "@/modules/selling-place-category/events/SellingPlaceCategoryEditRequestedEvent";

@Options({
  components: { Spinner },
  props: {
    sellingPlaceCategory: {
      type: Object,
      required: true
    }
  }
})
export default class SellingPlaceCategoryListItemWidget extends Vue {
  sellingPlaceCategory!: SellingPlaceCategory;
  isEditHovering = false;
  isDeleteHovering = false;

  editSellingPlaceCategory(sellingPlaceCategory: SellingPlaceCategory) {
    eventHub.emit(
      SellingPlaceCategoryEditRequestedEvent.Name,
      new SellingPlaceCategoryEditRequestedEvent(sellingPlaceCategory.id)
    );
  }

  deleteSellingPlaceCategory(sellingPlaceCategory: SellingPlaceCategory) {
    eventHub.emit(
      SellingPlaceCategoryDeleteRequestedEvent.Name,
      new SellingPlaceCategoryDeleteRequestedEvent(sellingPlaceCategory)
    );
  }
}
</script>
