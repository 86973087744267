<template>
  <div class="card">
    <div
      class="card-body"
      @mouseover="showEditIcon = true"
      @mouseleave="showEditIcon = false"
    >
      <div class="d-flex align-baseline justify-content-between">
        <div
          class="d-flex flex-column  align-items-start align-items-center w-25 h-90"
        >
          <Spinner :show-spinner="showSpinner">
            <OrganizationLogoWidget
              @logo-changed="onLogoChanged"
              @logo-removed="onLogoRemoved"
            />
          </Spinner>
        </div>
        <div class="w-75">
          <table class="table table-borderless">
            <tr>
              <td>Naziv</td>
              <th>{{ settings.organizationName }}</th>
              <th>
                <button
                  class="btn btn-xs btn-outline-primary"
                  @click="editOrganizationName()"
                >
                  <i class="ion ion-md-create mr-1"></i>
                  Izmena
                </button>
              </th>
            </tr>
            <tr>
              <td>Predstavnik/Direktor</td>
              <th>{{ settings.organizationRepresentative }}</th>
            </tr>
            <tr>
              <td>Adresa 1</td>
              <th>{{ settings.organizationAddressLine1 }}</th>
            </tr>
            <tr>
              <td>Adresa 2</td>
              <th>{{ settings.organizationAddressLine2 }}</th>
            </tr>
            <tr>
              <td>Mesto/Grad</td>
              <th>{{ settings.organizationAddressCity }}</th>
            </tr>
            <tr>
              <td>Postanski broj</td>
              <th>{{ settings.organizationAddressPostalCode }}</th>
            </tr>
            <tr>
              <td>Region</td>
              <th>{{ settings.organizationAddressState }}</th>
            </tr>
            <tr>
              <td>Drzava</td>
              <th>{{ settings.organizationAddressCountry }}</th>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <EditOrganizationNameModal
    v-if="showEditOrganisationNameModal"
    :organization-name="settings.organizationName"
    :organization-representative="settings.organizationRepresentative"
    :organization-address-line1="settings.organizationAddressLine1"
    :organization-address-line2="settings.organizationAddressLine2"
    :organization-address-city="settings.organizationAddressCity"
    :organization-address-postal-code="settings.organizationAddressPostalCode"
    :organization-address-country="settings.organizationAddressCountry"
    :organization-address-state="settings.organizationAddressState"
    @close-modal="closeEditOrganizationNameModal()"
  />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import EditOrganizationNameModal from "@/structure/Administrator/Settings/widgets/EditOrganizationNameModal.vue";
import { useStore } from "@/store";
import { inject } from "vue";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import { MutationType } from "@/store/mutations";
import {
  FetchSettings,
  FetchSettingsParams
} from "@/modules/settings/api/types";
import OrganizationLogoWidget from "@/structure/Administrator/Settings/widgets/OrganizationLogoWidget.vue";
import SettingsApi from "@/modules/settings/api";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import { Settings } from "@/modules/settings/types";

@Options({
  components: { EditOrganizationNameModal, OrganizationLogoWidget, Spinner }
})
export default class GeneralWidget extends Vue {
  store = useStore();

  showEditIcon = false;
  showEditOrganisationNameModal = false;

  fetchSettings = AsyncActionFactory(inject("fetchSettings") as FetchSettings);
  patchSettings = AsyncActionFactory(SettingsApi.patchSettings);
  removeSettingsLogo = AsyncActionFactory(SettingsApi.removeSettingsLogo);

  showSpinner = false;

  get settings(): Settings {
    return this.store.getters.settings;
  }

  editOrganizationName() {
    this.showEditOrganisationNameModal = true;
  }

  async closeEditOrganizationNameModal() {
    this.showEditOrganisationNameModal = false;
    await this.loadSettings();
  }

  async loadSettings() {
    await this.fetchSettings.call({
      extendWithSellingPlaceTypes: true,
      extendWithUserRoles: true,
      extendWithSellingPlaceCategories: true
    } as FetchSettingsParams);

    if (this.fetchSettings.isSuccessful) {
      this.store.commit(
        MutationType.SetSettings,
        this.fetchSettings.responseData
      );
    }
  }

  async onLogoChanged(logo: File) {
    this.showSpinner = true;
    const requestData = new FormData();
    requestData.append("organizationLogo", logo as Blob);

    await this.patchSettings.call(requestData);
    if (this.patchSettings.isSuccessful) {
      await this.loadSettings();
      this.showSpinner = false;
    }
  }

  async onLogoRemoved() {
    this.showSpinner = true;
    await this.removeSettingsLogo.call();
    if (this.removeSettingsLogo) {
      await this.loadSettings();
      this.showSpinner = false;
    }
  }
}
</script>
