
import { Options, Vue } from "vue-class-component";
import Input from "@/modules/common/forms/Input.vue";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";

@Options({
  components: {
    Input
  },
  props: {
    modelValue: {
      type: [String, Number],
      required: false
    },
    modelModifiers: {
      default: () => ({})
    },
    fieldName: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: "text",
      validator: value => {
        return ["text", "number", "password", "email"].includes(value);
      }
    },
    placeholder: {
      type: [String, Number],
      required: false
    },
    label: {
      type: String,
      required: false
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    errors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  },
  emits: ["update:modelValue", "input"]
})
export default class TextInput extends Vue {
  modelValue?: string | number;
  fieldName!: string;
  type!: string;
  placeholder?: string | number;
  label?: string;
  hideLabel!: boolean;
  errors!: BackendValidationErrors;

  value = this.modelValue;

  onUpdate(value) {
    this.errors.clear(this.fieldName);
    this.$emit("update:modelValue", value);
  }
}
