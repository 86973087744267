import { BreadcrumbItem, BreadcrumbItems } from "@/modules/common/types";
import getBasicBreadcrumbItems from "@/structure/Administrator/common/utils/getBreadcrumbItems.ts";
import { Contract } from "@/modules/contracts/types";

function createContractsItem(): BreadcrumbItem {
  return {
    name: "Ugovori",
    link: {
      name: "administrator-contracts"
    }
  };
}

function createContractItem(contract: Contract): BreadcrumbItem {
  return {
    name: contract.number,
    link: {
      name: "administrator-contract",
      params: {
        contractId: contract.id
      }
    }
  };
}

const getBreadcrumbItems = function(moduleName: string, contract?: Contract) {
  const items: BreadcrumbItems = [];

  items.push(createContractsItem());
  if (contract) {
    items.push(createContractItem(contract));
  }

  return getBasicBreadcrumbItems(moduleName, items);
};

export default getBreadcrumbItems;
