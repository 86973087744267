import { Settings } from "@/modules/settings/types";
import { User } from "@/modules/user/types";
import { Market, MarketList } from "@/modules/market/types";
import { Tenant } from "@/modules/tenant/types";
import { SellingPlace } from "@/modules/selling-place/types";
import { Contract, ContractList, ContractCancelReasons } from "@/modules/contracts/types";
import { WorkOrder, WorkOrderList } from "@/modules/work-orders/types";
import { Transaction, TransactionList } from "@/modules/transactions/types";

export type State = {
  settings: Settings | null;
  user: User | null;
  marketList: MarketList | null;
  tenant: Tenant | null;
  market: Market | null;
  sellingPlace: SellingPlace | null;
  contractList: ContractList | null;
  contract: Contract | null;
  workOrderList: WorkOrderList | null;
  workOrder: WorkOrder | null;
  transactionList: TransactionList | null;
  transaction: Transaction | null;
  contractCancelReasons: ContractCancelReasons | null;
};

export const state: State = {
  settings: null,
  user: null,
  marketList: null,
  tenant: null,
  market: null,
  sellingPlace: null,
  contractList: null,
  contract: null,
  workOrderList: null,
  workOrder: null,
  transactionList: null,
  transaction: null,
  contractCancelReasons: null
};
