
import { Options, Vue } from "vue-class-component";
import ContractNumberingRuleModule from "./contract-numbering-rules/Module.vue";
import TenantModule from "./tenant/Module.vue";
import MarketModule from "./market/Module.vue";
import ContractCancellationReasonModule from "./contracts-cancellation-reason/Module.vue";
import SellingPlaceTypeModule from "./selling-place-type/Module.vue";
import SellingPlaceCategoryModule from "./selling-place-category/Module.vue";
import UserModule from "./user/Module.vue";
import WorkOrderModule from "./work-orders/Module.vue";
import ContractModule from "./contracts/Module.vue";
import SellingPlaceModule from "./selling-places/Module.vue";

@Options({
  components: {
    ContractNumberingRuleModule,
    TenantModule,
    MarketModule,
    ContractCancellationReasonModule,
    SellingPlaceTypeModule,
    SellingPlaceCategoryModule,
    UserModule,
    ContractModule,
    WorkOrderModule,
    SellingPlaceModule
  }
})
export default class Modules extends Vue {}
