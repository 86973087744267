
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Cashier/Tenant/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { BreadcrumbItems, HeaderInfo, TabItems } from "@/modules/common/types";
import { useStore } from "@/store";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
import getBreadcrumbItems from "@/structure/Cashier/Tenant/utils/getBreadcrumbItems";
import getTabItems from "@/structure/Cashier/Tenant/utils/getTabItems";
import TabBarWidget from "@/modules/common/widgets/TabBarWidget.vue";
import { Tenant } from "@/modules/tenant/types";

@Options({
  components: { PageLayout, PageHeaderInfoWidget, Breadcrumbs, TabBarWidget }
})
export default class TenantSellingPlacesPage extends Vue {
  store = useStore();

  get tenant(): Tenant {
    return this.store.getters.tenant;
  }

  get breadcrumbItems(): BreadcrumbItems {
    const pageTitle = String(this.$route.meta.title) ?? "";
    return getBreadcrumbItems(pageTitle, this.tenant);
  }

  get tabItems(): TabItems {
    return getTabItems(this.tenant);
  }

  get headerInfo(): HeaderInfo {
    return {
      icon: "ion ion-md-people",
      section: this.tenant
        ? `${this.tenant.firstName} ${this.tenant.lastName}`
        : "",
      title: "Selling Places"
    };
  }
}
