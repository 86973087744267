<template>
  <div class="media align-items-baseline">
    <div>
      <i :class="contractCancellationReason.icon"></i>
    </div>
    <div class="media-body px-2">
      <p class="text-body mb-1 font-weight-bold">
        {{ contractCancellationReason.name }}
      </p>
      <small class="text-secondary">{{
        contractCancellationReason.description
      }}</small>
    </div>
    <div class="ml-auto">
      <i
        @click="editContractCancellationReason(contractCancellationReason)"
        @mouseover="isEditHovering = true"
        @mouseleave="isEditHovering = false"
        class="ion ion-md-create cursor-pointer"
        :class="{
          'text-primary': isEditHovering,
          'text-secondary': !isEditHovering
        }"
      ></i>
      <i
        v-if="contractCancellationReason.removable"
        @click="deleteContractCancellationReason(contractCancellationReason)"
        @mouseover="isDeleteHovering = true"
        @mouseleave="isDeleteHovering = false"
        class="ion ion-md-trash ml-2 cursor-pointer"
        :class="{
          'text-danger': isDeleteHovering,
          'text-secondary': !isDeleteHovering
        }"
      ></i>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import eventHub from "@/components/EventHub";
import ContractCancellationReasonDeleteRequestedEvent from "@/modules/contracts-cancellation-reason/events/ContractCancellationReasonDeleteRequestedEvent";
import { ContractCancellationReasonEditRequestedEvent } from "@/modules/contracts-cancellation-reason/events/ContractCancellationReasonEditRequestedEvent";
import { ContractCancellationReason } from "@/modules/contracts-cancellation-reason/types";

@Options({
  components: { Spinner },
  props: {
    contractCancellationReason: {
      type: Object,
      required: true
    }
  }
})
export default class ContractCancellationReasonListItemWidget extends Vue {
  contractCancellationReason!: ContractCancellationReason;
  isEditHovering = false;
  isDeleteHovering = false;

  editContractCancellationReason(
    contractCancellationReason: ContractCancellationReason
  ) {
    eventHub.emit<ContractCancellationReasonEditRequestedEvent>(
      ContractCancellationReasonEditRequestedEvent.Name,
      new ContractCancellationReasonEditRequestedEvent(
        contractCancellationReason.id
      )
    );
  }

  deleteContractCancellationReason(
    contractCancellationReason: ContractCancellationReason
  ) {
    eventHub.emit<ContractCancellationReasonDeleteRequestedEvent>(
      ContractCancellationReasonDeleteRequestedEvent.Name,
      new ContractCancellationReasonDeleteRequestedEvent(
        contractCancellationReason
      )
    );
  }
}
</script>
