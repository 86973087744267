
import { Options, Vue } from "vue-class-component";
import { Transaction } from "@/modules/transactions/types";

@Options({
  props: {
    transaction: {
      type: Object,
      required: true,
      default: {}
    }
  }
})
export default class TransactionListWidget extends Vue {
  transaction!: Transaction;

  transactionSelected() {
    this.$router.push({
      name: "administrator-transaction",
      params: {
        transactionId: this.transaction.id
      }
    });
  }
}
