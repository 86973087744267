<template>
  <Spinner :show-spinner="isDataLoading">
    <router-view></router-view>
  </Spinner>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import AsyncActionFactory from "@/components/AsyncAction";
import SellingPlaceApi from "@/modules/selling-places/api";
import { useRoute } from "vue-router";
import { MutationType } from "@/store/mutations";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import { FetchSellingPlaceParams } from "@/modules/selling-places/api/types";
import eventHub from "@/components/EventHub";
import { SellingPlace } from "@/modules/selling-places/types";

@Options({
  components: { Spinner },
  watch: {
    "$route.params.sellingPlaceId"(newSellingPlaceId) {
      if (newSellingPlaceId) {
        this.clearSellingPlace();
        this.loadData(newSellingPlaceId);
      }
    }
  }
})
export default class extends Vue {
  store = useStore();

  fetchSellingPlace = AsyncActionFactory(SellingPlaceApi.fetchSellingPlace);

  dataLoaded = false;

  async created() {
    await this.loadData(useRoute().params.sellingPlaceId);
    eventHub.on("SELLING_PLACE_EDITED", this.onSellingPlaceEdited);
    eventHub.on("SELLING_PLACE_DELETED", this.onSellingPlaceDeleted);
  }
  async onSellingPlaceEdited(sellingPlaceId) {
    if (this.store.getters.sellingPlace.id !== sellingPlaceId) return;

    await this.loadData(sellingPlaceId);
  }

  onSellingPlaceDeleted() {
    console.log('SELLING_PLACE_DELETED');

    this.$router.push({
      name: "administrator-selling-places"
    });
  }

  async loadData(sellingPlaceId) {
    await this.fetchSellingPlace.call(sellingPlaceId);

    if (this.fetchSellingPlace.isSuccessful) {
      this.store.commit(
        MutationType.SetSellingPlace,
        this.fetchSellingPlace.responseData
      );
      this.dataLoaded = true;
    }
  }

  clearSellingPlace() {
    this.store.commit(MutationType.SetSellingPlace, null);
  }

  beforeUnmount() {
    this.clearSellingPlace();
  }

  get isDataLoading(): boolean {
    return this.fetchSellingPlace.isBusy || !this.dataLoaded;
  }
}
</script>
