
import { Options, Vue } from "vue-class-component";
import { SellingPlace } from "@/modules/selling-place/types";
import eventHub from "@/components/EventHub";
import {SellingPlaceReport} from "@/modules/selling-place/types/SellingPlaceReport";

@Options({
  props: {
    sellingPlaceReport: {
      type: Object,
      required: true,
      default: {}
    }
  }
})
export default class SellingPlaceReportListItemWidget extends Vue {
  sellingPlaceReport!: SellingPlaceReport;
}
