<template>
  <WorkOrderDetailsPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import WorkOrderDetailsPage from "@/structure/Administrator/WorkOrders/pages/WorkOrderDetailsPage.vue";

@Options({
  components: { WorkOrderDetailsPage }
})
export default class WorkOrder extends Vue {}
</script>
