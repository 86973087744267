<template>
  <CreateModal
    v-if="displayDialog"
    @close="closeModal()"
    title="Novi Tip Prodajnog Objekta"
    @confirm="createSellingPlaceType()"
    :showSpinner="isDataLoading"
  >
    <SellingPlaceTypeForm
      v-model:selling-place-type="sellingPlaceType"
      :errors="postSellingPlaceTypeAction.errors"
    />
  </CreateModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";
import CreateModal from "@/modules/common/modals/CreateModal.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import SellingPlaceTypeApi from "@/modules/selling-place-type/api";
import SellingPlaceTypeForm from "@/modules/selling-place-type/forms/SellingPlaceTypeForm.vue";
import {
  FetchSellingPlaceType,
  NewSellingPlaceTypeRequestData,
  PostSellingPlaceTypeResponse
} from "@/modules/selling-place-type/api/types";
import SellingPlaceTypeNewRequestedEvent from "@/modules/selling-place-type/events/SellingPlaceTypeNewRequestedEvent";
import SellingPlaceTypeCreatedEvent from "@/modules/selling-place-type/events/SellingPlaceTypeCreatedEvent";

@Options({
  components: { CreateModal, SellingPlaceTypeForm }
})
export default class SellingPlaceTypeCreateRequestedListener extends Vue {
  displayDialog = false;
  sellingPlaceType: NewSellingPlaceTypeRequestData = this.createEmptySellingPlaceTypeRequestData();

  postSellingPlaceTypeAction = AsyncActionFactory(
    SellingPlaceTypeApi.createSellingPlaceType
  );

  fetchSellingPlaceType = AsyncActionFactory(
    SellingPlaceTypeApi.fetchSellingPlaceType as FetchSellingPlaceType
  );

  created() {
    eventHub.on<SellingPlaceTypeNewRequestedEvent>(
      SellingPlaceTypeNewRequestedEvent.Name,
      this.onSellingPlaceTypeNewRequested
    );
  }

  onSellingPlaceTypeNewRequested(event?: SellingPlaceTypeNewRequestedEvent) {
    if (!(event instanceof SellingPlaceTypeNewRequestedEvent)) {
      return;
    }
    this.displayDialog = true;
  }

  closeModal() {
    this.displayDialog = false;
  }

  async createSellingPlaceType() {
    await this.postSellingPlaceTypeAction.call(this.sellingPlaceType);

    if (this.postSellingPlaceTypeAction.isSuccessful) {
      await this.emitSellingPlaceTypeCreated(
        (this.postSellingPlaceTypeAction
          .responseData as PostSellingPlaceTypeResponse).id
      );
      this.closeModal();
    }
  }

  async emitSellingPlaceTypeCreated(sellingPlaceTypeId) {
    const sellingPlaceType = await this.fetchSellingPlaceType.call(
      sellingPlaceTypeId
    );

    if (this.fetchSellingPlaceType.isSuccessful) {
      eventHub.emit<SellingPlaceTypeCreatedEvent>(
        SellingPlaceTypeCreatedEvent.Name,
        new SellingPlaceTypeCreatedEvent(sellingPlaceType)
      );
    }
  }

  createEmptySellingPlaceTypeRequestData(): NewSellingPlaceTypeRequestData {
    return {
      name: "",
      description: "",
      icon: "",
      sellingArea: "",
      sellingBan: "",
      color: ""
    };
  }

  get isDataLoading(): boolean {
    return this.postSellingPlaceTypeAction.isBusy;
  }
}
</script>
