
import { Options, Vue } from "vue-class-component";
import EditOrganizationNameModal from "@/structure/Administrator/Settings/widgets/EditOrganizationNameModal.vue";
import { useStore } from "@/store";
import { inject } from "vue";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import { MutationType } from "@/store/mutations";
import {
  FetchSettings,
  FetchSettingsParams
} from "@/modules/settings/api/types";
import OrganizationLogoWidget from "@/structure/Administrator/Settings/widgets/OrganizationLogoWidget.vue";
import SettingsApi from "@/modules/settings/api";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import { Settings } from "@/modules/settings/types";

@Options({
  components: { EditOrganizationNameModal, OrganizationLogoWidget, Spinner }
})
export default class GeneralWidget extends Vue {
  store = useStore();

  showEditIcon = false;
  showEditOrganisationNameModal = false;

  fetchSettings = AsyncActionFactory(inject("fetchSettings") as FetchSettings);
  patchSettings = AsyncActionFactory(SettingsApi.patchSettings);
  removeSettingsLogo = AsyncActionFactory(SettingsApi.removeSettingsLogo);

  showSpinner = false;

  get settings(): Settings {
    return this.store.getters.settings;
  }

  editOrganizationName() {
    this.showEditOrganisationNameModal = true;
  }

  async closeEditOrganizationNameModal() {
    this.showEditOrganisationNameModal = false;
    await this.loadSettings();
  }

  async loadSettings() {
    await this.fetchSettings.call({
      extendWithSellingPlaceTypes: true,
      extendWithUserRoles: true,
      extendWithSellingPlaceCategories: true
    } as FetchSettingsParams);

    if (this.fetchSettings.isSuccessful) {
      this.store.commit(
        MutationType.SetSettings,
        this.fetchSettings.responseData
      );
    }
  }

  async onLogoChanged(logo: File) {
    this.showSpinner = true;
    const requestData = new FormData();
    requestData.append("organizationLogo", logo as Blob);

    await this.patchSettings.call(requestData);
    if (this.patchSettings.isSuccessful) {
      await this.loadSettings();
      this.showSpinner = false;
    }
  }

  async onLogoRemoved() {
    this.showSpinner = true;
    await this.removeSettingsLogo.call();
    if (this.removeSettingsLogo) {
      await this.loadSettings();
      this.showSpinner = false;
    }
  }
}
