
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import { Market } from "@/modules/market/types";
import { SellingPlace, SellingPlaces } from "@/modules/selling-place/types";
import FilterWidget from "@/modules/selling-place/widgets/SellingPlaceSidebar/FilterWidget.vue";

@Options({
  components: { FilterWidget },
  props: {
    sellingPlaces: {
      type: Array,
      required: true
    }
  },
  emits: ["filterChanged"]
})
export default class SellingPlaceListWidget extends Vue {
  sellingPlaces: SellingPlaces = [];

  get market(): Market {
    return useStore().getters.market;
  }

  selectSellingPlace(sellingPlace: SellingPlace) {
    this.$router.push({
      name: "cashier-selling-place",
      params: {
        sellingPlaceId: sellingPlace.id,
        marketId: this.market.id
      }
    });
  }

  onFilter(filter) {
    this.$emit("filterChanged", filter);
  }

  isSellingPlaceSelected(sellingPlace: SellingPlace): boolean {
    return this.$route.params.sellingPlaceId == sellingPlace.id;
  }
}
