import { SellingPlaceList } from "@/modules/selling-place/types/SellingPlaceList";

export const emptySellingPlaceList: SellingPlaceList = {
  pagination: {
    page: 1,
    lastPage: 1,
    perPage: 10,
    from: 1,
    to: 0,
    total: 0
  },
  data: []
};
