
import { Options, Vue } from "vue-class-component";
import TransactionsPage from "@/structure/Administrator/Transactions/pages/TransactionsPage.vue";
import TransactionApi from "@/modules/transactions/api";
import { provide } from "vue";
import { FetchTransactions } from "@/modules/transactions/api/types";

@Options({
  components: { TransactionsPage }
})
export default class Transactions extends Vue {
  created() {
    provide(
      "fetchTransactions",
      TransactionApi.fetchTransactions.bind(TransactionApi) as FetchTransactions
    );
  }
}
