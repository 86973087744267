
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    showSideBox: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
export default class HncfLayout extends Vue {
  showSideBox = false;
}
