
import { provide } from "vue";
import { Options, Vue } from "vue-class-component";
import MarketsHomePage from "../pages/MarketsHomePage.vue";
import MarketApi from "@/modules/market/api";
import { FetchMarkets } from "@/modules/market/api/types";

@Options({
  components: { MarketsHomePage }
})
export default class MarketsHome extends Vue {
  created() {
    provide(
      "fetchMarkets",
      MarketApi.fetchMarkets.bind(MarketApi) as FetchMarkets
    );
  }
}
