import { TabItem, TabItems } from "@/modules/common/types";
import { Tenant } from "@/modules/tenant/types";

function createHomeItem(tenant: Tenant): TabItem {
  return {
    name: "Početna",
    link: {
      name: "administrator-tenant-home",
      params: {
        tenantId: tenant.id
      }
    },
    active: false
  };
}

function createContractsItem(tenant: Tenant): TabItem {
  return {
    name: "Ugovori",
    link: {
      name: "administrator-tenant-contracts",
      params: {
        marketId: tenant.id
      }
    },
    active: false
  };
}

function createDetailsItem(tenant: Tenant): TabItem {
  return {
    name: "Detalji",
    link: {
      name: "administrator-tenant-details",
      params: {
        marketId: tenant.id
      }
    },
    active: false
  };
}

function createSellingPlacesItem(tenant: Tenant): TabItem {
  return {
    name: "Zakupljeni Prodajni Objekti",
    link: {
      name: "administrator-tenant-selling-places",
      params: {
        marketId: tenant.id
      }
    },
    active: false
  };
}

const getTabItems = function(tenant: Tenant): TabItems {
  const items: TabItems = [];

  if (!tenant) {
    return items;
  }

  // items.push(createHomeItem(tenant));
  items.push(createDetailsItem(tenant));
  items.push(createContractsItem(tenant));
  items.push(createSellingPlacesItem(tenant));

  return items;
};

export default getTabItems;
