<template>
  <PageLayout>
    <template v-slot:page-breadcrumbs>
      <Breadcrumbs :items="breadcrumbItems" />
    </template>
    <template v-slot:page-name>
      <PageHeaderInfoWidget :header-info="headerInfo" />
    </template>
    <template v-slot:page-tabs>
      <TabBarWidget :items="tabItems" />
    </template>

    <Spinner :show-spinner="isDataLoading">
      <div class="row" v-if="sellingPlace">
        <div class="col-lg">
          <SellingPlaceDetailsWidget
            :selling-place="sellingPlace"
            :selling-place-price="sellingPlacePrice"
            :reservation="reservation"
            :transaction="transaction"
          />
        </div>
      </div>
    </Spinner>
  </PageLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Tenant/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo, TabItems } from "@/modules/common/types";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
import { BreadcrumbItems } from "@/modules/common/types";
import getBreadcrumbItems from "../utils/getBreadcrumbItems";
import { useStore } from "@/store";
import TabBarWidget from "@/modules/common/widgets/TabBarWidget.vue";
import getTabItems from "@/structure/Administrator/Tenant/utils/getTabItems";
import { ContractItem } from "@/modules/contracts/types";
import { Transaction } from "@/modules/transactions/types";
import { SellingPlacePrice } from "@/modules/selling-place-price/types";
import { emptySellingPlacePrice } from "@/modules/selling-place-price/types/EmptySellingPlacePrice";
import { SellingPlace } from "@/modules/selling-place/types";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import SellingPlacePriceApi from "@/modules/selling-place-price/api";
import SellingPlaceApi from "@/modules/selling-place/api";
import TransactionApi from "@/modules/transactions/api";
import ContractApi from "@/modules/contracts/api";
import SellingPlaceDetailsWidget from "@/modules/selling-place/widgets/SellingPlaceDetailsWidget.vue";
import { emptySellingPlace } from "@/modules/selling-place/types/EmptySellingPlace";
import {FetchSellingPlaceDetailsParams} from "@/modules/selling-place/api/types";
import {PostTransactionRequestData} from "@/modules/transactions/api/types";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    Breadcrumbs,
    TabBarWidget,
    Spinner,
    SellingPlaceDetailsWidget
  },
  watch: {
    sellingPlace(newSellingPlace) {
      this.postTransactionData.marketId = newSellingPlace.market.id;
      this.postTransactionData.sellingPlaceId = newSellingPlace.id;
      this.getTodayTransaction();
      this.loadPrices();
      this.fetchReservation();
    }
  }
})
export default class SellingPlacesPage extends Vue {
  store = useStore();
  reservation: ContractItem | null = null;
  transaction: Transaction | null = null;
  sellingPlacePrice: SellingPlacePrice = emptySellingPlacePrice;
  sellingPlace: SellingPlace = emptySellingPlace;
  postTransactionData: PostTransactionRequestData = {
    marketId: "",
    sellingPlaceId: "",
    tenantShow: true
  };

  fetchSellingPlace = AsyncActionFactory(
    SellingPlaceApi.fetchSellingPlaceDetails
  );
  fetchSellingPlacePriceAction = AsyncActionFactory(
    SellingPlacePriceApi.fetchSellingPlacePrice
  );
  fetchContractItemAction = AsyncActionFactory(ContractApi.fetchContractItem);
  fetchTodayTransaction = AsyncActionFactory(
    TransactionApi.fetchTodayTransaction
  );

  created() {
    this.getSellingPlace();
  }

  get tenant() {
    return this.store.state.tenant;
  }

  get headerInfo(): HeaderInfo {
    return {
      icon: "ion ion-md-cube",
      section: this.sellingPlace ? this.sellingPlace.name : "",
      title: this.sellingPlace ? this.sellingPlace.name : "",
      description: this.sellingPlace ? this.sellingPlace.name : ""
    };
  }

  get tabItems(): TabItems {
    return getTabItems(this.tenant);
  }

  get breadcrumbItems(): BreadcrumbItems {
    return getBreadcrumbItems("Selling Place", this.tenant);
  }

  get isDataLoading() {
    return (
      this.fetchSellingPlace.isBusy ||
      this.fetchSellingPlacePriceAction.isBusy ||
      this.fetchContractItemAction.isBusy ||
      this.fetchTodayTransaction.isBusy
    );
  }

  async getSellingPlace() {
    await this.fetchSellingPlace.call({
      sellingPlaceId: this.$route.params.sellingPlaceId
    } as FetchSellingPlaceDetailsParams);

    if (this.fetchSellingPlace.isSuccessful) {
      this.sellingPlace = this.fetchSellingPlace.responseData as SellingPlace;
    }
  }

  async getTodayTransaction() {
    await this.fetchTodayTransaction.call(this.sellingPlace.id);

    if (this.fetchTodayTransaction.isSuccessful) {
      if (Object.keys(this.fetchTodayTransaction.responseData).length > 0) {
        this.transaction = this.fetchTodayTransaction
          .responseData as Transaction;
      }
    }
  }

  async loadPrices() {
    await this.fetchSellingPlacePriceAction.call(
      this.sellingPlace.market.id,
      this.sellingPlace.category.id,
      this.sellingPlace.type.id
    );

    if (this.fetchSellingPlacePriceAction.isSuccessful) {
      this.sellingPlacePrice = this.fetchSellingPlacePriceAction
        .responseData as SellingPlacePrice;
    }
  }

  async fetchReservation() {
    await this.fetchContractItemAction.call(
      this.postTransactionData.sellingPlaceId
    );

    if (this.fetchContractItemAction.isSuccessful) {
      if (Object.keys(this.fetchContractItemAction.responseData).length > 0) {
        this.reservation = this.fetchContractItemAction
          .responseData as ContractItem;
      } else {
        this.reservation = null;
      }
    }
  }
}
</script>
