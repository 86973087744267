<template>
  <SidebarLayout>
    <template v-slot:sidebar>
      <SellingPlaceSidebar />
    </template>

    <router-view></router-view>
  </SidebarLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import SidebarLayout from "@/modules/common/layouts/SidebarLayout.vue";
import SellingPlaceSidebar from "@/modules/selling-place/widgets/SellingPlaceSidebar/SellingPlaceSidebar.vue";

@Options({
  components: { SidebarLayout, SellingPlaceSidebar }
})
export default class extends Vue {}
</script>
