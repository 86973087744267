<template>
  <DeleteTenantRequestListener />
  <EditTenantDetailsRequestListener />
  <CreateTenantRequestListener />
  <EditTenantAddressRequestListener />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import DeleteTenantRequestListener from "./listeners/DeleteTenantRequestListener.vue";
import EditTenantDetailsRequestListener from "./listeners/EditTenantDetailsRequestListener.vue";
import CreateTenantRequestListener from "@/modules/tenant/listeners/CreateTenantRequestListener.vue";
import EditTenantAddressRequestListener from "@/modules/tenant/listeners/EditTenantAddressRequestListener.vue";

@Options({
  components: {
    DeleteTenantRequestListener,
    EditTenantDetailsRequestListener,
    CreateTenantRequestListener,
    EditTenantAddressRequestListener
  }
})
export default class Main extends Vue {}
</script>
