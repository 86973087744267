<template>
  <h5>General</h5>
  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Identifikator
        </div>
        <div>
          {{ transaction.id }}
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Naziv
        </div>
        <div>
          {{ transaction.name }}
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Datum Kreiranja
        </div>
        <div>
          {{ transaction.created_at }}
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          Ukupan iznos
        </div>
        <div>
          {{ transaction.total / 100 }}
        </div>
      </div>
      <br>
      <h5>Stavke</h5>
      <TransactionItemDetailsWidget
        v-for="(item, key) in transaction.items"
        :key="'item' + key"
        :transaction-item="item"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Transaction } from "../types";
import TransactionItemDetailsWidget from "@/modules/transactions/widgets/TransactionItemDetailsWidget.vue";

@Options({
  props: {
    transaction: {
      type: Object,
      required: true
    }
  },
  components: { TransactionItemDetailsWidget }
})
export default class TransactionDetailsWidget extends Vue {
  transaction!: Transaction;
}
</script>
