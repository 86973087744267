
import { Options, Vue } from "vue-class-component";
import { Contract } from "@/modules/contracts/types";
import ContractStatusWidget from "../ContractStatusWidget.vue";
import eventHub from "@/components/EventHub";

@Options({
  components: { ContractStatusWidget },
  props: {
    contract: {
      type: Object,
      required: true,
      default: {}
    }
  },
  emits: ["contractSelected"]
})
export default class ContractListItemWidget extends Vue {
  contract!: Contract;

  contractSelected() {
    this.$emit("contractSelected", this.contract.id);
  }
  contractCancel() {
      eventHub.emit("CREATE_CONTRACT_CANCEL_REQUEST", this.contract.id);
  }
  contractDelete() {
    console.log('emit contract DDDD', this.contract)
    eventHub.emit("DELETE_CONTRACT_REQUESTED", this.contract);

    // if(confirm(`Da li želite da obrišete ugovor ${this.contract.number}?`)) {
      // axios.delete('/api/artist/'+id)
      //         .then(resp => {
      //           this.artists.data.splice(index, 1);
      //         })
      //         .catch(error => {
      //           console.log(error);
      //         })
    // }
    // this.$emit("contractCancel", this.contract.id);
  }

}
