
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    hideContentDivider: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
export default class extends Vue {
  hideContentDivider = false;
}
