import { Address } from "@/modules/common/types";
import { emptyAddress } from "@/modules/common/types/EmptyAddress";

import { UserRole } from "./UserRole";

export default class User {
  public id = "";
  public email = "";
  public firstName = "";
  public lastName = "";
  public active = false;
  public imageUrl = "";
  public roles: Array<UserRole> = [];
  public address: Address = emptyAddress;

  public hasRole(role: UserRole) {
    return this.roles.indexOf(role) > -1;
  }

  public getName() {
    return this.firstName + " " + this.lastName;
  }
}
