
import { Options, Vue } from "vue-class-component";
import { UserDetails } from "@/modules/user/types";
import AddressDetailsWidget from "@/modules/common/widgets/AddressDetailsWidget.vue";

@Options({
  props: {
    user: {
      required: true
    }
  },

  components: { AddressDetailsWidget }
})
export default class UserDetailsWidget extends Vue {
  user!: UserDetails;
}
