
import { Options, Vue } from "vue-class-component";
import ProfileGeneralPage from "../pages/ProfileGeneralPage.vue";

@Options({
  components: {
    ProfileGeneralPage
  }
})
export default class extends Vue {}
