
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Cashier/SellingPlace/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo, TabItems } from "@/modules/common/types";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
import { BreadcrumbItems } from "@/modules/common/types";
import getBreadcrumbItems from "../utils/getBreadcrumbItems";
import { useStore } from "@/store";
import HeaderWidgets from "@/structure/Cashier/SellingPlace/widgets/HeaderWidgets.vue";
import { Market } from "@/modules/market/types";
import getTabItems from "@/structure/Cashier/SellingPlace/utils/getTabItems";
import AddressDetailsWidget from "@/modules/common/widgets/AddressDetailsWidget.vue";
import TabBarWidget from "@/modules/common/widgets/TabBarWidget.vue";
import { SellingPlaceList } from "@/modules/selling-place/types";
import { emptySellingPlaceList } from "@/modules/selling-place/types/EmptySellingPlaceList";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import SellingPlaceApi from "@/modules/selling-place/api";
import {
  FetchSellingPlaces,
  FetchSellingPlacesParams
} from "@/modules/selling-place/api/types";
import SpinnerWidget from "@/modules/common/widgets/SpinnerWidget.vue";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    Breadcrumbs,
    HeaderWidgets,
    TabBarWidget,
    AddressDetailsWidget,
    SpinnerWidget
  }
})
export default class SellingPlacesPage extends Vue {
  store = useStore();
  sellingPlaceList: SellingPlaceList = emptySellingPlaceList;
  fetchSellingPlaceList = AsyncActionFactory(
    SellingPlaceApi.fetchSellingPlaces as FetchSellingPlaces
  );

  created() {
    this.loadData();
  }

  async loadData() {
    await this.fetchSellingPlaceList.call({
      marketId: this.market.id,
      page: 1,
      perPage: 99999
    } as FetchSellingPlacesParams);

    if (this.fetchSellingPlaceList.isSuccessful) {
      this.sellingPlaceList = this.fetchSellingPlaceList
        .responseData as SellingPlaceList;
    }
  }

  getTransform(x, y) {
    return "transform: translate(" + x + "px, " + y + "px);";
  }

  async onSellingPlaceSelected(sellingPlaceId) {
    await this.$router.push({
      name: "cashier-selling-place-home",
      params: {
        marketId: this.market.id,
        sellingPlaceId: sellingPlaceId
      }
    });
  }

  get isDataLoading(): boolean {
    return this.fetchSellingPlaceList.isBusy;
  }

  get market(): Market {
    return this.store.getters.market;
  }

  get headerInfo(): HeaderInfo {
    return {
      icon: this.market ? this.market.icon : "ion ion-md-cube",
      section: this.market ? this.market.name : "",
      title: "Info",
      description: this.market ? this.market.description : ""
    };
  }

  get tabItems(): TabItems {
    return getTabItems(this.market);
  }

  get breadcrumbItems(): BreadcrumbItems {
    return getBreadcrumbItems("", this.market);
  }
}
