import eventHub from "@/components/EventHub";
import AsyncActionFactory from "@/components/AsyncAction";
import ContractApi from "@/modules/contracts/api";
import {
  PostContract,
  PostContractResponseData
} from "@/modules/contracts/api/types";
import { useRouter } from "@/components/Router";

export default class ContractCreateEventListener {
  constructor() {
    eventHub.on("CONTRACT_CREATE_REQUESTED", this.onContractCreateRequested);
  }

  async onContractCreateRequested() {
    await useRouter().push({
      name: "administrator-contracts-new"
    });
    // const postContract = AsyncActionFactory(
    //   ContractApi.postContract as PostContract
    // );
    // await postContract.call(tenantId);
    //
    // if (postContract.isSuccessful) {
    //   const contractId = (postContract.responseData as PostContractResponseData)
    //     .id;
    //   eventHub.emit("CONTRACT_EDIT_REQUESTED", contractId);
    // }
  }
}
