import { BreadcrumbItem, BreadcrumbItems } from "@/modules/common/types";

function createUserHomeItem(): BreadcrumbItem {
  return {
    name: "Aleksandar Sabo",
    link: {
      name: "profile",
      params: {
        userId: "1"
      }
    },
    icon: "ion ion-md-person"
  };
}

function createModuleItem(moduleName: string): BreadcrumbItem {
  return {
    name: moduleName
  } as BreadcrumbItem;
}

const getBreadcrumbItems = function(
  moduleName?: string,
  moduleBreadcrumbItems?: BreadcrumbItems
) {
  const items: BreadcrumbItems = [];

  items.push(createUserHomeItem());

  if (moduleBreadcrumbItems) {
    moduleBreadcrumbItems.forEach(function(breadcrumbItem: BreadcrumbItem) {
      items.push(breadcrumbItem);
    });
  }

  if (moduleName) {
    items.push(createModuleItem(moduleName));
  }

  return items;
};

export default getBreadcrumbItems;
