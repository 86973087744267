<template>
  <div>
    <span class="footer-text font-weight-bolder">Pijaca</span>
    ©
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class Footer extends Vue {}
</script>
