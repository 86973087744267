import { CustomErrorContext } from "../types";

/**
 * Custom Error is a base class to be used for the customization of errors.
 */
abstract class CustomError extends Error {
  private _logMessage: string;
  private _context: CustomErrorContext;

  /**
   * @param errorMessage    The message to be shown to users
   * @param logMessage      The actual error message for our internal usage (logging, debugging, etc.)
   * @param context         (optional) Any additional data on error (key: value
   */
  constructor(
    errorMessage: string,
    logMessage: string | null = null,
    context: CustomErrorContext = null
  ) {
    super(errorMessage);

    // Set the prototype explicitly to fix inheritance problems w/ built-in types
    Object.setPrototypeOf(this, CustomError.prototype);
    this.name = this.constructor.name;

    this._logMessage = logMessage === null ? errorMessage : logMessage;
    this._context = context;
  }

  get publicMessage() {
    return this.message;
  }

  get logMessage() {
    return this._logMessage;
  }

  get context() {
    return this._context;
  }

  get previousError() {
    return this.getFromContext("previousError");
  }

  getFromContext(key: string) {
    return this._context && this._context[key] ? this._context[key] : null;
  }
}

export default CustomError;
