<template>
  <span>{{ formattedDate }}</span>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { formatDate } from "@/modules/localisation/helpers/date-helpers";

@Options({
  props: {
    date: {
      type: Date,
      required: true
    }
  }
})
export default class DateFormatter extends Vue {
  date!: Date;

  get formattedDate(): string {
    return formatDate(this.date);
  }
}
</script>
