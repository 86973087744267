
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import { inject } from "vue";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import ListWidget from "@/modules/common/widgets/ListWidget";
import UserListItemWidget from "./UserListItemWidget.vue";
import { FetchUser, FetchUsers, FetchUsersParams } from "../../api/types";
import { User, UserList } from "@/modules/user/types";
import { emptyUserList } from "@/modules/user/types/emptyUserList";
import eventHub from "@/components/EventHub";
import UserCreatedEvent from "@/modules/user/events/UserCreatedEvent";
import UserUpdatedEvent from "@/modules/user/events/UserUpdatedEvent";
import UserDeletedEvent from "@/modules/user/events/UserDeletedEvent";
import UserPasswordEditedEvent from "@/modules/user/events/UserPasswordEditedEvent";

@Options({
  components: { Spinner, ListWidget, UserListItemWidget }
})
export default class UserListWidget extends Vue {
  userList: UserList = emptyUserList;
  searchTerm = "";
  page = 1;

  fetchUsers = AsyncActionFactory(inject("fetchUsers") as FetchUsers);
  fetchUser = AsyncActionFactory(inject("fetchUser") as FetchUser);

  created() {
    this.loadData();
    eventHub.on<UserDeletedEvent>(UserDeletedEvent.Name, this.onUserDeleted);
    eventHub.on<UserCreatedEvent>(UserCreatedEvent.Name, this.onUserCreated);
    eventHub.on<UserUpdatedEvent>(UserUpdatedEvent.Name, this.onUserEdited);
    eventHub.on<UserPasswordEditedEvent>(
      UserPasswordEditedEvent.Name,
      this.loadData
    );
  }

  onUserDeleted(event?: UserDeletedEvent) {
    if (!(event instanceof UserDeletedEvent)) {
      return;
    }

    this.removeUserFromList(event.getUser());
  }

  onUserCreated(event?: UserCreatedEvent) {
    if (!(event instanceof UserCreatedEvent)) {
      return;
    }

    this.addUserToList(event.getUser());
  }

  onUserEdited(event?: UserUpdatedEvent) {
    if (!(event instanceof UserUpdatedEvent)) {
      return;
    }

    this.replaceUserInList(event.getUser());
  }

  async loadData() {
    await this.fetchUsers.call({
      page: this.page,
      perPage: 20,
      searchTerm: this.searchTerm
    } as FetchUsersParams);

    if (this.fetchUsers.isSuccessful) {
      this.userList = this.fetchUsers.responseData as UserList;
    }
  }

  removeUserFromList(user: User) {
    this.userList.data = this.userList.data.filter(
      listUser => listUser.id !== user.id
    );

    this.userList.pagination.total -= 1;
  }

  replaceUserInList(user: User) {
    this.userList.data.forEach((item, i) => {
      if (item.id == user.id) {
        this.userList.data[i] = user;
      }
    });
  }

  addUserToList(user: User) {
    this.userList.data.push(user);
    this.userList.pagination.total += 1;
  }

  async loadUser(id: string) {
    await this.fetchUser.call(id);

    if (this.fetchUser.isSuccessful) {
      return this.fetchUser.responseData as User;
    }
  }

  get isDataLoading(): boolean {
    return this.fetchUsers.isBusy;
  }

  onPaginate(page) {
    this.page = page;
    this.loadData();
  }
}
