<template>
  <ConfirmDeleteModal
    v-if="displayDialog"
    @close="closeDeleteModal()"
    @confirm="deleteUser()"
    title="Brisanje Korisnika"
    description="Da li ste sigurni da želite da obrišete korisnika?"
    :showSpinner="isDataLoading"
  />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ConfirmDeleteModal from "@/modules/common/modals/ConfirmDeleteModal.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import UserApi from "@/modules/user/api";
import eventHub from "@/components/EventHub";
import { User } from "../types";
import UserDeleteRequestedEvent from "@/modules/user/events/UserDeleteRequestedEvent";
import UserDeletedEvent from "@/modules/user/events/UserDeletedEvent";

@Options({
  components: { ConfirmDeleteModal }
})
export default class DeleteUserListener extends Vue {
  user!: User;

  displayDialog = false;

  deleteUserAction = AsyncActionFactory(UserApi.deleteUser);

  created() {
    eventHub.on<UserDeleteRequestedEvent>(
      UserDeleteRequestedEvent.Name,
      this.onUserDeleteRequested
    );
  }

  onUserDeleteRequested(event?: UserDeleteRequestedEvent) {
    if (!(event instanceof UserDeleteRequestedEvent)) {
      return;
    }

    this.user = event.getUser();
    this.displayDialog = true;
  }

  async deleteUser() {
    await this.deleteUserAction.call(this.user.id);

    if (this.deleteUserAction.isSuccessful) {
      eventHub.emit<UserDeletedEvent>(
        UserDeletedEvent.Name,
        new UserDeletedEvent(this.user)
      );
      this.closeDeleteModal();
    }
  }

  closeDeleteModal() {
    this.displayDialog = false;
  }

  get isDataLoading(): boolean {
    return this.deleteUserAction.isBusy;
  }
}
</script>
