
import { Options, Vue } from "vue-class-component";
import Modal from "@/modules/common/modals/Modal.vue";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";

@Options({
  components: { Modal, Spinner },
  props: {
    title: {
      type: String,
      required: true
    },
    showSpinner: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["close", "confirm"]
})
export default class CreateModal extends Vue {
  title!: string;
  showSpinner!: boolean;

  close() {
    this.$emit("close");
  }

  confirm() {
    this.$emit("confirm");
  }
}
