
import { Options, Vue } from "vue-class-component";
import SettingsPage from "@/structure/Administrator/Settings/pages/SettingsPage.vue";
import { provide } from "vue";
import SettingsApi from "@/modules/settings/api";
import { FetchSettings } from "@/modules/settings/api/types";

@Options({
  components: { SettingsPage }
})
export default class Settings extends Vue {
  created() {
    provide("fetchSettings", SettingsApi.fetchSettings as FetchSettings);
  }
}
