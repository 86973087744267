<template>
  <Spinner :show-spinner="isDataLoading">
    <ListWidget
      :list="workOrderList"
      :hide-pagination="!workOrderList.data.length"
      @paginate="onPaginate($event)"
    >
      <template v-slot:item-widget="slotProps">
        <WorkOrderListItemWidget
          :workOrder="slotProps.item"
          @work-order-selected="onWorkOrderSelected($event)"
        />
      </template>
      <template v-slot:no-data>
        <div class="row mb-3">
          <div class="col text-center">
            <h1>Nema Kreiranih Radnih Naloga</h1>
          </div>
        </div>
      </template>
    </ListWidget>
  </Spinner>
</template>

<script lang="ts">
import { inject } from "vue";
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import ListWidget from "@/modules/common/widgets/ListWidget";
import WorkOrderListItemWidget from "@/modules/work-orders/widgets/WorkOrderListItemWidget.vue";
import {
  FetchWorkOrders,
  FetchWorkOrdersParams
} from "@/modules/work-orders/api/types";
import { emptyWorkOrderList } from "@/modules/work-orders/types/EmptyWorkOrderList";
import { WorkOrderList } from "@/modules/work-orders/types";

@Options({
  components: { Spinner, ListWidget, WorkOrderListItemWidget },
  emits: ["workOrderSelected"]
})
export default class WorkOrderListWidget extends Vue {
  workOrderList: WorkOrderList = emptyWorkOrderList;
  page = 1;
  dataLoaded = false;

  fetchWorkOrdersAction = AsyncActionFactory(
    inject("fetchWorkOrders") as FetchWorkOrders
  );

  async created() {
    await this.loadData();
    this.dataLoaded = true;
  }

  async loadData() {
    await this.fetchWorkOrdersAction.call({
      page: this.page,
      perPage: 30
    } as FetchWorkOrdersParams);

    if (this.fetchWorkOrdersAction.isSuccessful) {
      this.workOrderList = this.fetchWorkOrdersAction
        .responseData as WorkOrderList;
    }
  }

  get isDataLoading(): boolean {
    return this.fetchWorkOrdersAction.isBusy;
  }

  onPaginate(page) {
    this.page = page;
    this.loadData();
  }

  onWorkOrderSelected(workOrderId: string) {
    this.$emit("workOrderSelected", workOrderId);
  }
}
</script>
