<template>
  <Spinner :show-spinner="isDataLoading">
    <FilterWidget :filter="filter" @search="onSearch" />
    <ListWidget
      :list="sellingPlaceList"
      :hide-pagination="!sellingPlaceList.data.length"
      @paginate="onPaginate($event)"
    >
      <template v-slot:item-widget="slotProps">
        <SellingPlaceListItemWidget :sellingPlace="slotProps.item" />
      </template>
      <template v-slot:no-data>
        <div class="row mb-3">
          <div class="col text-center">
            <h1>Nema prodajnih mesta</h1>
          </div>
        </div>
      </template>
    </ListWidget>
  </Spinner>
</template>

<script lang="ts">
import { inject } from "vue";
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import ListWidget from "@/modules/common/widgets/ListWidget";
import SellingPlaceListItemWidget from "@/modules/selling-places/widgets/SellingPlaceList/SellingPlaceListItemWidget.vue";
import {
  FetchSellingPlaces,
  FetchSellingPlacesParams,
  SellingPlaceListFilter
} from "@/modules/selling-places/api/types";
import { emptySellingPlaceList } from "@/modules/selling-places/types/EmptySellingPlaceList.ts";
import { SellingPlaceList } from "@/modules/selling-places/types";
import FilterWidget from "@/modules/selling-places/widgets/SellingPlaceList/FilterWidget.vue";
import { emptySellingPlaceFilter } from "@/modules/selling-place/types/EmptySellingPlaceFilter";
import { useRoute } from "vue-router";

@Options({
  components: { Spinner, ListWidget, SellingPlaceListItemWidget, FilterWidget }
})
export default class SellingPlaceListWidget extends Vue {
  sellingPlaceList: SellingPlaceList = emptySellingPlaceList;

  filter: SellingPlaceListFilter = emptySellingPlaceFilter;

  page = 1;

  route = useRoute();

  types: Array<string> = [];

  fetchSellingPlacesAction = AsyncActionFactory(
    inject("fetchSellingPlaces") as FetchSellingPlaces
  );

  async created() {
    await this.loadData();
  }

  async loadData() {
    await this.fetchSellingPlacesAction.call({
      page: this.page,
      perPage: 30,
      searchTerm: this.filter.searchTerm,
      type: this.filter.typeId,
      category: this.filter.categoryId,
      market: this.filter.marketId
    } as FetchSellingPlacesParams);

    if (this.fetchSellingPlacesAction.isSuccessful) {
      this.sellingPlaceList = this.fetchSellingPlacesAction
        .responseData as SellingPlaceList;
    }
  }

  get isDataLoading(): boolean {
    return this.fetchSellingPlacesAction.isBusy;
  }

  onPaginate(page) {
    this.page = page;
    this.loadData();
  }

  async onSearch(filter) {
    this.filter = filter;
    await this.loadData();
  }
}
</script>
