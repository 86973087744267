<template>
  <ContractOrganizationDetailsPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ContractOrganizationDetailsPage from "@/structure/Administrator/Contracts/pages/ContractOrganizationDetailsPage.vue";

@Options({
  components: { ContractOrganizationDetailsPage }
})
export default class Tenant extends Vue {}
</script>
