import { RouteRecordRaw } from "vue-router";
import PassThroughViewTenant from "@/structure/Tenant/widgets/PassThroughViewTenant.vue";
import TenantHome from "@/structure/Tenant/views/TenantHome.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/tenant",
    name: "tenant",
    component: PassThroughViewTenant,
    children: [
      {
        path: "",
        name: "tenant-homepage",
        component: TenantHome,
        meta: {
          title: "Homepage"
        }
      }
    ]
  }
];

export default routes;
