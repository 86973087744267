import {SellingPlace} from "@/modules/selling-place/types/SellingPlace";

export const emptySellingPlace: SellingPlace = {
    id: "",
    name: "",
    type: {
        id: "",
        name: "",
        description: "",
        icon: "",
        removable: true,
        sellingArea: "",
        sellingBan: "",
        color: "blue"
    },
    category: {
        id: "",
        name: "",
        description: "",
        icon: "",
        removable: true,
    },
    market: {
        id: "",
        name: "",
        address: {
            line1: "",
            line2: "",
            postalCode: "",
            city: "",
            country: "",
            state: ""
        },
        description: "",
        icon: "",
    }
}