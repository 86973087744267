
import { Options, Vue } from "vue-class-component";
import TextInput from "@/modules/common/forms/TextInput.vue";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import SellingPlaceApi from "@/modules/selling-places/api";
import { useRouter } from "@/components/Router";
import { PostSellingPlaceRequestData } from "@/modules/selling-places/api/types";
import { MarketList } from "@/modules/market/types";
import { emptyMarketList } from "@/modules/market/types/EmptyMarketList";
import { SellingPlaceTypeList } from "@/modules/selling-place-type/types";
import { emptySellingPlaceTypeList } from "@/modules/selling-place-type/types/EmptySellingPlaceTypeList";
import { SellingPlaceCategoryList } from "@/modules/selling-place-category/types";
import { emptySellingPlaceCategoryList } from "@/modules/selling-place-category/types/EmptySellingPlaceCategoryList";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import MarketApi from "@/modules/market/api";
import SellingPlaceTypeApi from "@/modules/selling-place-type/api";
import SellingPlaceCategoryApi from "@/modules/selling-place-category/api";
import { FetchMarketsParams } from "@/modules/market/api/types";
import { FetchSellingPlaceTypesParams } from "@/modules/selling-place-type/api/types";
import { FetchSellingPlaceCategoriesParams } from "@/modules/selling-place-category/api/types";

@Options({
  components: { TextInput },
  props: {
    validationErrors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  }
})
export default class NewSellingPlaceForm extends Vue {
  validationErrors!: BackendValidationErrors;
  postSellingPlaceData: PostSellingPlaceRequestData = {
    name: "",
    marketId: "",
    sellingPlaceTypeId: "",
    sellingPlaceCategoryId: ""
  };

  marketList: MarketList = emptyMarketList;
  sellingPlaceTypeList: SellingPlaceTypeList = emptySellingPlaceTypeList;
  sellingPlaceCategoryList: SellingPlaceCategoryList = emptySellingPlaceCategoryList;

  fetchMarketsAction = AsyncActionFactory(MarketApi.fetchMarkets);
  fetchSellingPlaceTypeAction = AsyncActionFactory(
    SellingPlaceTypeApi.fetchSellingPlaceTypes
  );
  fetchSellingPlaceCategoryAction = AsyncActionFactory(
    SellingPlaceCategoryApi.fetchSellingPlaceCategories
  );
  postSellingPlace = AsyncActionFactory(SellingPlaceApi.postSellingPlace);

  created() {
    this.fetchMarkets();
    this.fetchSellingPlaceTypes();
    this.fetchSellingPlaceCategories();
  }

  async fetchMarkets() {
    await this.fetchMarketsAction.call({
      perPage: -1
    } as FetchMarketsParams);

    if (this.fetchMarketsAction.isSuccessful) {
      const response = this.fetchMarketsAction.responseData as MarketList;
      if (response.data.length > 0) {
        this.marketList = response;
      }
    }
  }
  async fetchSellingPlaceTypes() {
    await this.fetchSellingPlaceTypeAction.call({
      perPage: -1
    } as FetchSellingPlaceTypesParams);

    if (this.fetchSellingPlaceTypeAction.isSuccessful) {
      const response = this.fetchSellingPlaceTypeAction
        .responseData as SellingPlaceTypeList;
      if (response.data.length > 0) {
        this.sellingPlaceTypeList = response;
      }
    }
  }
  async fetchSellingPlaceCategories() {
    await this.fetchSellingPlaceCategoryAction.call({
      perPage: -1
    } as FetchSellingPlaceCategoriesParams);

    if (this.fetchSellingPlaceCategoryAction.isSuccessful) {
      const response = this.fetchSellingPlaceCategoryAction
        .responseData as SellingPlaceCategoryList;
      if (response.data.length > 0) {
        this.sellingPlaceCategoryList = response;
      }
    }
  }

  async create() {
    await this.postSellingPlace.call(this.postSellingPlaceData);

    if (this.postSellingPlace.isSuccessful) {
      await useRouter().push({
        name: "administrator-selling-places"
      });
    }
  }

  async cancel() {
    await useRouter().push({
      name: "administrator-selling-places"
    });
  }
}
