import { RouteRecordRaw } from "vue-router";
import PassThroughViewSellingPlaces from "../widgets/PassThroughViewSellingPlaces.vue";
import SellingPlaces from "@/structure/Cashier/SellingPlace/views/SellingPlaces.vue";
import sellingPlaceRoutes from "./selling-place";

const routes: Array<RouteRecordRaw> = [
  {
    path: "selling-places",
    name: "cashier-selling-places",
    component: PassThroughViewSellingPlaces,
    redirect: to => ({
      name: "cashier-selling-places-home",
      params: {
        marketId: to.params.marketId
      }
    }),
    children: [
      {
        path: "",
        name: "cashier-selling-places-home",
        component: SellingPlaces,
        meta: {
          title: "Selling Places"
        }
      },
      ...sellingPlaceRoutes
    ]
  }
];

export default routes;
