<template>
  <AppHcfLayout>
    <router-view></router-view>
  </AppHcfLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AppHcfLayout from "@/structure/common/layout/AppHcfLayout.vue";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
import { UserRole, User } from "@/modules/user/types";

@Options({
  components: { AppHcfLayout },
  watch: {
    "$route.name"() {
      this.rerouteUser();
    }
  }
})
export default class extends Vue {
  store = useStore();
  router = useRouter();
  route = useRoute();

  async created() {
    await this.rerouteUser();
  }

  async rerouteUser() {
    if (this.route.name !== "tenant") return;
    if (!this.user.hasRole(UserRole.Tenant)) {
      await this.router.push({
        name: "homepage"
      });
      return;
    }

    if (this.route.name === "tenant") {
      await this.router.push({
        name: "tenant-homepage"
      });
    }
  }

  get user(): User {
    return this.store.getters.user;
  }
}
</script>
