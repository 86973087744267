import { RouteRecordRaw } from "vue-router";
import Transactions from "@/structure/Administrator/Transactions/views/Transactions.vue";
import PassThroughViewTransactions from "@/structure/Administrator/Transactions/widgets/PassThroughViewTransactions.vue";
import NewTransaction from "@/structure/Administrator/Transactions/views/NewTransaction.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "transactions",
    name: "administrator-transactions",
    component: PassThroughViewTransactions,
    redirect: () => ({
      name: "administrator-transactions-homepage",
      params: {}
    }),
    children: [
      {
        path: "",
        name: "administrator-transactions-homepage",
        component: Transactions,
        meta: {
          title: "Transakcije"
        }
      },
      {
        path: "new",
        name: "administrator-transactions-new",
        component: NewTransaction,
        meta: {
          title: "Nova"
        }
      }
    ]
  }
];

export default routes;
