
import { Options, Vue } from "vue-class-component";
import { TabItem, TabItems } from "@/modules/common/types";

@Options({
  props: {
    items: {
      required: true,
      default: []
    }
  },
  watch: {
    items() {
      this.onItemsUpdated();
    }
  }
})
export default class extends Vue {
  items!: TabItems;

  updateActiveTab(activeRouteName: string) {
    this.items.forEach(function(item: TabItem) {
      item.active = item.link.name == activeRouteName;
    });
  }

  created() {
    this.onItemsUpdated();
  }

  onItemsUpdated() {
    this.updateActiveTab(this.$route.name as string);
  }
}
