
import { Vue } from "vue-class-component";
import { useStore } from "@/store";
import { SellingPlace } from "@/modules/selling-places/types";
import eventHub from "@/components/EventHub";

export default class HeaderWidgetsSellingPlace extends Vue {
  store = useStore();

  get sellingPlace(): SellingPlace {
    return this.store.getters.sellingPlace;
  }

  editSellingPlaceDetails(): void {
    eventHub.emit("EDIT_SELLING_PLACE_DETAILS_REQUESTED", this.sellingPlace.id);
  }

  deleteSellingPlace(): void {
    // alert("Nema brisanja");
    eventHub.emit("DELETE_SELLING_PLACE_REQUESTED", this.sellingPlace);
  }
}
