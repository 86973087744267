
import { Options, Vue } from "vue-class-component";
import { TenantsFilter } from "@/modules/tenant/api/types";

@Options({
  emits: ["filterChanged"]
})
export default class FilterWidget extends Vue {
  searchTerm = "";

  onInput() {
    this.$emit("filterChanged", this.createFilter());
  }

  createFilter(): TenantsFilter {
    return {
      searchTerm: this.searchTerm
    };
  }
}
