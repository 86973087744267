import ApiClient from "@/components/ApiClient/ApiClient";

import {
  FetchUser,
  FetchUsers,
  FetchUsersParams,
  PostUser,
  NewUserRequestData,
  PostUserResponse,
  EditUser,
  EditUserRequestData,
  DeleteUser,
  DeleteUserParams,
  FetchProfile, FetchCashierReports, FetchCashierReportsParams
} from "./types";

import { postUserResponseMapper, userListMapper, userMapper, cashierReportsListMapper } from "./mappers";
import {CashierReportList, User, UserList} from "@/modules/user/types";

class Api {
  public client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  fetchProfile: FetchProfile = (): Promise<User> => {
    const url = "profile";
    return this.client.get(url).then(profile => {
      return userMapper(profile);
    });
  };

  fetchUser: FetchUser = (
    id: string /*params: FetchUserParams*/
  ): Promise<User> => {
    const payload: { expand?: Array<string> } = {};

    const expand: Array<string> = [];

    if (expand.length) {
      payload.expand = expand;
    }

    const url = `users/${id}`;
    return this.client.get(url, payload).then(profile => {
      return userMapper(profile);
    });
  };

  fetchUsers: FetchUsers = (params: FetchUsersParams): Promise<UserList> => {
    const payload = {
      page: params.page,
      perPage: params.perPage,
      searchTerm: params.searchTerm,
      role: params.role
    };

    const url = "users";

    return this.client.get(url, payload).then(userList => {
      return userListMapper(userList);
    });
  };

  editUser: EditUser = (
    id: string,
    requestData: EditUserRequestData
  ): Promise<void> => {
    const url = `users/${id}`;

    return this.client.put(url, requestData);
  };

  postUser: PostUser = (
    requestData: NewUserRequestData
  ): Promise<PostUserResponse> => {
    const url = "users";

    return this.client.post(url, requestData).then(response => {
      return postUserResponseMapper(response);
    });
  };

  deleteUser: DeleteUser = (
    id: string,
    params?: DeleteUserParams
  ): Promise<void> => {
    const url = `users/${id}`;

    return this.client.delete(url, params);
  };

  fetchCashierReports: FetchCashierReports = (
      params: FetchCashierReportsParams
  ): Promise<CashierReportList> => {
    const url = "reports/cashiers";

    return this.client.post(url, params).then(response => {
      return cashierReportsListMapper(response);
    });
  }
}

export default Api;
