import { RouteRecordRaw } from "vue-router";
import PassThroughViewMarket from "./widgets/PassThroughViewMarket.vue";
import Market from "@/structure/Administrator/Market/views/Market.vue";
import SellingPlaces from "@/structure/Administrator/Market/views/SellingPlaces.vue";
import SellingPlace from "@/structure/Administrator/Market/views/SellingPlace.vue";
import Prices from "@/structure/Administrator/Market/views/Prices.vue";
import Map from "@/structure/Administrator/Market/views/Map.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "markets/:marketId",
    name: "administrator-market",
    component: PassThroughViewMarket,
    redirect: to => ({
      name: "administrator-market-home",
      params: {
        marketId: to.params.marketId
      }
    }),
    children: [
      {
        path: "",
        name: "administrator-market-home",
        component: Market,
        meta: {
          title: "Pijace"
        }
      },
      {
        path: "selling-places",
        name: "administrator-market-selling-places",
        component: SellingPlaces,
        meta: {
          title: "Prodajni Objekti"
        }
      },
      {
        path: "selling-places-map",
        name: "administrator-market-map",
        component: Map,
        meta: {
          title: "Mapa"
        }
      },
      {
        path: "selling-place/:sellingPlaceId",
        name: "administrator-market-selling-place",
        component: SellingPlace,
        meta: {
          title: "Selling Place"
        }
      },
      {
        path: "prices",
        name: "administrator-market-prices",
        component: Prices,
        meta: {
          title: "Prices"
        }
      }
    ]
  }
];

export default routes;
