<template>
  <div class="btn-group ml-2">
    <button
      type="button"
      class="btn btn-outline-primary"
      @click="editTenantDetails()"
    >
      <i class="ion ion-md-create mr-2"></i> Izmena
    </button>
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-outline-primary dropdown-toggle"
        data-toggle="dropdown"
      ></button>
      <div class="dropdown-menu">
        <a
          class="dropdown-item"
          href="javascript:void(0)"
          @click="editTenantAddress()"
        >
          <i class="ion ion-md-create"></i> Izmena Adrese
        </a>
        <a
          class="dropdown-item"
          href="javascript:void(0)"
          @click="deleteTenant()"
          v-if="canUserDeleteTenant()"
        >
          <i class="fas fa-trash-alt"></i> Brisanje
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import { useStore } from "@/store";
import { Tenant } from "@/modules/tenant/types";
import eventHub from "@/components/EventHub";
import { UserRole } from "@/modules/user/types";

export default class TenantHeaderWidgets extends Vue {
  store = useStore();

  get tenant(): Tenant {
    return this.store.getters.tenant;
  }

  addContract() {
    // eventHub.emit("CONTRACT_CREATE_REQUESTED", this.tenant.id);
    eventHub.emit("CONTRACT_CREATE_REQUESTED");
  }

  canUserDeleteTenant(): boolean {
    return this.store.getters.user.hasRole(UserRole.Admin);
  }

  editTenantDetails() {
    eventHub.emit("EDIT_TENANT_DETAILS_REQUESTED", this.tenant.id);
  }

  editTenantAddress() {
    eventHub.emit("EDIT_TENANT_ADDRESS_REQUESTED", this.tenant.id);
  }

  deleteTenant() {
    if (!this.canUserDeleteTenant()) return;
    eventHub.emit("DELETE_TENANT_REQUESTED", this.tenant);
  }
}
</script>
