
import { Options, Vue } from "vue-class-component";
import PaginationWidget from "./PaginationWidget.vue";
import { List } from "@/modules/common/types";

@Options({
  components: { PaginationWidget },
  props: {
    list: {
      type: Object,
      required: true,
      default: []
    },
    hidePagination: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["paginate"]
})
export default class ClientListItems extends Vue {
  list!: List<Record<string, string>>;
  hidePagination = false;

  onPaginate(page) {
    this.$emit("paginate", page);
  }
}
