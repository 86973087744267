<template>
  <HeaderWidget :header-options="headerOptions">
    <template v-slot:center>
      <ApplicationLinks :application-links="applicationLinks" />
    </template>
    <template v-slot:right>
      <UserProfileMenu :profile-menu-links="profileMenuLinks" :user="user" />
    </template>
  </HeaderWidget>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import { HeaderOptions, Links } from "@/modules/common/types";
import HeaderWidget from "@/modules/common/widgets/HeaderWidget.vue";
import UserProfileMenu from "@/modules/user/widgets/UserProfileMenu.vue";
import ApplicationLinks from "@/structure/common/widgets/ApplicationLinks.vue";
import { getHeaderOptions } from "@/structure/common/utils/getHeaderOptions";
import { getProfileMenuLinks } from "@/structure/common/utils/getProfileMenuLinks";
import { getApplicationLinks } from "@/structure/common/utils/getApplicationLinks";
import { Settings } from "@/modules/settings/types";
import { User } from "@/modules/user/types";

@Options({
  components: { HeaderWidget, UserProfileMenu, ApplicationLinks }
})
export default class AppHeaderWidget extends Vue {
  store = useStore();

  get user(): User {
    return this.store.getters.user;
  }

  get settings(): Settings {
    return this.store.getters.settings;
  }

  get headerOptions(): HeaderOptions {
    return getHeaderOptions(this.settings);
  }

  get profileMenuLinks(): Links {
    return getProfileMenuLinks();
  }

  get applicationLinks(): Links {
    return getApplicationLinks();
  }
}
</script>
