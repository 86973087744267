
import { Options, Vue } from "vue-class-component";
import EditModal from "@/modules/common/modals/EditModal.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import eventHub from "@/components/EventHub";
import EditUserForm from "@/modules/user/forms/EditUserForm.vue";
import UserApi from "@/modules/user/api";
import { EditUserRequestData, FetchUser } from "@/modules/user/api/types";
import { User } from "../types";
import UserUpdatedEvent from "@/modules/user/events/UserUpdatedEvent";
import UserEditRequestedEvent from "@/modules/user/events/UserEditRequestedEvent";

@Options({
  components: { EditModal, EditUserForm }
})
export default class UserEditRequestedListener extends Vue {
  user!: User;

  displayDialog = false;

  editUserAction = AsyncActionFactory(UserApi.editUser);
  fetchUser = AsyncActionFactory(UserApi.fetchUser as FetchUser);

  async created() {
    eventHub.on<UserEditRequestedEvent>(
      UserEditRequestedEvent.Name,
      this.onEditUserRequested
    );
  }

  async onEditUserRequested(event?: UserEditRequestedEvent) {
    if (!(event instanceof UserEditRequestedEvent)) {
      return;
    }

    await this.fetchUser.call(event.getId());

    if (this.fetchUser.isSuccessful) {
      this.user = this.fetchUser.responseData as User;
      this.displayDialog = true;
    }
  }

  async onUserEdited() {
    await this.editUserAction.call(
      this.user.id,
      this.userRequestDataFromUser(this.user)
    );

    if (this.editUserAction.isSuccessful) {
      await this.fetchUser.call(this.user.id);

      if (this.fetchUser.isSuccessful) {
        this.user = this.fetchUser.responseData as User;

        eventHub.emit<UserUpdatedEvent>(
          UserUpdatedEvent.Name,
          new UserUpdatedEvent(this.user)
        );
      }
      this.closeModal();
    }
  }

  userRequestDataFromUser(user: User): EditUserRequestData {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      active: user.active
    };
  }

  closeModal() {
    this.displayDialog = false;
    this.editUserAction.clear();
  }

  get isDataLoading(): boolean {
    return this.editUserAction.isBusy || this.fetchUser.isBusy;
  }
}
