
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import { Settings } from "@/modules/settings/types";

@Options({
  emits: ["logoChanged", "logoRemoved"]
})
export default class OrganizationLogoWidget extends Vue {
  store = useStore();

  file: File | null = null;

  get settings(): Settings {
    return this.store.getters.settings;
  }

  handleFileChange(event) {
    const files = event.target.files;
    this.file = files.length > 0 ? files[0] : null;
    if (this.validateFile()) {
      this.$emit("logoChanged", this.file);
    }
  }

  validateFile(): boolean {
    return this.file !== null;
  }

  onRemoveLogo() {
    this.$emit("logoRemoved");
  }

  get showRemoveButton(): boolean {
    return this.settings.organizationLogoUrl !== "/img/logo.png";
  }
}
