<template>
  <CreateContractRequestListener />
  <EditContractRequestListener />
  <CancelContractRequestListener />
  <DeleteContractRequestListener />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CreateContractRequestListener from "@/modules/contracts/listeners/CreateContractRequestListener.vue";
import EditContractRequestListener from "@/modules/contracts/listeners/EditContractRequestListener.vue";
import CancelContractRequestListener from "@/modules/contracts/listeners/CancelContractRequestListener.vue";
import DeleteContractRequestListener from "@/modules/contracts/listeners/DeleteContractRequestListener.vue";

@Options({
  components: {
    CreateContractRequestListener,
    EditContractRequestListener,
    CancelContractRequestListener,
    DeleteContractRequestListener
  }
})
export default class Module extends Vue {}
</script>
