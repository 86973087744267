import CustomError from "@/components/AsyncAction/Errors/CustomError";
import wrapRequestErrors from "@/components/AsyncAction/Errors/wrapRequestErrors";
import commonErrors from "@/components/AsyncAction/Errors/commonRequestErrorsMessages";
import { addError } from "@/components/toast";

const showErrorNotification = (err: Error, defaultErrorMeg: string) => {
  if (!(err instanceof CustomError)) {
    err = wrapRequestErrors(err, { ...commonErrors, default: defaultErrorMeg });
  }

  addError((err as CustomError).publicMessage);

  // if in the development mode log the full error details (for now just dump it in the console)
  if (process.env.NODE_ENV === "development") {
    if (err instanceof CustomError) {
      console.error("ERROR DETAILS: ", err.logMessage, err.context);
    } else {
      console.log("UNEXPECTED ERROR: ", err);
    }
  }
};

export default showErrorNotification;
