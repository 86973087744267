import { SellingPlaceTypeList } from "@/modules/selling-place-type/types";

export const emptySellingPlaceTypeList : SellingPlaceTypeList = {
  pagination: {
    page: 1,
    lastPage: 1,
    perPage: 10,
    from: 1,
    to: 0,
    total: 0
  },
  data: []
};
