<template>
  <h5 class="d-flex align-items-center justify-content-between">
    <span>Kategorije Prodajnih Objekata</span>
    <button
      class="btn btn-xs btn-default"
      title="Nova Kategorija"
      @click="newSellingPlaceCategory()"
    >
      <i class="ion ion-md-add"></i>
    </button>
  </h5>
  <div class="card">
    <div class="card-body p-0">
      <SellingPlaceCategoryListWidget />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import SellingPlaceCategoryListWidget from "@/modules/selling-place-category/widgets/SellingPlaceCategoryList/SellingPlaceCategoryListWidget.vue";
import eventHub from "@/components/EventHub";
import SellingPlaceCategoryNewRequestedEvent from "@/modules/selling-place-category/events/SellingPlaceCategoryNewRequestedEvent";

@Options({
  components: {
    SellingPlaceCategoryListWidget
  }
})
export default class SellingPlaceCategoriesWidget extends Vue {
  newSellingPlaceCategory() {
    eventHub.emit<SellingPlaceCategoryNewRequestedEvent>(
      SellingPlaceCategoryNewRequestedEvent.Name,
      new SellingPlaceCategoryNewRequestedEvent()
    );
  }
}
</script>
