<template>
  <Spinner :show-spinner="isDataLoading">
    <TransactionFilterWidget :filter="filter"
                             @search="onSearch"
    />
    <ListWidget
      :list="transactionList"
      :hide-pagination="!transactionList.data.length"
      @paginate="onPaginate($event)"
    >
      <template v-slot:item-widget="slotProps">
        <TransactionListItemWidget :transaction="slotProps.item" />
      </template>
      <template v-slot:no-data>
        <div class="row mb-3">
          <div class="col text-center">
            <h1>{{ $t("transaction.no transactions") }}</h1>
          </div>
        </div>
      </template>
    </ListWidget>
  </Spinner>
</template>

<script lang="ts">
import { inject } from "vue";
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import ListWidget from "@/modules/common/widgets/ListWidget";
import TransactionListItemWidget from "@/modules/transactions/widgets/TransactionList/TransactionListItemWidget.vue";
import {
  FetchTransactions,
  FetchTransactionsParams, TransactionFilter
} from "@/modules/transactions/api/types";
import { emptyTransactionList } from "@/modules/transactions/types/EmptyTransactionList.ts";
import { TransactionList } from "@/modules/transactions/types";
import TransactionFilterWidget from "@/modules/transactions/widgets/TransactionFilterWidget.vue";

@Options({
  components: { Spinner, ListWidget, TransactionListItemWidget, TransactionFilterWidget }
})
export default class TransactionListWidget extends Vue {
  transactionList: TransactionList = emptyTransactionList;
  page = 1;
  dataLoaded = false;
  filter: TransactionFilter = {
    marketId: null,
    sellingPlaceId: null,
    tenantId: null,
    from: null,
    to: null,
    month: null,
    year: null
  };

  fetchTransactionsAction = AsyncActionFactory(
    inject("fetchTransactions") as FetchTransactions
  );

  async created() {
    await this.loadData();
    this.dataLoaded = true;
  }

  async loadData() {
    await this.fetchTransactionsAction.call({
      page: this.page,
      perPage: 30,
      marketId: !this.filter.marketId ? undefined : this.filter.marketId,
      sellingPlaceId: !this.filter.sellingPlaceId ? undefined : this.filter.sellingPlaceId,
      tenantId: !this.filter.tenantId ? undefined : this.filter.tenantId,
      from: !this.filter.from ? undefined : this.filter.from,
      to: !this.filter.to ? undefined : this.filter.to
    } as FetchTransactionsParams);

    if (this.fetchTransactionsAction.isSuccessful) {
      this.transactionList = this.fetchTransactionsAction
        .responseData as TransactionList;
    }
  }

  get isDataLoading(): boolean {
    return this.fetchTransactionsAction.isBusy;
  }

  onPaginate(page) {
    this.page = page;
    this.loadData();
  }

  async onSearch(filter) {
    this.filter = filter;
    await this.loadData();
  }
}
</script>
