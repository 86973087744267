
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Contracts/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo } from "@/modules/common/types";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
import AddressDetailsWidget from "@/modules/common/widgets/AddressDetailsWidget.vue";
import TenantDetailsWidget from "@/modules/tenant/widgets/TenantDetailsWidget.vue";
import TransactionDetailsWidget from "@/modules/transactions/widgets/TransactionDetailsWidget.vue";
import { BreadcrumbItems } from "@/modules/common/types";
import getBreadcrumbItems from "../utils/getBreadcrumbItems";
import { Transaction } from "@/modules/transactions/types";
import { useStore } from "@/store";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    Breadcrumbs,
    TenantDetailsWidget,
    AddressDetailsWidget,
    TransactionDetailsWidget
  }
})
export default class TransactionDetailsPage extends Vue {
  store = useStore();

  get transaction(): Transaction {
    return this.store.getters.transaction;
  }

  get headerInfo(): HeaderInfo {
    return {
      icon: "ion ion-md-document",
      section: this.transaction ? this.transaction.name : ""
    };
  }

  get breadcrumbItems(): BreadcrumbItems {
    return getBreadcrumbItems("Detalji", this.transaction);
  }
}
