
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Market/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo, TabItems } from "@/modules/common/types";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
import { BreadcrumbItems } from "@/modules/common/types";
import getBreadcrumbItems from "../utils/getBreadcrumbItems";
import { useStore } from "@/store";
import HeaderWidgets from "@/structure/Administrator/Market/widgets/HeaderWidgets.vue";
import TabBarWidget from "@/modules/common/widgets/TabBarWidget.vue";
import getTabItems from "@/structure/Administrator/Market/utils/getTabItems";
import SellingPlaceListWidget from "@/modules/selling-place/widgets/SellingPlaceList/SellingPlaceListWidget.vue";
import { Market } from "@/modules/market/types";
import eventHub from "@/components/EventHub";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    Breadcrumbs,
    HeaderWidgets,
    TabBarWidget,
    SellingPlaceListWidget
  }
})
export default class SellingPlacesPage extends Vue {
  store = useStore();

  created() {
    eventHub.on("SELLING_PLACE_VIEW_REQUESTED", this.onSellingPlaceSelected);
  }

  get market(): Market {
    return this.store.getters.market;
  }

  get headerInfo(): HeaderInfo {
    return {
      icon: this.market ? this.market.icon : "ion ion-md-cube",
      section: this.market ? this.market.name : "",
      title: "Selling Places",
      description: this.market ? this.market.description : ""
    };
  }

  get tabItems(): TabItems {
    return getTabItems(this.market);
  }

  get breadcrumbItems(): BreadcrumbItems {
    return getBreadcrumbItems("Selling Places", this.market);
  }

  async onSellingPlaceSelected(sellingPlaceId) {
    await this.$router.push({
      name: "administrator-market-selling-place",
      params: {
        marketId: this.market.id,
        sellingPlaceId: sellingPlaceId
      }
    });
  }
}
