
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";
import AsyncActionFactory from "@/components/AsyncAction";
import WorkOrderApi from "@/modules/work-orders/api";
import ConfirmDeleteModal from "@/modules/common/modals/ConfirmDeleteModal.vue";
import { WorkOrder } from "@/modules/work-orders/types/WorkOrder";

@Options({
  components: { ConfirmDeleteModal }
})
export default class DeleteWorkOrderRequestListener extends Vue {
  workOrder!: WorkOrder;

  displayDialog = false;

  deleteWorkOrderAction = AsyncActionFactory(WorkOrderApi.deleteWorkOrder);

  created() {
    eventHub.on("DELETE_WORK_ORDER_REQUESTED", e => {
      this.workOrder = e;
      this.displayDialog = true;
    });
  }

  async deleteWorkOrder() {
    await this.deleteWorkOrderAction.call(this.workOrder.id);

    if (this.deleteWorkOrderAction.isSuccessful) {
      eventHub.emit("WORK_ORDER_DELETED", this.workOrder.id);
      this.closeDeleteModal();
    }
  }

  closeDeleteModal() {
    this.displayDialog = false;
  }

  get isDataLoading(): boolean {
    return this.deleteWorkOrderAction.isBusy;
  }
}
