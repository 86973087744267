<template>
  <label
    class="nav-item navbar-text navbar-search-box px-2 active selling-place-filter"
  >
    <i class="ion ion-ios-search navbar-icon align-middle"></i>
    <span class="navbar-search-input pl-2 w-100">
      <input
        type="text"
        class="form-control navbar-text mx-2 w-100"
        placeholder="Pretraga..."
        v-model.trim="searchTerm"
        @input="onInput($event)"
      />
    </span>
  </label>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { SellingPlaceListFilter } from "@/modules/selling-place/api/types";

@Options({
  emits: ["filterChanged"]
})
export default class FilterWidget extends Vue {
  searchTerm = "";

  onInput() {
    this.$emit("filterChanged", this.createFilter());
  }

  createFilter(): SellingPlaceListFilter {
    return {
      searchTerm: this.searchTerm
    };
  }
}
</script>

<style scoped>
.selling-place-filter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
</style>
