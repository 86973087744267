<template>
  <Modal>
    <Spinner :show-spinner="showSpinner">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ title }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click="close()"
        >
          ×
        </button>
      </div>
      <div class="modal-body">
        <slot></slot>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" @click="close()">
          Zatvori
        </button>
        <button type="button" class="btn btn-success" @click="confirm()">
          Sačuvaj
        </button>
      </div>
    </Spinner>
  </Modal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Modal from "@/modules/common/modals/Modal.vue";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";

@Options({
  components: { Modal, Spinner },
  props: {
    title: {
      type: String,
      required: true
    },
    showSpinner: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["close", "confirm"]
})
export default class EditModal extends Vue {
  title!: string;
  showSpinner!: boolean;

  close() {
    this.$emit("close");
  }

  confirm() {
    this.$emit("confirm");
  }
}
</script>
