<template>
  <router-link
    class="btn btn-default btn-outline-primary"
    :to="{
        name: 'administrator-contract-print'
    }">
    <i class="ion ion-ios-print"></i>
    Štampaj ugovor
  </router-link>
  <button class="btn btn-outline-primary" @click="editContract()">
    <i class="ion ion-md-create mr-1"></i> Izmeni ugovor
  </button>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";
import { useStore } from "@/store";
import { Contract } from "@/modules/contracts/types";

@Options({})
export default class ContractDetailsHeaderWidgets extends Vue {
  store = useStore();

  get contract(): Contract {
    return this.store.getters.contract;
  }

  editContract() {
    // console.log('edit contract', this.contract.id)
    eventHub.emit("CONTRACT_EDIT_REQUESTED", this.contract.id);
  }
}
</script>
