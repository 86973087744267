<template>
  <AppHcfLayout>
    <router-view></router-view>
  </AppHcfLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AppHcfLayout from "@/structure/common/layout/AppHcfLayout.vue";

@Options({
  components: { AppHcfLayout }
})
export default class extends Vue {}
</script>
