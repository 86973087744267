
import { Options, Vue } from "vue-class-component";
import { TransactionFilter } from "@/modules/transactions/api/types";
import { useStore } from "@/store";
import { MarketList } from "@/modules/market/types";
import { FetchMarketsParams } from "@/modules/market/api/types";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import MarketApi from "@/modules/market/api";
import { emptyMarketList } from "@/modules/market/types/EmptyMarketList";
import { FetchTenants, FetchTenantsParams } from "@/modules/tenant/api/types";
import { Tenant, TenantList } from "@/modules/tenant/types";
import TenantApi from "@/modules/tenant/api";
import SellingPlaceApi from "@/modules/selling-place/api";
import { FetchSellingPlacesParams } from "@/modules/selling-place/api/types";
import { SellingPlaceList } from "@/modules/selling-place/types";
import { emptySellingPlaceList } from "@/modules/selling-place/types/EmptySellingPlaceList";
import { MonthPickerInput } from "vue-month-picker";

@Options({
  props: {
    filter: {
      types: Object,
      required: true
    }
  },
  emits: ["search"],
  watch: {
    "filter.marketId"() {
      this.fetchSellingPlaces();
      this.filter.sellingPlaceId = null;
    }
  },
  components: { MonthPickerInput }
})
export default class TransactionFilterWidget extends Vue {
  filter!: TransactionFilter;
  store = useStore();
  marketList: MarketList = emptyMarketList;
  sellingPLaceList: SellingPlaceList = emptySellingPlaceList;
  tenantList: Array<Tenant> = [];

  fetchMarketsAction = AsyncActionFactory(MarketApi.fetchMarkets);
  fetchSellingPlacesAction = AsyncActionFactory(
    SellingPlaceApi.fetchSellingPlaces
  );
  fetchTenantsAction = AsyncActionFactory(
    TenantApi.fetchAllTenants as FetchTenants
  );

  mounted() {
    this.fetchMarkets();
    if (this.filter.marketId) {
      this.fetchSellingPlaces();
    }
    this.fetchTenants();
  }

  async fetchMarkets() {
    await this.fetchMarketsAction.call({
      perPage: -1
    } as FetchMarketsParams);

    if (this.fetchMarketsAction.isSuccessful) {
      const response = this.fetchMarketsAction.responseData as MarketList;
      if (response.data.length > 0) {
        this.marketList = response;
      }
    }
  }

  async fetchSellingPlaces() {
    await this.fetchSellingPlacesAction.call({
      marketId: this.filter.marketId,
      perPage: -1
    } as FetchSellingPlacesParams);

    if (this.fetchSellingPlacesAction.isSuccessful) {
      this.sellingPLaceList = this.fetchSellingPlacesAction
        .responseData as SellingPlaceList;
    }
  }

  async fetchTenants() {
    await this.fetchTenantsAction.call({
      perPage: -1
    } as FetchTenantsParams);
    if (this.fetchTenantsAction.isSuccessful) {
      this.tenantList = (this.fetchTenantsAction
        .responseData as TenantList).data;
    }
  }

  onSearch() {
    this.$emit("search", this.filter);
  }

  showDate(date) {
    this.filter.month = date.monthIndex;
    this.filter.year = date.year;
    this.filter.from = date.from;
    this.filter.to = date.to;
  }
}
