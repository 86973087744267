
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import AsyncActionFactory from "@/components/AsyncAction";
import { inject } from "vue";
import { MutationType } from "@/store/mutations";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import { FetchSettings } from "@/modules/settings/api/types";
import { LoginRequestData } from "@/modules/authentication/api/types";
import { Settings } from "@/modules/settings/types";

@Options({
  components: { Spinner },
  props: {
    showErrorMessage: {
      type: Boolean
    }
  },
  emits: ["login"]
})
export default class LoginWidget extends Vue {
  loginRequestData: LoginRequestData = {
    email: "",
    password: ""
  };

  showErrorMessage!: boolean;

  dataLoaded = false;
  store = useStore();

  fetchSettings = AsyncActionFactory(inject("fetchSettings") as FetchSettings);

  async created() {
    await this.loadData();
  }

  async loadData() {
    await this.fetchSettings.call({});

    if (this.fetchSettings.isSuccessful) {
      this.store.commit(
        MutationType.SetSettings,
        this.fetchSettings.responseData
      );
      this.dataLoaded = true;
    }
  }

  async submit() {
    this.$emit("login", this.loginRequestData);
  }

  get settings(): Settings {
    return this.store.getters.settings;
  }

  get isDataLoading(): boolean {
    return this.fetchSettings.isBusy || !this.dataLoaded;
  }
}
