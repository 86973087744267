import { TabItem, TabItems } from "@/modules/common/types";
import { Contract } from "@/modules/contracts/types";

function createHomeItem(contract: Contract): TabItem {
  return {
    name: "Početna",
    link: {
      name: "administrator-contract-details",
      params: {
        marketId: contract.id
      }
    },
    active: false
  };
}

function createContractDrafterDetailsItem(contract: Contract): TabItem {
  return {
    name: "Sastavljač",
    link: {
      name: "administrator-contract-drafter",
      params: {
        marketId: contract.id
      }
    },
    active: false
  };
}

function createContractTenantDetailsItem(contract: Contract): TabItem {
  return {
    name: "Zakupac",
    link: {
      name: "administrator-contract-tenant",
      params: {
        marketId: contract.id
      }
    },
    active: false
  };
}

function createContractOrganizationDetailsItem(contract: Contract): TabItem {
  return {
    name: "Organizacija",
    link: {
      name: "administrator-contract-organization",
      params: {
        marketId: contract.id
      }
    },
    active: false
  };
}

const getTabItems = function(contract: Contract): TabItems {
  const items: TabItems = [];
  if (!contract) {
    return items;
  }

  items.push(createHomeItem(contract));
  items.push(createContractDrafterDetailsItem(contract));
  items.push(createContractTenantDetailsItem(contract));
  items.push(createContractOrganizationDetailsItem(contract));

  return items;
};

export default getTabItems;
