
import { Options, Vue } from "vue-class-component";
import TextInput from "@/modules/common/forms/TextInput.vue";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import { MarketList } from "@/modules/market/types";
import { emptyMarketList } from "@/modules/market/types/EmptyMarketList";
import { SellingPlaceList } from "@/modules/selling-place/types";
import { emptySellingPlaceList } from "@/modules/selling-place/types/EmptySellingPlaceList";
import {
  SellingPlacePrice,
  SellingPlacePriceList
} from "@/modules/selling-place-price/types";
import { emptySellingPlacePriceList } from "@/modules/selling-place-price/types/EmptySellingPlacePriceList";
import { ContractItem } from "@/modules/contracts/types";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import MarketApi from "@/modules/market/api";
import SellingPlaceApi from "@/modules/selling-place/api";
import SellingPlacePriceApi from "@/modules/selling-place-price/api";
import ContractApi from "@/modules/contracts/api";
import TransactionApi from "@/modules/transactions/api";
import { FetchMarketsParams } from "@/modules/market/api/types";
import { FetchSellingPlacesParams } from "@/modules/selling-place/api/types";
import { useRouter } from "@/components/Router";
import { PostTransactionRequestData } from "@/modules/transactions/api/types";

@Options({
  components: { TextInput },
  props: {
    validationErrors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  },
  watch: {
    "postTransactionData.marketId"() {
      this.fetchSellingPlaces();
      this.fetchSellingPlacePrice();
      this.postTransactionData.sellingPlaceId = "";
      this.dailyPrice = 0;
    },

    "postTransactionData.sellingPlaceId"() {
      if (this.postTransactionData.sellingPlaceId !== "") {
        this.fetchReservation();
        this.setSellingPlacePrice();
      }
    },

    "postTransactionData.tenantShow"() {
      this.setDailyPrice();
    }
  }
})
export default class TransactionForm extends Vue {
  validationErrors!: BackendValidationErrors;
  postTransactionData: PostTransactionRequestData = {
    marketId: "",
    sellingPlaceId: "",
    tenantShow: true
  };

  dailyPrice = 0;

  marketList: MarketList = emptyMarketList;

  sellingPLaceList: SellingPlaceList = emptySellingPlaceList;
  selectedSellingPlacePrice!: SellingPlacePrice;
  sellingPlacePriceList: SellingPlacePriceList = emptySellingPlacePriceList;

  reservation: ContractItem | null = null;

  fetchMarketsAction = AsyncActionFactory(MarketApi.fetchMarkets);
  fetchSellingPlacesAction = AsyncActionFactory(
    SellingPlaceApi.fetchSellingPlaces
  );
  fetchSellingPlacePriceAction = AsyncActionFactory(
    SellingPlacePriceApi.fetchSellingPlacePrices
  );
  fetchContractItemAction = AsyncActionFactory(ContractApi.fetchContractItem);
  postTransaction = AsyncActionFactory(TransactionApi.postTransaction);

  mounted() {
    this.fetchMarkets();
  }

  async fetchMarkets() {
    await this.fetchMarketsAction.call({
      perPage: -1
    } as FetchMarketsParams);

    if (this.fetchMarketsAction.isSuccessful) {
      const response = this.fetchMarketsAction.responseData as MarketList;
      if (response.data.length > 0) {
        this.marketList = response;
      }
    }
  }

  async fetchSellingPlaces() {
    await this.fetchSellingPlacesAction.call({
      marketId: this.postTransactionData.marketId,
      perPage: -1
    } as FetchSellingPlacesParams);

    if (this.fetchSellingPlacesAction.isSuccessful) {
      const response = this.fetchSellingPlacesAction
        .responseData as SellingPlaceList;
      if (response.data.length > 0) {
        this.sellingPLaceList = response;
      }
    }
  }

  async fetchSellingPlacePrice() {
    await this.fetchSellingPlacePriceAction.call(
      this.postTransactionData.marketId
    );

    if (this.fetchSellingPlacePriceAction.isSuccessful) {
      const response = this.fetchSellingPlacePriceAction
        .responseData as SellingPlacePriceList;

      if (response.data.length > 0) {
        this.sellingPlacePriceList = response;
      }
    }
  }

  async fetchReservation() {
    await this.fetchContractItemAction.call(
      this.postTransactionData.sellingPlaceId
    );

    if (this.fetchContractItemAction.isSuccessful) {
      if (Object.keys(this.fetchContractItemAction.responseData).length > 0) {
        this.reservation = this.fetchContractItemAction
          .responseData as ContractItem;
      } else {
        this.reservation = null;
      }
    }

    this.setDailyPrice();
  }

  setSellingPlacePrice() {
    this.selectedSellingPlacePrice = this.sellingPlacePriceList.data.find(
      el => {
        const sellingPlace = this.sellingPLaceList.data.find(
          el => el.id == this.postTransactionData.sellingPlaceId
        );
        return (
          el.sellingPlaceCategory.id === sellingPlace.category.id &&
          el.sellingPlaceType.id === sellingPlace.type.id
        );
      }
    );
  }

  setDailyPrice() {
    if (this.reservation === null || !this.postTransactionData.tenantShow) {
      this.dailyPrice =
        this.selectedSellingPlacePrice.rentPriceWithoutReservationDaily / 100;
    } else {
      this.dailyPrice = this.selectedSellingPlacePrice.rentPriceDaily / 100;
    }
  }

  async create() {
    await this.postTransaction.call(this.postTransactionData);

    if (this.postTransaction.isSuccessful) {
      await useRouter().push({
        name: "administrator-transactions"
      });
    }
  }

  async cancel() {
    await useRouter().push({
      name: "administrator-transactions"
    });
  }
}
