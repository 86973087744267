
import { Options, Vue } from "vue-class-component";
import HncfLayout from "@/modules/common/layouts/HncfLayout.vue";
import FooterWidget from "@/modules/common/widgets/FooterWidget.vue";
import AppHeaderWidget from "@/structure/common/widgets/AppHeaderWidget.vue";

@Options({
  components: { HncfLayout, AppHeaderWidget, FooterWidget }
})
export default class extends Vue {}
