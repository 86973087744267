<template>
  <CreateModal
    v-if="displayDialog"
    @close="closeModal()"
    title="Otkazi ugovora"
    @confirm="createContractCancel()"
    :showSpinner="isDataLoading"
  >
    <CancelContractForm
      v-model:contract-cancel-request-data="contractCancelRequestData"
      :validationErrors="contractCancelAction.errors"
    />
  </CreateModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CreateModal from "@/modules/common/modals/CreateModal.vue";
import CancelContractForm from "@/modules/contracts/form/CancelContractForm.vue";
import { ContractCancelRequestData } from "@/modules/contracts/api/types";
import eventHub from "@/components/EventHub";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import ContractTypeApi from "@/modules/contracts/api";
import { useRouter } from "@/components/Router";

@Options({
  components: {
    CreateModal,
    CancelContractForm
  }
})
export default class CancelContractRequestListener extends Vue {
  displayDialog = false;
  contractId: any = null;

  contractCancelRequestData: ContractCancelRequestData = this.createContractCancelRequestData();
  contractCancelAction = AsyncActionFactory(ContractTypeApi.cancelContract);

  created() {
    eventHub.on("CREATE_CONTRACT_CANCEL_REQUEST", contractId => {
      this.displayDialog = true;
      this.contractId = contractId;
    });
  }

  async createContractCancel() {
    await this.contractCancelAction.call(
      this.contractId,
      this.contractCancelRequestData
    );

    if (this.contractCancelAction.isSuccessful) {
      this.closeModal();
      await useRouter().push({
        name: "administrator-contracts"
      });
    }
  }

  createContractCancelRequestData(): ContractCancelRequestData {
    return {
      cancellationReasonId: null,
      cancellationNote: ""
    };
  }

  closeModal() {
    this.displayDialog = false;
  }

  get isDataLoading(): boolean {
    return this.contractCancelAction.isBusy;
  }
}
</script>

<style scoped></style>
