<template>
  <div v-if="showSpinner" class="spinner-icon">
    <i class="fas fa-spinner fa-spin fa-2x"></i>
  </div>
  <slot v-if="!showSpinner"></slot>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    showSpinner: {
      required: true,
      default: false
    }
  }
})
export default class extends Vue {
  showSpinner!: boolean;
}
</script>
<style>
.spinner-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 120px;
  vertical-align: middle;
}
</style>
