
import { Options, Vue } from "vue-class-component";
import { useStore } from "@/store";
import SellingPlaceTypeSelector from "@/modules/selling-place-type/widgets/SellingPlaceTypeSelector.vue";
import SellingPlaceCategorySelector from "@/modules/selling-place-category/widgets/SellingPlaceCategorySelector.vue";
import MarketSelector from "@/modules/market/widgets/MarketListWidget/MarketSelector.vue";
import { SellingPlaceListFilter } from "@/modules/selling-places/api/types";
import { emptySellingPlaceFilter } from "@/modules/selling-places/types/EmptySellingPlaceFilter";

@Options({
  components: {
    SellingPlaceTypeSelector,
    SellingPlaceCategorySelector,
    MarketSelector
  },
  emits: ["search"]
})
export default class FilterWidget extends Vue {
  store = useStore();
  filter: SellingPlaceListFilter = emptySellingPlaceFilter;

  search() {
    this.$emit("search", this.filter);
  }
}
