import {SellingPlacePrice} from "@/modules/selling-place-price/types/SellingPlacePrice";

export const emptySellingPlacePrice: SellingPlacePrice = {
    rentPriceDaily: 0,
    rentPriceWithoutReservationDaily: 0,
    rentPriceMonthly: 0,
    rentPriceWithoutReservationMonthly: 0,
    reservationPriceDaily: 0,
    reservationPriceMonthly: 0,
    sellingPlaceType: {
        id: "",
        name: "",
        description: "",
        icon: "",
        removable: true,
        sellingArea: "",
        sellingBan: "",
        color: "blue"
    },
    sellingPlaceCategory: {
        id: "",
        name: "",
        description: "",
        icon: "",
        removable: true,
    }
};
