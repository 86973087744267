import { Settings } from "@/modules/settings/types";

export function settingsResponseMapper(response): Settings {
  return {
    ...response,
    organizationLogoUrl: response.organizationLogoUrl
      ? response.organizationLogoUrl
      : "/img/logo.png"
  };
}
