<template>
  <EditModal
    v-if="displayDialog"
    @close="closeModal()"
    title="Izmena Zakupca"
    @confirm="editTenant()"
    :showSpinner="isDataLoading"
  >
    <EditTenantDetailsForm
      v-model:tenant="tenant"
      :errors="editTenantAction.errors"
    />
  </EditModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";
import { FetchTenant, EditTenantRequestData } from "@/modules/tenant/api/types";
import AsyncActionFactory from "@/components/AsyncAction";
import TenantApi from "@/modules/tenant/api";
import EditModal from "@/modules/common/modals/EditModal.vue";
import EditTenantDetailsForm from "../forms/EditTenantDetailsForm.vue";
import { Tenant } from "@/modules/tenant/types";

@Options({
  components: { EditModal, EditTenantDetailsForm }
})
export default class EditTenantDetailsRequestListener extends Vue {
  tenant!: Tenant;

  displayDialog = false;

  editTenantAction = AsyncActionFactory(TenantApi.editTenant);
  fetchTenant = AsyncActionFactory(TenantApi.fetchTenant as FetchTenant);

  async created() {
    eventHub.on("EDIT_TENANT_DETAILS_REQUESTED", this.onEditTenantRequested);
  }

  async onEditTenantRequested(tenantId) {
    await this.fetchTenant.call(tenantId);

    if (this.fetchTenant.isSuccessful) {
      this.tenant = this.fetchTenant.responseData as Tenant;
      this.displayDialog = true;
    }
  }

  async editTenant() {
    await this.editTenantAction.call(this.tenant.id, {
      firstName: this.tenant.firstName,
      lastName: this.tenant.lastName,
      registrationId: this.tenant.registrationId,
      phoneNumber: this.tenant.phoneNumber,
      bpg: this.tenant.bpg
    } as EditTenantRequestData);

    if (this.editTenantAction.isSuccessful) {
      eventHub.emit("TENANT_EDITED", this.tenant.id);
      this.closeModal();
    }
  }

  closeModal() {
    this.editTenantAction.clear();
    this.displayDialog = false;
  }

  get isDataLoading(): boolean {
    return this.editTenantAction.isBusy || this.fetchTenant.isBusy;
  }
}
</script>
