<template>
  <div
    class="d-flex align-items-center p-3 border-bottom justify-content-between"
  >
    <div class="d-flex align-items-center">
      <i class="mr-2" :class="sellingPlace.type.icon"></i>
      <div>
        <h5 class="mb-0">
          {{ sellingPlace.name }} <small>({{ sellingPlace.type.name }})</small>
        </h5>
      </div>
    </div>
    <button
        @click="sellingPlaceSelected()"
        class="btn btn-outline-secondary btn-sm"
    >
      Detalji
    </button>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { SellingPlace } from "@/modules/selling-place/types";
import eventHub from "@/components/EventHub";

@Options({
  props: {
    sellingPlace: {
      type: Object,
      required: true,
      default: {}
    }
  }
})
export default class SellingPlaceListItemWidget extends Vue {
  sellingPlace!: SellingPlace;

  async sellingPlaceSelected() {
    eventHub.emit("SELLING_PLACE_VIEW_REQUESTED", this.sellingPlace.id);
  }
}
</script>
