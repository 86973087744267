
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";
@Options({})
export default class ContractsHeaderWidgets extends Vue {
  newContract() {
    // eventHub.emit("CONTRACT_CREATE_REQUESTED");
    // eventHub.emit("CONTRACT_CREATED");
    eventHub.emit("CREATE_CONTRACT_REQUEST");
  }
}
