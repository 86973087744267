<template>
  <ContractCancellationReasonCreateRequestedListener />
  <ContractCancellationReasonEditRequestedListener />
  <ContractCancellationReasonDeleteRequestedListener />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ContractCancellationReasonCreateRequestedListener from "@/modules/contracts-cancellation-reason/listeners/ContractCancellationReasonCreateRequestedListener.vue";
import ContractCancellationReasonEditRequestedListener from "@/modules/contracts-cancellation-reason/listeners/ContractCancellationReasonEditRequestedListener.vue";
import ContractCancellationReasonDeleteRequestedListener from "@/modules/contracts-cancellation-reason/listeners/ContractCancellationReasonDeleteRequestedListener.vue";

@Options({
  components: {
    ContractCancellationReasonCreateRequestedListener,
    ContractCancellationReasonEditRequestedListener,
    ContractCancellationReasonDeleteRequestedListener
  }
})
export default class Module extends Vue {}
</script>
