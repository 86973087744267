
import { Options, Vue } from "vue-class-component";
import ConfirmDeleteModal from "@/modules/common/modals/ConfirmDeleteModal.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import SellingPlaceTypeApi from "@/modules/selling-place-type/api";
import eventHub from "@/components/EventHub";
import { SellingPlaceType } from "../types";
import SellingPlaceTypeDeleteRequestedEvent from "@/modules/selling-place-type/events/SellingPlaceTypeDeleteRequestedEvent";
import SellingPlaceTypeDeletedEvent from "@/modules/selling-place-type/events/SellingPlaceTypeDeletedEvent";

@Options({
  components: { ConfirmDeleteModal }
})
export default class SellingPlaceTypeDeleteRequestedListener extends Vue {
  sellingPlaceType!: SellingPlaceType;

  displayDialog = false;

  deleteSellingPlaceTypeAction = AsyncActionFactory(
    SellingPlaceTypeApi.deleteSellingPlaceType
  );

  created() {
    eventHub.on<SellingPlaceTypeDeleteRequestedEvent>(
      SellingPlaceTypeDeleteRequestedEvent.Name,
      this.onSellingPlaceTypeDeleteRequested
    );
  }

  onSellingPlaceTypeDeleteRequested(
    event?: SellingPlaceTypeDeleteRequestedEvent
  ) {
    if (!(event instanceof SellingPlaceTypeDeleteRequestedEvent)) {
      return;
    }

    this.sellingPlaceType = event.getSellingPlaceType();
    this.displayDialog = true;
  }

  async deleteSellingPlaceType() {
    await this.deleteSellingPlaceTypeAction.call(this.sellingPlaceType.id);

    if (this.deleteSellingPlaceTypeAction.isSuccessful) {
      eventHub.emit(
        SellingPlaceTypeDeletedEvent.Name,
        new SellingPlaceTypeDeletedEvent(this.sellingPlaceType)
      );
      this.closeDeleteModal();
    }
  }

  closeDeleteModal() {
    this.displayDialog = false;
  }

  get isDataLoading(): boolean {
    return this.deleteSellingPlaceTypeAction.isBusy;
  }
}
