import { HttpClient } from "@/components/HttpClient/HttpClient";
import { HttpClientRequestConfig } from "@/components/HttpClient/types";

class ApiClient {
  public client: HttpClient;

  constructor(client: HttpClient) {
    this.client = client;
    const token = localStorage.getItem("accessToken");
    if (token) {
      this.client.addDefaultHeader("Authorization", `Bearer ${token}`);
    }
  }

  setAccessToken(token: string) {
    this.client.addDefaultHeader("Authorization", `Bearer ${token}`);
    localStorage.setItem("accessToken", token);
  }

  clearAccessToken() {
    this.client.removeDefaultHeader("Authorization");
    localStorage.removeItem("accessToken");
  }

  post(url: string, payload: {}, options: HttpClientRequestConfig = {}) {
    return this.client.post(url, payload, options);
  }

  get(url: string, payload = {}, options: HttpClientRequestConfig = {}) {
    return this.client.get(url, payload, options);
  }

  put(url: string, payload: {}, options: HttpClientRequestConfig = {}) {
    return this.client.put(url, payload, options);
  }

  patch(url: string, payload: {}, options: HttpClientRequestConfig = {}) {
    return this.client.patch(url, payload, options);
  }

  delete(
    url: string,
    payload: {} | null = null,
    options: HttpClientRequestConfig = {}
  ) {
    return this.client.delete(url, payload, options);
  }

  upload(
    url: string,
    formData: FormData,
    options: Partial<HttpClientRequestConfig> = {},
    onUploadProgressCallback?: (any) => void
  ) {
    return this.client.upload(url, formData, options, onUploadProgressCallback);
  }
}

export default ApiClient;
