<template>
  <div class="media align-items-baseline">
    <div>
      <i :class="sellingPlaceType.icon"></i>
    </div>
    <div class="media-body px-2">
      <p class="text-body mb-1 font-weight-bold">{{ sellingPlaceType.name }}</p>
      <small class="text-secondary">{{ sellingPlaceType.description }}</small>
    </div>
    <div class="ml-auto">
      <i
        @click="editSellingPlaceType(sellingPlaceType)"
        @mouseover="isEditHovering = true"
        @mouseleave="isEditHovering = false"
        class="ion ion-md-create cursor-pointer"
        :class="{
          'text-primary': isEditHovering,
          'text-secondary': !isEditHovering
        }"
      ></i>
      <i
        v-if="sellingPlaceType.removable"
        @click="deleteSellingPlaceType(sellingPlaceType)"
        @mouseover="isDeleteHovering = true"
        @mouseleave="isDeleteHovering = false"
        class="ion ion-md-trash ml-2 cursor-pointer"
        :class="{
          'text-danger': isDeleteHovering,
          'text-secondary': !isDeleteHovering
        }"
      ></i>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import { SellingPlaceType } from "@/modules/selling-place-type/types";
import eventHub from "@/components/EventHub";
import SellingPlaceTypeDeleteRequestedEvent from "@/modules/selling-place-type/events/SellingPlaceTypeDeleteRequestedEvent";
import SellingPlaceTypeEditRequestedEvent from "@/modules/selling-place-type/events/SellingPlaceTypeEditRequestedEvent";

@Options({
  components: { Spinner },
  props: {
    sellingPlaceType: {
      type: Object,
      required: true
    }
  }
})
export default class SellingPlaceTypeListItemWidget extends Vue {
  sellingPlaceType!: SellingPlaceType;
  isEditHovering = false;
  isDeleteHovering = false;

  editSellingPlaceType(sellingPlaceType: SellingPlaceType) {
    eventHub.emit(
      SellingPlaceTypeEditRequestedEvent.Name,
      new SellingPlaceTypeEditRequestedEvent(sellingPlaceType.id)
    );
  }

  deleteSellingPlaceType(sellingPlaceType: SellingPlaceType) {
    eventHub.emit(
      SellingPlaceTypeDeleteRequestedEvent.Name,
      new SellingPlaceTypeDeleteRequestedEvent(sellingPlaceType)
    );
  }
}
</script>
