import RequestError from "./RequestError";
import { AsyncActionErrorMessages } from "../types";

const wrapRequestErrors = (
  error,
  messages: Partial<AsyncActionErrorMessages>
): RequestError => {
  if (error.response) {
    // server returned an error response
    const status = error.response.status;
    const headers = error.response.headers;
    const data = error.response.data;
    const validationErrors = status === 422 && data ? data.errors : null;
    const logMsg = data?.message ?? null;

    // if there's custom error for the status, show it, otherwise show the default
    let errMsg = messages["status" + status] ?? messages.default;
    errMsg = status === 500 && !logMsg ? errMsg : logMsg;
    // Wrap the server response into the custom error
    return new RequestError(errMsg, logMsg, {
      request: error.request,
      httpStatus: status,
      headers,
      body: data,
      validationErrors,
      previousError: error
    });
  }

  if (error.request) {
    // The request was made but no response was received
    return new RequestError(messages.default as string, null, {
      request: error.request,
      previousError: error
    });
  }

  // Something happened in setting up the request that triggered an Error
  return new RequestError(messages.default as string, null, {
    previousError: error
  });
};

export default wrapRequestErrors;
