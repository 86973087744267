
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    modelValue: {
      type: [Number, String],
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    errorMessage: {
      type: String,
      required: false
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["update:modelValue", "input"]
})
export default class PriceInput extends Vue {
  modelValue?: number | string;
  placeholder?: string;
  errorMessage?: string;
  error!: boolean;
  internalError = false;
  internalErrorMessage?: string;

  onInput(event) {
    if (this.validate(event.target.value)) {
      this.clearErrorMessage();
    }
    this.$emit("update:modelValue", event.target.value);
    this.$emit("input");
  }

  validate(value: string) {
    if (value === "") {
      this.internalError = true;
      this.internalErrorMessage = "This field can't be empty";
      return false;
    }
    if (value) {
      if (!this.validateDecimalNumber(value) && !this.validateNumber(value)) {
        this.internalError = true;
        this.internalErrorMessage = "This field need to be number";
        return false;
      }
    }
    return true;
  }

  validateDecimalNumber(value: string): boolean {
    return /^\d+\.\d+$/.test(value);
  }

  validateNumber(value: string): boolean {
    return /^\d+$/.test(value);
  }

  clearErrorMessage(): void {
    this.internalError = false;
    this.internalErrorMessage = undefined;
  }
}
