<template>
  <ConfirmDeleteModal
    v-if="displayDialog"
    @close="closeDeleteModal()"
    @confirm="deleteSellingPlaceCategory()"
    title="Brisanje Kategorije Prodajnog Objekta"
    description="Da li ste sigurni da želite da obrišete prodajni objekat?"
    :showSpinner="isDataLoading"
  />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ConfirmDeleteModal from "@/modules/common/modals/ConfirmDeleteModal.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import SellingPlaceCategoryApi from "@/modules/selling-place-category/api";
import eventHub from "@/components/EventHub";
import { SellingPlaceCategory } from "../types";
import SellingPlaceCategoryDeleteRequestedEvent from "@/modules/selling-place-category/events/SellingPlaceCategoryDeleteRequestedEvent";
import SellingPlaceCategoryDeletedEvent from "@/modules/selling-place-category/events/SellingPlaceCategoryDeletedEvent";

@Options({
  components: { ConfirmDeleteModal }
})
export default class SellingPlaceCategoryDeleteRequestedListener extends Vue {
  sellingPlaceCategory!: SellingPlaceCategory;

  displayDialog = false;

  deleteSellingPlaceCategoryAction = AsyncActionFactory(
    SellingPlaceCategoryApi.deleteSellingPlaceCategory
  );

  created() {
    eventHub.on<SellingPlaceCategoryDeleteRequestedEvent>(
      SellingPlaceCategoryDeleteRequestedEvent.Name,
      this.onSellingPlaceCategoryDeleteRequestedEvent
    );
  }

  onSellingPlaceCategoryDeleteRequestedEvent(
    event?: SellingPlaceCategoryDeleteRequestedEvent
  ) {
    if (!(event instanceof SellingPlaceCategoryDeleteRequestedEvent)) {
      return;
    }

    this.sellingPlaceCategory = event?.getSellingPlaceCategory();
    this.displayDialog = true;
  }

  async deleteSellingPlaceCategory() {
    await this.deleteSellingPlaceCategoryAction.call(
      this.sellingPlaceCategory.id
    );

    if (this.deleteSellingPlaceCategoryAction.isSuccessful) {
      eventHub.emit(
        SellingPlaceCategoryDeletedEvent.Name,
        new SellingPlaceCategoryDeletedEvent(this.sellingPlaceCategory)
      );
      this.closeDeleteModal();
    }
  }

  closeDeleteModal() {
    this.displayDialog = false;
  }

  get isDataLoading(): boolean {
    return this.deleteSellingPlaceCategoryAction.isBusy;
  }
}
</script>
