
import { Options, Vue } from "vue-class-component";
import { Tenant } from "../types";

@Options({
  props: {
    tenant: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: "General"
    }
  }
})
export default class TenantDetailsWidget extends Vue {
  tenant!: Tenant;
}
