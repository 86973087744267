<template>
  <div
    class="d-flex align-items-center p-3 border-bottom justify-content-between"
    :class="{ deactivated: !user.active }"
  >
    <div class="d-flex align-items-center">
      <img
        :src="user.imageUrl"
        :alt="user.firstName + ' ' + user.lastName"
        width="30"
        class="mr-3"
      />
      <div>
        <h5 class="mb-0">
          {{ user.firstName }} {{ user.lastName }}
          <small v-if="!user.active">{{ $t("user.deactivated") }}</small>
        </h5>
        <a href="javascript:void(0)" class="badge" :class="badgeClass()">
          {{ user.roles[0] }}
        </a>
      </div>
    </div>
    <div>
      <i
        @click="editUser()"
        @mouseover="isEditHovering = true"
        @mouseleave="isEditHovering = false"
        class="ion ion-md-create mr-2 cursor-pointer"
        :class="{
          'text-primary': isEditHovering,
          'text-secondary': !isEditHovering
        }"
      ></i>
      <i
        @click="changeUserPassword()"
        @mouseover="isChangePasswordHovering = true"
        @mouseleave="isChangePasswordHovering = false"
        class="ion ion-md-key mr-2 cursor-pointer"
        :class="{
          'text-warning': isChangePasswordHovering,
          'text-secondary': !isChangePasswordHovering
        }"
      ></i>
      <i
        @click="deleteUser()"
        @mouseover="isDeleteHovering = true"
        @mouseleave="isDeleteHovering = false"
        class="ion ion-md-trash cursor-pointer"
        :class="{
          'text-danger': isDeleteHovering,
          'text-secondary': !isDeleteHovering
        }"
      ></i>
      <!--      <i class="ion ion-md-key"></i>-->
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { UserRole, User } from "@/modules/user/types";
import eventHub from "@/components/EventHub";
import UserDeleteRequestedEvent from "@/modules/user/events/UserDeleteRequestedEvent";
import UserEditRequestedEvent from "@/modules/user/events/UserEditRequestedEvent";
import UserPasswordEditRequestedEvent from "@/modules/user/events/UserPasswordEditRequestedEvent";

@Options({
  props: {
    user: {
      type: Object,
      required: true,
      default: {}
    }
  }
})
export default class UserListItemWidget extends Vue {
  user!: User;

  isEditHovering = false;
  isDeleteHovering = false;
  isChangePasswordHovering = false;

  editUser() {
    eventHub.emit<UserEditRequestedEvent>(
      UserEditRequestedEvent.Name,
      new UserEditRequestedEvent(this.user.id)
    );
  }

  deleteUser() {
    eventHub.emit<UserDeleteRequestedEvent>(
      UserDeleteRequestedEvent.Name,
      new UserDeleteRequestedEvent(this.user)
    );
  }

  changeUserPassword() {
    eventHub.emit<UserPasswordEditRequestedEvent>(
      UserPasswordEditRequestedEvent.Name,
      new UserPasswordEditRequestedEvent(this.user.id)
    );
  }

  badgeClass(): string {
    if (!this.user.active) {
      return "badge-outline-secondary";
    }
    if (this.user.roles.indexOf(UserRole.SuperAdmin) != -1) {
      return "badge-outline-danger";
    }
    if (this.user.roles.indexOf(UserRole.Admin) != -1) {
      return "badge-outline-warning";
    }
    if (this.user.roles.indexOf(UserRole.Cashier) != -1) {
      return "badge-outline-primary";
    }
    if (this.user.roles.indexOf(UserRole.Tenant) != -1) {
      return "badge-outline-success";
    }
    if (this.user.roles.indexOf(UserRole.Clerk) != -1) {
      return "badge-outline-info";
    }
    return "";
  }
}
</script>

<style scoped lang="scss">
.deactivated {
  color: lightgray;

  img {
    opacity: 0.5;
    filter: grayscale(100%);
  }
}
</style>
