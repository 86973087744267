
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import SellingPlaceApi from "@/modules/selling-place/api";
import { useRoute } from "vue-router";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import SellingPlaceListWidget from "@/modules/selling-place/widgets/SellingPlaceSidebar/SellingPlaceListWidget.vue";
import {
  FetchSellingPlaces,
  FetchSellingPlacesParams,
  SellingPlaceListFilter
} from "@/modules/selling-place/api/types";
import { SellingPlaceList, SellingPlaces } from "@/modules/selling-place/types";

@Options({
  components: { Spinner, SellingPlaceListWidget }
})
export default class SellingPlaceSidebar extends Vue {
  allSellingPlaces: SellingPlaces = [];
  filteredSellingPlaces: SellingPlaces = [];

  route = useRoute();

  fetchSellingPlaces = AsyncActionFactory(
    SellingPlaceApi.fetchSellingPlaces as FetchSellingPlaces
  );

  async created() {
    await this.loadData();
  }

  async loadData() {
    await this.fetchSellingPlaces.call({
      marketId: this.route.params.marketId,
      perPage: -1
    } as FetchSellingPlacesParams);

    if (this.fetchSellingPlaces.isSuccessful) {
      this.allSellingPlaces = (this.fetchSellingPlaces
        .responseData as SellingPlaceList).data;

      this.filteredSellingPlaces = this.allSellingPlaces;
    }
  }

  get isDataLoading(): boolean {
    return this.fetchSellingPlaces.isBusy;
  }

  onFilter(filter) {
    this.filteredSellingPlaces = this.filterSellingPlaces(filter);
  }

  filterSellingPlaces(filter: SellingPlaceListFilter) {
    return this.allSellingPlaces.filter(
      sellingPlace =>
        sellingPlace.name
          .toLowerCase()
          .indexOf(filter.searchTerm.toLowerCase()) !== -1
    );
  }
}
