
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";
import ContractNumberingRulesListWidget from "@/modules/contract-numbering-rules/widgets/ContractNumberingRulesList/ContractNumberingRulesListWidget.vue";
import ContractNumberingRuleNewRequestedEvent from "@/modules/contract-numbering-rules/events/ContractNumberingRuleNewRequestedEvent";

@Options({
  components: { ContractNumberingRulesListWidget }
})
export default class ContractNumberingRulesWidget extends Vue {
  newCreateContractNumberingRule() {
    eventHub.emit<ContractNumberingRuleNewRequestedEvent>(
      ContractNumberingRuleNewRequestedEvent.Name,
      new ContractNumberingRuleNewRequestedEvent()
    );
  }
}
