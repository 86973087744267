<template>
  <div class="container-fluid d-flex align-items-stretch flex-grow-1 p-0">
    <div
      class="flex-shrink-1 flex-grow-1 container-p-x container-p-y padding-bottom"
    >
      <div class="row">
        <div class="col" style="margin: 0 auto; max-width: 1200px;">
          <div class="mb-3">
            <div class="d-flex flex-row align-items-center mb-3">
              <slot name="page-breadcrumbs"></slot>
              <div class="flex-grow-1"></div>
              <slot name="page-links"></slot>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div>
                <slot name="page-name"></slot>
              </div>
              <div class="flex-grow-1"></div>
              <div>
                <slot name="page-widgets"></slot>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="clearfix"></div>
          <hr class="mt-4 mb-4" v-if="!hideContentDivider" />
          <slot name="page-tabs"></slot>
          <div class="clearfix"></div>
          <slot></slot>
          <div class="clearfix"></div>
          <slot name="page-footer"></slot>
        </div>
      </div>
    </div>
    <div id="modal-backdrop" class="fade"></div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    hideContentDivider: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
export default class extends Vue {
  hideContentDivider = false;
}
</script>

<style scoped>
.padding-bottom {
  padding-bottom: 70px !important;
}
</style>
