<template>
  <ContractPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ContractPage from "@/structure/Administrator/Settings/pages/ContractPage.vue";
import { provide } from "vue";
import ContractCancellationReasonApi from "@/modules/contracts-cancellation-reason/api";
import ContractNumberingRulesApi from "@/modules/contract-numbering-rules/api";
import {
  FetchContractCancellationReasons,
  FetchContractCancellationReason
} from "@/modules/contracts-cancellation-reason/api/types";
import { FetchContractNumberingRules } from "@/modules/contract-numbering-rules/api/types";

@Options({
  components: { ContractPage }
})
export default class Contract extends Vue {
  created() {
    provide(
      "fetchContractCancellationReasonList",
      ContractCancellationReasonApi.fetchContractCancellationReasons.bind(
        ContractCancellationReasonApi
      ) as FetchContractCancellationReasons
    );
    provide(
      "fetchContractCancellationReason",
      ContractCancellationReasonApi.fetchContractCancellationReason.bind(
        ContractCancellationReasonApi
      ) as FetchContractCancellationReason
    );
    provide(
      "fetchContractNumberingRules",
      ContractNumberingRulesApi.fetchContractNumberingRules.bind(
        ContractNumberingRulesApi
      ) as FetchContractNumberingRules
    );
  }
}
</script>
