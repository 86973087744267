/**
 * Class to encapsulate Laravel's validation errors
 */
class BackendValidationErrors {
  protected validationErrors = {};

  get hasValidationErrors(): boolean {
    return Object.keys(this.validationErrors).length > 0;
  }

  setErrors(errors: {}) {
    this.validationErrors = errors;
  }

  /**
   * Check if field has error
   * @param field  Form field name (use wildcards like foo.*.bar to match Laravel array syntax e.g. foo.1.bar)
   */
  has(field: string): boolean {
    if (field.indexOf(".*") > -1) {
      return this.matchWildcardKeys(field).length > 0;
    }

    return this.validationErrors[field] !== undefined;
  }

  /**
   * Check if
   * @param fieldList
   */
  hasAny(fieldList: string[]): boolean {
    return fieldList.reduce(
      (acc: boolean, field: string) => this.has(field) || acc,
      false
    );
  }

  /**
   * Get the field's error
   * @param field  Form field name (use wildcards like foo.*.bar to match Laravel array syntax e.g. foo.1.bar)
   */
  get(field: string): string | string[] {
    if (field.indexOf(".*") > -1) {
      const errors = this.matchWildcardKeys(field).flatMap(
        key => this.validationErrors[key]
      );
      return errors.length > 0 ? errors : "";
    }

    return this.validationErrors[field][0] ?? "";
  }

  /**
   * Get the field's error if present
   * @param field Form field name
   */

  getIfHas(field: string): string | string[] | void {
    if (!this.has(field)) {
      return;
    }
    return this.get(field);
  }

  /**
   * Clear error for the field
   * @param field  Form field name (use wildcards like foo.*.bar to match Laravel array syntax e.g. foo.1.bar)
   */
  clear(field: string) {
    const keys =
      field.indexOf(".*") === -1 ? [field] : this.matchWildcardKeys(field);

    keys.forEach(key => {
      delete this.validationErrors[key];
    });
  }

  /**
   * Get copy of all errors
   */
  getAll() {
    return { ...this.validationErrors };
  }

  /**
   * Clear all errors
   */
  clearAll() {
    this.validationErrors = {};
  }

  /**
   * Get the list of all fields with errors (all keys)
   */
  listFields() {
    return Object.keys(this.validationErrors);
  }

  /**
   * Get the matched list of fields (keys)
   * @param field  Wildcard pattern, each .* will be expanded to match any number of chars (other then the . itself)
   * @param field
   */
  matchWildcardKeys(field: string): Array<string> {
    const re = new RegExp("^" + field.replace(".*", "\\.[^.]+") + "$");
    return this.listFields().filter(field => re.test(field));
  }
}

export default BackendValidationErrors;
