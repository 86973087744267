
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";
import AsyncActionFactory from "@/components/AsyncAction";
import TenantApi from "@/modules/tenant/api";
import { useStore } from "@/store";
import CreateModal from "@/modules/common/modals/CreateModal.vue";
import CreateTenantForm from "@/modules/tenant/forms/CreateTenantForm.vue";
import {
  PostTenantRequestData,
  PostTenantResponse
} from "@/modules/tenant/api/types";

@Options({
  components: { CreateModal, CreateTenantForm }
})
export default class CreateTenantRequestListener extends Vue {
  store = useStore();
  tenantRequestData: PostTenantRequestData = this.createEmptyTenantRequestData();

  displayDialog = false;

  postTenant = AsyncActionFactory(TenantApi.postTenant);

  created() {
    eventHub.on("CREATE_TENANT_REQUESTED", () => {
      this.displayDialog = true;
    });
  }

  async createTenant() {
    await this.postTenant.call(this.tenantRequestData);

    if (this.postTenant.isSuccessful) {
      const tenantId = (this.postTenant.responseData as PostTenantResponse).id;

      this.closeModal();

      eventHub.emit("TENANT_CREATED", tenantId);
      eventHub.emit("TENANT_VIEW_REQUESTED", tenantId);
    }
  }

  createEmptyTenantRequestData(): PostTenantRequestData {
    return {
      firstName: "",
      lastName: "",
      registrationId: "",
      phoneNumber: "",
      bpg: ""
    };
  }

  closeModal() {
    // this.postTenant.clear();
    this.displayDialog = false;
  }

  get isDataLoading(): boolean {
    return this.postTenant.isBusy;
  }
}
