<template><div v-if="false"></div></template>

<script lang="ts">
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
import { UserRole, User } from "@/modules/user/types";
import { Options, Vue } from "vue-class-component";

@Options({
  watch: {
    "$route.name"() {
      this.onRouteNameChanged();
    }
  }
})
export default class AppHomepageRouteWatcher extends Vue {
  store = useStore();
  router = useRouter();
  route = useRoute();

  created() {
    this.reRouteUser();
  }

  onRouteNameChanged() {
    if (this.route.name !== "homepage") {
      return;
    }

    this.reRouteUser();
  }

  async reRouteUser() {
    const user: User = this.store.getters.user;

    if (!user) {
      await this.router.push({ name: "login" });
    }

    if (this.route.name == "homepage") {
      if (user.hasRole(UserRole.Clerk)) {
        await this.router.push({
          name: "administrator"
        });
        return;
      }
      if (user.hasRole(UserRole.Cashier)) {
        await this.router.push({
          name: "cashier"
        });
        return;
      }
      if (user.hasRole(UserRole.Tenant)) {
        await this.router.push({
          name: "tenant"
        });
        return;
      }
      await this.router.push({
        name: "profile"
      });
    }
  }
}
</script>
