
import { Vue } from "vue-class-component";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import { PostWorkOrderRequestData } from "@/modules/work-orders/api/types";
import AsyncActionFactory from "@/components/AsyncAction";
import WorkOrderApi from "@/modules/work-orders/api";
import { FetchMarketsParams } from "@/modules/market/api/types";
import MarketApi from "@/modules/market/api";
import UserApi from "@/modules/user/api";
import { Market } from "@/modules/market/types";
import { FetchUsersParams } from "@/modules/user/api/types";
import { User } from "@/modules/user/types";
import { useRouter } from "@/components/Router";

export default class CreateWorkOrderForm extends Vue {
  workOrderRequestData: PostWorkOrderRequestData = {
    date: "",
    marketId: null,
    cashierId: null
  };
  errors!: BackendValidationErrors;
  markets: Array<Market> = [];
  cashiers: Array<User> = [];

  fetchMarkets = AsyncActionFactory(MarketApi.fetchMarkets);
  fetchUsers = AsyncActionFactory(UserApi.fetchUsers);
  postWorkOrder = AsyncActionFactory(WorkOrderApi.postWorkOrder);

  async created() {
    await this.loadMarkets();
    await this.loadCashiers();
  }

  async createWorkOrder() {
    await this.postWorkOrder.call(this.workOrderRequestData);

    if (this.postWorkOrder.isSuccessful) {
      await useRouter().push({
        name: "administrator-work-orders"
      });
    }
  }

  async cancel() {
    await useRouter().push({
      name: "administrator-work-orders"
    });
  }

  async loadMarkets() {
    await this.fetchMarkets.call({
      perPage: -1
    } as FetchMarketsParams);

    if (this.fetchMarkets.isSuccessful) {
      this.markets = this.fetchMarkets.responseData
        ? this.fetchMarkets.responseData.data
        : [];
    }
  }

  async loadCashiers() {
    await this.fetchUsers.call({
      perPage: -1,
      role: "cashier"
    } as FetchUsersParams);

    if (this.fetchUsers.isSuccessful) {
      this.cashiers = this.fetchUsers.responseData
        ? this.fetchUsers.responseData.data
        : [];
    }
  }
}
