<template>
  <CreateModal
    v-if="displayDialog"
    @close="closeModal()"
    title="Nova Kategorija Prodajnog Objekta"
    @confirm="createSellingPlaceCategory()"
    :showSpinner="isDataLoading"
  >
    <SellingPlaceCategoryForm
      v-model:selling-place-category="sellingPlaceCategoryData"
      :errors="postSellingPlaceCategoryAction.errors"
    />
  </CreateModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import eventHub from "@/components/EventHub";
import CreateModal from "@/modules/common/modals/CreateModal.vue";
import AsyncActionFactory from "@/components/AsyncAction";
import SellingPlaceCategoryApi from "@/modules/selling-place-category/api";
import SellingPlaceCategoryForm from "@/modules/selling-place-category/forms/SellingPlaceCategoryForm.vue";
import {
  FetchSellingPlaceCategory,
  NewSellingPlaceCategoryRequestData,
  PostSellingPlaceCategoryResponse
} from "@/modules/selling-place-category/api/types";
import SellingPlaceCategoryNewRequestedEvent from "@/modules/selling-place-category/events/SellingPlaceCategoryNewRequestedEvent";
import SellingPlaceCategoryCreatedEvent from "@/modules/selling-place-category/events/SellingPlaceCategoryCreatedEvent";

@Options({
  components: { CreateModal, SellingPlaceCategoryForm }
})
export default class SellingPlaceCategoryCreateRequestedListener extends Vue {
  displayDialog = false;
  sellingPlaceCategoryData: NewSellingPlaceCategoryRequestData = this.createEmptySellingPlaceCategoryRequestData();

  postSellingPlaceCategoryAction = AsyncActionFactory(
    SellingPlaceCategoryApi.createSellingPlaceCategory
  );

  fetchSellingPlaceCategory = AsyncActionFactory(
    SellingPlaceCategoryApi.fetchSellingPlaceCategory as FetchSellingPlaceCategory
  );

  created() {
    eventHub.on<SellingPlaceCategoryNewRequestedEvent>(
      SellingPlaceCategoryNewRequestedEvent.Name,
      this.onSellingPlaceCategoryNewRequestedEvent
    );
  }

  onSellingPlaceCategoryNewRequestedEvent(
    event?: SellingPlaceCategoryNewRequestedEvent
  ) {
    if (!(event instanceof SellingPlaceCategoryNewRequestedEvent)) {
      return;
    }
    this.displayDialog = true;
  }

  closeModal() {
    this.displayDialog = false;
  }

  async createSellingPlaceCategory() {
    await this.postSellingPlaceCategoryAction.call(
      this.sellingPlaceCategoryData
    );

    if (this.postSellingPlaceCategoryAction.isSuccessful) {
      await this.emitSellingPlaceCategory(
        (this.postSellingPlaceCategoryAction
          .responseData as PostSellingPlaceCategoryResponse).id
      );
      this.closeModal();
    }
  }

  async emitSellingPlaceCategory(sellingPlaceCategoryId) {
    const sellingPlaceCategory = await this.fetchSellingPlaceCategory.call(
      sellingPlaceCategoryId
    );

    if (this.fetchSellingPlaceCategory.isSuccessful) {
      eventHub.emit<SellingPlaceCategoryCreatedEvent>(
        SellingPlaceCategoryCreatedEvent.Name,
        new SellingPlaceCategoryCreatedEvent(sellingPlaceCategory)
      );
    }
  }

  createEmptySellingPlaceCategoryRequestData(): NewSellingPlaceCategoryRequestData {
    return {
      name: "",
      description: "",
      icon: ""
    };
  }

  get isDataLoading(): boolean {
    return this.postSellingPlaceCategoryAction.isBusy;
  }
}
</script>
