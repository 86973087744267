import { BreadcrumbItem, BreadcrumbItems } from "@/modules/common/types";

import getBasicBreadcrumbItems from "@/structure/Administrator/common/utils/getBreadcrumbItems.ts";
import { Market } from "@/modules/market/types";

function createMarketsItem(): BreadcrumbItem {
  return {
    name: "Pijace",
    link: {
      name: "cashier-markets"
    }
  };
}

function createMarketItem(market: Market): BreadcrumbItem {
  return {
    name: market.name,
    link: {
      name: "cashier-market",
      params: {
        marketId: market.id
      }
    }
  };
}

const getBreadcrumbItems = function(moduleName: string, market?: Market) {
  const items: BreadcrumbItems = [];

  items.push(createMarketsItem());
  if (market) {
    items.push(createMarketItem(market));
  }

  return getBasicBreadcrumbItems(moduleName, items);
};

export default getBreadcrumbItems;
