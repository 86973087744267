<template>
  <PageLayout>
    <template v-slot:page-breadcrumbs>
      <Breadcrumbs :items="breadcrumbItems" />
    </template>
    <template v-slot:page-name>
      <PageHeaderInfoWidget :header-info="headerInfo" />
    </template>
    <template v-slot:page-widgets>
      <ContractDetailsHeaderWidgets />
    </template>
    <template v-slot:page-tabs>
      <TabBarWidget :items="tabItems" />
    </template>
    <h4>Detalji Zakupca</h4>
    <TenantDetailsWidget :tenant="contract.tenantDetails" />
    <br />
  </PageLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PageLayout from "@/structure/Administrator/Contracts/layout/PageLayout.vue";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import { HeaderInfo, TabItems } from "@/modules/common/types";
import Breadcrumbs from "@/modules/common/widgets/BreadcrumbsWidget.vue";
import { BreadcrumbItems } from "@/modules/common/types";
import getBreadcrumbItems from "../utils/getBreadcrumbItems";
import { Contract } from "@/modules/contracts/types";
import { useStore } from "@/store";
import ContractDetailsHeaderWidgets from "../widgets/ContractDetailsHeaderWidgets.vue";
import TenantDetailsWidget from "@/modules/tenant/widgets/TenantDetailsWidget.vue";
import TabBarWidget from "@/modules/common/widgets/TabBarWidget.vue";
import getTabItems from "@/structure/Administrator/Contracts/utils/getTabItems";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    Breadcrumbs,
    ContractDetailsHeaderWidgets,
    TenantDetailsWidget,
    TabBarWidget
  }
})
export default class ContractsDetailsPage extends Vue {
  store = useStore();

  get contract(): Contract {
    return this.store.getters.contract;
  }

  get headerInfo(): HeaderInfo {
    return {
      icon: "ion ion-md-document",
      section: this.contract ? this.contract.number : ""
    };
  }

  get tabItems(): TabItems {
    return getTabItems(this.contract);
  }

  get breadcrumbItems(): BreadcrumbItems {
    return getBreadcrumbItems("Detalji", this.contract);
  }
}
</script>
