import { Link, Links } from "@/modules/common/types";

function createDashboardItem(): Link {
  return {
    type: "link",
    name: "Početna",
    linkRoute: {
      name: "administrator-dashboard"
    },
    icon: "ion ion-md-home"
  };
}

function createAllMarketsItem(): Link {
  return {
    type: "link",
    name: "Pijace",
    linkRoute: {
      name: "administrator-markets"
    },
    icon: "ion ion-md-cube"
  };
}


function createAllSellingPlacesItem(): Link {
  return {
    type: "link",
    name: "Prodajna Mesta",
    linkRoute: {
      name: "administrator-selling-places"
    },
    icon: "ion ion-md-cube"
  };
}

// function createNewMarketItem(): Link {
//   return {
//     type: "link",
//     name: "New Market",
//     linkRoute: {
//       name: "administrator-markets-new"
//     },
//     icon: "ion ion-md-add"
//   };
// }

function createTransactionsItem(): Link {
  return {
    type: "link",
    name: "Transakcije",
    linkRoute: {
      name: "administrator-transactions"
    },
    icon: "ion ion-md-cash"
  };
}

function createWorkOrdersItem(): Link {
  return {
    type: "link",
    name: "Radni Nalozi",
    linkRoute: {
      name: "administrator-work-orders"
    },
    icon: "ion ion-md-document"
  };
}

function createTenantsItem(): Link {
  return {
    type: "link",
    name: "Zakupci",
    linkRoute: {
      name: "administrator-tenants"
    },
    icon: "ion ion-md-people"
  };
}

function createContractsItem(): Link {
  return {
    type: "link",
    name: "Ugovori",
    linkRoute: {
      name: "administrator-contracts"
    },
    icon: "ion ion-md-document"
  };
}

function createReportsItem(): Link {
  return {
    type: "link",
    name: "izveštaji",
    linkRoute: {
      name: "administrator-reports"
    },
    icon: "ion ion-md-document"
  };
}

function createSettingsItem(): Link {
  return {
    type: "link",
    name: "Podešavanja",
    linkRoute: {
      name: "administrator-settings"
    },
    icon: "ion ion-md-settings"
  };
}

function createDividerItem(): Link {
  return {
    type: "separator"
  };
}

export function getNavigationLinks(): Links {
  const items: Links = [];

  items.push(createDashboardItem());
  items.push(createDividerItem());
  items.push(createTransactionsItem());
  items.push(createWorkOrdersItem());
  items.push(createContractsItem());
  items.push(createDividerItem());
  items.push(createAllMarketsItem());
  items.push(createAllSellingPlacesItem());
  items.push(createTenantsItem());
  // items.push(createNewMarketItem());
  items.push(createDividerItem());
  items.push(createReportsItem());
  items.push(createDividerItem());
  items.push(createSettingsItem());

  return items;
}
