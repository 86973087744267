import { PostUserResponse } from "./types";
import {CashierReportList, User, UserList} from "@/modules/user/types";

export function userMapper(userData): User {
  const user = new User();

  user.id = userData.id;
  user.email = userData.email;
  user.firstName = userData.firstName;
  user.lastName = userData.lastName;
  user.active = userData.active;
  user.imageUrl = userData.imageUrl ? userData.imageUrl : "/img/person.png";
  user.roles = userData.roles;
  user.address = userData.address;

  return user;
}

export function userListMapper(userList): UserList {
  userList.data = userList.data.map(userData => userMapper(userData));
  return userList;
}

export function postUserResponseMapper(response): PostUserResponse {
  return {
    ...response
  };
}

export function cashierReportsListMapper(cashierReportsList): CashierReportList {
  return cashierReportsList;
}
