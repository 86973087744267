
import { Options, Vue } from "vue-class-component";
import PageLayout from "../layout/PageLayout.vue";
import LoginWidget from "@/structure/Authentication/widgets/LoginWidget.vue";
import { Login, LoginRequestData } from "@/modules/authentication/api/types";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import { inject } from "vue";
import { useRouter } from "vue-router";

@Options({
  components: { PageLayout, LoginWidget }
})
export default class LoginPage extends Vue {
  showErrorMessage = false;
  router = useRouter();

  loginAction = AsyncActionFactory(inject("login") as Login);

  async onLogin(requestData: LoginRequestData) {
    await this.loginAction.call(requestData);

    if (this.loginAction.isSuccessful) {
      await this.router.push({
        name: "homepage"
      });
    }
    if (!this.loginAction.isSuccessful) {
      this.showErrorMessage = true;
    }
  }
}
