<template>
  <div class="form-group">
    <EmailInput v-model="userData.email" :errors="errors" />
  </div>
  <div class="form-group">
    <TextInput
      field-name="firstName"
      label="Ime"
      v-model.trim="userData.firstName"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="lastName"
      label="Prezime"
      v-model.trim="userData.lastName"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="password"
      label="Lozinka"
      type="password"
      v-model.trim="userData.password.first"
      :errors="errors"
    />
  </div>
  <div class="form-group">
    <TextInput
      field-name="repeatedPassword"
      label="Potvrda Lozinke"
      type="password"
      v-model.trim="userData.password.second"
      :errors="errors"
    />
  </div>
  <div class="form-group">
<!--        <RoleSelector v-model:role="userData.role" :errors="errors" />-->
    <!--    todo: refactor this and then uncomment when finished-->
    <label class="form-label">Uloga</label>
    <select
        class="form-control form-control"
        v-model="userData.role"
    >
      <option value="ROLE_SUPER_ADMIN">
        Super Admin
      </option>
      <option value="ROLE_ADMIN">
        Admin
      </option>
      <option value="ROLE_CASHIER">
        Inkasant
      </option>
      <option value="ROLE_TENANT">
        Zakupac
      </option>
    </select>
  </div>
  <div class="form-group">
    <label class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        v-model="userData.active"
      />
      <div class="form-check-label">
        Odobren Pristup
      </div>
    </label>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import RoleSelector from "@/modules/user/widgets/RoleSelector.vue";
import { NewUserRequestData } from "@/modules/user/api/types";
import TextInput from "@/modules/common/forms/TextInput.vue";
import EmailInput from "@/modules/common/forms/EmailInput.vue";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";

@Options({
  components: { TextInput, EmailInput, RoleSelector },
  props: {
    userData: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  }
})
export default class CreateUserModal extends Vue {
  userData!: NewUserRequestData;
  errors!: BackendValidationErrors;
}
</script>
