<template>
  <PageLayout>
    <template v-slot:page-name>
      <PageHeaderInfoWidget :header-info="headerInfo" />
    </template>
    <template v-slot:page-widgets>
      <HeaderWidgets />
    </template>

    <h5>Promena Lozinke</h5>

    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label>Nova Lozinka</label>
          <input
            class="form-control"
            :class="{ 'is-invalid': internalError }"
            v-model="passwordChangeData.password"
          />
          <small v-if="internalError" class="invalid-feedback">
            {{ internalErrorMessage }}
          </small>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Potvrda Lozinke</label>
          <input
            class="form-control"
            :class="{ 'is-invalid': internalError }"
            v-model="passwordChangeData.passwordConfirmation"
          />
        </div>
      </div>
      <div class="col-6">
        <button type="submit" class="btn btn-primary" @click="submit">
          Sačuvaj
        </button>
      </div>
    </div>
  </PageLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PageLayout from "../layout/PageLayout.vue";
import { HeaderInfo } from "@/modules/common/types";
import PageHeaderInfoWidget from "@/modules/common/widgets/PageHeaderInfoWidget.vue";
import HeaderWidgets from "../widgets/HeaderWidgets.vue";
import { useStore } from "@/store";
import { User } from "@/modules/user/types";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import { inject } from "vue";
import {
  PasswordChange,
  PasswordChangeRequestData
} from "@/modules/account/api/types";
import { useRouter } from "vue-router";

@Options({
  components: {
    PageLayout,
    PageHeaderInfoWidget,
    HeaderWidgets
  }
})
export default class extends Vue {
  passwordChangeData: PasswordChangeRequestData = {
    password: "",
    passwordConfirmation: ""
  };

  internalError = false;
  internalErrorMessage?: string;
  showErrorMessage = false;

  store = useStore();
  router = useRouter();
  passwordChangeAction = AsyncActionFactory(
    inject("changePassword") as PasswordChange
  );

  get user(): User {
    return this.store.getters.user;
  }

  headerInfo: HeaderInfo = {
    icon: "ion ion-md-person",
    section: this.user ? this.user.firstName + " " + this.user.lastName : ""
  };

  async submit() {
    if (this.validate()) {
      this.internalError = false;
      this.internalErrorMessage = undefined;

      await this.passwordChangeAction.call(this.passwordChangeData);

      if (this.passwordChangeAction.isSuccessful) {
        this.passwordChangeData.password = "";
        this.passwordChangeData.passwordConfirmation = "";
      }

      if (!this.passwordChangeAction.isSuccessful) {
        this.showErrorMessage = true;
      }
    }
  }

  validate() {
    if (this.passwordChangeData.password === "") {
      this.internalError = true;
      this.internalErrorMessage = "This field can't be empty";
      return false;
    } else if (
      this.passwordChangeData.password !==
      this.passwordChangeData.passwordConfirmation
    ) {
      this.internalError = true;
      this.internalErrorMessage =
        "Password confirmation must be the same as password";
      return false;
    }
    return true;
  }
}
</script>
