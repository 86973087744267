<template>
  <DeleteWorkOrderRequestListener />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import DeleteWorkOrderRequestListener from "./listeners/DeleteWorkOrderRequestListener.vue";

@Options({
  components: {
    DeleteWorkOrderRequestListener
  }
})
export default class Main extends Vue {}
</script>
