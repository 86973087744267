
import { Options, Vue } from "vue-class-component";
import TextInput from "@/modules/common/forms/TextInput.vue";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import { NewContractNumberingRuleRequestData } from "../api/types";

@Options({
  components: { TextInput },
  props: {
    contractNumberingRule: {
      type: Object,
      required: true
    }
  },
  errors: {
    type: Object,
    required: false,
    default: new BackendValidationErrors()
  }
})
export default class ContractNumberingRuleForm extends Vue {
  contractNumberingRule!: NewContractNumberingRuleRequestData;
  errors!: BackendValidationErrors;
}
