<template>
  <ContractDrafterDetailsPage />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ContractDrafterDetailsPage from "@/structure/Administrator/Contracts/pages/ContractDrafterDetailsPage.vue";

@Options({
  components: { ContractDrafterDetailsPage }
})
export default class Drafter extends Vue {}
</script>
