import { RouteRecordRaw } from "vue-router";
import PassThroughViewReports from "./widgets/PassThroughViewReports.vue";
import Reports from "@/structure/Administrator/Reports/views/Reports.vue";
import Cashiers from "@/structure/Administrator/Reports/views/Cashiers.vue";
import SellingPlaces from "@/structure/Administrator/Reports/views/SellingPlaces.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "reports",
    name: "administrator-reports",
    component: PassThroughViewReports,
    redirect: () => ({
      name: "administrator-reports-selling-places"
    }),
    children: [
      {
        path: "",
        name: "administrator-reports-homepage",
        component: Reports,
        meta: {
          title: "Izveštaji"
        },
      },
      {
        path: "",
        name: "administrator-reports-selling-places",
        component: SellingPlaces,
        meta: {
          title: "Prodajni Objekti"
        }
      },
      {
        path: "",
        name: "administrator-reports-cashiers",
        component: Cashiers,
        meta: {
          title: "Inkasanti"
        }
      }
    ]
  }
];

export default routes;
