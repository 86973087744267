<template>
  <div
    class="d-flex align-items-center p-3 border-bottom justify-content-between"
  >
    <div class="d-flex align-items-center">
      <i class="mr-2 fa fa-user"></i>
      <div>
        <h5 class="mb-0">
          {{ cashierReport.firstName }} {{ cashierReport.lastName }}
          <small>{{  cashierReport.email }}</small>
        </h5>
        <p class="mb-0">
          Ukupno Naplaćeno: {{ cashierReport.totalSold }} ({{
            cashierReport.totalAmount / 100
          }}
          RSD), Naplaćeno Dnevnih Zakupa: {{ cashierReport.dailySold }} ({{
            cashierReport.dailyTotalAmount / 100
          }}
          RSD), Naplaćeno Mesečnih Rezervacija:
          {{ cashierReport.monthlySold }} ({{
            cashierReport.monthlyTotalAmount / 100
          }}
          RSD)
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { CashierReport } from "@/modules/user/types/CashierReport";

@Options({
  props: {
    cashierReport: {
      type: Object,
      required: true,
      default: {}
    }
  }
})
export default class CashierReportListItemWidget extends Vue {
  cashierReport!: CashierReport;
}
</script>
