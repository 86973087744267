import { Links } from "@/modules/common/types";

export function getProfileMenuLinks(): Links {
  return [
    {
      type: "link",
      name: "Moj Profil",
      icon: "ion ion-ios-person",
      linkRoute: {
        name: "profile"
      },
      active: false
    },
    {
      type: "separator"
    },
    {
      type: "link",
      name: "Odjava",
      icon: "ion ion-ios-log-out",
      linkRoute: {
        name: "logout"
      },
      active: false
    }
  ];
}
