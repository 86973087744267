
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction";
import eventHub from "@/components/EventHub";
import EditModal from "@/modules/common/modals/EditModal.vue";
import EditSellingPlaceForm from "@/modules/selling-places/forms/EditSellingPlaceForm.vue";
import { SellingPlace } from "@/modules/selling-places/types";
import {
  EditSellingPlaceRequestData,
  FetchSellingPlace
} from "@/modules/selling-places/api/types";
import SellingPlaceApi from "@/modules/selling-places/api";

@Options({
  components: {
    EditModal,
    EditSellingPlaceForm
  }
})
export default class EditSellingPlaceDetailsRequestListener extends Vue {
  sellingPlace!: SellingPlace;
  sellingPlaceRequestData!: EditSellingPlaceRequestData;

  displayDialog = false;

  editSellingPlaceAction = AsyncActionFactory(SellingPlaceApi.editSellingPlace);
  fetchSellingPlace = AsyncActionFactory(
    SellingPlaceApi.fetchSellingPlace as FetchSellingPlace
  );

  async editSellingPlace() {
    await this.editSellingPlaceAction.call(
      this.sellingPlace.id,
      this.sellingPlaceRequestData
    );

    if (this.editSellingPlaceAction.isSuccessful) {
      eventHub.emit("SELLING_PLACE_EDITED", this.sellingPlace.id);
      this.closeModal();
    }
  }

  async created() {
    eventHub.on(
      "EDIT_SELLING_PLACE_DETAILS_REQUESTED",
      this.onEditSellingPlaceRequested
    );
  }

  async onEditSellingPlaceRequested(sellingPlaceId) {
    await this.fetchSellingPlace.call(sellingPlaceId);

    if (this.fetchSellingPlace.isSuccessful) {
      this.sellingPlace = this.fetchSellingPlace.responseData as SellingPlace;
      this.sellingPlaceRequestData = this.createEditSellingPlaceRequestData();
      this.displayDialog = true;
    }
  }

  createEditSellingPlaceRequestData(): EditSellingPlaceRequestData {
    return {
      name: this.sellingPlace.name,
      marketId: this.sellingPlace.market.id,
      sellingPlaceTypeId: this.sellingPlace.type.id,
      sellingPlaceCategoryId: this.sellingPlace.category.id
    };
  }

  closeModal() {
    this.displayDialog = false;
    this.editSellingPlaceAction.clear();
  }

  get isDataLoading(): boolean {
    return this.editSellingPlaceAction.isBusy || this.fetchSellingPlace.isBusy;
  }
}
