<template>
  <EditModal
    v-if="displayDialog"
    @close="closeModal()"
    title="Izmeni Market"
    @confirm="editMarket()"
    :showSpinner="isDataLoading"
  >
    <EditMarketDetailsForm
      v-model:request-data="marketRequestData"
      :errors="editMarketAction.errors"
    />
  </EditModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import EditModal from "@/modules/common/modals/EditModal.vue";
import { EditMarketRequestData, FetchMarket } from "@/modules/market/api/types";
import AsyncActionFactory from "@/components/AsyncAction";
import MarketApi from "@/modules/market/api";
import EditMarketDetailsForm from "@/modules/market/forms/EditMarketDetailsForm.vue";
import eventHub from "@/components/EventHub";
import { Market } from "@/modules/market/types";

@Options({
  components: { EditModal, EditMarketDetailsForm }
})
export default class EditMarketDetailsRequestListener extends Vue {
  market!: Market;
  marketRequestData!: EditMarketRequestData;

  displayDialog = false;

  editMarketAction = AsyncActionFactory(MarketApi.editMarket);
  fetchMarket = AsyncActionFactory(MarketApi.fetchMarket as FetchMarket);

  async created() {
    eventHub.on("EDIT_MARKET_DETAILS_REQUESTED", this.onEditMarketRequested);
  }

  async editMarket() {
    await this.editMarketAction.call(this.market.id, this.marketRequestData);

    if (this.editMarketAction.isSuccessful) {
      eventHub.emit("MARKET_EDITED", this.market.id);
      this.closeModal();
    }
  }

  async onEditMarketRequested(marketId) {
    await this.fetchMarket.call(marketId);

    if (this.fetchMarket.isSuccessful) {
      this.market = this.fetchMarket.responseData as Market;
      this.marketRequestData = this.createEditMarketRequestData();
      this.displayDialog = true;
    }
  }

  createEditMarketRequestData(): EditMarketRequestData {
    return {
      name: this.market.name,
      description: this.market.description,
      icon: this.market.icon
    };
  }

  closeModal() {
    this.displayDialog = false;
    this.editMarketAction.clear();
  }

  get isDataLoading(): boolean {
    return this.editMarketAction.isBusy || this.fetchMarket.isBusy;
  }
}
</script>
