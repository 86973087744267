
import { Options, Vue } from "vue-class-component";
import TextInput from "@/modules/common/forms/TextInput.vue";
import BackendValidationErrors from "@/components/AsyncAction/Errors/BackendValidationErrors";
import { Tenant, TenantList } from "@/modules/tenant/types";
import TenantApi from "../../tenant/api";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import { FetchTenants, FetchTenantsParams } from "@/modules/tenant/api/types";
import SellingPlaceApi from "../../selling-place/api";
import { FetchAllSellingPlaces } from "@/modules/selling-place/api/types";
import { SellingPlace, SellingPlaceList } from "@/modules/selling-place/types";
import { MonthPickerInput } from "vue-month-picker";
import { EditContractRequestData } from "@/modules/contracts/api/types";
import ContractTypeApi from "@/modules/contracts/api";

@Options({
  components: { TextInput, MonthPickerInput },
  props: {
    contractRequestData: {
      type: Object,
      required: true
    },
    validationErrors: {
      type: Object,
      required: false,
      default: new BackendValidationErrors()
    }
  }
})
export default class EditContractForm extends Vue {
  validationErrors!: BackendValidationErrors;
  allTenants: Array<Tenant> = [];
  allSellingPlaces: Array<SellingPlace> = [];
  rentStartDate = {
    from: null,
    to: null,
    month: null,
    year: 2020,
    monthIndex: 7
  };
  rentEndDate = {
    from: null,
    to: null,
    month: null,
    year: null
  };
  startDate: string | any = {};
  endDate: string | any = {};
  contractRequestData!: EditContractRequestData;

  fetchTenants = AsyncActionFactory(TenantApi.fetchAllTenants as FetchTenants);
  fetchSellingPlaces = AsyncActionFactory(
    SellingPlaceApi.fetchAllSellingPlaces as FetchAllSellingPlaces
  );
  postContractTypeAction = AsyncActionFactory(
    ContractTypeApi.createContractType
  );

  showStartDate(date) {
    this.rentStartDate = date;
    this.contractRequestData.rentStartDate = date.to;
  }

  showEndDate(date) {
    this.rentEndDate = date;
    this.contractRequestData.rentEndDate = date.to;
  }

  async created() {
    await this.loadTenants();
    await this.loadSellingPlaces();
    this.startDate.data = new Date(this.contractRequestData.rentStartDate);
    this.startDate.year = this.startDate.data.getFullYear();
    this.startDate.monthIndex = this.startDate.data.getMonth();
    this.endDate.data = new Date(this.contractRequestData.rentEndDate);
    this.endDate.year = this.endDate.data.getFullYear();
    this.endDate.monthIndex = this.endDate.data.getMonth();
  }

  async loadTenants() {
    await this.fetchTenants.call({
      perPage: -1
    } as FetchTenantsParams);
    if (this.fetchTenants.isSuccessful) {
      this.allTenants = (this.fetchTenants.responseData as TenantList).data;
    }
  }
  async loadSellingPlaces() {
    await this.fetchSellingPlaces.call();
    if (this.fetchSellingPlaces.isSuccessful) {
      this.allSellingPlaces = (this.fetchSellingPlaces
        .responseData as SellingPlaceList).data;
    }
  }
}
