
import { Options, Vue } from "vue-class-component";
import AsyncActionFactory from "@/components/AsyncAction/AsyncActionFactory";
import Spinner from "@/modules/common/widgets/SpinnerWidget.vue";
import ListWidget from "@/modules/common/widgets/ListWidget";
import CashierApi from "@/modules/user/api";
import { MonthPickerInput } from "vue-month-picker";
import { CashierReportList } from "@/modules/user/types";
import { emptyCashierReportsList } from "@/modules/user/types/emptyCashierReportsList";
import { FetchCashierReportsParams } from "@/modules/user/api/types";
import CashierReportListItemWidget from "@/modules/user/widgets/UserList/CashierReportListItemWidget.vue";
import { MarketList } from "@/modules/market/types";
import { emptyMarketList } from "@/modules/market/types/EmptyMarketList";
import MarketApi from "@/modules/market/api";
import { FetchMarketsParams } from "@/modules/market/api/types";

@Options({
  components: {
    Spinner,
    ListWidget,
    MonthPickerInput,
    CashierReportListItemWidget
  },
  watch: {
    date: {
      handler(newDate) {
        this.params.from = newDate.from;
        this.params.to = newDate.to;
      },
      deep: true
    }
  }
})
export default class CashierReportsListWidget extends Vue {
  date = new Date();
  first = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
  last = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
  marketList: MarketList = emptyMarketList;
  cashierReportsList: CashierReportList = emptyCashierReportsList;
  params: FetchCashierReportsParams = {
    from: this.formatDate(this.first),
    to: this.formatDate(this.last),
    market: undefined
  };

  fetchReports = AsyncActionFactory(CashierApi.fetchCashierReports);
  fetchMarketsAction = AsyncActionFactory(MarketApi.fetchMarkets);

  async created() {
    this.loadData();
    this.fetchMarkets();
  }

  async loadData() {
    await this.fetchReports.call(this.params);

    if (this.fetchReports.isSuccessful) {
      this.cashierReportsList = this.fetchReports.responseData;
    }
  }

  get isDataLoading(): boolean {
    return this.fetchReports.isBusy;
  }

  async fetchMarkets() {
    await this.fetchMarketsAction.call({
      perPage: -1
    } as FetchMarketsParams);

    if (this.fetchMarketsAction.isSuccessful) {
      const response = this.fetchMarketsAction.responseData as MarketList;
      if (response.data.length > 0) {
        this.marketList = response;
      }
    }
  }

  formatDate(date) {
    const d = date,
      year = d.getFullYear();

    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  printDiv() {
    const printContents = document.getElementById("printMe").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
}
