import {
  createRouter,
  createWebHistory,
  Router,
  RouteRecordRaw
} from "vue-router";
import applicationRoutes from "@/structure/routes";

const routes: Array<RouteRecordRaw> = [...applicationRoutes];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export function useRouter(): Router {
  return router;
}

export default router;
